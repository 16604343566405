import React from 'react';
import { AppointmentModel } from '../../../../api/models';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Stack, Typography } from '@mui/joy';
import moment from 'moment';
import { getTranslatedAppointmentStatus } from '../../../../api/utils';
import { AppointmentActionsModal } from '../../../SlotsPage/SlotList/AppointmentModal/AppointmentActionsModal';
import { dateToLocaleString } from '../../../../utils/stringUtils';

type Props = {
    appointment: AppointmentModel;
    reloadAppointments: () => void;
};
export const AppointmentDetailsTab = ({ appointment, reloadAppointments }: Props) => {
    const { t } = useTranslation();
    const dateFromMoment = moment(appointment.date_from);
    const dateUpdated = moment(appointment.updated_at);

    const getCapitalizedStatus = (): string => {
        const status = getTranslatedAppointmentStatus(appointment, t);
        return status[0].toUpperCase() + status.substring(1);
    };

    return (
        <Stack spacing={1} marginX={-2}>
            <Grid container spacing={2}>
                <Grid xs={12} width="100%">
                    <Typography level="body-md" fontWeight="bold" fontSize={16}>
                        {t('appointmentDetails:title_patient')}
                    </Typography>
                    <Divider style={{ marginBottom: 10 }} />
                    <Grid container width="100%" spacing={1}>
                        <Grid textAlign="left" width="50%">
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_full_name')}</Typography>
                                <Typography>{appointment.patient_metadata.full_name}</Typography>
                            </div>
                            <div>
                                <Typography level="body-xs">{t('appointmentDetails:label_email')}</Typography>
                                <Typography
                                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                                >
                                    {appointment?.patient_metadata.email || '-'}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid width="50%">
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_phone_number')}</Typography>
                                <Typography>{appointment.patient_metadata.phone || '-'}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_date_of_birth')}</Typography>
                                <Typography>
                                    {dateToLocaleString(appointment.patient_metadata.date_of_birth) || '-'}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} width="100%">
                    <Typography level="body-md" fontWeight="bold" fontSize={16}>
                        {t('appointmentDetails:title_general')}
                    </Typography>
                    <Divider style={{ marginBottom: 10 }} />
                    <Grid container width="100%">
                        <Grid textAlign="left" width="50%">
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_id')}</Typography>
                                <Typography>{appointment?.id}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_service_name')}</Typography>
                                <Typography>{appointment.healthcare_service.name}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_time')}</Typography>
                                <Typography>
                                    <span>{dateToLocaleString(appointment.date_from)}</span>
                                </Typography>
                                <Typography>
                                    <span>{dateFromMoment.format('HH:mm')}</span>{' '}
                                    <span>{dateFromMoment.format('dddd')}</span>
                                </Typography>
                            </div>
                        </Grid>
                        <Grid width="50%">
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_status')}</Typography>
                                <Typography>{getCapitalizedStatus()}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_last_change')}</Typography>
                                <Typography>
                                    <span>{dateToLocaleString(appointment.updated_at)}</span>
                                </Typography>
                                <Typography>
                                    <span>{dateUpdated.format('HH:mm')}</span> <span>{dateUpdated.format('dddd')}</span>
                                </Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('appointmentDetails:label_message')}</Typography>
                                <Typography>{appointment.comment || '-'}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AppointmentActionsModal
                appointment={appointment}
                reloadSchedules={reloadAppointments}
                button={<Button fullWidth>{t('buttons:button_actions')}</Button>}
            />
        </Stack>
    );
};
