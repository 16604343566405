import React, { FC, ReactNode, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalClose, ModalDialog, Stack } from '@mui/joy';
import '../styles/hideScroll.css';
export interface ModalState {
    toggleModal: () => void;
    isOpen: boolean;
}

interface Props {
    state: ModalState;
    body?: ReactNode | string;
    button?: ReactNode;
    title: ReactNode | string;
    actions?: ReactNode;
    maxWidth?: number;
    minWidth?: number | string;
    className?: string;
    disabled?: boolean;
    style?: React.CSSProperties;
    layout?: ModalLayout;
    closeOnlyOnSubmit?: boolean;
    onClose?: () => void;
}
type ModalLayout = 'center' | 'fullscreen' | undefined;
export const BetterModal: FC<Props> = ({
    body,
    button,
    state,
    className,
    title,
    maxWidth = 500,
    minWidth = 'auto',
    actions,
    closeOnlyOnSubmit = false,
    disabled = false,
    layout = 'center',
    onClose = state.toggleModal,
}: Props) => {
    return (
        <div className={className} style={{ position: button === undefined ? 'absolute' : 'relative' }}>
            {button && (
                <div
                    onClick={() => {
                        if (disabled) return;
                        state.toggleModal();
                    }}
                    style={{
                        cursor: disabled ? '' : 'pointer',
                    }}
                >
                    {button}
                </div>
            )}
            {state.isOpen && (
                <Modal
                    onClose={
                        closeOnlyOnSubmit
                            ? () => {
                                  return;
                              }
                            : onClose
                    }
                    open={state.isOpen}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <ModalDialog layout={layout} variant="outlined" minWidth={minWidth} maxWidth={maxWidth}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            {!closeOnlyOnSubmit && <ModalClose variant="plain" />}
                            <DialogTitle level="h3">{title}</DialogTitle>
                        </Stack>
                        <Divider />
                        {body && (
                            <DialogContent>
                                {
                                    <div className="hideScroll" style={{ overflowY: 'scroll' }}>
                                        {body}
                                    </div>
                                }
                            </DialogContent>
                        )}
                        {actions && (
                            <DialogActions sx={{ marginTop: layout === 'center' ? -2.5 : -7.5 }}>
                                {actions}
                            </DialogActions>
                        )}
                    </ModalDialog>
                </Modal>
            )}
        </div>
    );
};
