import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { startOfWeek, endOfWeek, addWeeks, addDays, isSameDay, parseISO } from 'date-fns';
import { useSlotsAndAppointments } from '../hooks/useSlotsAndAppointments';
import { useSlotTime } from '../hooks/useSlotTime';
import { Stack, Typography } from '@mui/joy';
import { SlotItem } from './SlotItem/SlotItem';
import { FlatList } from '../../shared/FlatList/FlatList';
import { AppointmentModel, Slot } from '../../../api/models';
import { SchedulePopover } from './EditSlotModal/SchedulePopover';
import { ScheduleType, SlotUiMode } from './EditSlotModal/EditSlotForm.types';
import { AppointmentActionsModal } from './AppointmentModal/AppointmentActionsModal';
import { useLoading } from '../../shared/LoadingPage/useLoading';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { dateToLocaleString } from '../../../utils/stringUtils';
import { AppointmentDetailsTab } from '../../Dashboard/AppointmentDetailsSection/AppointmentDetailsTab/AppointmentDetailsTab';
import { DetailsTabs } from '../../Dashboard/AppointmentDetailsSection/DetailsTabs/DetailsTabs';
import { AppointmentDetailsModal } from './AppointmentModal/AppointmentDetailsModal';

type Props = {
    dayIndex: number;
    weekIndex: number;
    itemLayout?: 'horizontal' | 'vertical';
    filteredOutServices: string[];
    filteredOutSchedules: string[];
};
export const SlotList = ({
    dayIndex,
    weekIndex,
    itemLayout = 'horizontal',
    filteredOutSchedules,
    filteredOutServices,
}: Props) => {
    const [selectedWeek, setSelectedWeek] = useState({
        dateFrom: startOfWeek(new Date(), { weekStartsOn: 1 }),
        dateTo: endOfWeek(new Date(), { weekStartsOn: 1 }),
    });
    const [selectedDate, setSelectedDate] = useState(new Date());

    const { timeDayRange } = useSlotTime(dayIndex, weekIndex);
    const isExtraSmall = useWindowSize().isExtraSmall;

    useEffect(() => {
        const newWeekStart = addWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), weekIndex);
        const newSelectedWeek = {
            dateFrom: newWeekStart,
            dateTo: endOfWeek(newWeekStart, { weekStartsOn: 1 }),
        };
        setSelectedWeek(newSelectedWeek);
        setSelectedDate(addDays(newSelectedWeek.dateFrom, dayIndex));
    }, [weekIndex, dayIndex]);

    const { slots, appointments, isLoading, reloadSchedules } = useSlotsAndAppointments(selectedWeek, weekIndex);

    const getCombinedArr = useCallback((): SlotProps[] => {
        const combinedArr: SlotProps[] = [];

        const filterByDateAndService = (item: { date_from: string; healthcare_service: { name: string } }) => {
            return (
                isSameDay(parseISO(item.date_from), selectedDate) &&
                !filteredOutServices.includes(item.healthcare_service.name)
            );
        };

        if (!filteredOutSchedules.includes(ScheduleType.slot)) {
            slots.filter(filterByDateAndService).forEach((s) => {
                combinedArr.push({ emptySlot: s, ...s });
            });
        }

        if (!filteredOutSchedules.includes(ScheduleType.appointment)) {
            appointments.filter(filterByDateAndService).forEach((a) => {
                combinedArr.push({ appointment: a, ...a });
            });
        }

        return combinedArr.sort((a, b) => Number(new Date(a.date_from)) - Number(new Date(b.date_from)));
    }, [slots, appointments, selectedDate, filteredOutSchedules, filteredOutServices]);

    useLoading(isLoading);

    if (isLoading) return null;

    return (
        <FlatList
            ListEmptyComponent={
                <Stack display="flex" direction="row" justifyContent="center">
                    <Typography>No slots available for {dateToLocaleString(timeDayRange?.dateFrom)}!</Typography>
                </Stack>
            }
            data={getCombinedArr()}
            renderItem={({ item, index }: { item: SlotProps; index: number }) => {
                return (
                    <div key={index}>
                        {item.emptySlot ? (
                            <SchedulePopover
                                button={<SlotItem vertical={itemLayout === 'vertical'} slotKey={index} slot={item} />}
                                uiMode={SlotUiMode.Edit}
                                slot={item.emptySlot}
                                isMobile={isExtraSmall}
                                scheduleType={ScheduleType.slot}
                            />
                        ) : (
                            <AppointmentDetailsModal
                                appointment={item.appointment}
                                button={<SlotItem vertical={itemLayout === 'vertical'} slotKey={index} slot={item} />}
                                reloadSchedules={reloadSchedules}
                            />
                        )}
                    </div>
                );
            }}
        />
    );
};

export type SlotProps = Common<OmitOptional<Slot>, OmitOptional<AppointmentModel>> & {
    emptySlot?: Slot;
    appointment?: AppointmentModel;
};

type OmitOptional<T> = {
    [P in keyof Required<T> as Pick<T, P> extends Required<Pick<T, P>> ? P : never]: T[P];
};

type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P];
};
