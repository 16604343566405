import React, { useState } from 'react';
import { OutlinedMenuButton } from '../styled/OutlinedMenuButton.styled';
import IconButton from '@mui/joy/IconButton';
import { Avatar, Box, Dropdown, ListItemDecorator, MenuItem, Typography } from '@mui/joy';
import { getFullName, getInitials } from '../../../api/utils';
import { CornerMenu } from '../styled/CornerMenu.styled';
import { grantAccess, mapUserRoleToSecuredMode, routeArrToRouteStr } from '../../shared/Routing/routeUtils';
import { LogoutOutlined } from '@mui/icons-material';
import { UserProfile } from '../../../api/models';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { RouteInfo } from '../../shared/Routing/routes.types';
import { adminPanelRouteInfo, errorManagementRouteInfo, profileRouteInfo } from '../../shared/Routing/routeObjects';
import { useTranslation } from 'react-i18next';
import { LogoutModal } from '../../Auth/LogoutModal/LogoutModal';
import { useNavigate } from 'react-router-dom';

type Props = {
    authUser: UserProfile | undefined;
};
export const UserAvatar = ({ authUser }: Props) => {
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();
    const userMenuItems: RouteInfo[] = [adminPanelRouteInfo(t), errorManagementRouteInfo(t), profileRouteInfo(t)];
    const [isModalOpened, toggleModal] = useState(false);
    const navigate = useNavigate();
    const userAvatar = authUser?.practitioner?.image?.file;

    return (
        <>
            <Dropdown>
                <OutlinedMenuButton
                    enable_outline={isExtraSmall ? 1 : 0}
                    slots={{ root: IconButton }}
                    sx={{
                        '&:active': {
                            backgroundColor: 'transparent',
                        },
                    }}
                    variant="outlined"
                >
                    {isExtraSmall ? (
                        <Typography level="h1" fontSize={16} textColor="var(--joy-palette-neutral-softColor)">
                            {getInitials(authUser)}
                        </Typography>
                    ) : (
                        <Avatar
                            sx={{ background: 'var(--joy-palette-background-level2)' }}
                            variant="outlined"
                            size="lg"
                            src={userAvatar}
                        >
                            {getInitials(authUser)}
                        </Avatar>
                    )}
                </OutlinedMenuButton>
                <CornerMenu placement="bottom-end" variant="outlined">
                    <MenuItem>
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 1,
                                p: 1.5,
                                pb: 2,
                                borderBottom: '1px solid',
                                borderColor: 'divider',
                            }}
                        >
                            <Avatar
                                sx={{ background: 'var(--joy-palette-background-level2)' }}
                                variant="outlined"
                                size="lg"
                                src={userAvatar}
                            >
                                {getInitials(authUser)}
                            </Avatar>
                            <div>
                                <Typography level="title-md">{getFullName(authUser)}</Typography>
                                <Typography level="body-sm">{authUser?.email}</Typography>
                            </div>
                        </Box>
                    </MenuItem>
                    {userMenuItems.map(
                        (element, index) =>
                            grantAccess(element.secureMode, mapUserRoleToSecuredMode(authUser?.role)) && (
                                <MenuItem key={index} onClick={() => navigate(routeArrToRouteStr(element))}>
                                    <ListItemDecorator>{element.icon}</ListItemDecorator>
                                    {element.name}
                                </MenuItem>
                            ),
                    )}
                    <MenuItem onClick={() => toggleModal(true)}>
                        <ListItemDecorator>
                            <LogoutOutlined />
                        </ListItemDecorator>
                        {t('sidebar:logout')}
                    </MenuItem>
                </CornerMenu>
            </Dropdown>
            <LogoutModal isOpen={isModalOpened} closeModal={() => toggleModal(false)} />
        </>
    );
};
