import React, { Fragment, useEffect, useState } from 'react';
import { Box, Card, Divider, Grid, Link, Stack, Typography } from '@mui/joy';
import { useAuthContext } from '../Auth/AuthContext/AuthContext';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../shared/hooks/useWindowSize';
import { translateUserRole } from '../../utils/i18n';
import { dateToLocaleString } from '../../utils/stringUtils';
import { UserRole } from '../../api/models';
import { privatePolicyLink } from '../../klaro/klaroConstants';
import EditDoctorForm from './EditModal/EditDoctorForm';
import { useModalState } from '../shared/BetterModal/useModalState';
import { EditDoctorValues } from './EditModal/EditDoctor.types';
import { useDoctor } from '../../hooks/useDoctor';
import { BetterAlert } from '../shared/BetterAlert/BetterAlert';

export const AccountDetails = () => {
    const authContext = useAuthContext();
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const user = authContext?.authUser;
    const modalState = useModalState();
    const { request, updateDoctor, getDoctor } = useDoctor();
    const isDoctor = user?.role === UserRole.organizationMember;
    const [r, refresh] = useState(false);
    const successAlertState = useModalState();

    useEffect(() => {
        refresh(r);
    }, [authContext?.authUser?.doctor]);

    const editDoctor = (values: EditDoctorValues) => {
        updateDoctor(values).then(() => {
            getDoctor().then(() => {
                modalState.toggleModal();
                successAlertState.toggleModal();
            });
        });
    };

    const getDescription = () => {
        if (user?.practitioner === undefined) return '-';
        const descriptionArray = user.practitioner.description.split('\n');
        return descriptionArray.map((element, index) => (
            <Fragment key={index}>
                {element}
                {index + 1 < descriptionArray.length && <br />}
            </Fragment>
        ));
    };

    const content = (
        <>
            <BetterAlert state={successAlertState} description={t('editDoctorModal:success_message')} />
            {!isExtraSmall && (
                <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography level="h3">{t('profile:profile_title')}</Typography>
                        {isDoctor && (
                            <EditDoctorForm
                                doctor={
                                    user?.doctor && {
                                        ...user?.doctor,
                                        image: user?.practitioner?.image?.id,
                                        description: user?.practitioner?.description || '',
                                    }
                                }
                                handleSubmit={editDoctor}
                                modalState={modalState}
                                request={request}
                            />
                        )}
                    </Stack>
                    <Divider sx={{ mb: isExtraSmall ? 1.3 : 0, width: '100%' }} />
                </>
            )}
            {isDoctor && (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography level="h3">{t('profile:title_base_data')}</Typography>
                    {isExtraSmall && (
                        <EditDoctorForm
                            doctor={
                                user?.doctor && {
                                    ...user?.doctor,
                                    image: user?.practitioner?.image?.id,
                                    description: user?.practitioner?.description || '',
                                }
                            }
                            handleSubmit={editDoctor}
                            modalState={modalState}
                            request={request}
                        />
                    )}
                </Stack>
            )}
            <Grid container justifyContent={isExtraSmall ? 'flex-start' : 'center'}>
                <Grid spacing={1} xs={12} md={6}>
                    <div>
                        <Typography level="body-sm">{t('profile:full_name')}</Typography>
                        <Typography>{user?.first_name + ' ' + user?.last_name}</Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('profile:email')}</Typography>
                        <Typography>{user?.email}</Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('profile:phone')}</Typography>
                        <Typography>{user?.doctor?.phone_number || user?.phone || '-'}</Typography>
                    </div>
                </Grid>
                <Grid spacing={1} xs={12} md={6}>
                    <div>
                        <Typography level="body-sm">{t('profile:user_id')}</Typography>
                        <Typography>{user?.id}</Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('profile:role')}</Typography>
                        <Typography>{translateUserRole(user?.role)}</Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('profile:date_joined')}</Typography>
                        <Typography>{dateToLocaleString(user?.date_joined)}</Typography>
                    </div>
                </Grid>
            </Grid>
            {isDoctor && (
                <>
                    <Typography level="h3">{t('profile:title_detailed_data')}</Typography>
                    <Grid container justifyContent={isExtraSmall ? 'flex-start' : 'center'}>
                        <Grid spacing={1} xs={12} md={6}>
                            <div>
                                <Typography level="body-sm">{t('profile:user_address2')}</Typography>
                                <Typography>{user?.doctor?.city}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('profile:user_address1')}</Typography>
                                <Typography>{user?.doctor?.street}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('profile:user_postal_code')}</Typography>
                                <Typography>{user?.doctor?.post_code}</Typography>
                            </div>
                        </Grid>
                        <Grid spacing={1} xs={12} md={6}>
                            <div>
                                <Typography level="body-sm">{t('profile:fax')}</Typography>
                                <Typography>{user?.doctor?.fax_number || '-'}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('profile:vat_id')}</Typography>
                                <Typography>{user?.doctor?.vat_id || '-'}</Typography>
                            </div>
                            <div>
                                <Typography level="body-sm">{t('profile:bank_nr')}</Typography>
                                <Typography>{user?.doctor?.bank_number}</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </>
            )}
            {isDoctor && (
                <Stack width="100%">
                    <div>
                        <Typography level="body-sm">{t('profile:image')}</Typography>
                        {user?.practitioner?.image ? (
                            <img
                                className="mb-4"
                                alt={'Member profile'}
                                style={{ maxHeight: '100px' }}
                                src={user?.practitioner?.image.file}
                            />
                        ) : (
                            <Typography>-</Typography>
                        )}
                    </div>
                    <div>
                        <Typography level="body-sm">{t('profile:description')}</Typography>
                        <Typography>{getDescription()}</Typography>
                    </div>
                </Stack>
            )}
            <Link href={privatePolicyLink} target="_blank" rel="noopener noreferrer" style={{ marginTop: 30 }}>
                {t('profile:data_privacy_notice')}
            </Link>
        </>
    );

    return (
        <Box
            sx={{
                mt: 1,
                width: '100%',
                display: 'grid',
            }}
            alignItems="center"
            justifyContent="stretch"
        >
            {!isExtraSmall && <Card> {content} </Card>}
            {isExtraSmall && <Stack>{content}</Stack>}
        </Box>
    );
};
