import { Frequency } from '../../../../../../api/models';
import { useTranslation } from 'react-i18next';

export const useIntervalOptions = (isPlural: boolean) => {
    const { t } = useTranslation();

    const intervalOptions = [
        { label: t('editSlotModal:customRepetition.frequency.option_day'), value: Frequency.DAILY },
        { label: t('editSlotModal:customRepetition.frequency.option_week'), value: Frequency.WEEKLY },
        { label: t('editSlotModal:customRepetition.frequency.option_month'), value: Frequency.MONTHLY },
        { label: t('editSlotModal:customRepetition.frequency.option_year'), value: Frequency.YEARLY },
    ];
    const intervalOptionsPlural = [
        { label: t('editSlotModal:customRepetition.frequency.option_days'), value: Frequency.DAILY },
        { label: t('editSlotModal:customRepetition.frequency.option_weeks'), value: Frequency.WEEKLY },
        { label: t('editSlotModal:customRepetition.frequency.option_months'), value: Frequency.MONTHLY },
        { label: t('editSlotModal:customRepetition.frequency.option_years'), value: Frequency.YEARLY },
    ];

    return isPlural ? intervalOptionsPlural : intervalOptions;
};
