import React, { Fragment } from 'react';
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Stack, Typography } from '@mui/joy';
import { InvoiceMobileProps, InvoiceMobileValues } from '../InvoiceMobileForm.types';
import { FormikProps } from 'formik';
import { DeleteOutlined } from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';
import { useTranslation } from 'react-i18next';

type Props = {
    baseProps: InvoiceMobileProps & FormikProps<InvoiceMobileValues>;
};
export const InvoiceItemMobileDisplay = ({ baseProps }: Props) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <AccordionGroup>
                {baseProps.values?.items?.map((element, index) => (
                    <Accordion key={index} variant="outlined">
                        <AccordionSummary>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
                                <Typography>{element.name}</Typography>
                                <IconButton
                                    variant="outlined"
                                    color="danger"
                                    onClick={() => {
                                        const curr = baseProps.values.items;
                                        curr?.splice(index, 1);
                                        baseProps.setFieldValue('items', curr);
                                    }}
                                >
                                    <DeleteOutlined />
                                </IconButton>
                            </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={1}>
                                {t('invoice:table.factor')}: {element.factor}
                            </Stack>
                            <Stack spacing={1}>
                                {t('invoice:table.price')}: {element.price}
                            </Stack>
                            <Stack spacing={1}>
                                {t('invoice:table.gross')}: {element.price * element.factor}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </AccordionGroup>
            {baseProps.values.items?.length === 0 && (
                <Stack alignItems="center" justifyContent="center" height="min-content">
                    {t('invoice:table.empty_message')}
                </Stack>
            )}
        </Fragment>
    );
};
