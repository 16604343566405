import React, { useEffect, useState } from 'react';
import { RecurringFieldProps, RecurringFieldValues } from '../RecuringFieldModal/RecurringFieldModal.types';
import { FormikProps } from 'formik';
import { Stack } from '@mui/joy';
import { CircleRadioButton } from '../../../../../shared/CircleRadioButton/CircleRadioButton';
import { useTranslation } from 'react-i18next';
import { Weekday } from '../../../../../../api/models';
import { useWindowSize } from '../../../../../shared/hooks/useWindowSize';

type Props = {
    props: RecurringFieldProps & FormikProps<RecurringFieldValues>;
};
export const DaySelector = ({ props }: Props) => {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();

    const onClick = (value: number, selected: boolean) => {
        const arr = props.values.days;
        const day = Object.values(Weekday)[value];
        if (selected) arr.push(day);
        else arr.splice(arr.indexOf(day), 1);
        props.setFieldValue('days', arr);
    };

    const defaultSelected = (value: number): boolean => {
        const day = Object.values(Weekday)[value];
        return props.values.days.indexOf(day) != -1;
    };

    return (
        <Stack direction="row" spacing={1}>
            {[0, 1, 2, 3, 4, 5, 6].map((element, index) => (
                <CircleRadioButton
                    onClick={onClick}
                    style={{ width: isExtraSmall ? 35 : 40, height: isExtraSmall ? 35 : 40 }}
                    selectable
                    defaultSelected={defaultSelected}
                    key={index}
                    defaultColor="neutral"
                    defaultVariant="soft"
                    selectedColor="primary"
                    selectedVariant="solid"
                    label={t('dateTime:weekShort.' + index)}
                    value={index}
                />
            ))}
        </Stack>
    );
};
