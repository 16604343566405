import React, { useState } from 'react';
import { UserProfile } from '../../../api/models';
import { Grid } from '@mui/joy';
import { loginRoute } from '../../shared/Routing/routes';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { UserAvatar } from './UserAvatar';
import { UserSettings } from '../UserSettings';
import { LoginButton } from './LoginButton';

type Props = {
    isLoggedIn: boolean;
    user: UserProfile | undefined;
};
export const TopBarOptions = ({ isLoggedIn, user }: Props) => {
    const { isExtraSmall } = useWindowSize();

    const displayUser = (): boolean => {
        if (isLoggedIn) return true;

        const pathName = window.location.pathname;
        const slashIndex = pathName.indexOf('/');
        const firstQueryIndex = pathName.indexOf('?');
        return !pathName.includes(loginRoute) && !(pathName.substring(slashIndex, firstQueryIndex).length <= 0);
    };

    return (
        <Grid container spacing={1}>
            {isExtraSmall && (
                <Grid xs={displayUser() ? 6 : 12}>
                    <UserSettings />
                </Grid>
            )}
            {displayUser() && (
                <Grid xs={isExtraSmall ? 6 : 12}>{isLoggedIn ? <UserAvatar authUser={user} /> : <LoginButton />}</Grid>
            )}
        </Grid>
    );
};
