import React, { RefCallback } from 'react';
import { CustomProps } from './CustomProps';
import { IMaskInput } from 'react-imask';

export const FloatAdapter = React.forwardRef<HTMLElement, CustomProps>(function BankMaskAdapter(props, ref) {
    const { onChange, ...other } = props;

    const getValue = (value: string) => {
        value = value.toString().replaceAll(',', '.');
        if (value.split('.').length - 1 > 1) value = value.slice(0, -1);
        return value;
    };

    return (
        <IMaskInput
            {...other}
            mask={/[-+]?([0-9]+([.,][0-9]*)?|[.,][0-9]+)/}
            inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
            onAccept={(value: any) =>
                onChange({
                    target: {
                        name: props.name,
                        value: getValue(value),
                    },
                })
            }
            overwrite
        />
    );
});
