import { withFormik } from 'formik';
import { InvoiceMobileProps, InvoiceMobileValues } from './InvoiceMobileForm.types';
import { withTranslation } from 'react-i18next';
import InvoiceModal from './InvoiceModal';
import { handleSubmit, mapPropsToValues, validationSchema } from './InvoiceMobileForm.schema';

const InvoiceMobileForm = withFormik<InvoiceMobileProps, InvoiceMobileValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(InvoiceModal);
export default withTranslation()(InvoiceMobileForm);
