import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export type ErrorType = {
    message: string;
    status?: string;
} | null;

export interface ErrorContextType {
    error: ErrorType;
    setError: (error: ErrorType) => void;
}

export const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useErrorContext = () => {
    const context = useContext(ErrorContext);
    if (context === undefined) {
        throw new Error('useErrorContext must be used within a ErrorProvider');
    }
    return context;
};

export const ErrorProvider = ({ children }: { children: ReactNode }) => {
    const [error, setError] = useState<ErrorType>(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (error && error.status) {
            const statusCode = parseInt(error.status);
            if (statusCode === 401) {
                navigate('/login', { replace: true });
            } else if (statusCode >= 500 && statusCode < 600) {
                navigate('/error', { replace: true });
            }
        }
    }, [error, navigate]);

    return <ErrorContext.Provider value={{ error, setError }}>{children}</ErrorContext.Provider>;
};
