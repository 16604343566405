export const ColorStyles = {
    dark: {
        palette: {
            modalBackground: '#0b0d0e',
            danger: {
                500: '#e12c2c',
                400: '#ff3f3c',
                200: '#E47474',
                700: '#ae1a1a',
            },
            sidebarShadow: '#0B0D0E',
            successButtonShadow: '#042F04',
            dangerButtonShadow: '#430A0A',
            primaryButtonShadow: '#0A2744',
            darkslategray: '#2f4f4f',
            saddlebrown: '#8b4513',
            darkgreen: '#006400',
            darkkhaki: '#bdb76b',
            indigo: '#4b0082',
            red: '#ff0000',
            darkturquoise: '#00ced1',
            lime: '#00ff00',
            mediumspringgreen: '#00fa9a',
            blue: '#0000ff',
            fuchsia: '#ff00ff',
            cornflower: '#6495ed',
            deeppink: '#ff1493',
            lightpink: '#ffb6c1',
            background: {
                body: '#131517',
            },
            primary: {
                700: '#5218FA',
                600: '#6734fe',
                500: '#320d9d',
            },
            secondary: {
                700: '#e12c2c',
                400: '#ff3f3c',
                200: '#E47474',
                500: '#ae1a1a',
            },
            neutral: {
                softBg: '#303438',
                plainActiveBg: '#171a1c',
                softDisabledBg: '#222629',
            },
        },
    },
    light: {
        palette: {
            modalBackground: '#fbfcfe',
            danger: {
                500: '#e12c2c',
                400: '#ff3f3c',
                200: '#E47474',
                700: '#ae1a1a',
            },
            sidebarShadow: '#CDD7E1',
            successButtonShadow: '#51BC51',
            dangerButtonShadow: '#E47474',
            primaryButtonShadow: '#4393E4',
            darkslategray: '#2f4f4f',
            saddlebrown: '#8b4513',
            darkgreen: '#006400',
            darkkhaki: '#bdb76b',
            indigo: '#4b0082',
            red: '#ff0000',
            darkturquoise: '#00ced1',
            lime: '#00ff00',
            mediumspringgreen: '#00fa9a',
            blue: '#0000ff',
            fuchsia: '#ff00ff',
            cornflower: '#6495ed',
            deeppink: '#ff1493',
            lightpink: '#ffb6c1',
            background: {
                body: '#F5F9FC',
                level1: '#ebf3f9',
                level2: '#d8e7f3',
                level3: '#c4dced',
            },
            primary: {
                500: '#5218FA',
                600: '#6734fe',
                700: '#320d9d',
            },
            secondary: {
                500: '#e12c2c',
                600: '#ff3f3c',
                200: '#E47474',
                700: '#ae1a1a',
            },
            neutral: {
                softBg: '#ffffff',
                plainActiveBg: '#ffffff',
                softDisabledBg: '#ffffff',
            },
        },
    },
};
