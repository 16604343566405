import { InvoiceMobileProps, InvoiceMobileValues } from './InvoiceMobileForm.types';
import { FormikBag } from 'formik';
import * as Yup from 'yup';
import { addDays } from 'date-fns';
import { pdf } from '@react-pdf/renderer';
import InvoiceModalBody from '../InvoiceModalBody';
import React from 'react';
import FileSaver from 'file-saver';
import { mapInvoiceToInvoiceRequestData } from '../../utils';

export const mapPropsToValues = (props: InvoiceMobileProps): InvoiceMobileValues => {
    return {
        diagnosis: '',
        doctorAddress: props.authUser?.doctor?.street || '',
        doctorAddress2: props.authUser?.doctor?.city || '',
        doctorBankNr: props.authUser?.doctor?.bank_number || '',
        doctorEmail: props.authUser?.email || '',
        doctorFax: props.authUser?.doctor?.fax_number || '',
        doctorName: props.authUser?.first_name || '',
        doctorSurname: props.authUser?.last_name || '',
        doctorTitle: props.authUser?.personal_title || '',
        doctorTel: props.authUser?.doctor?.phone_number || props.authUser?.phone || '',
        doctorVatID: props.authUser?.doctor?.vat_id || '',
        invoiceDate: new Date(),
        invoiceDueDate: addDays(new Date(), 7),
        invoiceNr: '',
        patientAddress: props.appointment.patient?.patient?.city || '',
        patientAddress2: props.appointment.patient?.patient?.street || '',
        patientName: props.appointment.patient?.first_name || '',
        patientSurname: props.appointment.patient?.last_name || '',
        patientTitle: props.appointment.patient?.personal_title || '',
        items: [],
        patientPostalCode: props.appointment.patient?.patient?.post_code || '',
        doctorPostalCode: props.authUser?.doctor?.post_code || '',
        appointment: props.appointment,
        bank_name: '',
    };
};

export const validationSchema = ({ t }: InvoiceMobileProps) =>
    Yup.object().shape({
        invoiceNr: Yup.string().required(t('invoice:error_field_required')),
        diagnosis: Yup.string().required(t('invoice:error_field_required')).max(500, t('invoice:error_diagnosis_max')),
        doctorAddress: Yup.string().required(t('invoice:error_field_required')),
        doctorAddress2: Yup.string().required(t('invoice:error_field_required')),
        patientAddress: Yup.string().required(t('invoice:error_field_required')),
        patientAddress2: Yup.string().required(t('invoice:error_field_required')),
        doctorBankNr: Yup.string()
            .required(t('invoice:error_field_required'))
            .min(16, t('invoice:error_bank_nr_length'))
            .max(35, t('invoice:error_bank_nr_length')),
        bank_name: Yup.string().required(t('invoice:error_bank_name')),
        doctorEmail: Yup.string().email(t('invoice:error_email')).required(t('invoice:error_field_required')),
        doctorName: Yup.string().required(t('invoice:error_field_required')).max(50, t('invoice:error_name_max')),
        doctorSurname: Yup.string().required(t('invoice:error_field_required')).max(50, t('invoice:error_name_max')),
        patientName: Yup.string().required(t('invoice:error_field_required')).max(50, t('invoice:error_name_max')),
        patientSurname: Yup.string().required(t('invoice:error_field_required')).max(50, t('invoice:error_name_max')),
        doctorTel: Yup.string()
            .required(t('invoice:error_field_required'))
            .max(18, t('invoice:error_tel_length'))
            .min(15, t('invoice:error_tel_length')),
        invoiceDate: Yup.string().required(t('invoice:error_field_required')),
        invoiceDueDate: Yup.string().required(t('invoice:error_field_required')),
        items: Yup.array().min(1, t('invoice:error_item')),
        patientPostalCode: Yup.string()
            .required(t('invoice:error_field_required'))
            .length(5, t('invoice:error_postal_code_length')),
        doctorPostalCode: Yup.string()
            .required(t('invoice:error_field_required'))
            .length(5, t('invoice:error_postal_code_length')),
    });

export const handleSubmit = async (
    values: InvoiceMobileValues,
    { props, resetForm }: FormikBag<InvoiceMobileProps, InvoiceMobileValues>,
) => {
    const invoiceReqData = mapInvoiceToInvoiceRequestData(values, values.appointment, props?.authUser?.id || -1);
    const blob: Blob | undefined =
        values.submitType !== 'save'
            ? await pdf(<InvoiceModalBody pdfMode={true} data_={values} />).toBlob()
            : undefined;

    if (values.submitType === 'download' && blob) FileSaver.saveAs(blob, `invoice_${new Date()}.pdf`);

    if (values.submitType !== 'send') {
        props.handleSubmit(invoiceReqData, resetForm);
        return;
    }

    if (!blob) return;

    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
        const base64data = reader.result;
        if (base64data) {
            invoiceReqData.file = base64data.toString();
        }

        props.handleSubmit(invoiceReqData, resetForm);
    };
};
