import { EndsOnOption, RecurringFieldProps, RecurringFieldValues } from './RecurringFieldModal.types';
import { numberToWeekday, rruleToFreq } from '../helpers/rrule';
import * as Yup from 'yup';
import { Frequency } from '../../../../../../api/models';
import { FormikBag } from 'formik';
import { mapValuesToScheduleRule } from '../helpers/mapValuesToScheduleRule';
import { addWeeks } from 'date-fns';

export const mapPropsToValues = ({ currentRule }: RecurringFieldProps): RecurringFieldValues => {
    const ruleSchema = currentRule?.options;

    const endsOnValue = ruleSchema?.until
        ? EndsOnOption.onDay
        : ruleSchema?.count
        ? EndsOnOption.afterX
        : EndsOnOption.never;

    return {
        interval: ruleSchema?.interval !== undefined ? ruleSchema.interval : 1,
        freq: ruleSchema?.freq !== undefined ? rruleToFreq(ruleSchema.freq) : Frequency.WEEKLY,
        days: (ruleSchema?.byweekday || []).map(numberToWeekday),
        endsOn: endsOnValue,
        until:
            ruleSchema?.until !== undefined && ruleSchema?.until !== null
                ? new Date(ruleSchema.until)
                : addWeeks(new Date(), 4),
        count: ruleSchema?.count !== undefined && ruleSchema?.count !== null ? ruleSchema.count : 4,
    };
};

export const validationSchema = ({ t }: RecurringFieldProps) =>
    Yup.object().shape({
        interval: Yup.number(),
        freq: Yup.string(),
        days: Yup.array().when('freq', {
            is: (freq: Frequency) => freq === Frequency.WEEKLY,
            then: (schema) => schema.min(1, t('editSlotModal:customRepetition.errors.days')),
        }),
        count: Yup.number().when('endsOn', {
            is: (endsOn: EndsOnOption) => endsOn === EndsOnOption.afterX,
            then: (schema) =>
                schema
                    .min(1, t('editSlotModal:customRepetition.errors.endsOn_negative'))
                    .integer(t('editSlotModal:customRepetition.errors.endsOn_integer')),
        }),
    });

export const handleSubmit = (
    values: RecurringFieldValues,
    { props }: FormikBag<RecurringFieldProps, RecurringFieldValues>,
) => {
    values.count = values.endsOn === EndsOnOption.afterX ? values.count : undefined;
    values.until = values.endsOn === EndsOnOption.onDay ? values.until : undefined;
    const rrule = mapValuesToScheduleRule(values);
    props.onSubmit(rrule);
};
