import React, { Fragment, ReactNode } from 'react';
import { useModalState } from '../../../shared/BetterModal/useModalState';
import { DetailsTabs } from '../../../Dashboard/AppointmentDetailsSection/DetailsTabs/DetailsTabs';
import { AppointmentModel } from '../../../../api/models';
import { useWindowSize } from '../../../shared/hooks/useWindowSize';
import { BetterModal } from '../../../shared/BetterModal/BetterModal';
import { useTranslation } from 'react-i18next';

type Props = {
    appointment: AppointmentModel | undefined;
    button: ReactNode;
    reloadSchedules: () => void;
};
export const AppointmentDetailsModal = ({ appointment, button, reloadSchedules }: Props) => {
    const modalState = useModalState();
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();

    if (appointment === undefined) return null;

    return (
        <BetterModal
            title={t('appointmentDetails:label_title')}
            state={modalState}
            minWidth={600}
            button={button}
            body={
                <DetailsTabs appointment={appointment} reloadAppointments={reloadSchedules} isMobile={isExtraSmall} />
            }
        />
    );
};
