import { LoginProps, LoginValues } from './LoginForm.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
import { LoginStep } from '../../../api/models';

export const mapPropsToValues = (): LoginValues => {
    return {
        email: '',
        token: '',
        password: '',
        step: LoginStep.email,
        login_fields: {
            email: '',
            token: '',
            password: '',
            step: LoginStep.email,
        },
        password_error: false,
        otp_error: false,
    };
};

export const validationSchema = ({ t }: LoginProps) =>
    Yup.object().shape({
        email: Yup.string().email(t('login:warning_wrong_email')).required(t('login:warning_email_required')),
        password: Yup.string().when('step', {
            is: (step: LoginStep) => [LoginStep.password].includes(step),
            then: (schema) =>
                schema.min(3, t('login:warning_password_min_length')).required(t('login:warning_password_required')),
        }),
        token: Yup.string().when('step', {
            is: (step: LoginStep) => [LoginStep.otpCode].includes(step),
            then: (schema) =>
                schema
                    .required(t('otp:warning_code_required'))
                    .matches(/^\d+$/, t('otp:warning_code_not_numeric'))
                    .min(6, t('otp:warning_code_short'))
                    .max(6, t('otp:warning_code_long')),
        }),
    });

export const handleSubmit = (
    values: LoginValues,
    { props, setSubmitting, setFieldValue, setFieldTouched }: FormikBag<LoginProps, LoginValues>,
) => {
    const goNextStep = () => {
        setSubmitting(false);
        setFieldValue('step', values.step + 1);
    };

    switch (values.step) {
        case LoginStep.email:
            props
                .ensureEmailExists(values)
                .then((exists) => {
                    if (exists) {
                        goNextStep();
                        setFieldTouched('password', false);
                    }
                })
                .catch(() => {
                    setFieldValue('password_error', true);
                })
                .finally(() => setFieldValue('isLoading', false));
            break;
        case LoginStep.password:
            props
                .submitCredentials(values)
                .then(() => {
                    setFieldValue('password_error', false);
                    goNextStep();
                    setFieldTouched('token', false);
                })
                .catch(() => {
                    setFieldValue('password_error', true);
                })
                .finally(() => setFieldValue('isLoading', false));
            break;
        case LoginStep.otpCode:
            props
                .submitOtp(values)
                .then((success) => {
                    setFieldValue('otp_error', false);
                    if (success) return;
                    setFieldValue('step', LoginStep.password);
                    setFieldValue('token', '');
                    setFieldTouched('token', false);
                    setFieldTouched('password', false);
                })
                .catch(() => {
                    setFieldValue('otp_error', true);
                    //setTouched({});
                })
                .finally(() => setFieldValue('isLoading', false));
            break;
    }
};
