import React from 'react';
import {
    Avatar,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    Typography,
    Button,
    ToggleButtonGroup,
    Stack,
} from '@mui/joy';
import { ReactSVG } from 'react-svg';
import SecondOpinionLogoWithName from '../../Assets/SecondOpinionLogoWithName.svg';
import SecondOpinionLogoWithNameDarkTheme from '../../Assets/SecondOpinionLogoWithNameDarkTheme.svg';
import { getFullName, getInitials } from '../../api/utils';
import ColorSchemeToggle from '../shared/ColorScheme/ColorSchemeToggle';
import { useTranslation } from 'react-i18next';
import { UserProfile } from '../../api/models';
import { StringParam, useQueryParam } from 'use-query-params';
import { dateToLocaleString } from '../../utils/stringUtils';
import { RoutingList } from '../shared/Routing/RoutingList';
import { useSharedColorScheme } from 'components/shared/ColorScheme/useSharedColorScheme';

type Props = {
    toggleDrawer: (open: boolean) => void;
    isOpen: boolean;
    authUser: UserProfile | undefined;
    isLoggedIn: boolean;
};
export const ContentDrawer = ({ toggleDrawer, isOpen, authUser, isLoggedIn }: Props) => {
    const { t } = useTranslation();
    const [lang, setLang] = useQueryParam('lang', StringParam);
    const { theme } = useSharedColorScheme();

    return (
        <Drawer variant="plain" color="neutral" open={isOpen} onClose={() => toggleDrawer(false)}>
            <DialogTitle level="h2">
                <Stack direction="row" justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
                    <div style={{ width: '50%', paddingBottom: '8px', paddingLeft: '8px' }}>
                        <ReactSVG
                            src={theme === 'light' ? SecondOpinionLogoWithName : SecondOpinionLogoWithNameDarkTheme}
                        />
                    </div>
                    <ColorSchemeToggle />
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <RoutingList
                    listOrientation="vertical"
                    listItemOrientation="horizontal"
                    onPickedRoute={() => toggleDrawer(false)}
                />
            </DialogContent>
            <Divider />
            <Stack direction="row" alignItems="center" justifyContent="space-between" paddingX={2} paddingY={3}>
                <Avatar sx={{ background: 'var(--joy-palette-background-level2)' }} variant="outlined" size="lg">
                    {isLoggedIn ? getInitials(authUser) : null}
                </Avatar>
                <div>
                    {isLoggedIn ? (
                        <>
                            <Typography level="title-md">{getFullName(authUser)}</Typography>
                            <Typography level="body-sm">
                                {t('sidebar:joined')} {authUser ? dateToLocaleString(authUser?.date_joined) : null}
                            </Typography>
                        </>
                    ) : (
                        <Typography level="title-md">{t('sidebar:message_not_logged')}</Typography>
                    )}
                </div>
                <ToggleButtonGroup
                    value={lang}
                    onChange={(event, newValue) => {
                        setLang(newValue);
                    }}
                    size="sm"
                >
                    <Button value={'de'}> DE </Button>
                    <Button value={'en'}> EN </Button>
                </ToggleButtonGroup>
            </Stack>
        </Drawer>
    );
};
