import React, { useState } from 'react';
import Document from './Invoice/Document';
import Page from './Invoice/Page';
import View from './Invoice/View';
import { Invoice, InvoiceItem } from '../../../../../api/models';
import Text from './Invoice/Text';
import ReactPDF from '@react-pdf/renderer';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Font = ReactPDF.Font;
import { useTranslation } from 'react-i18next';
import { dateToLocaleString } from '../../../../../utils/stringUtils';
import { getFullName } from '../../../../../api/utils';

interface Props {
    data_?: Invoice;
    pdfMode?: boolean;
}
Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
            fontWeight: 300,
        },
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
            fontWeight: 400,
        },
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
            fontWeight: 500,
        },
        {
            src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
            fontWeight: 600,
        },
    ],
});

function InvoiceModalBody({ data_, pdfMode }: Props) {
    const items: InvoiceItem[] = [];
    const [invoice, setInvoice] = useState<Invoice>(
        data_
            ? { ...data_ }
            : {
                  diagnosis:
                      'Diagnoza coś tamDiagnoza coś tamDiagnoza coś tamDiagnoza coś tamDiagnoza coś tamDiagnoza coś tam ',
                  doctorAddress: 'DoctorAddress',
                  doctorAddress2: 'DoctorAddress2',
                  doctorBankNr: '1234567899987877',
                  doctorEmail: 'mail@mail.com',
                  doctorFax: '1234567',
                  doctorName: 'Doctor Name',
                  doctorSurname: 'surname',
                  doctorTitle: '',
                  doctorTel: '1234567',
                  doctorVatID: 'vat id 123456',
                  invoiceNr: 'invoice number',
                  invoiceDate: new Date(),
                  invoiceDueDate: new Date(),
                  patientAddress: 'patientAddres',
                  patientAddress2: 'patientAddres',
                  patientName: 'patientName',
                  patientTitle: '',
                  patientSurname: 'paitentSurname',
                  items: [items[0]],
                  doctorPostalCode: '12-312',
                  patientPostalCode: '32-123',
                  bank_name: 'ING',
              },
    );
    const dateFormat = 'MMM dd, yyyy';
    const invoiceDate = invoice.invoiceDate !== null ? new Date(invoice.invoiceDate) : new Date();
    const invoiceDueDate =
        invoice.invoiceDueDate !== null ? new Date(invoice.invoiceDueDate) : new Date(invoiceDate.valueOf());

    if (invoice.invoiceDueDate === null) {
        invoiceDueDate.setDate(invoiceDueDate.getDate() + 30);
    }

    const handleChange = (name: keyof Invoice, value: string | Date) => {
        const newInvoice = { ...invoice };
        if (name !== 'items') {
            if (name === 'invoiceDate' || name === 'invoiceDueDate') {
                newInvoice[name] = new Date(value);
            } else {
                if (typeof value === 'string') {
                    newInvoice[name] = value;
                }
            }
        }
        setInvoice(newInvoice);
    };
    const handleItemChange = (index: number, name: keyof InvoiceItem, value: number) => {
        if (!invoice.items) return;
        const items = invoice.items.map((item, i) => {
            if (i === index) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const newItem = { ...item };
                if (name === 'factor') {
                    newItem.factor = value;
                }
                return newItem;
            } else {
                return item;
            }
        });
        setInvoice((prevState) => {
            return { ...prevState, items };
        });
    };
    const handleAddItem = () => {
        if (selectedItem && selectedFactor) {
            setInvoice((prevState) => {
                let items: InvoiceItem[];
                if (prevState.items) {
                    prevState.items.push({
                        name: selectedItem.name,
                        price: selectedItem.price,
                        factor: selectedFactor,
                        factor_is_constant: false,
                        vat: selectedItem.vat,
                        number: selectedItem.number,
                    });
                    items = prevState.items;
                } else {
                    items = [
                        {
                            name: selectedItem.name,
                            price: selectedItem.price,
                            factor: selectedFactor,
                            factor_is_constant: false,
                            vat: selectedItem.vat,
                            number: selectedItem.number,
                        },
                    ];
                }
                setAddLine(false);
                return { ...prevState, items };
            });
        }
    };
    const handleChangeSelection = (name: string) => {
        const newSelection = items.find((item) => item.name.includes(name));
        if (newSelection) setSelectedItem(newSelection);
    };

    // const [pdfMode, setPdfMode] = useState(false);
    const [addLine, setAddLine] = useState(false);
    const [selectedItem, setSelectedItem] = useState<InvoiceItem>(items[0]);
    const [selectedFactor, setSelectedFactor] = useState<number>(1);

    const { t } = useTranslation();
    invoice.doctorPostalCode = '41333';
    invoice.patientPostalCode = '41111';
    //invoice.items?.map((item) => (item.vat = 0.19));

    const getName = (title: string, name: string, surname: string) => {
        let toReturn = '';
        if (title.length > 0) toReturn = title.trimEnd() + ' ';
        toReturn += name.trimEnd() + ' ';
        toReturn += surname.trimEnd();
        return toReturn;
    };
    return (
        <Document pdfMode={true}>
            <Page className="invoice-wrapper" pdfMode={true}>
                <View pdfMode={true} className="flex w-100 fs-10">
                    <View pdfMode={true} className="w-50">
                        <View pdfMode={true} className="mt-40"></View>
                        <Text pdfMode={true}>
                            {getName(invoice.patientTitle, invoice.patientName, invoice.patientSurname)}
                        </Text>
                        <Text pdfMode={true}>{invoice.patientAddress}</Text>
                        <Text pdfMode={true}>{invoice.patientAddress2}</Text>
                        <Text pdfMode={true}>{invoice.patientPostalCode}</Text>
                    </View>
                    <View pdfMode={true} className="w-50">
                        <Text pdfMode={true} className="bold marginLeft">
                            {getName(invoice.doctorTitle, invoice.doctorName, invoice.doctorSurname)}
                        </Text>
                        <Text pdfMode={true} className="marginLeft">
                            {invoice.doctorAddress}
                        </Text>
                        <Text pdfMode={true} className="marginLeft">
                            {invoice.doctorAddress2}
                        </Text>
                        <Text pdfMode={true} className="marginLeft">
                            {invoice.doctorPostalCode}
                        </Text>
                        <Text pdfMode={true} className="marginLeft">
                            {invoice.doctorTel}
                        </Text>
                        {invoice.doctorFax && (
                            <Text pdfMode={true} className="marginLeft">
                                {invoice.doctorFax}
                            </Text>
                        )}
                        {invoice.doctorVatID && (
                            <Text pdfMode={true} className="marginLeft">
                                {invoice.doctorVatID}
                            </Text>
                        )}
                        <Text pdfMode={true} className="marginLeft">
                            {invoice.doctorEmail}
                        </Text>
                    </View>
                </View>
                <View pdfMode={true} className="mt-20">
                    <Text pdfMode={true} className="fs-20 grayFont">
                        {t('invoice:pdf:title')}
                    </Text>
                </View>
                <View pdfMode={true} className="flex row">
                    <Text pdfMode={true} className="">
                        {t('invoice:pdf:invoice_number') + ': ' + invoice.invoiceNr}
                    </Text>
                    <Text pdfMode={true} className="marginLeft">
                        {t('invoice:pdf:due_date') + ': ' + dateToLocaleString(invoice.invoiceDate)}
                    </Text>
                </View>
                <View pdfMode={true} className="mt-20 flex fs-10">
                    <View pdfMode={true} className="w-25">
                        <Text pdfMode={true}>{t('invoice:pdf:patient') + ': '}</Text>
                        <Text pdfMode={true}>{t('invoice:pdf:diagnosis') + ':'}</Text>
                    </View>
                    <View pdfMode={true} className="w-75">
                        <Text pdfMode={true}>
                            {getFullName({
                                personal_title: invoice.patientTitle,
                                first_name: invoice.patientName,
                                last_name: invoice.patientSurname,
                            })}
                        </Text>
                        <Text pdfMode={true}>{invoice.diagnosis}</Text>
                    </View>
                </View>
                <View pdfMode={true} className="flex w-100 mt-10 fs-10">
                    <Text pdfMode={true} className="bg-gray w-12 bold textCenter m-0">
                        {t('invoice:pdf:date')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 bold textCenter m-0">
                        {t('invoice:pdf:number_of_item')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-25 bold textCenter m-0">
                        {t('invoice:pdf:item_name')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 bold textCenter m-0">
                        {t('invoice:pdf:quantity')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 bold textCenter m-0">
                        {t('invoice:pdf:price')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 bold textCenter m-0">
                        {t('invoice:pdf:factor')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 bold textCenter m-0 ">
                        {t('invoice:pdf:amount')}
                    </Text>
                </View>
                {data_?.items?.map((item) => {
                    return (
                        <View key={item.number} pdfMode={true} className="flex w-100 m-0 fs-8">
                            <Text pdfMode={true} className="w-12 textCenter m-0">
                                {dateToLocaleString(invoice.invoiceDate)}
                            </Text>
                            <Text pdfMode={true} className="w-12 textCenter m-0">
                                {item.number.toString()}
                            </Text>
                            <Text pdfMode={true} className="w-25 m-0 ">
                                {item.name}
                            </Text>
                            <Text pdfMode={true} className="w-12 textCenter m-0">
                                1
                            </Text>
                            <Text pdfMode={true} className="w-12 right m-0">
                                {Number(item.price).toFixed(2).toString().replace('.', ',') + ' €'}
                            </Text>
                            <Text pdfMode={true} className="w-12 textCenter m-0">
                                {Number(item.factor).toFixed(2).toString().replace('.', ',')}
                            </Text>
                            <Text pdfMode={true} className="w-12 right m-0">
                                {(item.price * item.factor).toFixed(2).toString().replace('.', ',') + ' €'}
                            </Text>
                        </View>
                    );
                })}
                <View pdfMode={true} className="mt-20 flex fs-12 bg-gray ">
                    <Text pdfMode={true} className="marginLeft bold">
                        {t('invoice:pdf:sum')}
                    </Text>
                    <Text pdfMode={true} className="ml-10 bold mr-5">
                        {invoice.items
                            ?.reduce((sum, item) => sum + item.price * item.factor, 0)
                            .toFixed(2)
                            .toString()
                            .replace('.', ',') + ' €'}
                    </Text>
                </View>
                <View pdfMode={true} className="w-100">
                    <Text pdfMode={true} className="fs-8">
                        {t('invoice:pdf:vat_info')}
                    </Text>
                </View>
                <View pdfMode={true} className="row"></View>
                <View pdfMode={true} className="mt-20">
                    <Text pdfMode={true} className="fs-10">
                        {t('invoice:pdf:date_due_text') + ' ' + dateToLocaleString(invoice.invoiceDueDate) + '.'}
                    </Text>
                    <Text pdfMode={true} className="fs-10">
                        {t('invoice:pdf:info_about_not_paying')}
                    </Text>
                </View>
                <View pdfMode={true} className="flex w-100 fs-10 mt-20">
                    <View pdfMode={true} className="w-40 marginLeft">
                        <Text pdfMode={true} className="textLeft">
                            {t('invoice:pdf:bank_name') + ': ' + invoice.bank_name}
                        </Text>
                        <Text pdfMode={true} className="textLeft">
                            {t('invoice:pdf:IBAN') + ': ' + invoice.doctorBankNr}
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
    return (
        <Document pdfMode={true}>
            <Page className="invoice-wrapper" pdfMode={true}>
                <View className="flex flexCenter" pdfMode={true}>
                    <Text className="fs-20 bold flexCenter" pdfMode={true}>
                        {t('invoice:pdf:title') + ' ' + invoice.invoiceNr}
                    </Text>
                </View>
                <View className="inlineBlock marginLeft" pdfMode={true}>
                    <View pdfMode={true}>
                        <Text pdfMode={true} className="fs-10">
                            {t('invoice:pdf:date_of_invoice') + ' ' + dateToLocaleString(invoice.invoiceDate)}
                        </Text>
                    </View>
                </View>
                <View pdfMode={true} className="row mt-5"></View>
                <View pdfMode={true} className="flex">
                    <View pdfMode={true} className="flex w-50">
                        <Text pdfMode={true} className="bold">
                            {t('invoice:pdf:doctor')}
                        </Text>
                    </View>
                    <View pdfMode={true} className="flex w-50">
                        <Text pdfMode={true} className="bold">
                            {t('invoice:pdf:patient')}
                        </Text>
                    </View>
                </View>
                <View pdfMode={true} className="flex">
                    <View pdfMode={true} className="flex w-50">
                        <View pdfMode={true} className="w-50">
                            <Text pdfMode={true}>{t('invoice:pdf:name') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:street') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:city') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:post_code') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:phone_number') + ':'}</Text>
                            {invoice.doctorFax && <Text pdfMode={true}>{t('invoice:pdf:fax') + ':'}</Text>}
                            <Text pdfMode={true}>{t('invoice:pdf:vat_id') + ':'}</Text>
                        </View>
                        <View pdfMode={true} className="w-50">
                            <Text pdfMode={true}>{invoice.doctorName}</Text>
                            <Text pdfMode={true}>{invoice.doctorAddress}</Text>
                            <Text pdfMode={true}>{invoice.doctorAddress2}</Text>
                            <Text pdfMode={true}>{invoice.doctorPostalCode}</Text>
                            <Text pdfMode={true}>{invoice.doctorTel}</Text>
                            {invoice.doctorFax && <Text pdfMode={true}>{invoice.doctorFax}</Text>}
                            <Text pdfMode={true}>{invoice.doctorVatID}</Text>
                        </View>
                    </View>
                    <View pdfMode={true} className="flex w-50">
                        <View pdfMode={true} className="w-50">
                            <Text pdfMode={true}>{t('invoice:pdf:name') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:street') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:city') + ':'}</Text>
                            <Text pdfMode={true}>{t('invoice:pdf:post_code') + ':'}</Text>
                        </View>
                        <View pdfMode={true} className="w-50">
                            <Text pdfMode={true}>{invoice.patientName}</Text>
                            <Text pdfMode={true}>{invoice.patientAddress}</Text>
                            <Text pdfMode={true}>{invoice.patientAddress2}</Text>
                            <Text pdfMode={true}>{invoice.patientPostalCode}</Text>
                        </View>
                    </View>
                </View>
                <View pdfMode={true} className="row mt-5 mb-10"></View>
                <View pdfMode={true} className="flex w-100 m-0">
                    <Text pdfMode={true} className="bg-gray w-12 border bold textCenter m-0">
                        {t('invoice:pdf:number_of_item')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-25 border bold textCenter m-0">
                        {t('invoice:pdf:item_name')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 border bold textCenter m-0">
                        {t('invoice:pdf:factor')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 border bold textCenter m-0">
                        {t('invoice:pdf:price')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 border bold textCenter m-0">
                        {t('invoice:pdf:net')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 border bold textCenter m-0">
                        {t('invoice:pdf:vat')}
                    </Text>
                    <Text pdfMode={true} className="bg-gray w-12 border bold textCenter m-0 ">
                        {t('invoice:pdf:gross')}
                    </Text>
                </View>
                {data_?.items?.map((item) => {
                    item.vat = 19;
                    return (
                        <View key={item.number} pdfMode={true} className="flex w-100 m-0">
                            <Text pdfMode={true} className="w-12 border textCenter m-0">
                                {item.number.toString()}
                            </Text>
                            <Text pdfMode={true} className="w-25 border textCenter m-0">
                                {item.name}
                            </Text>
                            <Text pdfMode={true} className="w-12 border textCenter m-0 ">
                                {item.factor.toString()}
                            </Text>
                            <Text pdfMode={true} className="w-12 border textCenter m-0">
                                {item.price.toFixed(2).toString().replace('.', ',') + ' €'}
                            </Text>
                            <Text pdfMode={true} className="w-12 border textCenter m-0">
                                {(
                                    item.price * item.factor -
                                    (item.price * item.factor - item.price * item.factor * (1 - item.vat / 100))
                                )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',') + ' €'}
                            </Text>
                            <Text pdfMode={true} className="w-12 border textCenter m-0">
                                {(item.price * item.factor - item.price * item.factor * (1 - item.vat / 100))
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',') + ' €'}
                            </Text>
                            <Text pdfMode={true} className="w-12 border textCenter m-0">
                                {(item.price * item.factor).toFixed(2).toString().replace('.', ',') + ' €'}
                            </Text>
                        </View>
                    );
                })}
                <View pdfMode={true} className="inlineBlock">
                    <View pdfMode={true} className="flex marginLeft">
                        <Text pdfMode={true}>{t('invoice:pdf:sum')}</Text>
                        <Text pdfMode={true} className="bg-gray w-12 border textCenter">
                            {invoice.items
                                ?.reduce((sum, item) => sum + item.price * item.factor * (1 - 0.19), 0)
                                .toFixed(2)
                                .toString()
                                .replace('.', ',') + ' €'}
                        </Text>
                        <Text pdfMode={true} className="bg-gray w-12 border textCenter">
                            {invoice.items
                                ?.reduce(
                                    (sum, item) =>
                                        sum + (item.price * item.factor - item.price * item.factor * (1 - 0.19)),
                                    0,
                                )
                                .toFixed(2)
                                .toString()
                                .replace('.', ',') + ' €'}
                        </Text>
                        <Text pdfMode={true} className="bg-gray w-12 border textCenter">
                            {invoice.items
                                ?.reduce((sum, item) => sum + item.price * item.factor, 0)
                                .toFixed(2)
                                .toString()
                                .replace('.', ',') + ' €'}
                        </Text>
                    </View>
                </View>
                <View pdfMode={true} className="row mt-10"></View>
                <View pdfMode={true} className="flex">
                    <View pdfMode={true} className="w-25">
                        <Text pdfMode={true}>{t('invoice:pdf:method_of_pay') + ': '}</Text>
                        <Text pdfMode={true}>{t('invoice:pdf:bank_acc') + ': '}</Text>
                        <Text pdfMode={true}>{t('invoice:pdf:due_date') + ': '}</Text>
                        <Text pdfMode={true} className="bold">
                            {t('invoice:pdf:to_pay') + ': '}
                        </Text>
                    </View>
                    <View pdfMode={true} className="w-75">
                        <Text pdfMode={true}>{t('invoice:pdf:bank_transfer')}</Text>
                        <Text pdfMode={true} className="bold">
                            {invoice.doctorBankNr}
                        </Text>
                        <Text pdfMode={true}>{dateToLocaleString(invoice.invoiceDueDate)}</Text>
                        <Text pdfMode={true} className="bold">
                            {invoice.items
                                ?.reduce((sum, item) => sum + item.price * item.factor, 0)
                                .toFixed(2)
                                .toString()
                                .replace('.', ',') + ' €'}
                        </Text>
                    </View>
                </View>
                <View pdfMode={true} className="inlineBlock">
                    <Text pdfMode={true}>{t('invoice:pdf:diagnosis') + ':'}</Text>
                    <Text pdfMode={true}>{invoice.diagnosis}</Text>
                </View>
            </Page>
        </Document>
    );
    // return (
    //     <>
    //         {!pdfMode && <Download data={invoice} />}
    //         <Document pdfMode={pdfMode}>
    //             <Page className="invoice-wrapper" pdfMode={pdfMode}>
    //                 <View className="flex ml-50" pdfMode={pdfMode}>
    //                     <Text className="fs-20 bold" pdfMode={pdfMode}>
    //                         Invoice
    //                     </Text>
    //                     <div style={{ marginLeft: '10px' }} />
    //                     <EditableInput
    //                         className="fs-20 bold"
    //                         placeholder={invoice.invoiceNr}
    //                         value={invoice.invoiceNr}
    //                         onChange={(value) => {
    //                             handleChange('invoiceNr', value);
    //                         }}
    //                         pdfMode={pdfMode}
    //                     />
    //                 </View>
    //                 <View className="flex" pdfMode={pdfMode}>
    //                     <View className="w-50" pdfMode={pdfMode}>
    //                         <Row>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorName}
    //                                 value={invoice.doctorName}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorName', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </Row>
    //                         <Row>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorAddress}
    //                                 value={invoice.doctorAddress}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorAddress', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </Row>
    //                         <Row>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorAddress2}
    //                                 value={invoice.doctorAddress2}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorAddress2', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </Row>
    //                     </View>
    //                     <View className="w-50 invoice-right" pdfMode={pdfMode}>
    //                         <View pdfMode={pdfMode} className="flex invoice-right">
    //                             <Text className="fs-10" pdfMode={pdfMode}>
    //                                 Tel.
    //                             </Text>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorTel}
    //                                 value={invoice.doctorTel}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorTel', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </View>
    //                         {invoice.doctorFax !== '' && (
    //                             <View pdfMode={pdfMode} className="flex invoice-right">
    //                                 <Text className="fs-10" pdfMode={pdfMode}>
    //                                     Fax
    //                                 </Text>
    //                                 <EditableInput
    //                                     className="fs-10"
    //                                     placeholder={invoice.doctorFax}
    //                                     value={invoice.doctorFax}
    //                                     onChange={(value) => {
    //                                         handleChange('doctorFax', value);
    //                                     }}
    //                                     pdfMode={pdfMode}
    //                                 />
    //                             </View>
    //                         )}
    //                         <View pdfMode={pdfMode} className="flex invoice-right">
    //                             <Text className="fs-10" pdfMode={pdfMode}>
    //                                 E-mail
    //                             </Text>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorEmail}
    //                                 value={invoice.doctorEmail}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorEmail', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </View>
    //                         <View pdfMode={pdfMode} className="flex invoice-right">
    //                             <Text className="fs-10" pdfMode={pdfMode}>
    //                                 Vat ID
    //                             </Text>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorVatID}
    //                                 value={invoice.doctorVatID}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorVatID', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </View>
    //                     </View>
    //                 </View>
    //                 <br />
    //                 <View className="flex" pdfMode={pdfMode}>
    //                     <View pdfMode={pdfMode} className="w-50">
    //                         <Row style={{ display: 'flex', justifyContent: 'left' }}>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.patientName}
    //                                 value={invoice.patientName}
    //                                 onChange={(value) => {
    //                                     handleChange('patientName', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </Row>
    //                         <Row style={{ display: 'flex', justifyContent: 'left' }}>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.patientAddress}
    //                                 value={invoice.patientAddress}
    //                                 onChange={(value) => {
    //                                     handleChange('patientAddress', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </Row>
    //                         <Row style={{ display: 'flex', justifyContent: 'left' }}>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.patientAddress2}
    //                                 value={invoice.patientAddress2}
    //                                 onChange={(value) => {
    //                                     handleChange('patientAddress2', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </Row>
    //                     </View>
    //                     <View pdfMode={pdfMode} className="w-50">
    //                         <View pdfMode={pdfMode} className="flex invoice-right">
    //                             <Text className="fs-10" pdfMode={pdfMode}>
    //                                 Date of invoice
    //                             </Text>
    //                             <EditableCalendarInput
    //                                 className="fs-10"
    //                                 value={format(invoiceDate, dateFormat)}
    //                                 selected={invoiceDate}
    //                                 onChange={(date) => {
    //                                     handleChange(
    //                                         'invoiceDate',
    //                                         date && !Array.isArray(date) ? format(date, dateFormat) : '',
    //                                     );
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </View>
    //                         <View pdfMode={pdfMode} className="flex invoice-right">
    //                             <Text className="fs-10" pdfMode={pdfMode}>
    //                                 Paymant due
    //                             </Text>
    //                             <EditableCalendarInput
    //                                 className="fs-10"
    //                                 value={format(invoiceDueDate, dateFormat)}
    //                                 selected={invoiceDueDate}
    //                                 onChange={(date) => {
    //                                     handleChange(
    //                                         'invoiceDueDate',
    //                                         date && !Array.isArray(date) ? format(date, dateFormat) : '',
    //                                     );
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                             {/*<EditableInput*/}
    //                             {/*    className="fs-10"*/}
    //                             {/*    placeholder={invoice.invoiceDueDate}*/}
    //                             {/*    value={invoice.invoiceDueDate}*/}
    //                             {/*    onChange={(value) => {*/}
    //                             {/*        handleChange('invoiceDueDate', value);*/}
    //                             {/*    }}*/}
    //                             {/*    pdfMode={pdfMode}*/}
    //                             {/*/>*/}
    //                         </View>
    //                         <View pdfMode={pdfMode} className="flex invoice-right">
    //                             <Text className="fs-10" pdfMode={pdfMode}>
    //                                 Bank account
    //                             </Text>
    //                             <EditableInput
    //                                 className="fs-10"
    //                                 placeholder={invoice.doctorBankNr}
    //                                 value={invoice.doctorBankNr}
    //                                 onChange={(value) => {
    //                                     handleChange('doctorBankNr', value);
    //                                 }}
    //                                 pdfMode={pdfMode}
    //                             />
    //                         </View>
    //                     </View>
    //                 </View>
    //                 <View className="row"></View>
    //                 <View className="mt-20">
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         Diagnoza:
    //                     </Text>
    //                     <EditableTextarea
    //                         className="w-100 fs-10"
    //                         pdfMode={pdfMode}
    //                         rows={3}
    //                         placeholder={invoice.diagnosis}
    //                         value={invoice.diagnosis}
    //                         onChange={(value) => handleChange('diagnosis', value)}
    //                     ></EditableTextarea>
    //                 </View>
    //                 <br />
    //                 <View className="mt-30 bg-dark flex" pdfMode={pdfMode}>
    //                     <View className="w-75 p-4-8" pdfMode={pdfMode}>
    //                         <EditableInput className="white bold" value={'Item'} readonly={true} pdfMode={pdfMode} />
    //                     </View>
    //                     <View className="w-8 p-4-8" pdfMode={pdfMode}>
    //                         <EditableInput
    //                             className="w-100 white bold right"
    //                             readonly={true}
    //                             value={'Factor'}
    //                             pdfMode={pdfMode}
    //                         />
    //                     </View>
    //                     <View className="w-8 p-4-8" pdfMode={pdfMode}>
    //                         <EditableInput
    //                             className="w-100 white bold right"
    //                             readonly={true}
    //                             value={'Price'}
    //                             pdfMode={pdfMode}
    //                         />
    //                     </View>
    //                     <View className="w-8 p-4-8" pdfMode={pdfMode}>
    //                         <EditableInput
    //                             className="w-100 white bold right"
    //                             readonly={true}
    //                             value={'Amount'}
    //                             pdfMode={pdfMode}
    //                         />
    //                     </View>
    //                 </View>
    //                 {invoice.items?.map((item, index) => {
    //                     return (
    //                         <View key={item.name} className="flex row" pdfMode={pdfMode}>
    //                             <View className="w-75 p-4-8" pdfMode={pdfMode}>
    //                                 <EditableTextarea
    //                                     className="w-100 fs-8"
    //                                     value={item.name}
    //                                     pdfMode={pdfMode}
    //                                     rows={4}
    //                                 />
    //                             </View>
    //                             <View className="w-8 p-4-8" pdfMode={pdfMode}>
    //                                 <EditableInput
    //                                     className="w-100 fs-10"
    //                                     value={item.factor.toString()}
    //                                     pdfMode={pdfMode}
    //                                     onChange={(value) => handleItemChange(index, 'factor', parseFloat(value))}
    //                                 />
    //                             </View>
    //                             <View className="w-8 p-4-8 " pdfMode={pdfMode}>
    //                                 <EditableInput
    //                                     className="w-100 fs-10"
    //                                     value={item.price.toString() + ' €'}
    //                                     pdfMode={pdfMode}
    //                                     readonly={true}
    //                                 />
    //                             </View>
    //                             <View className="w-8 p-4-8" pdfMode={pdfMode}>
    //                                 <EditableInput
    //                                     className="w-100 fs-10"
    //                                     value={(item.price * item.factor).toFixed(2).toString() + ' €'}
    //                                     pdfMode={pdfMode}
    //                                     readonly={true}
    //                                 />
    //                             </View>
    //                         </View>
    //                     );
    //                 })}
    //                 {!pdfMode && (
    //                     <>
    //                         <button
    //                             onClick={() => {
    //                                 setAddLine(true);
    //                             }}
    //                         >
    //                             <AddIcon></AddIcon>
    //                             Add Item
    //                         </button>
    //                         {addLine && (
    //                             <>
    //                                 <br />
    //                                 <View pdfMode={false}>
    //                                     <label>Item</label>
    //                                     <EditableSelect
    //                                         pdfMode={false}
    //                                         options={items}
    //                                         value={selectedItem}
    //                                         onChange={(value) => handleChangeSelection(value)}
    //                                     />
    //                                     <label>Factor</label>
    //                                     <EditableInput
    //                                         value={selectedFactor.toString()}
    //                                         pdfMode={false}
    //                                         onChange={(value) => {
    //                                             setSelectedFactor(parseFloat(value));
    //                                         }}
    //                                     />
    //                                     <button onClick={handleAddItem}>Add</button>
    //                                 </View>
    //                             </>
    //                         )}
    //                     </>
    //                 )}
    //                 <View pdfMode={pdfMode} className="flex invoice-right">
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         Net :
    //                     </Text>
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         {invoice.items
    //                             ?.reduce((sum, v) => (sum = sum + v.factor * v.price * 0.81), 0)
    //                             .toFixed(2)
    //                             .toString() + ' €'}
    //                     </Text>
    //                 </View>
    //                 <View pdfMode={pdfMode} className="flex invoice-right">
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         Tax :
    //                     </Text>
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         {invoice.items
    //                             ?.reduce((sum, v) => (sum = sum + v.factor * v.price * 0.19), 0)
    //                             .toFixed(2)
    //                             .toString() + ' €'}
    //                     </Text>
    //                 </View>
    //                 <View pdfMode={pdfMode} className="flex invoice-right">
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         Total :
    //                     </Text>
    //                     <Text className="fs-10" pdfMode={pdfMode}>
    //                         {invoice.items
    //                             ?.reduce((sum, v) => (sum = sum + v.factor * v.price), 0)
    //                             .toFixed(2)
    //                             .toString() + ' €'}
    //                     </Text>
    //                 </View>
    //             </Page>
    //         </Document>
    //     </>
    // );
}

export default InvoiceModalBody;
