import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import klarConfig, { klaroConfig } from 'klaro/config';
import { privatePolicyLink } from './klaroConstants';

const klaroScriptSrc = 'https://cdn.kiprotect.com/klaro/v0.7.0/klaro.js';

interface StylingConfig {
    theme: string[];
}

interface Service {
    name: string;
    default: boolean;
    contextualConsentOnly: boolean;
    purposes: string[];
    cookies?: string[];
    required?: boolean;
}

interface PurposeDescription {
    title: string;
    description: string;
}

interface LanguageTranslations {
    privacyPolicyUrl: string;
    consentModal: {
        description?: string;
        title?: string;
    };
    _ga: {
        title: string;
        description: string;
    };
    _ga_224E3R0888: {
        title: string;
        description: string;
    };
    Invisalign: {
        title: string;
        description: string;
    };
    purposes: {
        necessary: PurposeDescription;
        functional: PurposeDescription;
        analytics: PurposeDescription;
        marketing: PurposeDescription;
    };
}

interface Translations {
    [lang: string]: LanguageTranslations;
}

export interface KlaroConfig {
    version: number;
    elementID: string;
    styling: StylingConfig;
    noAutoLoad: boolean;
    htmlTexts: boolean;
    embedded: boolean;
    groupByPurpose: boolean;
    storageMethod: 'cookie' | 'localStorage';
    cookieName: string;
    cookieExpiresAfterDays: number;
    cookieDomain?: string;
    cookiePath?: string;
    default: boolean;
    mustConsent: boolean;
    acceptAll: boolean;
    hideDeclineAll: boolean;
    hideLearnMore: boolean;
    noticeAsModal: boolean;
    disablePoweredBy: boolean;
    additionalClass?: string;
    lang: string;
    translations: Translations;
    services: Service[];
}

const useKlaro = () => {
    const initializedRef = useRef(false);
    const { i18n, ready } = useTranslation();
    const [lang, setLang] = useState<string>('de');
    const [translationsLoaded, setTranslationsLoaded] = useState(false);
    const previousLang = useRef<string>(lang);

    const loadTranslations = useCallback(
        async (language: string) => {
            if (language !== 'de' && language !== 'en') {
                console.warn(`Translations for language: ${language} are not supported. Defaulting to 'de'.`);
                language = 'de';
            }

            if (ready) {
                try {
                    await i18n.loadNamespaces('policy_notes');

                    const translations = i18n.getResourceBundle(language, 'policy_notes');

                    if (translations) {
                        return translations;
                    } else {
                        throw new Error(`Translations not found for language: ${language}`);
                    }
                } catch (error) {
                    console.error(`Error loading translations for language: ${language}`, error);
                    throw new Error(`Error loading translations for language: ${language}`);
                }
            } else {
                throw new Error('i18next is not ready');
            }
        },
        [i18n, ready],
    );

    const setupKlaro = useCallback(
        async (language: string) => {
            if (initializedRef.current) return;

            const existingScript = document.querySelector(`script[src="${klaroScriptSrc}"]`) as HTMLElement;
            if (existingScript) {
                document.body.removeChild(existingScript);
            }

            const script = document.createElement('script');
            script.src = klaroScriptSrc;
            script.async = true;
            document.body.appendChild(script);

            const translations = await loadTranslations(language);
            klaroConfig['translations'][language] = translations as LanguageTranslations;
            klarConfig.lang = language;
            window.klarConfig = klarConfig;

            script.onload = () => {
                if (window.klaro) {
                    window.klaro.setup(klarConfig);
                }
                const intervalId = setInterval(() => {
                    const modalContent = document.querySelector('.cm-modal') as HTMLElement;

                    const cookieNotice = document.querySelector('.cookie-notice') as HTMLElement;
                    const cnBody = document.querySelector('.cn-body');
                    const learnMoreLink = document.querySelector('.cn-learn-more') as HTMLElement;
                    const buttons = document.querySelector('.cn-buttons') as HTMLElement;
                    const successBtn = document.querySelector('.cm-btn-success') as HTMLElement;
                    const dangerBtn = document.querySelector('.cm-btn-danger') as HTMLElement;

                    if (modalContent && !modalContent.dataset.stylesApplied) {
                        modalContent.style.backgroundColor = 'white';

                        const title = modalContent.querySelector('.title') as HTMLElement;
                        const paragraph = modalContent.querySelector('p');
                        const privatePolicyNote = modalContent.querySelector('a');
                        const acceptAllBtn = modalContent.querySelector('.cm-btn-accept-all') as HTMLElement;
                        const acceptBtn = modalContent.querySelector('.cm-btn-accept') as HTMLElement;
                        const declineBtn = modalContent.querySelector('.cm-btn-decline') as HTMLElement;
                        const analytics = modalContent.querySelector('.cm-list-title') as HTMLElement;
                        const serviceRoll = modalContent.querySelector('.cm-caret a') as HTMLElement;
                        const purposes = modalContent.querySelectorAll<HTMLInputElement>('.cm-purpose');

                        const policyLink = modalContent.querySelector('.cm-header a') as HTMLAnchorElement;

                        if (policyLink) {
                            policyLink.href = privatePolicyLink;
                            policyLink.target = '_blank';
                            policyLink.rel = 'noopener';
                        }

                        const checkboxes = modalContent.querySelectorAll<HTMLInputElement>(
                            '.cm-list-input:checked+.cm-list-label .slider',
                        );

                        if (checkboxes?.length > 0 && !modalContent.dataset.stylesApplied) {
                            checkboxes.forEach((checkbox) => {
                                const checkboxInput =
                                    checkbox.parentElement?.querySelector<HTMLInputElement>('.cm-list-input');
                                if (checkboxInput && checkboxInput.checked) {
                                    checkboxInput.style.backgroundColor = '#3640E1';
                                    modalContent.dataset.stylesApplied = 'true';
                                }

                                checkbox.style.backgroundColor = '#3640E1';
                                checkbox.dataset.stylesApplied = 'true';

                                checkboxInput?.addEventListener('change', function () {
                                    const label = checkboxInput.nextElementSibling as HTMLElement;
                                    if (label) {
                                        const slider = label.querySelector('.slider') as HTMLElement;
                                        if (slider) {
                                            if (checkboxInput.checked) {
                                                slider.style.backgroundColor = '#3640E1';
                                            } else {
                                                slider.style.backgroundColor = '#ccc';
                                            }
                                        }
                                    }
                                });
                            });
                        }

                        const listItems = modalContent.querySelectorAll('.cm-content.expanded li');
                        listItems.forEach((li) => {
                            const spanTitle = li.querySelector('.cm-list-title') as HTMLElement;
                            if (spanTitle) {
                                spanTitle.style.color = 'black';
                            }
                        });

                        purposes.forEach((item) => {
                            const anchor = item.querySelector('.cm-services .cm-caret a') as HTMLElement;
                            const listTitle = item.querySelector('.cm-list-title') as HTMLElement;
                            if (anchor) {
                                anchor.style.color = '#3640E1';
                            }
                            if (listTitle) {
                                listTitle.style.color = 'black';
                            }
                        });

                        const otherCheckboxes = modalContent.querySelectorAll<HTMLInputElement>(
                            '.cm-list-input:not(:checked)+.cm-list-label .slider',
                        );
                        if (otherCheckboxes) {
                            otherCheckboxes.forEach((checkbox) => {
                                const checkboxInput =
                                    checkbox.parentElement?.querySelector<HTMLInputElement>('.cm-list-input');
                                checkbox.style.backgroundColor = '#ccc';

                                checkboxInput?.addEventListener('change', function () {
                                    const label = checkboxInput.nextElementSibling as HTMLElement;
                                    if (label) {
                                        const slider = label.querySelector('.slider') as HTMLElement;
                                        if (slider) {
                                            if (checkboxInput.checked) {
                                                slider.style.backgroundColor = '#3640E1';
                                            } else {
                                                slider.style.backgroundColor = '#ccc';
                                            }
                                        }
                                    }
                                });
                            });
                        }

                        if (privatePolicyNote && !privatePolicyNote.dataset.stylesApplied) {
                            privatePolicyNote.style.color = '#3640E1';
                            privatePolicyNote.style.textDecoration = 'underline';
                            privatePolicyNote.dataset.stylesApplied = 'true';
                        }

                        if (title) {
                            title.style.color = 'black';
                        }

                        if (paragraph) {
                            paragraph.style.color = 'black';
                        }
                        if (analytics && !analytics.dataset.stylesApplied) {
                            analytics.style.color = 'black';
                        }

                        if (acceptAllBtn && !acceptAllBtn.dataset.stylesApplied) {
                            acceptAllBtn.style.width = '100%';
                            acceptAllBtn.style.paddingLeft = '8px';
                            acceptAllBtn.style.paddingRight = '8px';
                            acceptAllBtn.style.backgroundColor = '#3640E1';
                            acceptAllBtn.dataset.stylesApplied = 'true';
                        }

                        if (acceptBtn && !acceptBtn.dataset.stylesApplied) {
                            acceptBtn.style.width = '100%';
                            acceptBtn.style.paddingLeft = '8px';
                            acceptBtn.style.paddingRight = '8px';
                            acceptBtn.style.backgroundColor = '#3640E1';
                            acceptBtn.dataset.stylesApplied = 'true';
                        }

                        if (declineBtn && !declineBtn.dataset.stylesApplied) {
                            declineBtn.style.width = '100%';
                            declineBtn.style.backgroundColor = '#5c5c5c';
                            declineBtn.style.color = 'white';
                            declineBtn.dataset.stylesApplied = 'true';
                        }
                        if (serviceRoll && !serviceRoll.dataset.stylesApplied) {
                            serviceRoll.style.color = 'blue';
                            serviceRoll.dataset.stylesApplied = 'true';
                        }
                    }

                    if (cnBody) {
                        const paragraph = cnBody.querySelector('p');

                        if (paragraph) {
                            paragraph.style.color = 'black';

                            const strongTag = paragraph.querySelector('strong');
                            if (strongTag) {
                                strongTag.style.color = 'black';
                            }
                        }
                    }

                    if (learnMoreLink) {
                        learnMoreLink.style.color = '#3640E1';
                    }

                    if (buttons) {
                        successBtn.style.paddingLeft = '8px';
                        successBtn.style.paddingLeft = '8px';
                        successBtn.style.backgroundColor = '#3640E1';
                        dangerBtn.style.backgroundColor = 'white';
                        dangerBtn.style.color = 'black';
                        dangerBtn.style.border = '0.5px solid rgba(0, 0, 0, 0.5)';
                    }

                    if (cookieNotice) {
                        cookieNotice.style.backgroundColor = 'white';
                    }

                    if (modalContent && cnBody && learnMoreLink && buttons && cookieNotice) {
                        clearInterval(intervalId);
                    }
                }, 30);
            };
            initializedRef.current = true;

            return () => {
                document.body.removeChild(script);
            };
        },
        [loadTranslations],
    );

    useEffect(() => {
        if (ready) {
            loadTranslations(lang)
                .then(() => {
                    setTranslationsLoaded(true);
                })
                .catch((error) => {
                    console.error('Failed to load translations:', error);
                });
        }
    }, [lang, ready, loadTranslations]);

    useEffect(() => {
        const handleLanguageChange = (lng: string) => {
            if (lng === 'de' || lng === 'en') {
                previousLang.current = lang;
                setLang(lng);
                initializedRef.current = false;
                setTranslationsLoaded(false);
                setupKlaro(lng);
            } else {
                console.warn(
                    `Language change to ${lng} is not supported. Reverting to previous language: ${previousLang.current}`,
                );
                i18n.changeLanguage(previousLang.current);
            }
        };

        i18n.on('languageChanged', handleLanguageChange);
        return () => i18n.off('languageChanged', handleLanguageChange);
    }, [i18n, lang, setupKlaro]);

    const showConsentModal = useCallback(() => {
        if (window.klaro) {
            window.klaro.show();
        }
    }, []);

    return { setupKlaro, showConsentModal };
};

export default useKlaro;
