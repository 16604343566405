import { withFormik } from 'formik';
import { EditDoctorProps, EditDoctorValues } from './EditDoctor.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './EditDoctor.schema';
import { EditDoctorModal } from './EditDoctorModal';
import { withTranslation } from 'react-i18next';

const EditDoctorForm = withFormik<EditDoctorProps, EditDoctorValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(EditDoctorModal);
export default withTranslation()(EditDoctorForm);
