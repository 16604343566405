import React, { ReactNode, useEffect, useState } from 'react';
import { Card, Radio, Stack } from '@mui/joy';

type Props = {
    value: number;
    label: string;
    onClick?: ((value: number) => void) | undefined;
    style?: React.CSSProperties | undefined;
    startDecorator?: ReactNode | undefined;
    defaultSelected?: boolean | ((value: number) => boolean);
    selected?: boolean | undefined;
    size?: 'sm' | 'md' | 'lg';
};
export const RadioButton = ({
    value,
    label,
    selected = undefined,
    onClick = undefined,
    style = undefined,
    startDecorator = undefined,
    defaultSelected = false,
    size = 'md',
}: Props) => {
    const [localSelected, setLocalSelected] = useState(
        typeof defaultSelected === 'boolean' ? defaultSelected : defaultSelected(value),
    );

    useEffect(() => {
        if (selected === undefined) return;

        setLocalSelected(selected);
    }, [selected]);

    const handleClick = () => {
        if (onClick) onClick(value);

        setLocalSelected(!localSelected);
    };

    const isSelected = () => {
        return selected !== undefined ? localSelected && selected : localSelected;
    };

    return (
        <Card
            size={size}
            variant="outlined"
            onClick={handleClick}
            style={style}
            sx={{
                background: 'var(--joy-palette-background-level-2)',
                borderColor: isSelected() ? 'var(--joy-palette-primary-500)' : { undefined },
            }}
        >
            <Stack direction="row" spacing={1}>
                <Radio
                    overlay
                    checked={isSelected()}
                    value={value}
                    label={label}
                    sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                />
                {startDecorator}
            </Stack>
        </Card>
    );
};
