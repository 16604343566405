import React from 'react';
import { FormControl } from '@mui/joy';

type Props = {
    children: React.ReactNode;
    isError: boolean;
    isTouched: boolean | undefined;
    readOnly?: boolean;
    className?: string;
    style?: React.CSSProperties | undefined;
    showSuccessColors?: boolean;
};
export const FormControlStyled = ({
    isError,
    isTouched,
    children,
    style = undefined,
    readOnly = false,
    className = '',
    showSuccessColors = false,
}: Props) => {
    return (
        <FormControl
            error={isError}
            color={!isError && !readOnly && (isTouched ?? false) && showSuccessColors ? 'success' : undefined}
            className={className}
            style={style}
        >
            {children}
        </FormControl>
    );
};
