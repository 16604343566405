import React from 'react';
import { Button, Grid, Sheet, Stack, Table, Typography } from '@mui/joy';
import { adminPanelRouteInfo } from '../shared/Routing/routeObjects';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../shared/hooks/useWindowSize';
import { useInvoiceData } from '../Dashboard/AppointmentDetailsSection/BillingTab/hooks/useInvoiceData';
import IconButton from '@mui/joy/IconButton';
import { AddOutlined, Check } from '@mui/icons-material';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import { getScaledVh } from '../../utils/styleUtils';
import { EditInvoiceItemModal } from './EditInvoiceItem/EditInvoiceItemModal';
import { InvoiceActions } from './SubModules/InvoiceActions';
import { AddBankCsv } from './AddBankCsv/AddBankCsv';
import { TableHeader } from '../shared/TypeUtils/TableHeader';

export const AdminPanel = () => {
    const { t } = useTranslation();
    const adminPanelInfo = adminPanelRouteInfo(t);
    const { isLarge } = useWindowSize();
    const { invoiceBillingItems, deleteInvoice, editInvoice, refreshBillingItems, addInvoice, request } =
        useInvoiceData();
    const tableHeaders: TableHeader[] = [
        { label: t('adminPage:table_label_number'), percentage: 10 },
        { label: t('adminPage:table_label_description'), percentage: 40 },
        { label: t('adminPage:table_label_amount'), percentage: 10 },
        { label: t('adminPage:table_label_max_factor'), percentage: 10 },
        { label: t('adminPage:table_label_constant'), percentage: 10 },
        { label: t('adminPage:table_label_actions'), percentage: 10 },
    ];
    const middleRowMobileScaleMultiplier = 6;

    const getColumnWidth = (index: number, baseWidth: number) => {
        if (isLarge) return baseWidth;
        return baseWidth * middleRowMobileScaleMultiplier;
    };

    return (
        <Stack
            display="flex"
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            sx={{ width: 'calc(100% + 40px)' }}
            spacing={1}
            style={{ marginTop: 0, marginLeft: -20, marginRight: -20 }}
        >
            {isLarge ? (
                <Stack
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        paddingX: !isLarge ? '5px' : '20px',
                    }}
                >
                    <Typography level="h2" startDecorator={adminPanelInfo.icon}>
                        {adminPanelInfo.name}
                    </Typography>
                    <Stack spacing={1} direction="row" alignItems="center" justifyContent="end">
                        <AddBankCsv />
                        <EditInvoiceItemModal
                            request={request}
                            editInvoice={editInvoice}
                            addInvoice={addInvoice}
                            refreshItems={refreshBillingItems}
                            button={
                                <Button variant="outlined" startDecorator={<AddOutlined />} color="neutral">
                                    {t('buttons:button_add_item')}
                                </Button>
                            }
                            mode="add"
                        />
                    </Stack>
                </Stack>
            ) : (
                <Grid container width="100%" alignItems="center">
                    <Grid container xs={2} justifyContent="center">
                        {adminPanelInfo.icon}
                    </Grid>
                    <Grid container xs={8} justifyContent="center">
                        <Typography startDecorator={<ArrowLeftIcon />} endDecorator={<ArrowRightIcon />}>
                            {t('adminPage:label_scroll_direction')}
                        </Typography>
                    </Grid>
                    <Grid container xs={2} justifyContent="center">
                        <EditInvoiceItemModal
                            editInvoice={editInvoice}
                            request={request}
                            addInvoice={addInvoice}
                            refreshItems={refreshBillingItems}
                            button={
                                <IconButton variant="outlined">
                                    <AddOutlined />
                                </IconButton>
                            }
                            mode="add"
                        />
                    </Grid>
                </Grid>
            )}
            <Sheet
                variant="outlined"
                sx={{
                    '--TableCell-height': '40px',
                    '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
                    '--Table-firstColumnWidth': '80px',
                    '--Table-lastColumnWidth': '144px',
                    maxHeight: getScaledVh(92, !isLarge),
                    overflow: 'auto',
                    width: '100%',
                    backgroundSize:
                        '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'local, local, scroll, scroll',
                    backgroundPosition:
                        'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
                }}
            >
                <Table
                    stickyHeader
                    borderAxis="bothBetween"
                    style={{ maxHeight: '100%', overflowY: 'auto' }}
                    sx={{
                        '& tr > *:first-child': {
                            position: 'sticky',
                            left: 0,
                            bgcolor: 'background.surface',
                        },
                        '& tr > *:last-child': {
                            position: 'sticky',
                            right: 0,
                            bgcolor: 'var(--TableCell-headBackground)',
                        },
                    }}
                >
                    <thead>
                        <tr>
                            {tableHeaders.map((element, index) => (
                                <th
                                    key={index}
                                    style={{
                                        textAlign: 'center',
                                        width: `${getColumnWidth(index, element.percentage)}px`,
                                        zIndex: index === 0 ? 2 : 1,
                                    }}
                                    aria-label={index === tableHeaders.length - 1 ? 'last' : undefined}
                                >
                                    {element.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceBillingItems.map((element, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'center', zIndex: 0 }}>{element.number}</td>
                                <td style={{ zIndex: -1 }}>{element.description}</td>
                                <td style={{ textAlign: 'center', zIndex: -1 }}>{element.price.toLocaleString()}</td>
                                <td style={{ textAlign: 'center', zIndex: -1 }}>{element.max_factor}</td>
                                <td style={{ textAlign: 'center', zIndex: -1 }}>
                                    {element.factor_is_constant && <Check />}
                                </td>
                                <td style={{ textAlign: 'center', zIndex: 0 }}>
                                    {
                                        <InvoiceActions
                                            request={request}
                                            addInvoice={addInvoice}
                                            editInvoice={editInvoice}
                                            invoice={element}
                                            deleteInvoice={deleteInvoice}
                                            refreshItems={refreshBillingItems}
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Sheet>
        </Stack>
    );
};
