import React from 'react';
import { BetterModal } from '../../../../../shared/BetterModal/BetterModal';
import { Button, ButtonGroup, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../../../shared/hooks/useWindowSize';
import { Form } from 'react-bootstrap';
import { InvoiceMobileProps, InvoiceMobileValues } from './InvoiceMobileForm.types';
import { FormikProps } from 'formik';
import { InvoiceMobileBody } from './InvoiceMobileBody';
import { useAuthContext } from '../../../../../Auth/AuthContext/AuthContext';
import { UserRole } from '../../../../../../api/models';

function InvoiceModal(baseProps: InvoiceMobileProps & FormikProps<InvoiceMobileValues>) {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const authContext = useAuthContext();

    return (
        <Form>
            <BetterModal
                state={baseProps.modalState}
                title={t('billing:invoice')}
                disabled={authContext?.authUser?.role != UserRole.organizationMember}
                button={
                    <Button disabled={authContext?.authUser?.role != UserRole.organizationMember} fullWidth>
                        {t('buttons:button_issue_invoice')}
                    </Button>
                }
                body={<InvoiceMobileBody baseProps={baseProps} />}
                minWidth={650}
                maxWidth={2000}
                layout={isExtraSmall ? 'fullscreen' : 'center'}
                actions={
                    <Stack spacing={1} style={{ width: '100%' }}>
                        <ButtonGroup style={{ maxWidth: '100%' }} variant="solid" color="primary" spacing={1}>
                            <Button
                                type="submit"
                                onClick={(e) => {
                                    baseProps.setFieldValue('submitType', 'download');
                                    e.preventDefault();
                                    baseProps.handleSubmit();
                                }}
                                fullWidth
                                style={{ maxWidth: 'calc(50% - 5px)' }}
                            >
                                {t('buttons:button_save_and_download_pdf')}
                            </Button>
                            <Button
                                type="submit"
                                onClick={(e) => {
                                    baseProps.setFieldValue('submitType', 'save');
                                    e.preventDefault();
                                    baseProps.handleSubmit();
                                }}
                                fullWidth
                                style={{ maxWidth: 'calc(50% - 5px)' }}
                            >
                                {t('buttons:button_save')}
                            </Button>
                        </ButtonGroup>
                        <Button
                            type="submit"
                            onClick={(e) => {
                                baseProps.setFieldValue('submitType', 'send');
                                e.preventDefault();
                                baseProps.handleSubmit();
                            }}
                        >
                            {t('buttons:button_save_and_send')}
                        </Button>
                    </Stack>
                }
            />
        </Form>
    );
}

export default InvoiceModal;
