import React, { useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Table from '@mui/joy/Table';
import { Sheet, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { useNavigate } from 'react-router-dom';
import { InvoiceRequestData } from 'api/models';
import { getScaledVh } from 'utils/styleUtils';
import { InvoiceTableBody } from 'components/Invoice/components/TableBody';
import { InvoiceTableHeader } from 'components/Invoice/components/TableHeader';
import { invoiceRoute } from 'components/shared/Routing/routes';
import { invoiceQueryKey } from 'components/Invoice/hooks/invoice.query-keys';
import useInvoices from 'hooks/useInvoices';
import { useQueryClient } from 'react-query';

type InvoiceProp = {
    invoices: InvoiceRequestData[];
    isMobile: boolean;
    refetch: () => void;
};

export const Invoices = ({ invoices, isMobile, refetch }: InvoiceProp) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [expandedRow, setExpandedRow] = useState(0);
    const { isExtraSmall, isMedium } = useWindowSize();
    const { markPaid } = useInvoices();
    const invoiceNoToRender = 5;

    const handleMarkAsPaid = async (idBill: number) => {
        markPaid(idBill, {
            onSuccess: () => {
                queryClient.invalidateQueries(invoiceQueryKey.invoice());
                refetch();
            },
        });
    };

    const tableHeaders: string[] = [
        t('invoice:invoice_no'),
        t('invoice:invoice_patient_name'),
        t('invoice:invoice_date'),
        isExtraSmall ? t('buttons:button_details') : t('buttons:button_actions'),
    ];

    const handleInvoicePageNavigation = () => {
        navigate(invoiceRoute);
    };

    useEffect(() => {
        if (!isMobile || !isExtraSmall || isMedium) setExpandedRow(0);
    }, [isExtraSmall, isMedium, isMobile]);

    const noInvoiceSheet = () => (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                p: 1,
            }}
        >
            <Typography fontWeight="bold" level="h4">
                {t('dashboard:no_unpaid_invoices')}
            </Typography>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Typography fontWeight="bold" level="body-xs">
                    {t('dashboard:no_unpaid_invoices_description')}
                </Typography>
            </Box>
            <Button
                onClick={handleInvoicePageNavigation}
                sx={{ backgroundColor: 'var(--joy-palette-primary-solidBg)' }}
            >
                <Typography level="body-sm" sx={{ color: 'var(--joy-palette-primary-solidColor)' }}>
                    {t('dashboard:no_unpaid_invoices_button')}
                </Typography>
            </Button>
        </Box>
    );

    return (
        <Box
            sx={{
                height: '100%',
                p: 1,
                width: '100%',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: 0.5, alignItems: 'start' }}>
                <Typography level="title-md" sx={{ fontWeight: 'bold' }}>
                    {t('invoice:invoice_unpaid')}
                </Typography>
            </Box>

            {invoices.length === 0 ? (
                <Box
                    sx={{
                        height: '80%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {noInvoiceSheet()}
                </Box>
            ) : (
                <Sheet
                    sx={{
                        height: isMobile ? '100%' : getScaledVh(50, isExtraSmall),
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        backgroundColor: 'transparent',
                    }}
                >
                    <Table
                        stickyHeader
                        sx={{
                            marginTop: 1,
                            width: '100%',
                            tableLayout: 'auto',
                            padding: 0,
                            borderTop: 1,
                            overflow: 'hidden',
                            borderColor: 'transparent',
                        }}
                    >
                        <InvoiceTableHeader headers={tableHeaders} isDashboard={true} />
                        <InvoiceTableBody
                            isExtraSmall={isExtraSmall}
                            isDashboard={true}
                            invoices={invoices.slice(0, invoiceNoToRender)}
                            onMarkAsPaid={handleMarkAsPaid}
                        />
                    </Table>
                </Sheet>
            )}
        </Box>
    );
};
