import { RRule } from 'rrule';
import moment from 'moment';
import { GetText } from 'rrule/dist/esm/nlp/totext';
import { Language } from 'rrule/dist/esm/nlp/i18n';

// TODO take tranlations from useTranslation hook
const yourStrings = {
    every: 'jedes',
    until: 'bis',
    day: 'Tag',
    days: 'Tage',
    week: 'Woche',
    weeks: 'Wochen',
    on: 'ein',
    in: 'in',
    'on the': 'auf dem',
    for: 'für',
    and: 'und',
    or: 'oder',
    at: 'bei',
    last: 'zuletzt',
    '(~ approximate)': '(~ approximativ)',
    times: 'Zeiten',
    time: 'Zeit',
    minutes: 'Minuten',
    hours: 'Stunden',
    weekdays: 'Wochentage',
    weekday: 'Wochentag',
    months: 'Monate',
    month: 'Monat',
    years: 'Jahre',
    year: 'Jahr',
};

export const rruleToText = (strRRule: RRule, lang: string) => {
    if (lang === 'en') {
        return strRRule.toText();
    }

    const language: Language = {
        dayNames: moment.weekdays(),
        monthNames: moment.months(),
        tokens: {},
    };

    const getText = (id: keyof typeof yourStrings) => {
        return yourStrings[id];
    };

    const dateFormat = (year: number, month: string, day: number) =>
        moment().date(day).year(year).month(month).format('LL');

    return strRRule ? strRRule.toText(getText as GetText, language, dateFormat) : '';
};
