import { Frequency as RRuleFrequency, RRule, Weekday as RRuleWeekday } from 'rrule';
import moment from 'moment';
import { ALL_WEEKDAYS, Frequency, ScheduleRule, Weekday } from '../../../../../../api/models';
import { RecurringSlotOption } from '../../EditSlotForm.types';
import { formatISODate } from '../../../../../../api/utils';

export const rruleToScheduleRule = (rrule: RRule): ScheduleRule => {
    const { options } = rrule;

    let byweekday = options.byweekday;
    if (options.byweekday == null) {
        byweekday = [];
    } else if (!Array.isArray(options.byweekday)) {
        byweekday = [options.byweekday];
    }

    return {
        freq: rruleToFreq(options.freq),
        interval: options.interval,
        count: options.count,
        until: options.until ? formatISODate(moment(options.until)) : null,
        byweekdays: byweekday.map(numberToWeekday),
        bymonthday: (options.bymonthday || [])[0],
        bymonth: (options.bymonth || [])[0],
    };
};

export const scheduleRuleToRRule = (scheduleRule: ScheduleRule): RRule => {
    return new RRule({
        freq: freqToRRule(scheduleRule.freq),
        interval: scheduleRule.interval,
        count: scheduleRule.count,
        until: scheduleRule.until ? new Date(scheduleRule.until) : null,
        bymonthday: scheduleRule.bymonthday,
        bymonth: scheduleRule.bymonth,
        byweekday: scheduleRule.byweekdays.map(weekdayToRRule),
    });
};

export const freqToRRule = (value: Frequency): RRuleFrequency => {
    switch (value) {
        case Frequency.DAILY:
            return RRuleFrequency.DAILY;
        case Frequency.WEEKLY:
            return RRuleFrequency.WEEKLY;
        case Frequency.MONTHLY:
            return RRuleFrequency.MONTHLY;
        default:
            return RRuleFrequency.YEARLY;
    }
};

export const rruleToFreq = (value: RRuleFrequency): Frequency => {
    switch (value) {
        case RRuleFrequency.DAILY:
            return Frequency.DAILY;
        case RRuleFrequency.WEEKLY:
            return Frequency.WEEKLY;
        case RRuleFrequency.MONTHLY:
            return Frequency.MONTHLY;
        default:
            return Frequency.YEARLY;
    }
};

export const weekdayToRRule = (value: Weekday): RRuleWeekday => {
    switch (value) {
        case Weekday.MO:
            return RRule.MO;
        case Weekday.TU:
            return RRule.TU;
        case Weekday.WE:
            return RRule.WE;
        case Weekday.TH:
            return RRule.TH;
        case Weekday.FR:
            return RRule.FR;
        case Weekday.SA:
            return RRule.SA;
        default:
            return RRule.SU;
    }
};

export const numberToWeekday = (value: number): Weekday => {
    return ALL_WEEKDAYS[value];
};

export const getByWeekDay = (date: Date) => {
    return date.getDay() === 0 ? 6 : date.getDay() - 1;
};

export const getByMonthDay = (date: Date) => {
    return parseInt(moment(date).format('D'));
};

export const getByMonth = (date: Date) => {
    return parseInt(moment(date).format('M'));
};

export const replaceRRuleDate = (rrule: RRule, date: Date, option?: RecurringSlotOption): RRule => {
    switch (rrule.options.freq) {
        case RRuleFrequency.WEEKLY:
            const changeWeekDay = option === RecurringSlotOption.weekly;
            return new RRule({
                ...rrule.origOptions,
                byweekday: changeWeekDay ? getByWeekDay(date) : rrule.origOptions?.byweekday,
            });
        case RRuleFrequency.MONTHLY:
            return new RRule({
                ...rrule.origOptions,
                bymonthday: getByMonthDay(date),
            });
        case RRuleFrequency.YEARLY:
            return new RRule({
                ...rrule.origOptions,
                bymonthday: getByMonthDay(date),
                bymonth: getByMonth(date),
            });
        default:
            return new RRule({ ...rrule.origOptions });
    }
};
