import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/joy';
import useApiRequest from '../../../../hooks/useApiRequest';
import { useAuthContext } from '../../../Auth/AuthContext/AuthContext';
import InvoiceMobileForm from './InvoiceModal/InvoiceMobileModal/InvoiceMobileForm';
import { AppointmentModel, InvoiceRequestData } from '../../../../api/models';
import useInvoices from '../../../../hooks/useInvoices';
import InvoiceElement from './InvoiceElement/InvoiceElement';
import { useModalState } from '../../../shared/BetterModal/useModalState';
import { useQueryClient } from 'react-query';
import { AppointmentRevalidation } from 'hooks/useAppointments';
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal';

type Props = {
    appointment: AppointmentModel;
};

export const BillingTab = ({ appointment }: Props) => {
    const { invoices, addInvoice, markCanceled, markPaid } = useInvoices(appointment.id);
    const { t } = useTranslation();
    const request = useApiRequest();
    const authContext = useAuthContext();
    const modalState = useModalState();
    const queryClient = useQueryClient();
    const confirmModalState = useModalState();
    const [submitValues, setSubmitValues] = useState<
        { invoice: InvoiceRequestData; resetForm: () => void; blob?: string } | undefined
    >(undefined);

    const handleSubmit = (invoice: InvoiceRequestData, resetForm: () => void, blob?: string) => {
        setSubmitValues({
            invoice: invoice,
            blob: blob,
            resetForm: resetForm,
        });
        confirmModalState.toggleModal();
    };

    const confirmSubmit = async (): Promise<void> => {
        if (!submitValues) return Promise.reject();

        //todo: addInvoice should be awaitable!!
        addInvoice(submitValues.invoice);
        modalState.toggleModal();
        submitValues.resetForm();
        return queryClient.invalidateQueries(['invoices-so', AppointmentRevalidation.PAST]);
    };

    return (
        <>
            <ConfirmModal
                modalDescription={t('billing:message_issue_invoice')}
                successAlertDescription={t('billing:message_issue_invoice_success')}
                onConfirm={confirmSubmit}
                modalState={confirmModalState}
            />
            <Stack spacing={1}>
                {invoices.length === 0 && <Typography>{t('billing:no_invoice')}</Typography>}
                {invoices.length > 0 &&
                    invoices.map((invoice, index) => (
                        <InvoiceElement key={index} invoice={invoice} markCanceled={markCanceled} markPaid={markPaid} />
                    ))}

                <InvoiceMobileForm
                    modalState={modalState}
                    handleSubmit={handleSubmit}
                    authUser={authContext?.authUser}
                    request={request}
                    appointment={appointment}
                />
            </Stack>
        </>
    );
};
