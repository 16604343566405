import { useState } from 'react';
import { ApiErrorResponse } from '../api/ApiTypes';
import { useTemporaryState } from 'web-kit/hooks/useTemporaryState';
import { useTranslation } from 'react-i18next';

export interface ApiRequestOptions {
    errorTimeout?: number;
}

export interface ApiRequest {
    errors?: ApiErrorResponse;
    isLoading: boolean;
    isCompleted: boolean;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    dispatch: <T = any>(promise: Promise<T>) => Promise<T>;
    clearErrors: () => void;
}

/**
 * A hook that wraps an API promise to get a basic information about the request.
 */
const useApiRequest = (options?: ApiRequestOptions): ApiRequest => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [errors, setErrors] = useTemporaryState<ApiErrorResponse | undefined>(undefined, options?.errorTimeout);
    const { t } = useTranslation();

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const dispatch = <T = any>(promise: Promise<T>): Promise<T> => {
        setErrors(undefined);
        setIsCompleted(false);
        setIsLoading(true);

        return new Promise((resolve, reject) => {
            promise
                .then((result) => {
                    setIsCompleted(true);
                    setIsLoading(false);
                    resolve(result);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    try {
                        setErrors(error.response.data);
                    } catch (e) {
                        setErrors({
                            non_field_errors: [
                                {
                                    message: t('global:unknown_error_from_api'),
                                    code: 'error',
                                },
                            ],
                        });
                    }
                    reject(error);
                });
        });
    };

    const clearErrors = () => {
        setErrors(undefined);
    };

    return {
        errors,
        isLoading,
        isCompleted,
        dispatch,
        clearErrors,
    };
};

export default useApiRequest;
