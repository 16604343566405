import { Components, Theme } from '@mui/joy';
import React from 'react';

export const ComponentsStyles: Components<Theme> = {
    JoyCard: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...(ownerState && {
                    boxShadow: theme.vars.shadow.md,
                    background: theme.vars.palette.background.level1,
                }),
            }),
        },
    },
    JoyTabs: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...((ownerState.variant === 'outlined' && {
                    boxShadow: theme.vars.shadow.md,
                }) ||
                    (ownerState.variant === 'plain' && {
                        backgroundColor: theme.vars.palette.background.level1,
                    })),
            }),
        },
    },
    JoyDivider: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...(ownerState && {
                    backgroundColor: theme.vars.palette.background.level3,
                }),
            }),
        },
    },
    JoyDrawer: {
        styleOverrides: {
            root: ({ ownerState, theme }) => ({
                ...(ownerState && {
                    color: theme.vars.palette.neutral.plainActiveBg,
                }),
            }),
        },
    },
};
