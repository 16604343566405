import React from 'react';
import { FilterDrawer, FilterGroup, FilterTypes } from '../../shared/FilterDrawer/FilterDrawer';
import { getDateXDaysAgo, NumericOption, Option } from '../../../utils/formUtils';
import { useTranslation } from 'react-i18next';
import { SearchParams } from '../hooks/useErrorLogs';
import { ErrorOrderByOptions } from '../../../api/models/errorLog';

type Props = {
    isFilterDrawerOpen: boolean;
    toggleFilterDrawer: (open: boolean) => void;
    setParams: (params: SearchParams) => void;
    currentParams: SearchParams;
};
export const Filters = ({ isFilterDrawerOpen, toggleFilterDrawer, setParams, currentParams }: Props) => {
    const { t } = useTranslation();

    const dateFilters: Option[] = [
        { value: getDateXDaysAgo(7), label: t('errorManagement:filters.date_7_days_ago') },
        { value: getDateXDaysAgo(30), label: t('errorManagement:filters.date_30_days_ago') },
        { value: getDateXDaysAgo(60), label: t('errorManagement:filters.date_60_days_ago') },
        { value: getDateXDaysAgo(90), label: t('errorManagement:filters.date_90_days_ago') },
        { value: getDateXDaysAgo(180), label: t('errorManagement:filters.date_6_months_ago') },
    ];

    const resolvedFilters: Option[] = [
        { value: 'resolved', label: t('errorManagement:filters.resolved_true') },
        { value: 'not_resolved', label: t('errorManagement:filters.resolved_false') },
    ];

    const otherFilters: Option[] = [{ value: 'descending', label: t('errorManagement:filters.descending') }];

    const sortFilters: NumericOption[] = [
        {
            value: ErrorOrderByOptions.errorDescription,
            label: t('errorManagement:filters.errorDescription'),
        },
        { value: ErrorOrderByOptions.errorComment, label: t('errorManagement:filters.errorComment') },
        { value: ErrorOrderByOptions.errorTimestamp, label: t('errorManagement:filters.errorTimestamp') },
        { value: ErrorOrderByOptions.doctorName, label: t('errorManagement:filters.doctorName') },
        { value: ErrorOrderByOptions.patientName, label: t('errorManagement:filters.patientName') },
        { value: ErrorOrderByOptions.invoiceNr, label: t('errorManagement:filters.invoiceNr') },
        { value: ErrorOrderByOptions.invoiceDate, label: t('errorManagement:filters.invoiceDate') },
        { value: ErrorOrderByOptions.invoiceDateDue, label: t('errorManagement:filters.invoiceDateDue') },
        { value: ErrorOrderByOptions.totalPrice, label: t('errorManagement:filters.totalPrice') },
    ];

    const dateFilterKey = 'date';
    const resolvedFilterKey = 'resolved';
    const otherFilterKey = 'other';
    const sortFilterKey = 'sort';
    const filterGroups: FilterGroup[] = [
        {
            title: t('errorManagement:filters.label_date_filters'),
            options: dateFilters,
            key: dateFilterKey,
            many_options: false,
            default_values: [],
        },
        {
            title: t('errorManagement:filters.label_resolved_filters'),
            options: resolvedFilters,
            key: resolvedFilterKey,
            many_options: true,
            default_values: [],
        },
        {
            title: t('errorManagement:filters.label_other_filters'),
            options: otherFilters,
            key: otherFilterKey,
            many_options: true,
            default_values: [],
        },
        {
            title: t('errorManagement:filters.label_sort_filters'),
            options: sortFilters,
            key: sortFilterKey,
            many_options: false,
            default_values: [],
        },
    ];

    const applyFilters = (filteredTypes: FilterTypes[]) => {
        const dateFilters = filteredTypes.find((f) => f.key === dateFilterKey)?.values;
        const otherFilters = filteredTypes.find((f) => f.key === otherFilterKey)?.values;
        const resolvedFilters = filteredTypes.find((f) => f.key === resolvedFilterKey)?.values;
        const sortFilters = filteredTypes.find((f) => f.key === sortFilterKey)?.values;

        if (!dateFilters || !otherFilters || !resolvedFilters || !sortFilters) return;

        const params: SearchParams = {
            current_page: currentParams.current_page,
            date_from: dateFilters.length > 0 ? new Date(dateFilters[0]) : undefined,
            date_to: undefined,
            descending: otherFilters.includes('descending'),
            is_resolved: resolvedFilters.length === 1 ? resolvedFilters.includes('resolved') : undefined,
            page_size: currentParams.page_size,
            sort_by:
                sortFilters.length > 0
                    ? (Object.values(ErrorOrderByOptions)[Number(sortFilters[0])] as ErrorOrderByOptions)
                    : undefined,
        };
        setParams(params);
    };

    return (
        <FilterDrawer
            filterGroups={filterGroups}
            isOpen={isFilterDrawerOpen}
            toggleDrawer={toggleFilterDrawer}
            applyFilters={applyFilters}
        />
    );
};
