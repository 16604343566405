import React, { RefCallback } from 'react';
import { IMaskInput } from 'react-imask';
import { CustomProps } from './CustomProps';

export const BankMaskAdapter = React.forwardRef<HTMLElement, CustomProps>(function BankMaskAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="AA ################################"
            definitions={{
                '#': /[0-9]/,
                A: /[A-Za-z]/,
            }}
            inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
            onAccept={(value: any) => onChange({ target: { name: props.name, value: value.toString().toUpperCase() } })}
            overwrite
        />
    );
});
