import { withFormik } from 'formik';
import { handleSubmit, mapPropsToValues, validationSchema } from './MissingInfo.schema';
import { MissingInfoProps, MissingInfoValues } from './MissingInfo.types';
import { MissingInfoData } from './MissingInfoData';
import { withTranslation } from 'react-i18next';

const MissingInfoForm = withFormik<MissingInfoProps, MissingInfoValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(MissingInfoData);
export default withTranslation()(MissingInfoForm);
