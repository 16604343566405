import React, { ReactElement, useEffect, useState } from 'react';
import { useRef } from 'react';
import { Button, Input } from '@mui/joy';
import { api } from '../../../api';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../BetterAlert/useAlert';

interface Props {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    value?: number | undefined;
    name: string;
    acceptFileTypes?: string;
}

export default function AttachmentInput({ setFieldValue, value, name, acceptFileTypes }: Props): ReactElement {
    const uploadInput = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [image, setImage] = useState<File | null>(null);
    const [initialValue] = useState(value);
    const removeButtonId = 'image_remove';
    const alert = useAlert();

    const handleInputChange = () => {
        const uploadImage = uploadInput.current?.files?.item(0);
        if (!uploadImage) return;

        if (uploadImage.size > 5000000) {
            alert.openAlert(t('attachmentInput:file_too_big_message'), 'danger');
            return;
        }

        setImage(uploadImage ? uploadImage : null);

        api.uploadAttachment(uploadImage).then((data) => {
            setFieldValue(name, data.id);
        });
    };

    return (
        <>
            <input
                title="imageUpload"
                ref={uploadInput}
                id={name}
                name={name}
                type="file"
                accept={acceptFileTypes ? acceptFileTypes : 'image/*'}
                onChange={handleInputChange}
                style={{ visibility: 'hidden', zIndex: 90 }}
            />
            <Input
                id="image_input"
                sx={{ mt: -3.5 }}
                onClick={(e) => {
                    if ((e.target as HTMLInputElement).id === removeButtonId) return;
                    e.preventDefault();
                    uploadInput?.current && uploadInput.current.click();
                }}
                endDecorator={
                    image && (
                        <Button
                            id={removeButtonId}
                            onClick={() => {
                                setImage(null);
                                setFieldValue(name, initialValue);
                            }}
                        >
                            X
                        </Button>
                    )
                }
                readOnly
                value={image ? image.name : t('buttons:button_select_image')}
            />
        </>
    );
}
