import React, { SyntheticEvent } from 'react';
import { SlotProps, SlotValues } from '../../EditSlotForm.types';
import { FormikProps } from 'formik';
import { Checkbox, Stack } from '@mui/joy';
import { SlotMode } from '../../../../../../api/models';
import { useTranslation } from 'react-i18next';

type Props = {
    props: SlotProps & FormikProps<SlotValues>;
};
export const ModeSelector = ({ props }: Props) => {
    const { t } = useTranslation();

    const handleModeChange = (e: SyntheticEvent<Element, Event>) => {
        const newValue = (e.target as HTMLInputElement).id;
        const value = props.values.mode;
        const { ONLINE, ONSITE } = SlotMode;

        const isModeOnsite = newValue === ONLINE && (value === null || value === ONLINE);

        const isModeOnline = newValue === ONSITE && (value === null || value === ONSITE);

        const isModeNull = (newValue === ONLINE || newValue === ONSITE) && (value === ONLINE || value === ONSITE);

        if (isModeOnsite) {
            props.setFieldValue('mode', ONSITE);
        } else if (isModeOnline) {
            props.setFieldValue('mode', ONLINE);
        } else if (isModeNull) {
            props.setFieldValue('mode', null);
        }
    };

    return (
        <>
            <Stack direction="row" alignItems="flex-start" spacing={2}>
                <Checkbox
                    id={SlotMode.ONSITE}
                    color="primary"
                    variant="outlined"
                    label={t('editSlotModal:label_onsite')}
                    onChange={handleModeChange}
                    checked={props.values.mode === null || props.values.mode === SlotMode.ONSITE}
                />
                <Checkbox
                    id={SlotMode.ONLINE}
                    color="primary"
                    variant="outlined"
                    label={t('editSlotModal:label_online')}
                    onChange={handleModeChange}
                    checked={props.values.mode === null || props.values.mode === SlotMode.ONLINE}
                />
            </Stack>
        </>
    );
};
