import { HealthcareService, HealthcareServiceOption } from '../models';

export const healthcareServiceFactory = (
    data: Pick<HealthcareService, 'id' | 'name' | 'comment'>,
): HealthcareServiceOption => {
    return {
        value: data.id,
        label: data.name,
        comment: data.comment,
    };
};
