import {
    clearAuthTokens,
    getAuthTokens,
    isTokenValid,
    refreshAuthToken,
    setAuthTokens,
    setSoAuthTokens,
} from '../../utils/tokens';
import { api, apiSecondOpinion } from '../../api';
import { SecondOpinionToken, TokenPair, UserRole } from '../../api/models';

export const checkAuthentication = async () => {
    const { token, refresh, soToken } = getAuthTokens();

    try {
        if (!refresh || !isTokenValid(refresh)) {
            clearAuthTokens();
        }

        if (!token || !isTokenValid(token)) {
            const refreshData = await refreshAuthToken();
            setAuthTokens(refreshData);
            api.setAuthToken(refreshData.token);
        }

        if (!soToken || !isTokenValid(soToken)) {
            const secondOpinionData = await api.generateSecondOpinionToken();
            setSoAuthTokens(secondOpinionData.second_opinion_token);
        }

        await getUserData();
    } catch (error) {}
};

export const finalizeLogin = async (data: TokenPair, secondOptionToken: SecondOpinionToken) => {
    setAuthTokens(data);
    setSoAuthTokens(secondOptionToken.second_opinion_token);
    await getUserData();
    api.client.refreshAndScheduleTokens();
};

export const getUserData = async () => {
    try {
        const user = await api.getUserProfile();
        if (user.role !== UserRole.organization) {
            user.doctor = await apiSecondOpinion.getDoctorProfile();
        }
        return user;
    } catch (err) {
        throw err;
    }
};
