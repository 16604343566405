import { withFormik } from 'formik';
import { InvitationRegisterFormProps, InvitationRegisterFormValues } from './InvitationRegister.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './InvitationRegister.schema';
import { InvitationRegisterData } from './InvitationRegisterData';
import { withTranslation } from 'react-i18next';

const InvitationRegisterForm = withFormik<InvitationRegisterFormProps, InvitationRegisterFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(InvitationRegisterData);
export default withTranslation()(InvitationRegisterForm);
