import React, { Fragment, useState } from 'react';
import { InvoiceItemData } from '../../../../../../../api/models';
import { InvoiceMobileProps, InvoiceMobileValues } from '../InvoiceMobileForm.types';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { NumericOption } from '../../../../../../../utils/formUtils';
import { Alert, Button, FormLabel, Input, Stack, Typography } from '@mui/joy';
import { FormControlStyled } from '../../../../../../shared/Form/FormControlStyled';
import { BetterSelect } from '../../../../../../shared/BetterSelect/BetterSelect';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { ErrorHelperText } from '../../../../../../shared/Form/ErrorHelperText';
import { InfoOutlined } from '@mui/icons-material';
import { FloatAdapter } from '../../../../../../shared/adapters/FloatAdapter';

export type InvoiceItemBodyValues = {
    factor: number;
    pickedBillingItemId: number;
    selectedItem: InvoiceItemData | undefined;
    vat: number;
    max_factor: number;
    is_constant: boolean;
};

interface InvoiceItemBodyProps extends WithTranslation {
    handleSubmit: (values: InvoiceItemBodyValues) => void;
    invoiceBillingItems: InvoiceItemData[];
    baseProps: InvoiceMobileProps & FormikProps<InvoiceMobileValues>;
}

const mapPropsToValues = (props: InvoiceItemBodyProps): InvoiceItemBodyValues => {
    return {
        selectedItem: undefined,
        pickedBillingItemId: 0,
        factor: 1,
        vat: 0,
        max_factor: 0,
        is_constant: false,
    };
};
const validationSchema = ({ t }: InvoiceItemBodyProps) =>
    Yup.object().shape({
        factor: Yup.number()
            .required(t('invoice:modal.error_factor_required'))
            .moreThan(0, t('invoice:modal.error_factor_min')),
    });
const handleSubmit = (
    values: InvoiceItemBodyValues,
    { props }: FormikBag<InvoiceItemBodyProps, InvoiceItemBodyValues>,
) => {
    const curr = values;
    for (const item of props.invoiceBillingItems) {
        if (item.id !== values.pickedBillingItemId) continue;
        curr.selectedItem = item;
        break;
    }
    props.handleSubmit(curr);
};

export const Body = (props: InvoiceItemBodyProps & FormikProps<InvoiceItemBodyValues>) => {
    const { t } = useTranslation();
    const billingItemsOptions = (): NumericOption[] => {
        const arr: NumericOption[] = [];
        props.invoiceBillingItems.forEach((e) =>
            arr.push({
                value: e.id,
                label: e.description,
            }),
        );
        return arr;
    };
    const vatOptions: NumericOption[] = [
        { label: '0%', value: 0 },
        { label: '7%', value: 7 },
        { label: '19%', value: 19 },
    ];

    const getPickedItem = (id: number = props.values.pickedBillingItemId): InvoiceItemData | undefined => {
        for (const item of props.invoiceBillingItems) {
            if (item.id !== id) continue;
            return item;
        }
        return undefined;
    };

    const isMaxFactorError = () => {
        const item = getPickedItem();
        if (item === undefined) return false;
        if (!item.max_factor) return false;
        return props.values.factor > item.max_factor;
    };

    const getCalculatedPrice = () => {
        const price = (getPickedItem()?.price || 0) * props.values.factor;
        return price >= 0
            ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(price)
            : t('invoice:modal.incorrect_factor');
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Stack spacing={1}>
                <FormControlStyled isError={false} isTouched={false}>
                    <FormLabel required>{t('invoice:modal.pick_invoice_item')}</FormLabel>
                    <BetterSelect
                        options={billingItemsOptions()}
                        value={props.values.pickedBillingItemId || -1}
                        onChange={(val) => {
                            props.setFieldValue('pickedBillingItemId', val);
                            props.setFieldValue('factor', 1);
                            props.setFieldTouched('pickedBillingItemId');
                            if (typeof val === 'string') return;

                            const item = getPickedItem(val);
                            if (item === undefined) return;

                            props.setFieldValue('max_factor', item.max_factor);
                            props.setFieldValue('is_constant', item.factor_is_constant);

                            if (!item.factor_is_constant) return;
                            props.setFieldValue('factor', item.max_factor && item.max_factor > 0 ? item.max_factor : 1);
                        }}
                    />
                </FormControlStyled>
                {props.touched.pickedBillingItemId && (
                    <Fragment>
                        <Stack direction="row" spacing={1} width="100%">
                            <FormControlStyled
                                style={{ maxWidth: '50%' }}
                                isError={!!(props.errors.factor && props.touched.factor)}
                                isTouched={props.touched.factor || false}
                            >
                                <FormLabel required>{t('invoice:modal.set_factor')}</FormLabel>
                                <Input
                                    id="factor"
                                    name="factor"
                                    disabled={getPickedItem()?.factor_is_constant}
                                    value={props.values.factor.toString().replaceAll('.', ',')}
                                    slotProps={{
                                        input: {
                                            maxLength: 5,
                                            component: FloatAdapter,
                                        },
                                    }}
                                    onChange={props.handleChange}
                                />
                                <ErrorHelperText
                                    isError={!!(props.errors.factor && props.touched.factor)}
                                    errorText={props.errors.factor}
                                />
                            </FormControlStyled>
                            <Stack spacing={1} direction="column" maxWidth="50%">
                                <Typography>{t('invoice:modal.calculated_price')}</Typography>
                                <Typography>{getCalculatedPrice()}</Typography>
                            </Stack>
                        </Stack>
                        <FormControlStyled isError={false} isTouched={false}>
                            <FormLabel required>{t('invoice:modal.pick_vat')}</FormLabel>
                            <BetterSelect
                                options={vatOptions}
                                value={props.values.vat}
                                onChange={(val) => {
                                    props.setFieldValue('vat', val);
                                    props.setFieldTouched('vat');
                                }}
                            />
                        </FormControlStyled>
                    </Fragment>
                )}
                {isMaxFactorError() && (
                    <Alert startDecorator={<InfoOutlined />} color="danger">
                        {t('invoice:modal.error_factor_max')} {getPickedItem()?.max_factor}!
                    </Alert>
                )}
                <Button type="submit" disabled={isMaxFactorError() || !props.touched.pickedBillingItemId}>
                    {t('buttons:button_confirm')}
                </Button>
            </Stack>
        </Form>
    );
};

const AddInvoiceItemBody = withFormik<InvoiceItemBodyProps, InvoiceItemBodyValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(Body);
export default withTranslation()(AddInvoiceItemBody);
