import moment from 'moment';

export const truncateString = (text: string, length: number) => {
    if (text.length > length) {
        return text.slice(0, length) + '…';
    }
    return text;
};

export const removePrefix = (text: string, prefix: string) => {
    if (text.startsWith(prefix)) {
        return text.substring(prefix.length);
    }
    return text;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseEnumProperty = <E>(e: any, value?: string): E | undefined => {
    return value ? e[value] : undefined;
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseToEnumPropertyList = <E>(e: any, values: string[]): E[] => {
    return values.map((value) => e[value]).filter(Boolean);
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseEnumValue = <E>(myEnum: any, enumValue?: string): E | undefined => {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
    return keys.length > 0 ? (myEnum[keys[0]] as unknown as E) : undefined;
};

export const parseToNumberList = (value: string) => {
    // "1,2,3,4" -> [1, 2, 3, 4]
    return value
        .split(',')
        .map((num) => parseInt(num))
        .filter((num) => !isNaN(num));
};

export const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

export const dateToLocaleString = (str: string | Date | undefined): string => {
    const date = new Date(str || '');
    if (isNaN(date.getTime())) return '';
    return moment(date).format('DD MMM YYYY');
};
