import { api, apiSecondOpinion } from 'api';
import { getStorageItem, removeStorageItem, setStorageItem } from 'services/local-storage-service';
import jwt_decode from 'jwt-decode';
import { TokenPair } from 'api/models';

export const isTokenValid = (token: string) => {
    try {
        const decodedAccess = jwt_decode<{ exp: number }>(token);
        return decodedAccess.exp * 1000 >= Date.now();
    } catch {
        return false;
    }
};

const tokenStorageKey = 'auth/access-token';
const refreshStorageKey = 'auth/refresh-token';
const secondOpinionTokenStorageKey = 'auth/second-opinion-access-token';

export const getAuthTokens = () => {
    const token = getStorageItem(tokenStorageKey);
    const refresh = getStorageItem(refreshStorageKey);
    const soToken = getStorageItem(secondOpinionTokenStorageKey);

    return { token, refresh, soToken };
};

export const setAuthTokens = (pair: TokenPair) => {
    setStorageItem(tokenStorageKey, pair.token);
    setStorageItem(refreshStorageKey, pair.refresh_token);
    api.setAuthToken(pair.token);
};

export const setSoAuthTokens = (token: string) => {
    setStorageItem(secondOpinionTokenStorageKey, token);
    apiSecondOpinion.setSecondOpinionAuthToken(token);
};

export const clearAuthTokens = () => {
    removeStorageItem(tokenStorageKey);
    removeStorageItem(refreshStorageKey);
    removeStorageItem(secondOpinionTokenStorageKey);
    api.clearAuthToken();
};

export const refreshAuthToken = async (): Promise<TokenPair> => {
    const { refresh } = getAuthTokens();
    if (!refresh || !isTokenValid(refresh)) {
        clearAuthTokens();
        throw new Error('Refresh token is invalid or expired');
    }

    const newTokens = await api.refreshToken(refresh);
    setAuthTokens(newTokens);
    return newTokens;
};
