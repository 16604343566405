import { Card, Grid, Stack, Typography } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { AppointmentModel, InvoiceRequestData, InvoiceStatus } from '../../../../api/models';
import { getFullName } from '../../../../api/utils';
import moment from 'moment';
import { DetailsTabs } from '../../AppointmentDetailsSection/DetailsTabs/DetailsTabs';
import Collapse from 'react-bootstrap/Collapse';
import { dateToLocaleString } from '../../../../utils/stringUtils';
import IconButton from '@mui/joy/IconButton';
import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type Props = {
    appointment: AppointmentModel;
    pickedAppointment: AppointmentModel | undefined;
    reloadAppointment: () => void;
    viewDetails: (appointment: AppointmentModel) => void;
    isMobile: boolean;
    invoices: InvoiceRequestData[];
};
export const AppointmentItem = ({
    appointment,
    viewDetails,
    pickedAppointment,
    isMobile,
    reloadAppointment,
    invoices,
}: Props) => {
    const dateFromMoment = moment(appointment.date_from);
    const isPicked = pickedAppointment !== undefined && pickedAppointment.id === appointment.id;
    const [openMobileDetails, setOpenMobileDetails] = useState(false);
    const { t } = useTranslation();

    const handleCardClick = () => {
        if (!isMobile) return;

        viewDetails(appointment);
        setOpenMobileDetails(!openMobileDetails);
    };

    useEffect(() => {
        if (pickedAppointment?.id === appointment.id) return;

        setOpenMobileDetails(false);
    }, [pickedAppointment]);

    useEffect(() => {
        if (!isMobile || pickedAppointment?.id !== appointment.id) return;

        setOpenMobileDetails(true);
    }, [isMobile]);

    return (
        <Card
            sx={{
                mr: isMobile ? -1.5 : 0.5,
                ml: isMobile ? -1.5 : 0.5,
                mt: 0.5,
                background:
                    isPicked && !isMobile
                        ? 'var(--joy-palette-background-level2)'
                        : 'var(--joy-palette-background-level1)',
            }}
        >
            <Stack
                style={{ height: '20px', width: '100%' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid
                    onClick={handleCardClick}
                    spacing={0.5}
                    container
                    width="100%"
                    direction="row"
                    alignItems="center"
                >
                    <Grid xs={4} justifyContent="center" alignItems="center">
                        <Stack direction="column">
                            <Typography fontSize="90%" level={isMobile ? 'body-sm' : 'body-md'}>
                                <span>{dateToLocaleString(appointment.date_from)}</span>
                            </Typography>
                            <Typography fontSize="90%" level={isMobile ? 'body-sm' : 'body-md'}>
                                <span>{dateFromMoment.format('HH:mm')}</span>{' '}
                                <span>{dateFromMoment.format('dddd')}</span>
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={6}>
                        <Typography level={isMobile ? 'body-sm' : 'body-md'}>
                            {getFullName(appointment.patient)}
                        </Typography>
                        <Typography fontSize="90%" level={isMobile ? 'body-sm' : 'body-md'}>
                            {t('appointmentDetails:invoice_count') +
                                ': ' +
                                invoices
                                    .filter(
                                        (inv) =>
                                            inv.id_appointment === appointment.id &&
                                            inv.status !== InvoiceStatus.Cancelled,
                                    )
                                    .length.toString()}
                        </Typography>
                    </Grid>
                    {!isMobile && (
                        <Grid xs={2} container justifyContent="right">
                            <IconButton
                                variant="outlined"
                                color="neutral"
                                onClick={() => viewDetails(appointment)}
                                disabled={isPicked}
                            >
                                <InfoOutlined />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </Stack>
            {isMobile && (
                <Collapse in={isMobile && openMobileDetails && pickedAppointment?.id === appointment.id}>
                    <div>
                        <DetailsTabs appointment={appointment} isMobile={true} reloadAppointments={reloadAppointment} />
                    </div>
                </Collapse>
            )}
        </Card>
    );
};
