import React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@mui/icons-material';
import { useWindowSize } from '../hooks/useWindowSize';
import { dashboardRoute, loginRoute } from '../Routing/routes';
import { useAuthContext } from '../../Auth/AuthContext/AuthContext';

export const ErrorPage = () => {
    const { t } = useTranslation();
    const { isLarge } = useWindowSize();
    const authContext = useAuthContext();

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={2}
            style={{ margin: '20px' }}
        >
            <Typography fontSize={120} style={{ color: 'var(--joy-palette-primary-500)' }} fontWeight={1000}>
                404
            </Typography>
            <Divider color="var(--joy-palette-primary-500)" />
            {!isLarge && <WarningOutlined style={{ scale: '2' }} />}
            <Typography startDecorator={isLarge ? <WarningOutlined /> : undefined} level="h1">
                {t('error:title_error')}
            </Typography>
            <Typography level="body-xs">{t('error:description_error')}</Typography>
            <Button
                onClick={() => {
                    window.location.replace(authContext?.isLoggedIn ? dashboardRoute : loginRoute);
                }}
            >
                {authContext?.isLoggedIn
                    ? t('buttons:button_return_to_dashboard')
                    : t('buttons:button_return_to_login_page')}
            </Button>
        </Stack>
    );
};
