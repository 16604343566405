import React from 'react';
import { CircularProgress, Modal } from '@mui/joy';

//todo: DO USUNIECIA TRWALE
export const LoadingPage = () => {
    return (
        <Modal
            className="loading"
            open={true}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}
            disableAutoFocus={true}
        >
            <CircularProgress variant="solid" size="lg" />
        </Modal>
    );
};
