import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { t } from 'i18next';
import {
    DescriptionOutlined,
    Done,
    InfoOutlined,
    KeyboardArrowUp,
    MoreVert,
    NotificationsActive,
} from '@mui/icons-material';
import { Box, Button, ButtonGroup, Dropdown, Grid, IconButton, Menu, MenuButton, MenuItem, Typography } from '@mui/joy';
import { TableBody, TableRow, TableCell } from '@mui/material';

import useInvoices from 'hooks/useInvoices';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { useAuthContext } from 'components/Auth/AuthContext/AuthContext';
import { useModalState } from 'components/shared/BetterModal/useModalState';

import InvoiceStatusBadge from 'components/shared/InvoiceStatusBadge/InvoiceStatusBadge';
import { generatePdfDocument } from 'components/Dashboard/AppointmentDetailsSection/BillingTab/InvoiceElement/InvoiceElement';
import { BetterModal } from 'components/shared/BetterModal/BetterModal';

import { InvoiceReminderRequestData, InvoiceRequestData, InvoiceStatus, UserRole } from 'api/models';

import { dateToLocaleString } from 'utils/stringUtils';
import { useErrorContext } from 'context/errorContext';
import { InvoicePreview } from 'components/Invoice/components/InvoicePreview';
import { invoiceQueryKey } from '../hooks/invoice.query-keys';
import ConfirmModal from 'components/shared/ConfirmModal/ConfirmModal';

interface TableBodyProps {
    invoices: InvoiceRequestData[];
    isExtraSmall: boolean;
    isDashboard?: boolean;
    onMarkAsPaid: (idBill: number) => Promise<void>;
}

export const InvoiceTableBody = ({
    invoices,
    isExtraSmall,
    isDashboard = false,
    onMarkAsPaid: handleMarkAsPaid,
}: TableBodyProps) => {
    const queryClient = useQueryClient();
    const authContext = useAuthContext();
    const modalState = useModalState();
    const { sendReminderInvoice } = useInvoices();
    const { isMedium } = useWindowSize();
    const { setError } = useErrorContext();

    const [invoiceReminderData, setInvoiceReminderData] = useState<InvoiceReminderRequestData>({
        idBill: 0,
        file: '',
        isReminder: true,
    });
    const [expandedRow, setExpandedRow] = useState(0);
    const [btnIndex, setBtnIndex] = useState(0);
    const [invoiceData, setInvoiceData] = useState<InvoiceRequestData>(invoices[0]);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);

    const currencyUnit = '€';
    const isAdmin = authContext?.authUser?.role === UserRole.organization;

    useEffect(() => {
        if (!isExtraSmall) setExpandedRow(0);
    }, [isExtraSmall]);

    const handleSetReminder = async (invoice: InvoiceRequestData) => {
        const generatedPdfDocument = await generatePdfDocument(invoice, setIsLoadingPdf, true);
        if (typeof generatedPdfDocument === 'string') {
            const updatedInvoiceReminderData = {
                ...invoiceReminderData,
                file: generatedPdfDocument,
                idBill: invoice.idBill,
                isReminder: true,
            };

            setInvoiceReminderData(updatedInvoiceReminderData);
            await sendReminderInvoice(updatedInvoiceReminderData);
            modalState.toggleModal();
            queryClient.invalidateQueries(invoiceQueryKey.invoice());
        } else {
            setError({ message: 'Failed to generate PDF document.' });
        }
    };

    const handleSendInvoice = async (invoice: InvoiceRequestData) => {
        const generatedPdfDocument = await generatePdfDocument(invoice, setIsLoadingPdf, true);
        if (typeof generatedPdfDocument === 'string') {
            const updatedInvoiceReminderData = {
                ...invoiceReminderData,
                file: generatedPdfDocument,
                idBill: invoice.idBill,
                isReminder: false,
            };

            setInvoiceReminderData(updatedInvoiceReminderData);
            await sendReminderInvoice(updatedInvoiceReminderData);
            modalState.toggleModal();
            queryClient.invalidateQueries(invoiceQueryKey.invoice());
        } else {
            setError({ message: 'Failed to generate PDF document.' });
        }
    };

    const onCancel = () => {
        modalState.toggleModal();
    };

    const handlePaidConfirm = () => {
        setBtnIndex(0);
        modalState.toggleModal();
    };

    const handleReminderConfirm = () => {
        setBtnIndex(1);
        modalState.toggleModal();
    };

    const acceptanceActions = (
        <ButtonGroup variant="solid" spacing={2} style={{ width: '100%' }}>
            <Button
                fullWidth
                onClick={async () => {
                    await handleMarkAsPaid(invoiceData.idBill);
                    modalState.toggleModal();
                }}
                color="primary"
            >
                {t('buttons:button_confirm')}
            </Button>
            <Button fullWidth variant="plain" onClick={onCancel}>
                {t('buttons:button_cancel')}
            </Button>
        </ButtonGroup>
    );
    const reminderActions = (
        <ButtonGroup variant="solid" spacing={2} style={{ width: '100%' }}>
            <Button fullWidth onClick={() => handleSetReminder(invoiceData)} color="primary">
                {t('buttons:button_confirm')}
            </Button>
            <Button fullWidth variant="plain" onClick={onCancel}>
                {t('buttons:button_cancel')}
            </Button>
        </ButtonGroup>
    );

    const invoiceActions = (
        <ButtonGroup variant="solid" spacing={2} style={{ width: '100%' }}>
            <Button fullWidth onClick={() => handleSendInvoice(invoiceData)} color="primary">
                {t('buttons:button_confirm')}
            </Button>
            <Button fullWidth variant="plain" onClick={onCancel}>
                {t('buttons:button_cancel')}
            </Button>
        </ButtonGroup>
    );

    type ActionProp = {
        action: JSX.Element;
        description: string;
    };

    const actions: ActionProp[] = [
        {
            action: acceptanceActions,
            description: t('invoice:paid_modal:text'),
        },
        {
            action: reminderActions,
            description: t('invoice:send_reminder_modal:text'),
        },
        {
            action: invoiceActions,
            description: t('invoice:send_email_invoice_modal:text'),
        },
    ];

    if (invoices.length === 0) {
        return (
            <TableBody>
                <TableRow>
                    <TableCell colSpan={6} align="center">
                        {t('No invoices.')}
                    </TableCell>
                </TableRow>
            </TableBody>
        );
    }

    return (
        <>
            <TableBody sx={{ fontSize: '12px' }}>
                {invoices.map((invoice) => {
                    return (
                        <React.Fragment key={invoice.idBill}>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '150px',
                                    }}
                                >
                                    {invoice.invoiceNr}
                                </TableCell>
                                <TableCell>
                                    {invoice.patient.name} {invoice.patient.surname}
                                </TableCell>
                                {!isDashboard && (
                                    <TableCell
                                        sx={{
                                            display: { xs: 'none', sm: 'table-cell' },
                                            width: { xs: '0', md: '20%' },
                                        }}
                                    >
                                        {dateToLocaleString(invoice.invoiceDueDate)}
                                    </TableCell>
                                )}
                                {!isDashboard && (
                                    <>
                                        <TableCell
                                            sx={{
                                                display: { xs: 'none', sm: 'table-cell' },
                                                width: { xs: '0', md: '15%' },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    textAlign: 'start',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        minWidth: { sm: '70%', md: '50%', lg: '40%', xl: '30%' },
                                                    }}
                                                >
                                                    {invoice.total_price.toFixed(2).split('.').join(',')} {currencyUnit}
                                                </Typography>
                                            </Box>
                                        </TableCell>

                                        <TableCell
                                            sx={{
                                                display: { xs: 'none', sm: 'table-cell', alignSelf: 'end' },
                                                width: '15%',
                                            }}
                                        >
                                            <InvoiceStatusBadge invoice={invoice} />
                                        </TableCell>
                                    </>
                                )}
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 2,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        <Dropdown>
                                            <MenuButton
                                                slots={{ root: IconButton }}
                                                slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                                                sx={{ borderRadius: 40 }}
                                            >
                                                <MoreVert sx={{ height: 20, width: 20 }} />
                                            </MenuButton>

                                            <Menu>
                                                <MenuItem
                                                    onClick={() => {
                                                        setInvoiceData(invoice);
                                                        setPreviewModalVisible(true);
                                                    }}
                                                >
                                                    <DescriptionOutlined sx={{ height: 16, margin: 0 }} />
                                                    {t('buttons:button_preview')}
                                                </MenuItem>
                                                <MenuItem
                                                    disabled={
                                                        isAdmin ||
                                                        invoice.status === InvoiceStatus.Paid ||
                                                        invoice.status === InvoiceStatus.Cancelled
                                                    }
                                                    onClick={() => {
                                                        setInvoiceData(invoice);
                                                        setBtnIndex(0);
                                                        handlePaidConfirm();
                                                    }}
                                                >
                                                    <Done sx={{ height: 16, margin: 0 }} />
                                                    {t('invoice:accept_invoice')}
                                                </MenuItem>

                                                <MenuItem
                                                    disabled={isAdmin || invoice.status === InvoiceStatus.Paid}
                                                    onClick={() => {
                                                        setInvoiceData(invoice);
                                                        setBtnIndex(1);
                                                        handleReminderConfirm();
                                                    }}
                                                >
                                                    <NotificationsActive sx={{ height: 16, margin: 0 }} />
                                                    {t('invoice:send_reminder_invoice')}
                                                </MenuItem>
                                                {/* {!isDashboard && (
                                                    <MenuItem
                                                        disabled={isAdmin || invoice.status === InvoiceStatus.Paid}
                                                        onClick={() => {
                                                            setInvoiceData(invoice);
                                                            setBtnIndex(2);
                                                            handleInvoiceConfirm();
                                                        }}
                                                    >
                                                        <MarkEmailReadIcon sx={{ height: 16, margin: 0 }} />
                                                        Send email
                                                    </MenuItem>
                                                )} */}
                                                {/* <MenuItem
                                                    onClick={() => {
                                                        handlePdfGeneration(invoice);
                                                    }}
                                                >
                                                    <Download sx={{ height: 16, margin: 0 }} />
                                                    {t('invoice:download_invoice')}
                                                </MenuItem> */}
                                            </Menu>
                                        </Dropdown>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            {expandedRow === invoice.idBill && (
                                <TableRow>
                                    <TableCell sx={{ verticalAlign: 'top' }} colSpan={2}>
                                        {(isExtraSmall || isDashboard) && (
                                            <>
                                                <Grid container spacing={2}>
                                                    <Grid xs={6}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 1,
                                                                justifyContent: 'start',
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold">
                                                                {t('invoice:invoice_due_date')}
                                                            </Typography>
                                                            <Typography>
                                                                {new Date(invoice.invoiceDueDate).toLocaleDateString()}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 1,
                                                                justifyContent: 'start',
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold">
                                                                {t('invoice:invoice_amount')}
                                                            </Typography>
                                                            <Typography>
                                                                {invoice.total_price.toFixed(2).split('.').join(',')}{' '}
                                                                {currencyUnit}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                gap: 1,
                                                                justifyContent: 'start',
                                                                marginTop: 1,
                                                            }}
                                                        >
                                                            <Typography fontWeight="bold">
                                                                {t('invoice:invoice_status')}
                                                            </Typography>

                                                            <InvoiceStatusBadge invoice={invoice} />
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 1,
                                                        justifyContent: 'start',
                                                        marginTop: 1,
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">
                                                        {t('invoice:invoice_email')}
                                                    </Typography>
                                                    <Typography>{invoice.patient.email}</Typography>
                                                </Box>
                                            </>
                                        )}
                                        {isDashboard || isExtraSmall ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 1,
                                                    justifyContent: 'start',
                                                    marginTop: 1,
                                                }}
                                            >
                                                <Typography fontWeight="bold">
                                                    {t('invoice:invoice_address')}
                                                </Typography>
                                                <Typography>{invoice.patient.street}</Typography>
                                                <Typography>
                                                    {invoice.patient.city} {invoice.patient.post_code}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: 1,
                                                    justifyContent: 'start',
                                                    marginTop: 0,
                                                }}
                                            >
                                                <Typography fontWeight="bold">{t('invoice:invoice_email')}</Typography>
                                                <Typography>{invoice.patient.email}</Typography>
                                            </Box>
                                        )}
                                    </TableCell>
                                    {!isExtraSmall ? (
                                        !isDashboard ? (
                                            <TableCell colSpan={isExtraSmall ? 1 : 2} sx={{ verticalAlign: 'top' }}>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 1,
                                                        justifyContent: 'start',
                                                    }}
                                                >
                                                    <Typography fontWeight="bold">
                                                        {t('invoice:invoice_address')}
                                                    </Typography>
                                                    <Typography>{invoice.patient.street}</Typography>
                                                    <Typography>
                                                        {invoice.patient.city} {invoice.patient.post_code}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                    {!isDashboard && (isExtraSmall || isMedium) && (
                                        <>
                                            <TableCell
                                                sx={{ display: { xs: 'none', sm: 'table-cell', md: 'none' } }}
                                            ></TableCell>
                                            <TableCell sx={{ display: 'none' }}></TableCell>
                                        </>
                                    )}
                                    {!isDashboard && (
                                        <>
                                            <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}></TableCell>
                                        </>
                                    )}

                                    <TableCell
                                        sx={{
                                            verticalAlign: 'top',
                                            backgroundColor: 'black',
                                            horizontalAlign: 'center',
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            sx={{ border: 'none' }}
                                            onClick={() => setExpandedRow(0)}
                                        >
                                            <KeyboardArrowUp
                                                sx={{ height: 16, width: 16, padding: 0, color: 'black' }}
                                            />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    );
                })}
            </TableBody>
            {modalState.isOpen && (
                <ConfirmModal
                    button={null}
                    modalState={modalState}
                    title={t('basics:warning')}
                    modalDescription={actions[btnIndex].description}
                    successAlertDescription={t('invoice:success_message')}
                    onConfirm={async () => {
                        if (btnIndex === 0) await handleMarkAsPaid(invoiceData.idBill);
                        if (btnIndex === 1) await handleSetReminder(invoiceData);
                        if (btnIndex === 2) await handleSendInvoice(invoiceData);
                    }}
                />
            )}
            {previewModalVisible && (
                <InvoicePreview
                    invoice={invoiceData}
                    modalState={{
                        isOpen: previewModalVisible,
                        toggleModal: () => setPreviewModalVisible(!previewModalVisible),
                    }}
                />
            )}
        </>
    );
};
