import React from 'react';
import InvitationRegisterForm from './InvitationRegisterForm';
import { useLoading } from '../../shared/LoadingPage/useLoading';
import { useAcceptInvitation } from './hooks/useInvitationRegister';

export const InvitationRegisterPage = () => {
    const { invitationToken, isTokenValid, request, onSubmit } = useAcceptInvitation();
    useLoading(invitationToken == null || !isTokenValid);

    if (invitationToken == null || !isTokenValid) return <></>;

    return <InvitationRegisterForm request={request} handleSubmit={onSubmit} invitationToken={invitationToken} />;
};
