import React, { useState } from 'react';
import { useWindowSize } from '../shared/hooks/useWindowSize';
import { MobileLayout } from './SlotList/Layouts/MobileLayout';
import { DesktopLayout } from './SlotList/Layouts/DesktopLayout';
import { Button, ButtonGroup, Stack, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { Add, CalendarTodayOutlined, FilterList } from '@mui/icons-material';
import { SchedulePopover } from './SlotList/EditSlotModal/SchedulePopover';
import { ScheduleType, SlotUiMode } from './SlotList/EditSlotModal/EditSlotForm.types';
import { useSlotTime } from './hooks/useSlotTime';
import { useTranslation } from 'react-i18next';
import { BetterSelect } from '../shared/BetterSelect/BetterSelect';
import { NumericOption } from '../../utils/formUtils';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import { FilterDrawer, FilterGroup, FilterTypes } from '../shared/FilterDrawer/FilterDrawer';
import { dateToLocaleString } from '../../utils/stringUtils';
import { Option } from '../../utils/formUtils';
import { useSlotsDisplay } from './hooks/useSlotsDisplay';

export type GroupServicePair = {
    groupName: string;
    serviceName: string;
};

export const SlotsPage = () => {
    const { isExtraSmall, isLarge } = useWindowSize();
    const [dayIndex, setDayIndex] = useState(new Date().getDay() - 1 < 0 ? 6 : new Date().getDay() - 1);
    const [weekIndex, setWeekIndex] = useState(0);
    const { weekRange } = useSlotTime(dayIndex, weekIndex);
    const { t } = useTranslation();
    const [forceMobileLayout, setForceMobileLayout] = useState(false);
    const [isFilterDrawerOpen, toggleFilterDrawer] = useState(false);

    const displayOptions: NumericOption[] = [
        { value: 0, label: t('buttons:button_change_layout_week') },
        { value: 1, label: t('buttons:button_change_layout_day') },
    ];

    const scheduleTitle = 'Schedule Type';

    const scheduleOptions: Option[] = [
        { value: ScheduleType.appointment, label: t('filterDrawer:label_schedule_type_appointment') },
        { value: ScheduleType.slot, label: t('filterDrawer:label_schedule_type_slot') },
    ];

    const { getSlotColor, organizationLocations } = useSlotsDisplay();

    const scheduleFilterKey = 'schedule';
    const scheduleFilterGroup: FilterGroup[] = [
        {
            title: scheduleTitle,
            options: scheduleOptions,
            many_options: true,
            key: scheduleFilterKey,
            default_values: scheduleOptions,
        },
    ];

    const isMobile = !isLarge || forceMobileLayout;

    const [filteredOutServices, setFilteredOutServices] = useState<GroupServicePair[]>([]);
    const [filteredOutScheduleTypes, setFilteredOutScheduleTypes] = useState<string[]>([]);

    const applyFilters = (filterTypes: FilterTypes[], services: GroupServicePair[]) => {
        const schedules = filterTypes.find((f) => f.key === scheduleFilterKey)?.values;
        if (schedules === undefined) return;

        const filteredOutScheduleTypes = scheduleOptions.filter((o) => !schedules.includes(o.value));
        setFilteredOutScheduleTypes(filteredOutScheduleTypes.map((s) => s.value));
        setFilteredOutServices(services);
    };

    const getFilteredOutServicesNames = (): string[] => {
        const names: string[] = [];

        filteredOutServices.forEach((s) => {
            names.push(s.serviceName);
        });
        return names;
    };

    const handlePrevWeek = () => {
        setWeekIndex((prev) => prev - 1);
        setDayIndex(0);
    };

    const handleNextWeek = () => {
        setWeekIndex((prev) => prev + 1);
        setDayIndex(0);
    };

    return (
        <Stack
            display="flex"
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            sx={{ width: 'calc(100% + 40px)' }}
            spacing={1}
            style={{ marginTop: 0, marginLeft: -20, marginRight: -20 }}
        >
            <Stack
                spacing={1}
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                sx={{
                    width: '100%',
                    paddingX: !isLarge ? '5px' : '20px',
                }}
            >
                <Stack direction="row" spacing={isLarge ? 2 : 1} alignItems="center">
                    {isLarge && (
                        <Typography startDecorator={<CalendarTodayOutlined />}>{t('slotsPage:title')}</Typography>
                    )}
                    <ButtonGroup size="sm" variant="plain" spacing={-1}>
                        <IconButton onClick={handlePrevWeek}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <IconButton onClick={handleNextWeek}>
                            <ArrowRightIcon />
                        </IconButton>
                    </ButtonGroup>
                    <Typography level={weekIndex < 0 ? 'body-xs' : 'body-md'} fontSize={isLarge ? 20 : 16}>
                        <b>
                            {dateToLocaleString(weekRange?.dateFrom)} - {dateToLocaleString(weekRange?.dateTo)}
                        </b>
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                    {isLarge && (
                        <>
                            <Button
                                onClick={() => {
                                    setWeekIndex(0);
                                }}
                                variant="outlined"
                                color="neutral"
                                style={{ height: 'min-content' }}
                            >
                                {t('buttons:button_today')}
                            </Button>
                            <BetterSelect
                                value={+forceMobileLayout}
                                options={displayOptions}
                                width={'100px'}
                                onChange={(value) => setForceMobileLayout(!!value)}
                            />
                        </>
                    )}
                    <ButtonGroup variant="outlined">
                        <IconButton>
                            <SchedulePopover
                                button={<Add />}
                                uiMode={SlotUiMode.Add}
                                isMobile={isExtraSmall}
                                scheduleType={ScheduleType.slot}
                            />
                        </IconButton>
                        <IconButton onClick={() => toggleFilterDrawer(true)}>
                            <FilterList />
                        </IconButton>
                    </ButtonGroup>
                </Stack>
            </Stack>
            <div>
                {isMobile ? (
                    <MobileLayout
                        dayIndex={dayIndex}
                        weekIndex={weekIndex}
                        setDayIndex={setDayIndex}
                        filteredOutServices={getFilteredOutServicesNames()}
                        filteredOutSchedules={filteredOutScheduleTypes}
                    />
                ) : (
                    <DesktopLayout
                        weekIndex={weekIndex}
                        weekRange={weekRange}
                        filteredOutServices={getFilteredOutServicesNames()}
                        filteredOutSchedules={filteredOutScheduleTypes}
                    />
                )}
            </div>
            <FilterDrawer
                applyFilters={applyFilters}
                toggleDrawer={toggleFilterDrawer}
                isOpen={isFilterDrawerOpen}
                filterGroups={scheduleFilterGroup}
                filterCityGroups={organizationLocations}
                locationSlotColor={getSlotColor}
            />
        </Stack>
    );
};
