import { withFormik } from 'formik';
import { RecurringFieldProps, RecurringFieldValues } from './RecurringFieldModal.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './RecurringFieldModal.schema';
import { withTranslation } from 'react-i18next';
import { RecurringFieldModalData } from './RecurringFieldModalData';

const RecurringFieldModalForm = withFormik<RecurringFieldProps, RecurringFieldValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(RecurringFieldModalData);
export default withTranslation()(RecurringFieldModalForm);
