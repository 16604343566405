import { useEffect, useState } from 'react';
import { InvoiceItemData } from '../../../../../api/models';
import { apiSecondOpinion } from '../../../../../api';
import useApiRequest from '../../../../../hooks/useApiRequest';

export const useInvoiceData = () => {
    const [invoiceBillingItems, setInvoiceBillingItems] = useState<InvoiceItemData[]>([]);
    const request = useApiRequest();

    useEffect(() => {
        refreshBillingItems();
    }, []);

    const refreshBillingItems = () => {
        apiSecondOpinion.getBillingItems().then((response) => setInvoiceBillingItems(response));
    };

    const deleteInvoice = async (id: number) => {
        if (id < 0) return;
        await request.dispatch(apiSecondOpinion.deleteBillingItems(id));
    };

    const editInvoice = async (data: InvoiceItemData) => {
        await request.dispatch(apiSecondOpinion.editBillingItems(data));
    };

    const addInvoice = async (data: InvoiceItemData) => {
        await request.dispatch(apiSecondOpinion.createBillingItems(data));
    };

    return { invoiceBillingItems, refreshBillingItems, request, addInvoice, deleteInvoice, editInvoice };
};
