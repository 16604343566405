import React from 'react';
import { Box, Typography } from '@mui/joy';
import { Pagination, PaginationItem } from '@mui/material';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { SearchParams } from '../hooks/useErrorLogs';
import { ErrorLogMetadata } from '../../../api/models/errorLog';

type Props = {
    colSpan: number;
    params: SearchParams;
    setParams: React.Dispatch<React.SetStateAction<SearchParams>>;
    metadata: ErrorLogMetadata | undefined;
};
export const PaginationRow = ({ colSpan, params, setParams, metadata }: Props) => {
    const { isExtraSmall } = useWindowSize();

    const handlePagination = (page: number) => {
        if (!metadata) return;
        if (page > metadata.pagination.total_pages) return;
        setParams((current) => ({ ...current, current_page: page }));
    };

    return (
        <tr>
            <td
                colSpan={colSpan}
                style={{
                    borderTop: '1px solid var(--joy-palette-background-level2)',
                    position: 'sticky',
                    bottom: 0,
                }}
            >
                <Box
                    sx={{
                        bgcolor: 'var(--TableCell-headBackground)',
                        background: 'var(--TableCell-headBackground)',
                        display: 'flex',
                        justifyContent: isExtraSmall ? 'space-between' : 'end',
                        alignItems: 'center',
                        backgroundColor: 'var(--joy-palette-background-body)',
                        marginLeft: 2,
                        marginRight: 2,
                    }}
                >
                    <Typography>
                        Showing <span style={{ fontWeight: 'bold' }}>{params.current_page}</span> of
                        <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            {metadata?.pagination.total_pages === 0 ? 1 : metadata?.pagination.total_pages}{' '}
                        </span>
                        total pages
                    </Typography>
                    <Pagination
                        sx={{
                            bgcolor: 'var(--TableCell-headBackground)',
                        }}
                        disabled={metadata == undefined || metadata?.pagination.total_pages === 0}
                        count={metadata?.pagination.total_pages}
                        page={params.current_page}
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                sx={{
                                    bgcolor: 'var(--TableCell-headBackground)',
                                }}
                                onClick={(event) => {
                                    if (item.page !== 0) {
                                        handlePagination(item.page || 0);
                                    }
                                    item.onClick(event);
                                }}
                            />
                        )}
                    />
                </Box>
            </td>
        </tr>
    );
};
