import React, { useState } from 'react';

import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

import { useWindowSize } from '../../shared/hooks/useWindowSize';
import useKlaro from 'klaro/useKlaro';
import { KlaroLinks } from 'klaro/klaroLinks';
import { impressumLink, privatePolicyLink, termsAndConditionsLink } from 'klaro/klaroConstants';

export const Footer = () => {
    const { isExtraSmall } = useWindowSize();
    const { showConsentModal } = useKlaro();

    const [isVisible, setIsVisible] = useState(true);

    const toggleFooter = () => {
        setIsVisible(!isVisible);
    };

    if (isExtraSmall || !isVisible) {
        return (
            <IconButton
                onClick={toggleFooter}
                size="lg"
                sx={{
                    position: 'fixed',
                    display: isExtraSmall ? 'none' : 'block',
                    bottom: '12px',
                    right: '12px',
                    zIndex: 50,
                    borderRadius: '100%',
                    backgroundColor: 'var(--joy-palette-background-level1)',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                }}
            >
                <ArrowUpward sx={{ fontSize: '16px', color: 'primary.softBg' }} />
            </IconButton>
        );
    }

    return (
        <Box
            sx={{
                position: 'fixed',
                width: '100%',
                height: isVisible ? '60px' : '0px',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 40,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'var(--joy-palette-background-level1)',
                color: 'black',
                fontSize: '14px',
                transform: isVisible ? 'translateY(0)' : 'translateY(100%)',
                transition: 'transform 1s ease-in-out 3s, opacity 1s ease-in-out 2s',
                opacity: isVisible ? 1 : 0,
                overflow: 'hidden',
            }}
        >
            <KlaroLinks
                termsAndConditionsLink={termsAndConditionsLink}
                privatePolicyLink={privatePolicyLink}
                impressumLink={impressumLink}
                flexDirection="column"
                flexDirectionLink="row"
                copyrightPosition="bottom"
                showConsentModal={showConsentModal}
            />

            <IconButton
                onClick={toggleFooter}
                size="lg"
                sx={{
                    position: 'fixed',
                    bottom: '12px',
                    right: '12px',
                    zIndex: 50,
                    borderRadius: '100%',
                    backgroundColor: 'var(--joy-palette-background-surface)',
                }}
            >
                <ArrowDownward sx={{ fontSize: '16px', color: 'primary.softBg' }} />
            </IconButton>
        </Box>
    );
};
