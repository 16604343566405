import React, { useEffect } from 'react';
import { AppointmentModel } from '../../../../api/models';
import { useNavigate } from 'react-router-dom';
import { appointmentListRoute, videoCallRoute } from '../../../shared/Routing/routes';
import { Button, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import useAppointmentDataForVideoCall from '../../../../hooks/useAppointmentDataForVideoCall';

type Props = {
    appointment: AppointmentModel;
};

export const VideoCallTab = ({ appointment }: Props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { videoCallAvailable } = useAppointmentDataForVideoCall(appointment.id);
    return (
        <Stack>
            <Button
                disabled={!videoCallAvailable}
                onClick={() => {
                    navigate({ pathname: videoCallRoute, search: '?appId=' + appointment.id });
                }}
            >
                {videoCallAvailable ? t('videoCall:navigateToVideoCall') : t('videoCall:videoCallUnavailable')}
            </Button>
        </Stack>
    );
};
