import { UserDoctor } from '../api/models';
import useApiRequest from './useApiRequest';
import { api, apiSecondOpinion } from '../api';
import { useAuthContext } from '../components/Auth/AuthContext/AuthContext';
import { EditDoctorValues } from '../components/AccountDetails/EditModal/EditDoctor.types';

export const useDoctor = () => {
    const request = useApiRequest();
    const authContext = useAuthContext();

    const updateDoctor = async (doctor: EditDoctorValues) => {
        await request.dispatch(api.updateMemberProfile(doctor));
        const res = await request.dispatch(apiSecondOpinion.updateDoctorProfile(doctor));
        authContext?.updateDoctor();
        authContext?.updateProfile();
        return res;
    };

    const addDoctor = async (doctor: UserDoctor) => {
        return await request.dispatch(apiSecondOpinion.addDoctorProfile(doctor));
    };

    const getDoctor = async (): Promise<EditDoctorValues> => {
        const doctor = await request.dispatch(apiSecondOpinion.getDoctorProfile());
        const user = await request.dispatch(api.getUserProfile());
        return {
            ...doctor,
            description: user.practitioner?.description || '',
            image: user.practitioner?.image?.id,
        };
    };

    const isValid = (doctor: UserDoctor | undefined) => {
        if (!doctor) return false;
        return doctor.city && doctor.bank_number && doctor.street && doctor.post_code && doctor.phone_number;
    };

    return { isValid, request, updateDoctor, addDoctor, getDoctor };
};
