import React, { RefCallback } from 'react';
import { IMaskInput } from 'react-imask';
import { CustomProps } from './CustomProps';

export const PostalCodeAdapter = React.forwardRef<HTMLElement, CustomProps>(function VatMaskAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="#####"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});
