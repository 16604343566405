import React, { useMemo, useState } from 'react';
import { SlotProps, SlotValues } from '../EditSlotForm.types';
import { FormikProps, isString } from 'formik';
import {
    Select,
    Option as SelectOption,
    Stack,
    selectClasses,
    FormLabel,
    Box,
    Chip,
    Typography,
    SelectStaticProps,
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Option } from '../../../../../utils/formUtils';
import { useOrganizationCriteriaOptions } from './hooks/useOrganizationCriteriaOptions';
import IconButton from '@mui/joy/IconButton';
import { AddOutlined, CloseOutlined, DeleteOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { FormControlStyled } from '../../../../shared/Form/FormControlStyled';

type Props = {
    props: SlotProps & FormikProps<SlotValues>;
};
export const SearchCriteriaPicker = ({ props }: Props) => {
    const { t } = useTranslation();
    const [selectedCriteria, setSelectedCriteria] = useState<string | null>(null);
    const { slotsCriteriaOptions, getSingleCriteria, getCriteriaValueOptions, isLoading } =
        useOrganizationCriteriaOptions(process.env.REACT_APP_ORGANIZATION_ID || '0');
    const currentSlotsCriteriaOptions = useMemo(() => {
        return slotsCriteriaOptions?.filter(
            (criteriaOption) => !props.values.criteriaIds.some((criteria) => criteria.id === criteriaOption.value),
        );
    }, [props.values.criteriaIds, slotsCriteriaOptions]);
    const action: SelectStaticProps['action'] = React.useRef(null);

    return (
        <Stack spacing={1}>
            <FormControlStyled
                isError={!!(props.errors.criteriaIds && props.touched.criteriaIds)}
                isTouched={props.touched.criteriaIds !== undefined}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    style={{ width: '100%', height: '100%' }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <FormLabel style={{ minWidth: '100px', marginTop: '6px', display: 'inline-block' }} required>
                        {t('editSlotModal:criteriaPicker.label_add_criteria')}
                    </FormLabel>
                    <Select
                        indicator={<KeyboardArrowDown />}
                        sx={{
                            [`& .${selectClasses.indicator}`]: {
                                transition: '0.2s',
                                [`&.${selectClasses.expanded}`]: {
                                    transform: 'rotate(-180deg)',
                                },
                            },
                        }}
                        placeholder={t('editSlotModal:criteriaPicker.select_pick_criteria')}
                        style={{ width: '100%' }}
                        value={selectedCriteria}
                        onChange={(e, value) => {
                            if (value == null) return;
                            setSelectedCriteria(value);
                        }}
                    >
                        {currentSlotsCriteriaOptions?.map((value, index) => (
                            <SelectOption key={index} value={value.value}>
                                {value.label}
                            </SelectOption>
                        ))}
                    </Select>
                    <IconButton
                        variant="soft"
                        color="neutral"
                        disabled={selectedCriteria === null}
                        onClick={() => {
                            if (selectedCriteria == null) return;
                            const criterias = props.values.criteriaIds;
                            const singleCriteria = getSingleCriteria(selectedCriteria);
                            criterias.push({
                                id: selectedCriteria,
                                multiple_selection: singleCriteria?.multiple_selection || false,
                                values: [],
                            });
                            props.setFieldValue('criteriaIds', criterias);
                            setSelectedCriteria(null);
                        }}
                    >
                        <AddOutlined />
                    </IconButton>
                </Stack>
            </FormControlStyled>
            {props.values.criteriaIds.map((value, index) => (
                <FormControlStyled key={index} isError={false} isTouched={false}>
                    <Stack direction="column" spacing={1}>
                        <FormLabel required>
                            <Typography>{getSingleCriteria(value.id)?.name}</Typography>
                        </FormLabel>
                        <Stack key={index} direction="row" spacing={1}>
                            <Select
                                indicator={<KeyboardArrowDown />}
                                sx={{
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                                placeholder={t('editSlotModal:criteriaPicker.select_pick_value')}
                                style={{ width: '100%' }}
                                action={action}
                                multiple={value.multiple_selection}
                                value={
                                    value.multiple_selection
                                        ? props.values.criteriaIds[props.values.criteriaIds.indexOf(value)].values
                                        : props.values.criteriaIds[props.values.criteriaIds.indexOf(value)].values[0]
                                }
                                onChange={(e, newVal) => {
                                    if (newVal == null) return;
                                    const criteriaIds = props.values.criteriaIds;
                                    const index = criteriaIds.indexOf(value);
                                    const currentElement = criteriaIds[index];
                                    currentElement.values = isString(newVal) ? [newVal] : newVal;
                                    criteriaIds[index] = currentElement;
                                    props.setFieldValue('criteriaIds', criteriaIds);
                                }}
                                renderValue={(selected) =>
                                    value.multiple_selection ? (
                                        <>
                                            {Array.isArray(selected) && (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '0.25rem',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {selected?.map(
                                                        (selectedOption, index) =>
                                                            props.values.criteriaIds[
                                                                props.values.criteriaIds.indexOf(value)
                                                            ].values[
                                                                props.values.criteriaIds[
                                                                    props.values.criteriaIds.indexOf(value)
                                                                ].values.indexOf(selectedOption.value)
                                                            ] !== undefined && (
                                                                <Chip key={index} variant="soft" color="primary">
                                                                    <Stack
                                                                        direction="row"
                                                                        spacing={1}
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Typography fontSize="small">
                                                                            {selectedOption?.label}
                                                                        </Typography>
                                                                        <IconButton
                                                                            variant="plain"
                                                                            size="sm"
                                                                            style={{
                                                                                marginRight: -5,
                                                                                marginLeft: -2,
                                                                                width: '5px !important',
                                                                                height: '5px !important',
                                                                            }}
                                                                            sx={{
                                                                                '&:selected': {
                                                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                                                },
                                                                                '&:hover': {
                                                                                    backgroundColor: 'rgba(0,0,0,0)',
                                                                                },
                                                                            }}
                                                                            onMouseDown={(event) => {
                                                                                event.stopPropagation();
                                                                            }}
                                                                            onClick={() => {
                                                                                const criteriaIndex =
                                                                                    props.values.criteriaIds.indexOf(
                                                                                        value,
                                                                                    );
                                                                                const currentCriteria =
                                                                                    props.values.criteriaIds[
                                                                                        criteriaIndex
                                                                                    ];
                                                                                const indexOfVal =
                                                                                    currentCriteria.values.indexOf(
                                                                                        selectedOption.value,
                                                                                    );
                                                                                currentCriteria.values.splice(
                                                                                    indexOfVal,
                                                                                    1,
                                                                                );
                                                                                const criteriaIds =
                                                                                    props.values.criteriaIds;
                                                                                criteriaIds[criteriaIndex] =
                                                                                    currentCriteria;
                                                                                props.setFieldValue(
                                                                                    'criteriaIds',
                                                                                    criteriaIds,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <CloseOutlined />
                                                                        </IconButton>
                                                                    </Stack>
                                                                </Chip>
                                                            ),
                                                    )}
                                                </Box>
                                            )}
                                        </>
                                    ) : (
                                        <>{!Array.isArray(selected) && <>{selected?.label}</>}</>
                                    )
                                }
                            >
                                {getCriteriaValueOptions(value.id).map((option, optionIndex) => (
                                    <SelectOption key={optionIndex} value={option.value}>
                                        {option.label}
                                    </SelectOption>
                                ))}
                            </Select>
                            <IconButton
                                variant="soft"
                                color="neutral"
                                onClick={() => {
                                    const criteriaIds = props.values.criteriaIds;
                                    criteriaIds.splice(index, 1);
                                    props.setFieldValue('criteriaIds', criteriaIds);
                                }}
                                type="button"
                            >
                                <DeleteOutlined />
                            </IconButton>
                        </Stack>
                    </Stack>
                </FormControlStyled>
            ))}
        </Stack>
    );
};
