import React, { useEffect } from 'react';
import { LoadMoreButton } from './LoadMoreButton';
import { FlatList, FlatListProps } from '../FlatList/FlatList';

interface LoadMoreListProps<Item = unknown> extends FlatListProps<Item> {
    hasMore?: boolean;
    isLoading?: boolean;
    loadMore?: (prev: Array<Item>) => void;
    initialLoad?: boolean;
    loadMoreText?: string;
    fullWidth?: boolean;
}

export const LoadMoreList = <Item,>({
    data,
    hasMore,
    isLoading,
    loadMore,
    initialLoad,
    loadMoreText,
    fullWidth = false,
    ...rest
}: LoadMoreListProps<Item>) => {
    useEffect(() => {
        if (initialLoad === true) {
            loadMoreIfNeeded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMoreIfNeeded = () => {
        if (!loadMore || !hasMore) return;
        loadMore(data);
    };

    return (
        <FlatList
            key="test"
            data={data}
            ListFooterComponent={
                <div style={{ marginBottom: 10 }}>
                    <LoadMoreButton
                        fullWidth={fullWidth}
                        hasMore={hasMore}
                        isLoading={isLoading}
                        loadMore={loadMoreIfNeeded}
                        text={loadMoreText}
                    />
                </div>
            }
            {...rest}
        />
    );
};
