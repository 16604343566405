import { SlotProps, SlotValues } from './EditSlotForm.types';
import { defaultSlotMode, SlotEditScope, SlotPermission, SlotStatus } from '../../../../api/models';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
import {
    getInitialRecurringOption,
    getInitialRecurringRule,
    serializeRecurringRule,
} from './RecurringField/helpers/values';
import { SlotData } from 'api/models/forms/SlotData';
import axios from 'axios';
import { addMinutes } from 'date-fns';
export const mapPropsToValues = (props: SlotProps): SlotValues => {
    const criteriaIds =
        props.activeSlot?.criteria?.map((criteria) => ({
            id: criteria.id.toString(),
            multiple_selection: criteria.multiple_selection,
            values: criteria.values.map((value) => value.id.toString()),
        })) || [];

    return {
        healthcare_service: props.activeSlot?.healthcare_service.id || 0,
        appointment_reasons: [props.secondOpinionReason],
        repeat: '',
        status: SlotStatus.FREE,
        date: props.activeSchedule.dateFrom,
        date_hour: props.activeSchedule.dateFrom || '',
        dateTo_hour:
            // props.activeSchedule.dateTo ||
            addMinutes(new Date(props.activeSchedule.dateFrom), props.activeSchedule.duration),
        duration: props.activeSchedule.duration,
        comment: props.activeSlot?.comment || '',
        recurring_option: getInitialRecurringOption(props.activeSlot),
        recurring_rule: getInitialRecurringRule(props.activeSlot),
        scope: SlotEditScope.SINGLE,
        mode: props.activeSlot ? props.activeSlot.mode : defaultSlotMode,
        criteriaIds: criteriaIds,
        duration_type: 1,
        slot_visibility: Object.values(SlotPermission).indexOf(SlotPermission.PUBLIC),
    };
};

export const validationSchema = ({ t }: SlotProps) =>
    Yup.object().shape({
        healthcare_service: Yup.string()
            .matches(/[^0]/, t('editSlotModal:error_healthcare_service_matches'))
            .required(t('editSlotModal:error_healthcare_service_required')),
        date: Yup.date().required(t('editSlotModal:error_invalid_date')).nullable(),
        date_hour: Yup.string().required(t('editSlotModal:error_invalid_date_hour')).nullable(),
        dateTo_hour: Yup.string().required(t('editSlotModal:error_invalid_date_hour')).nullable(),
        duration: Yup.number()
            .required(t('editSlotModal:error_duration_required'))
            .min(5, t('editSlotModal:error_duration_range', { min: 5, max: 360 }))
            .max(360, t('editSlotModal:error_duration_range', { min: 5, max: 360 })),
        comment: Yup.string(),
    });

export const handleSubmit = async (
    values: SlotValues,
    { props, setErrors, setStatus, setSubmitting }: FormikBag<SlotProps, SlotValues>,
) => {
    setSubmitting(true);

    const dateFrom = new Date(
        values.date.getFullYear(),
        values.date.getMonth(),
        values.date.getDate(),
        values.date_hour.getHours(),
        values.date_hour.getMinutes(),
        0,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const criteria_values = values.criteriaIds.reduce((acc: any, next) => {
        return [...acc, ...next.values];
    }, []);
    const slot_permission = Object.values(SlotPermission)[values.slot_visibility];
    const dateTo = new Date(dateFrom.getTime());
    dateTo.setMinutes(dateTo.getMinutes() + values.duration);

    const data: SlotData = {
        healthcare_service: values.healthcare_service,
        available_for_reasons: values.appointment_reasons,
        status: values.status,
        date_from: dateFrom,
        date_to: dateTo,
        comment: values.comment,
        schedule_rule: serializeRecurringRule(values),
        mode: values.mode,
        criteria_values,
        slot_permission: slot_permission,
    };

    if (values.duration_type != 0) {
        try {
            props.handleSubmit(data, props.activeSchedule?.id || '', values.scope);
            setStatus({ success: true });
        } catch (e) {
            setStatus({ success: false });
        }
        setSubmitting(false);
        return;
    }

    const dateToRepeat = new Date(
        values.date.getFullYear(),
        values.date.getMonth(),
        values.date.getDate(),
        values.dateTo_hour.getHours(),
        values.dateTo_hour.getMinutes(),
    );
    for (let i = 0; i < Math.floor((dateToRepeat.getTime() - dateFrom.getTime()) / 1000 / 60 / values.duration); i++) {
        const data: SlotData = {
            healthcare_service: values.healthcare_service,
            available_for_reasons: values.appointment_reasons,
            status: values.status,
            date_from: new Date(
                values.date.getFullYear(),
                values.date.getMonth(),
                values.date.getDate(),
                values.date_hour.getHours(),
                values.date_hour.getMinutes() + values.duration * i,
                0,
            ),
            date_to: new Date(
                values.date.getFullYear(),
                values.date.getMonth(),
                values.date.getDate(),
                values.date_hour.getHours(),
                values.date_hour.getMinutes() + values.duration * (i + 1),
                0,
            ),
            comment: values.comment,
            schedule_rule: serializeRecurringRule(values),
            mode: values.mode,
            criteria_values,
            slot_permission: slot_permission,
        };

        props.handleSubmit(data, props.activeSchedule?.id || '', values.scope);
    }
};
