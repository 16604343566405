import useApiRequest from 'hooks/useApiRequest';
import { StringParam, useQueryParam } from 'use-query-params';
import { useEffect, useState } from 'react';
import { User, UserDoctor, UserRole, UserUpdateData } from '../../../../api/models';
import { errorTimeout } from '../../../../utils/constants';
import { api, apiSecondOpinion } from '../../../../api';
import { useAuthContext } from '../../AuthContext/AuthContext';
import { useErrorContext } from '../../../../context/errorContext';
import { InvitationRegisterFormValues } from '../InvitationRegister.types';
import axios from 'axios';
import { clearAuthTokens, setAuthTokens, setSoAuthTokens } from '../../../../utils/tokens';

export const useAcceptInvitation = () => {
    const request = useApiRequest({ errorTimeout });
    const [invitationToken] = useQueryParam('invitationToken', StringParam);
    const [invitee, setInvitee] = useState<User | null>(null);
    const [isTokenValid, setIsTokenValid] = useState(true);
    const authContext = useAuthContext();

    const onSubmit = async (data: InvitationRegisterFormValues) => {
        await request.dispatch(dispatchAcceptInvitation(data));
    };

    const dispatchAcceptInvitation = async (data: InvitationRegisterFormValues) => {
        await request.dispatch(acceptInvitation(data));
    };

    const acceptInvitation = async (data: InvitationRegisterFormValues) => {
        try {
            const response = await api.acceptInvitation(data);
            setAuthTokens(response);

            const secondOpinionData = await api.generateSecondOpinionToken();
            if (secondOpinionData) {
                setSoAuthTokens(secondOpinionData.second_opinion_token);
            } else {
                console.warn('Failed to fetch second opinion token');
            }

            api.client.refreshAndScheduleTokens();

            await apiSecondOpinion.updateDoctorProfile(data);

            const userProfile = await api.getUserProfile();
            if (userProfile) {
                authContext?.login(userProfile);
            } else {
                throw new Error('User profile information is missing in the response');
            }
        } catch (error) {
            console.error('Error during invitation acceptance:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (invitationToken != null) {
            api.validateInvitation(invitationToken)
                .then(setInvitee)
                .catch(() => setIsTokenValid(false));
        } else {
            setIsTokenValid(false);
        }
    }, [invitationToken, setIsTokenValid, setInvitee]);

    return {
        invitee,
        invitationToken,
        isTokenValid,
        request,
        onSubmit,
    };
};
