import { useContext } from 'react';
import { AlertContext, AlertMode } from './AlertContext';

export const useAlert = () => {
    const successAlertContext = useContext(AlertContext);

    const openAlert = (description: string, alertMode: AlertMode = 'success') => {
        if (!successAlertContext) return;

        successAlertContext.openAlert(description, alertMode);
    };

    return { openAlert };
};
