import React from 'react';
import { Button, Divider, Dropdown, MenuItem, Stack, ToggleButtonGroup, Typography } from '@mui/joy';
import { DarkModeOutlined, LightModeOutlined, Settings } from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';
import { OutlinedMenuButton } from './styled/OutlinedMenuButton.styled';
import { CornerMenu } from './styled/CornerMenu.styled';
import { useSharedColorScheme } from '../shared/ColorScheme/useSharedColorScheme';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import useKlaro from 'klaro/useKlaro';
import { KlaroLinks } from 'klaro/klaroLinks';
import { impressumLink, privatePolicyLink, termsAndConditionsLink } from 'klaro/klaroConstants';

export const UserSettings = () => {
    const { setTheme, theme } = useSharedColorScheme();
    const { t } = useTranslation();
    const { showConsentModal } = useKlaro();
    const [lang, setLang] = useQueryParam('lang', StringParam);

    return (
        <Dropdown>
            <OutlinedMenuButton slots={{ root: IconButton }}>
                <Settings sx={{ height: '20px', width: '20px' }} />
            </OutlinedMenuButton>
            <CornerMenu placement="bottom-end" variant="outlined" style={{ width: '250px' }}>
                <MenuItem style={{ width: '100%' }}>
                    <Stack width="100%">
                        <Typography level="body-md">{t('sidebar:language')}</Typography>
                        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
                        <ToggleButtonGroup
                            value={lang}
                            onChange={(event, newValue) => {
                                setLang(newValue);
                            }}
                            style={{ width: '100%' }}
                        >
                            <Button fullWidth value={'de'} size="sm">
                                <Typography
                                    fontSize={lang === 'de' ? 14 : 12}
                                    fontWeight={lang === 'de' ? 'bold' : '400'}
                                >
                                    DE
                                </Typography>
                            </Button>
                            <Button fullWidth value={'en'} size="sm">
                                <Typography
                                    fontSize={lang === 'en' ? 14 : 12}
                                    fontWeight={lang === 'en' ? 'bold' : '400'}
                                >
                                    EN
                                </Typography>
                            </Button>
                        </ToggleButtonGroup>
                    </Stack>
                </MenuItem>
                <MenuItem>
                    <Stack width="100%">
                        <Typography level="body-md">{t('sidebar:theme')}</Typography>
                        <Divider sx={{ marginBottom: 1, marginTop: 1 }} />
                        <ToggleButtonGroup
                            value={theme}
                            onChange={(event, newValue) => {
                                if (newValue !== 'light' && newValue !== 'dark') return;
                                setTheme(newValue);
                            }}
                            style={{ width: '100%' }}
                        >
                            <Button fullWidth value={'light'} size="sm">
                                {theme === 'light' ? (
                                    <LightModeIcon sx={{ fontSize: 20 }} />
                                ) : (
                                    <LightModeOutlined sx={{ fontSize: 20 }} />
                                )}
                            </Button>
                            <Button fullWidth value={'dark'} size="sm">
                                {theme === 'dark' ? (
                                    <DarkModeRoundedIcon sx={{ fontSize: 20 }} />
                                ) : (
                                    <DarkModeOutlined sx={{ fontSize: 20 }} />
                                )}
                            </Button>
                        </ToggleButtonGroup>
                    </Stack>
                </MenuItem>

                <MenuItem sx={{ marginTop: 1 }}>
                    <Stack width="100%">
                        <Typography level="body-md" sx={{ marginBottom: '8px' }}>
                            Policy
                        </Typography>
                        <Divider sx={{ marginBottom: 1 }} />
                        <KlaroLinks
                            termsAndConditionsLink={termsAndConditionsLink}
                            privatePolicyLink={privatePolicyLink}
                            impressumLink={impressumLink}
                            showConsentModal={showConsentModal}
                            flexDirection="column"
                            alignItems="start"
                            copyrightPosition="bottom"
                        />
                    </Stack>
                </MenuItem>
            </CornerMenu>
        </Dropdown>
    );
};
