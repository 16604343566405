import { AppointmentReason, HealthcareService, Organization, Price, UserPractitioner } from '.';
import { ScheduleRule } from './ScheduleRule';
import { SlotCriteria } from './SlotsCriteria';

export enum SlotStatus {
    BUSY = 'BUSY',
    FREE = 'FREE',
}

export enum SlotMode {
    ONLINE = 'ONLINE',
    ONSITE = 'ONSITE',
}

export enum SlotPermission {
    PRIVATE = 'Priv',
    PUBLIC = 'Pub',
    INTERNAL = 'Int',
}

export const defaultSlotMode = SlotMode.ONLINE;

export interface SlotSimplified {
    key: string;
    status: SlotStatus;
    date_from: string;
    date_to: string;
    comment: string;
    is_recurring: boolean;
}

export enum SlotEditScope {
    SINGLE = 'SINGLE',
    FOLLOWING = 'FOLLOWING',
    ALL = 'ALL',
}

export interface Slot {
    key: string;
    name: string;
    status: SlotStatus;
    date_from: string;
    date_to: string;
    is_recurring: boolean;
    available_for_reasons: AppointmentReason[];
    healthcare_service: HealthcareService;
    comment?: string;
    schedule_rule?: ScheduleRule;
    organization?: Organization;
    managing_user?: UserPractitioner;
    criteria: SlotCriteria[];
    mode: SlotMode | null;
    price: Price | null;
    slot_permission: SlotPermission;
}
