import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import { getAuthTokens, isTokenValid, setAuthTokens, setSoAuthTokens } from 'utils/tokens';
import { TokenPair, SecondOpinionToken } from './models';
import { navigateToLogin } from 'utils/loginNavigation';

export class HttpClient {
    private axiosInstance: AxiosInstance;
    private apiRoot: string;
    private tokenRefreshInterval?: NodeJS.Timeout;

    constructor(
        apiRoot: string,
        private refreshAuthToken: (refreshToken: string) => Promise<TokenPair>,
        private generateSecondOpinionToken: () => Promise<SecondOpinionToken>,
    ) {
        this.apiRoot = apiRoot;
        this.axiosInstance = axios.create({
            baseURL: apiRoot,
        });
        this.setupInterceptors();
        this.scheduleTokenRefresh();
    }

    private setupInterceptors() {
        this.axiosInstance.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
                config.headers = config.headers || {};

                if (config.headers['skip-auth'] || this.isSkippedRoute(config.url)) {
                    return config as InternalAxiosRequestConfig;
                }

                const { token } = getAuthTokens();
                if (token && isTokenValid(token)) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                } else if (this.isUserLoggedIn()) {
                    const newToken = await this.refreshAndRetryToken();
                    config.headers['Authorization'] = `Bearer ${newToken}`;
                }
                return config as InternalAxiosRequestConfig;
            },
            (error) => Promise.reject(error),
        );

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (
                    (error.response?.status === 401 || error.response?.status === 403) &&
                    !originalRequest._retry &&
                    !this.isSkippedRoute(originalRequest.url)
                ) {
                    originalRequest._retry = true;
                    if (this.isUserLoggedIn()) {
                        try {
                            const newToken = await this.refreshAndRetryToken();
                            originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                            return this.axiosInstance(originalRequest);
                        } catch (refreshError) {
                            navigateToLogin();
                            return Promise.reject(refreshError);
                        }
                    } else {
                        navigateToLogin();
                    }
                }
                return Promise.reject(error);
            },
        );
    }

    private isUserLoggedIn(): boolean {
        const { token, refresh } = getAuthTokens();
        return !!token && isTokenValid(refresh!);
    }

    private async refreshAndRetryToken(): Promise<string> {
        const { refresh } = getAuthTokens();
        if (refresh && isTokenValid(refresh)) {
            const newTokens = await this.refreshAuthToken(refresh);
            setAuthTokens(newTokens);
            this.setCommonHeader('Authorization', `Bearer ${newTokens.token}`);
            this.scheduleTokenRefresh();
            return newTokens.token;
        }
        throw new Error('Unable to refresh token');
    }

    private async refreshAllTokens() {
        const { refresh } = getAuthTokens();
        try {
            if (refresh && isTokenValid(refresh)) {
                const refreshData = await this.refreshAuthToken(refresh);
                setAuthTokens(refreshData);
                this.setCommonHeader('Authorization', `Bearer ${refreshData.token}`);

                const secondOpinionData = await this.generateSecondOpinionToken();
                setSoAuthTokens(secondOpinionData.second_opinion_token);
                this.scheduleTokenRefresh();
            } else {
                throw new Error('Refresh token is invalid or expired.');
            }
        } catch (error) {
            if (this.tokenRefreshInterval) {
                clearInterval(this.tokenRefreshInterval);
            }
        }
    }

    private scheduleTokenRefresh() {
        if (this.tokenRefreshInterval) {
            clearInterval(this.tokenRefreshInterval);
        }

        const { soToken } = getAuthTokens();
        const tokenExpiryTime = this.getTokenExpiryTime(soToken);
        const refreshTime = tokenExpiryTime - Date.now() - 5 * 60 * 1000;

        if (refreshTime > 0) {
            this.tokenRefreshInterval = setTimeout(() => {
                this.refreshAllTokens().then(() => this.scheduleTokenRefresh());
            }, refreshTime);
        }
    }

    private getTokenExpiryTime(token: string | null): number {
        if (!token) return 0;
        const decoded = jwt_decode<{ exp: number }>(token);
        return decoded.exp * 1000;
    }

    private isSkippedRoute(url?: string): boolean {
        const skippedRoutes = ['/auth/login/otp', '/auth/login', '/check-email'];
        return skippedRoutes.some((route) => url?.includes(route));
    }

    public refreshAndScheduleTokens() {
        this.refreshAllTokens().then(() => this.scheduleTokenRefresh());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async get<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const response = await this.axiosInstance.get<T, AxiosResponse<T>>(this.apiRoute(route), config);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const promise = this.axiosInstance.delete<T, AxiosResponse<T>>(this.apiRoute(route), config);
            return promise;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    head<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const promise = this.axiosInstance.head<T, AxiosResponse<T>>(this.apiRoute(route), config);
            return promise;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options<T = any>(route: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const promise = this.axiosInstance.options<T, AxiosResponse<T>>(this.apiRoute(route), config);
            return promise;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const promise = this.axiosInstance.post<T, AxiosResponse<T>>(this.apiRoute(route), data, config);
            return promise;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    put<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const promise = this.axiosInstance.put<T, AxiosResponse<T>>(this.apiRoute(route), data, config);
            return promise;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    patch<T = any>(route: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            const promise = this.axiosInstance.patch<T, AxiosResponse<T>>(this.apiRoute(route), data, config);
            return promise;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    setCommonHeader(key: string, value: string) {
        this.axiosInstance.defaults.headers.common[key] = value;
    }

    deleteCommonHeader(key: string) {
        delete this.axiosInstance.defaults.headers.common[key];
    }

    private apiRoute(route: string): string {
        return `${this.apiRoot}${route}`;
    }
}
