import React from 'react';
import { Grid } from '@mui/joy';
import { useTranslatedDates } from '../../../shared/hooks/useTranslatedDates';
import { SlotList } from '../SlotList';
import '../../../shared/styles/hideScroll.css';
import { DateRange } from '../../hooks/Calendar.types';
import { addDays } from 'date-fns';
import { getScaledVh } from '../../../../utils/styleUtils';

type Props = {
    weekIndex: number;
    weekRange: DateRange | undefined;
    filteredOutServices: string[];
    filteredOutSchedules: string[];
};
export const DesktopLayout = ({ weekIndex, weekRange, filteredOutServices, filteredOutSchedules }: Props) => {
    const { getWeek } = useTranslatedDates();

    if (weekRange === undefined) return null;

    const getDayNumber = (date: Date) => {
        const num = date.getDate().toString();
        return num.length > 1 ? num : '0' + num;
    };

    return (
        <Grid
            columns={7}
            container
            columnSpacing={0}
            sx={{
                '--Grid-borderWidth': '1px',
                borderTop: 'var(--Grid-borderWidth) solid',
                borderLeft: 'var(--Grid-borderWidth) solid',
                borderColor: 'divider',
                '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                },
            }}
        >
            {getWeek(false, false).map((element, index) => (
                <Grid md={1} key={index} style={{ height: '50px', textAlign: 'center', paddingTop: 2 }}>
                    <div>{element.value}</div>
                    <div>{getDayNumber(addDays(weekRange.dateFrom, index))}</div>
                </Grid>
            ))}
            {getWeek(false, false).map((element, index) => (
                <Grid
                    md={1}
                    key={index}
                    style={{ overflowY: 'scroll', height: getScaledVh(93, false) }}
                    className="hideScroll"
                >
                    <SlotList
                        dayIndex={index}
                        weekIndex={weekIndex}
                        itemLayout="vertical"
                        filteredOutServices={filteredOutServices}
                        filteredOutSchedules={filteredOutSchedules}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
