import React, { useState } from 'react';
import { useModalState } from '../BetterModal/useModalState';
import { BetterAlert } from './BetterAlert';

export type SuccessContextValues = {
    openAlert: (description: string, alertMode: AlertMode) => void;
};
export type AlertMode = 'success' | 'warning' | 'danger';
export const AlertContext = React.createContext<SuccessContextValues | undefined>(undefined);

type Props = {
    children: React.ReactNode;
};

export const SuccessContextProvider = ({ children }: Props) => {
    const alertState = useModalState();
    const [description, setDescription] = useState('');
    const [alertMode, setAlertMode] = useState<AlertMode>('success');

    const openAlert = (description: string, alertMode: AlertMode) => {
        alertState.toggleModal();
        setDescription(description);
        setAlertMode(alertMode);
        if (!alertState.isOpen) return;

        setTimeout(alertState.toggleModal, 500);
    };

    const value: SuccessContextValues = {
        openAlert: openAlert,
    };

    return (
        <AlertContext.Provider value={value}>
            <>
                {children}
                {alertState.isOpen && (
                    <BetterAlert state={alertState} description={description} alertMode={alertMode} />
                )}
            </>
        </AlertContext.Provider>
    );
};
