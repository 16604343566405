import React, { useEffect } from 'react';
import { SlotProps, SlotUiMode, SlotValues } from './EditSlotForm.types';
import { FormikProps } from 'formik';
import { Form } from 'react-bootstrap';
import { Button, FormLabel, Stack, Textarea, Typography } from '@mui/joy';
import { useEditSlotFieldOptions } from '../../hooks/useEditSlotFieldOptions';
import { FormControlStyled } from '../../../shared/Form/FormControlStyled';
import { MobileDatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { RecurringField } from './RecurringField/RecurringField';
import { useTranslation } from 'react-i18next';
import { SearchCriteriaPicker } from './SearchCriteriaPicker/SearchCriteriaPicker';
import { useWindowSize } from '../../../shared/hooks/useWindowSize';
import { ModeSelector } from './RecurringField/ModeSelector/ModeSelector';
import { BetterSelect } from '../../../shared/BetterSelect/BetterSelect';
import { OutlinedInputSx } from 'components/shared/OutlinedInputSx/OutlinedInputSx';
import { FormErrorMessage } from '../../../shared/FormErrorMessage/FormErrorMessage';
import { DeleteSlotModal } from './DeleteSlotModal/DeleteSlotModal';
import { addMinutes } from 'date-fns';

export const EditSlotData = (baseProps: SlotProps & FormikProps<SlotValues>) => {
    const { healthcareServiceOptions } = useEditSlotFieldOptions();
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const slotsType = [
        { value: 0, label: t('editSlotModal:slot_private') },
        { value: 1, label: t('editSlotModal:slot_public') },
        { value: 2, label: t('editSlotModal:slot_internal') },
    ];
    const durationTypes = [
        { value: 0, label: t('editSlotModal:slot_with_duration') },
        { value: 1, label: t('editSlotModal:slot_single') },
    ];
    const durationOptions = () => {
        const options = [];

        for (let i = 5; i <= 360; i += 5) {
            options.push({ label: i.toString(), value: i });
        }

        return options;
    };

    const getMinDate = () => {
        const date = new Date();
        date.setHours(6);
        return date;
    };

    const getMaxDate = () => {
        const date = new Date();
        date.setHours(21);
        return date;
    };

    const getDefaultDate = () => {
        const date = new Date();
        date.setHours(date.getHours() < 6 ? 6 : date.getHours());
        date.setHours(date.getHours() > 21 ? 21 : date.getHours());
        return date;
    };
    useEffect(() => {
        const hours = baseProps.values.date_hour.getHours();
        if (hours < 21 && hours > 6) return;

        const date = new Date();
        date.setHours(6);
        baseProps.setFieldValue('date_hour', date);
    }, []);

    return (
        <Form
            style={{ height: isExtraSmall ? '100%' : 'max-content', position: 'relative' }}
            onSubmit={(e) => {
                e.preventDefault();
                baseProps.handleSubmit();
            }}
        >
            <Stack
                direction="column"
                sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                spacing={2}
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: isExtraSmall ? 'calc(100% - 40px)' : '100%',
                    marginBottom: isExtraSmall ? '0' : '40px',
                }}
            >
                <Stack direction="row" justifyContent="space-evenly" spacing={1} alignItems="center">
                    <FormControlStyled
                        isError={!!(baseProps.errors.healthcare_service && baseProps.touched.healthcare_service)}
                        isTouched={baseProps.touched.healthcare_service}
                        style={{ width: '100%' }} // width should be set to 49% if slot_visibility field is enabled!
                    >
                        <FormLabel required>{t('editSlotModal:label_service')}</FormLabel>
                        <BetterSelect
                            options={healthcareServiceOptions}
                            value={baseProps.values.healthcare_service}
                            placeHolder={t('basics:select_placeholder')}
                            onChange={(value) => baseProps.setFieldValue('healthcare_service', value)}
                        />
                    </FormControlStyled>
                    {/*Currently disabled because slot access is set to public by default and should always stay this way, however, we are only commenting just in case this rule will change*/}
                    {/*<FormControlStyled
                        isError={!!(baseProps.errors.slot_visibility && baseProps.touched.slot_visibility)}
                        isTouched={baseProps.touched.slot_visibility}
                        style={{ width: '49%' }}
                    >
                        <FormLabel required>{t('editSlotModal:label_access')}</FormLabel>
                        <BetterSelect
                            options={slotsType}
                            value={baseProps.values.slot_visibility}
                            onChange={(val) => baseProps.setFieldValue('slot_visibility', val)}
                            defaultValue={1}
                        />
                    </FormControlStyled>*/}
                </Stack>
                <FormControlStyled
                    isError={!!(baseProps.errors.duration_type && baseProps.touched.duration_type)}
                    isTouched={baseProps.touched.duration_type}
                >
                    <FormLabel required>{t('editSlotModal:label_type_of_duration')}</FormLabel>
                    <BetterSelect
                        options={durationTypes}
                        value={baseProps.values.duration_type}
                        onChange={(val) => baseProps.setFieldValue('duration_type', val)}
                        defaultValue={1}
                    />
                </FormControlStyled>
                <Stack
                    direction={baseProps.values.duration_type === 0 ? 'column' : 'row'}
                    spacing={baseProps.values.duration_type === 0 ? 2 : 1}
                    justifyContent="center"
                    alignItems="stretch"
                    sx={{
                        width: '100%',
                        height: 'min-content',
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="stretch"
                        sx={{
                            width: '100%',
                        }}
                    >
                        <FormControlStyled
                            isError={!!(baseProps.touched.date && baseProps.errors.date)}
                            isTouched={baseProps.touched.date !== undefined}
                        >
                            <FormLabel required>{t('editSlotModal:label_date')}</FormLabel>
                            <MobileDatePicker
                                sx={OutlinedInputSx}
                                formatDensity={isExtraSmall ? 'dense' : 'spacious'}
                                orientation={isExtraSmall ? 'portrait' : 'landscape'}
                                format="dd/MM/yyyy"
                                minDate={baseProps.activeSchedule.dateFrom}
                                value={baseProps.values.date}
                                onAccept={(value) => {
                                    if (value === null) return;
                                    baseProps.setFieldValue('date', value);
                                }}
                            />
                        </FormControlStyled>
                        {baseProps.values.duration_type === 1 ? (
                            <FormControlStyled
                                isError={!!(baseProps.touched.date_hour && baseProps.errors.date_hour)}
                                isTouched={baseProps.touched.date_hour !== undefined}
                            >
                                <FormLabel required>{t('editSlotModal:label_hour')}</FormLabel>
                                <MobileTimePicker
                                    sx={OutlinedInputSx}
                                    defaultValue={getDefaultDate()}
                                    ampm={false}
                                    formatDensity={isExtraSmall ? 'dense' : 'spacious'}
                                    orientation={isExtraSmall ? 'portrait' : 'landscape'}
                                    minTime={getMinDate()}
                                    maxTime={getMaxDate()}
                                    value={baseProps.values.date_hour}
                                    onAccept={(value) => {
                                        if (value === null) return;
                                        baseProps.setFieldValue('date_hour', value);
                                    }}
                                />
                            </FormControlStyled>
                        ) : (
                            <Stack direction="row" spacing={1}>
                                <FormControlStyled
                                    isError={!!(baseProps.touched.date && baseProps.errors.date)}
                                    isTouched={baseProps.touched.date !== undefined}
                                >
                                    <FormLabel required>{t('editSlotModal:label_start_hour')}</FormLabel>
                                    <MobileTimePicker
                                        sx={OutlinedInputSx}
                                        formatDensity={isExtraSmall ? 'dense' : 'spacious'}
                                        defaultValue={getDefaultDate()}
                                        ampm={false}
                                        orientation={isExtraSmall ? 'portrait' : 'landscape'}
                                        minTime={getMinDate()}
                                        maxTime={getMaxDate()}
                                        onAccept={(value) => {
                                            if (value === null) return;
                                            baseProps.setFieldValue('date_hour', value);
                                            if (value > baseProps.values.dateTo_hour) {
                                                baseProps.values.dateTo_hour = addMinutes(
                                                    value,
                                                    baseProps.values.duration,
                                                );
                                                baseProps.setFieldValue(
                                                    'dateTo_hour',
                                                    addMinutes(value, baseProps.values.duration),
                                                );
                                            }
                                        }}
                                        value={new Date(baseProps.values.date_hour || getMinDate())}
                                    />
                                </FormControlStyled>
                                <FormControlStyled
                                    isError={!!(baseProps.touched.date && baseProps.errors.date)}
                                    isTouched={baseProps.touched.date !== undefined}
                                >
                                    <FormLabel required>{t('editSlotModal:label_end_hour')}</FormLabel>
                                    <MobileTimePicker
                                        sx={OutlinedInputSx}
                                        defaultValue={getDefaultDate()}
                                        ampm={false}
                                        minutesStep={baseProps.values.duration ?? 5}
                                        orientation={isExtraSmall ? 'portrait' : 'landscape'}
                                        minTime={addMinutes(
                                            new Date(baseProps.values.date_hour),
                                            baseProps.values.duration,
                                        )}
                                        maxTime={getMaxDate()}
                                        value={
                                            // baseProps.values.dateTo_hour > baseProps.values.date_hour
                                            //     ?
                                            baseProps.values.dateTo_hour
                                            // : addMinutes(getMinDate(), baseProps.values.duration)
                                        }
                                        onAccept={(value) => {
                                            if (value === null) return;
                                            baseProps.setFieldValue('dateTo_hour', value);
                                        }}
                                    />
                                </FormControlStyled>
                            </Stack>
                        )}
                    </Stack>
                    <FormControlStyled
                        isError={!!(baseProps.touched.duration && baseProps.errors.duration)}
                        isTouched={baseProps.touched.duration}
                        style={{ width: '50%' }}
                    >
                        <FormLabel required>{t('editSlotModal:label_duration')}</FormLabel>
                        <BetterSelect
                            options={durationOptions()}
                            value={baseProps.values.duration}
                            onChange={(val) => baseProps.setFieldValue('duration', val)}
                        />
                    </FormControlStyled>
                </Stack>
                {(baseProps.activeSlot === undefined || baseProps.activeSlot?.is_recurring) && (
                    <Stack spacing={1}>
                        <FormLabel>{t('editSlotModal:recurringOption.repeat')}</FormLabel>
                        <RecurringField props={baseProps} />
                    </Stack>
                )}
                <FormControlStyled
                    isError={!!(baseProps.touched.comment && baseProps.errors.comment)}
                    isTouched={baseProps.touched.comment}
                >
                    <FormLabel>{t('editSlotModal:label_comment')}</FormLabel>
                    <Textarea
                        minRows={3}
                        value={baseProps.values.comment}
                        onChange={(value) => {
                            if (value.target.value === null) return;
                            baseProps.setFieldValue('comment', value.target.value);
                        }}
                        onBlur={baseProps.handleBlur}
                    />
                </FormControlStyled>
                <Stack spacing={1}>
                    <Typography level="h3">{t('editSlotModal:label_appointment_mode')}</Typography>
                    <ModeSelector props={baseProps} />
                </Stack>
                <FormControlStyled
                    isError={!!(baseProps.errors.healthcare_service && baseProps.touched.healthcare_service)}
                    isTouched={baseProps.touched.healthcare_service}
                >
                    <Typography level="h3">{t('editSlotModal:label_define_search_criteria')}</Typography>
                    <SearchCriteriaPicker props={baseProps} />
                </FormControlStyled>
                <FormErrorMessage onlyTouched={false} />
            </Stack>
            <div style={{ position: 'fixed', width: '95%', bottom: 10, left: '50%', transform: 'translate(-50%, 0)' }}>
                {baseProps.mode === SlotUiMode.Edit ? (
                    <Stack direction="row" spacing={1} justifyContent="space-around" alignItems="center">
                        <div style={{ width: '100%' }}>
                            <DeleteSlotModal props={baseProps} />
                        </div>
                        <Button color="primary" type="submit" fullWidth>
                            {t('buttons:button_save')}
                        </Button>
                    </Stack>
                ) : (
                    <Button fullWidth type="submit" variant="solid" color="primary">
                        {t('buttons:button_add')}
                    </Button>
                )}
            </div>
        </Form>
    );
};
