import React, { useMemo } from 'react';
import { RecurringSlotOption, SlotProps, SlotValues } from '../EditSlotForm.types';
import { FormikProps } from 'formik';
import { useModalState } from '../../../../shared/BetterModal/useModalState';
import { Option, Select, selectClasses } from '@mui/joy';
import { KeyboardArrowDown } from '@mui/icons-material';
import { recurringOptionText } from './helpers/values';
import { BetterModal } from '../../../../shared/BetterModal/BetterModal';
import { useTranslation } from 'react-i18next';
import { rruleToText } from '../../../../../i18n/rrule-i18n';
import { getActiveLanguage } from 'utils/i18n';
import { Language } from '../../../../../api/models';
import { toTitleCase } from '../../../../../utils/stringUtils';
import RecurringFieldModalForm from './RecuringFieldModal/RecurringFieldModalForm';
import { RRule } from 'rrule';
import { getByMonth, getByMonthDay, getByWeekDay } from './helpers/rrule';

type Props = {
    props: SlotProps & FormikProps<SlotValues>;
};
export const RecurringField = ({ props }: Props) => {
    const { t } = useTranslation();
    const customRepeatModal = useModalState();
    const { activeSlot } = props;
    const recurringSlotOptions = useMemo(() => {
        const recurringOption = (value: RecurringSlotOption) => ({ label: recurringOptionText(value), value });

        const options = [
            recurringOption(RecurringSlotOption.daily),
            recurringOption(RecurringSlotOption.weekly),
            recurringOption(RecurringSlotOption.monthly),
            recurringOption(RecurringSlotOption.yearly),
            recurringOption(RecurringSlotOption.everyWeekday),
            recurringOption(RecurringSlotOption.custom),
        ];

        if (!activeSlot?.is_recurring) {
            options.unshift(recurringOption(RecurringSlotOption.dontRepeat));
        }

        return options;
    }, [activeSlot]);

    const getSlotOptionIndex = () => {
        for (let i = 0; i < recurringSlotOptions.length; i++) {
            const option = recurringSlotOptions[i];
            if (option.value === props.values.recurring_option) return i;
        }
        return 0;
    };

    const getSlotOptionText = (option: RecurringSlotOption, showCustom = false) => {
        const rule = props.values.recurring_rule;
        const optionText =
            option === RecurringSlotOption.custom && rule !== undefined && showCustom
                ? rruleToText(rule, getActiveLanguage() as Language)
                : recurringOptionText(option);
        return toTitleCase(optionText);
    };

    const handleSubmit = (rule: RRule) => {
        customRepeatModal.toggleModal();
        props.setFieldValue('recurring_rule', rule);
        props.setFieldValue('recurring_option', RecurringSlotOption.custom);
    };

    function scheduleRuleMapper(recurringOption: RecurringSlotOption, dateFrom: Date): RRule | undefined {
        switch (recurringOption) {
            case RecurringSlotOption.daily:
                return new RRule({
                    freq: RRule.DAILY,
                    interval: 1,
                });
            case RecurringSlotOption.weekly:
                return new RRule({
                    freq: RRule.WEEKLY,
                    interval: 1,
                    byweekday: [getByWeekDay(dateFrom)],
                });
            case RecurringSlotOption.everyWeekday:
                return new RRule({
                    freq: RRule.WEEKLY,
                    interval: 1,
                    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
                });
            case RecurringSlotOption.monthly:
                return new RRule({
                    freq: RRule.MONTHLY,
                    interval: 1,
                    bymonthday: getByMonthDay(dateFrom),
                });
            case RecurringSlotOption.yearly:
                return new RRule({
                    freq: RRule.YEARLY,
                    interval: 1,
                    bymonthday: getByMonthDay(dateFrom),
                    bymonth: getByMonth(dateFrom),
                });
            default:
                return undefined;
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <Select
                indicator={<KeyboardArrowDown />}
                sx={{
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                }}
                defaultValue={5}
                value={getSlotOptionIndex()}
                onChange={(event, value) => {
                    if (value === null) return;
                    const optionVal = recurringSlotOptions[+value].value;
                    if (optionVal === RecurringSlotOption.custom) {
                        customRepeatModal.toggleModal();
                        return;
                    }
                    props.setFieldValue('recurring_option', optionVal);
                    props.setFieldValue('recurring_rule', scheduleRuleMapper(optionVal, props.values.date));
                }}
            >
                {recurringSlotOptions.map((element, index) => (
                    <Option key={index} value={index} label={getSlotOptionText(element.value, true)}>
                        {getSlotOptionText(element.value, false)}
                    </Option>
                ))}
            </Select>
            <BetterModal
                layout="center"
                maxWidth={800}
                state={customRepeatModal}
                title={t('editSlotModal:customRepetition.title')}
                body={<RecurringFieldModalForm currentRule={props.values.recurring_rule} onSubmit={handleSubmit} />}
            />
        </div>
    );
};
