import React from 'react';
import { InvoiceRequestData, InvoiceStatus, UserRole } from '../../../../../api/models';
import InvoiceStatusBadge from '../../../../shared/InvoiceStatusBadge/InvoiceStatusBadge';
import { Button, Grid, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { pdf } from '@react-pdf/renderer';
import InvoiceModalBody from '../InvoiceModal/InvoiceModalBody';
import * as FileSaver from 'file-saver';
import { mapInvoiceRequestDataToInvoice } from '../utils';
import { useAuthContext } from '../../../../Auth/AuthContext/AuthContext';
import { useModalState } from '../../../../shared/BetterModal/useModalState';
import ConfirmModal from '../../../../shared/ConfirmModal/ConfirmModal';
import { InvoicePreview } from '../../../../Invoice/components/InvoicePreview';
import { getFullName } from '../../../../../api/utils';

interface InvoiceElementProps {
    invoice: InvoiceRequestData;
    markPaid: (id: number) => void;
    markCanceled: (id: number) => void;
}

export const generatePdfDocument = async (
    invoice: InvoiceRequestData,
    setLoading: (isLoading: boolean) => void,
    reminder = false,
) => {
    try {
        setLoading(true);
        const blob = await pdf(
            <InvoiceModalBody pdfMode={true} data_={mapInvoiceRequestDataToInvoice(invoice)} />,
        ).toBlob();

        const base64String = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });

        if (reminder) {
            return base64String;
        } else {
            FileSaver.saveAs(blob, `${invoice.invoiceNr}.pdf`);
        }
    } catch (error) {
        console.log(error);
        throw error;
    } finally {
        setLoading(false);
    }
};

function InvoiceElement({ invoice, markCanceled, markPaid }: InvoiceElementProps) {
    const authContext = useAuthContext();
    const cancelConfirmModal = useModalState();
    const paidConfirmModal = useModalState();
    const modalState = useModalState();

    const { t } = useTranslation();
    return (
        <>
            <Grid container>
                <Grid xs={12} md={6}>
                    <div>
                        <Typography level="body-sm">{t('billing:invoiceNr')}</Typography>
                        <Typography>{invoice?.invoiceNr}</Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('billing:date')}</Typography>
                        <Typography>{moment(invoice.invoiceDate).format('DD MMMM YYYY')}</Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('billing:dateDue')}</Typography>
                        <Typography>{moment(invoice.invoiceDueDate).format('DD MMMM YYYY')}</Typography>
                    </div>
                </Grid>
                <Grid xs={12} md={6}>
                    <div>
                        <Typography level="body-sm">{t('billing:patientName')}</Typography>
                        <Typography>
                            {getFullName({
                                personal_title: invoice?.patient.personal_title || '',
                                first_name: invoice?.patient.name,
                                last_name: invoice?.patient.surname,
                            })}
                        </Typography>
                    </div>
                    <div>
                        <Typography level="body-sm">{t('billing:status')}</Typography>
                        <InvoiceStatusBadge invoice={invoice} />
                    </div>
                    <div>
                        <Typography level="body-sm">{t('billing:cost')}</Typography>
                        <Typography>{invoice.total_price.toFixed(2) + ' €'}</Typography>
                    </div>
                </Grid>
            </Grid>
            <Stack direction="row" spacing={1} justifyContent="space-around" alignItems="center">
                <Button
                    disabled={
                        authContext?.authUser?.role != UserRole.organizationMember ||
                        invoice.status != InvoiceStatus.Pending
                    }
                    fullWidth={true}
                    onClick={() => {
                        paidConfirmModal.toggleModal();
                    }}
                >
                    {t('invoice:accept_invoice')}
                </Button>
                <Button
                    disabled={
                        authContext?.authUser?.role != UserRole.organizationMember ||
                        invoice.status != InvoiceStatus.Pending
                    }
                    color="danger"
                    fullWidth={true}
                    onClick={() => {
                        cancelConfirmModal.toggleModal();
                    }}
                >
                    {t('invoice:cancell_invoice')}
                </Button>

                <div style={{ width: '100%' }}>
                    <InvoicePreview
                        modalState={modalState}
                        invoice={invoice}
                        toggleButton={
                            <Button type="button" fullWidth={true}>
                                {t('buttons:button_preview')}
                            </Button>
                        }
                    />
                </div>
            </Stack>
            <ConfirmModal
                title={t('invoice:cancel_modal:title')}
                successAlertDescription={t('invoice:success_message')}
                modalDescription={t('invoice:cancel_modal:text')}
                onConfirm={async () => {
                    markCanceled(invoice.idBill);
                    cancelConfirmModal.toggleModal();
                    return Promise.resolve();
                }}
                modalState={cancelConfirmModal}
            />
            <ConfirmModal
                title={t('invoice:paid_modal:title')}
                successAlertDescription={t('invoice:success_message')}
                modalDescription={t('invoice:paid_modal:text')}
                onConfirm={async () => {
                    markPaid(invoice.idBill);
                    paidConfirmModal.toggleModal();
                    return Promise.resolve();
                }}
                modalState={paidConfirmModal}
            />
        </>
    );
}

export default InvoiceElement;
