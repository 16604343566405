import React, { ReactNode, useEffect, useState } from 'react';
import { BetterModal, ModalState } from '../BetterModal/BetterModal';
import { Button, ButtonGroup, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@mui/icons-material';
import { useAlert } from '../BetterAlert/useAlert';

interface ConfirmModalProps {
    title?: string;
    button?: ReactNode;
    modalDescription: string;
    successAlertDescription: string;
    onConfirm: () => Promise<void>;
    modalState: ModalState;
}

function ConfirmModal({
    title,
    modalDescription,
    modalState,
    button,
    onConfirm,
    successAlertDescription,
}: ConfirmModalProps) {
    const { t } = useTranslation();
    const { openAlert } = useAlert();
    const [r, refresh] = useState(true);

    useEffect(() => {
        refresh(!r);
    }, [successAlertDescription]);

    const handleConfirm = () => {
        onConfirm().then(() => openAlert(successAlertDescription));
        modalState.toggleModal();
    };

    return (
        <BetterModal
            state={modalState}
            minWidth={400}
            button={button}
            title={
                <Typography startDecorator={<WarningOutlined />} level="h1">
                    {title ?? t('basics:warning')}
                </Typography>
            }
            body={
                <div>
                    <Stack
                        className="my-2"
                        direction="row"
                        spacing={1}
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <Typography>{modalDescription}</Typography>
                    </Stack>
                    <ButtonGroup spacing={1} variant="solid">
                        <Button color="danger" fullWidth={true} onClick={handleConfirm}>
                            {t('buttons:button_yes')}
                        </Button>
                        <Button color="primary" fullWidth={true} onClick={modalState.toggleModal}>
                            {t('buttons:button_cancel')}
                        </Button>
                    </ButtonGroup>
                </div>
            }
        />
    );
}

export default ConfirmModal;
