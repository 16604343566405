import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/joy';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { InvoiceSortingOptions } from 'api/models';

type InvoiceTableHeaderProps = {
    headers: string[];
    showStatus?: boolean;
    isDashboard?: boolean;
    onSortChange?: (field: InvoiceSortingOptions) => void;
    sortField?: InvoiceSortingOptions | null;
    isDescending?: boolean | null;
};

export const InvoiceTableHeader = ({
    headers,
    showStatus = true,
    isDashboard = false,
    onSortChange,
    sortField,
    isDescending,
}: InvoiceTableHeaderProps) => {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const handleHeaderClick = (field: InvoiceSortingOptions) => {
        if (onSortChange) {
            onSortChange(field);
        }
    };

    const renderSortArrow = (field: InvoiceSortingOptions) => {
        if (sortField !== field) return '';
        if (isDescending === null) return '';
        return isDescending ? '↓' : '↑';
    };

    const headerCellStyle = {
        width: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        flexShrink: 0,
        cursor: 'pointer',
    };

    return (
        <TableHead sx={{ '--TableCell-headBackground': 'transparent' }}>
            <TableRow>
                <TableCell sx={headerCellStyle} onClick={() => handleHeaderClick(InvoiceSortingOptions.InvoiceNr)}>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 4 }}>
                        <Typography>{headers[0]}</Typography>
                        {renderSortArrow(InvoiceSortingOptions.InvoiceNr)}
                    </div>
                </TableCell>
                <TableCell sx={headerCellStyle} onClick={() => handleHeaderClick(InvoiceSortingOptions.PatientName)}>
                    <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 4 }}>
                        <Typography>{headers[1]}</Typography>
                        {renderSortArrow(InvoiceSortingOptions.PatientName)}
                    </div>
                </TableCell>
                {!isDashboard && (
                    <TableCell
                        sx={{ ...headerCellStyle, display: { xs: 'none', sm: 'table-cell' } }}
                        onClick={() => handleHeaderClick(InvoiceSortingOptions.InvoiceDateDue)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 4 }}>
                            <Typography>{headers[2]}</Typography>
                            {renderSortArrow(InvoiceSortingOptions.InvoiceDateDue)}
                        </div>
                    </TableCell>
                )}
                {!isDashboard && (
                    <TableCell
                        sx={{
                            ...headerCellStyle,
                            display: { xs: 'none', sm: 'table-cell' },
                        }}
                        onClick={() => handleHeaderClick(InvoiceSortingOptions.TotalPrice)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 4 }}>
                            <Typography sx={{ textAlign: 'end', width: { sm: '50%', lg: '30%' } }}>
                                {t('invoice:invoice_amount')}
                            </Typography>
                            {renderSortArrow(InvoiceSortingOptions.TotalPrice)}
                        </div>
                    </TableCell>
                )}
                {showStatus && !isDashboard && (
                    <TableCell
                        sx={{
                            ...headerCellStyle,
                            display: { xs: 'none', sm: 'table-cell' },
                        }}
                    >
                        {t('invoice:invoice_status')}
                    </TableCell>
                )}
                <TableCell sx={{ width: isDashboard ? '15%' : '1%' }}>
                    {isExtraSmall || isDashboard ? '' : t('invoice:invoice_action')}
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
