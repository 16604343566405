import React, { RefCallback } from 'react';
import { CustomProps } from './CustomProps';
import { IMaskInput } from 'react-imask';

export const PhoneAdapter = React.forwardRef<HTMLElement, CustomProps>(function BankMaskAdapter(props, ref) {
    const { onChange, ...other } = props;

    return (
        <IMaskInput
            {...other}
            mask="+## ### ### ######"
            definitions={{
                '#': /[0-9]/,
            }}
            inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
            onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
            overwrite="shift"
        />
    );
});
