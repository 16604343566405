import React, { ReactNode, useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Height } from 'react-animate-height/dist/cjs';

type Props = {
    children: ReactNode;
    style?: React.CSSProperties;
    duration?: number;
};
export const DynamicHeight = ({ children, style, duration = 500 }: Props) => {
    const [height, setHeight] = useState<Height>('auto');
    const contentDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const element = contentDiv.current as HTMLDivElement;

        if (element === null) return;

        const resizeObserver = new ResizeObserver(() => {
            setHeight(element.clientHeight);
        });

        resizeObserver.observe(element);

        return () => resizeObserver.disconnect();
    }, [contentDiv]);

    return (
        <div style={style}>
            <AnimateHeight height={height} duration={duration}>
                <div ref={contentDiv}>{children}</div>
            </AnimateHeight>
        </div>
    );
};
