import { Chip, Grid, Stack } from '@mui/joy';
import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentList } from './AppointmentList/AppointmentList';
import { AppointmentModel, InvoiceRequestData } from '../../../api/models';
import { MyAppointmentsData } from '../../../hooks/constants';
import '../../shared/styles/hideScroll.css';
import { useModalState } from '../../shared/BetterModal/useModalState';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { DetailsTabs } from '../AppointmentDetailsSection/DetailsTabs/DetailsTabs';
import { getScaledVh } from '../../../utils/styleUtils';
import { NumberParam, useQueryParam } from 'use-query-params';
import { api } from '../../../api';
import { AppointmentRevalidation } from 'hooks/useAppointments';

type Props = {
    pastAppointments: MyAppointmentsData;
    cancelledAppointments: MyAppointmentsData;
    upcomingWithOngoingAppointments: MyAppointmentsData;
    loadMoreCancelledAppointments: (prev: AppointmentModel[]) => void;
    loadMorePastAppointments: (prev: AppointmentModel[]) => void;
    reloadAppointments: () => void;
    loadMoreUpcomingAppointments: (prev: AppointmentModel[]) => void;
    invoices: InvoiceRequestData[];
    isLoadMoreLoading: { [key in AppointmentRevalidation]: boolean };
};
export const AppointmentsTable = ({
    pastAppointments,
    cancelledAppointments,
    upcomingWithOngoingAppointments,
    loadMoreCancelledAppointments,
    loadMorePastAppointments,
    loadMoreUpcomingAppointments,
    reloadAppointments,
    invoices,
    isLoadMoreLoading,
}: Props) => {
    const { t } = useTranslation();
    const [appointmentId] = useQueryParam('appId', NumberParam);
    const [didOpen, setDidOpen] = useState(false);

    useEffect(() => {
        if (!appointmentId || modalState.isOpen || didOpen) return;

        const joinedList = pastAppointments.appointments.concat(
            cancelledAppointments.appointments.concat(upcomingWithOngoingAppointments.appointments),
        );
        const foundAppointment = joinedList.find((a) => a.id === appointmentId);
        if (foundAppointment === undefined) {
            api.getAppointment(appointmentId).then((res) => {
                handlePickAppointment(res);
                setDidOpen(true);
            });
        } else {
            handlePickAppointment(foundAppointment);
            setDidOpen(true);
        }
    }, [
        appointmentId,
        cancelledAppointments.appointments,
        cancelledAppointments.isLoading,
        pastAppointments.appointments,
        pastAppointments.isLoading,
        upcomingWithOngoingAppointments.appointments,
        upcomingWithOngoingAppointments.isLoading,
    ]);

    const columnLabel = (count: number, label: string): ReactNode => (
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
            <div>{label}</div>
            <Chip size="sm" variant="solid" color="neutral">
                {count}
            </Chip>
        </Stack>
    );
    const [pickedAppointment, pickAppointment] = useState<AppointmentModel | undefined>(undefined);
    const modalState = useModalState();
    const handlePickAppointment = (data: AppointmentModel) => {
        pickAppointment(data);
        modalState.toggleModal();
    };

    const columns = [
        {
            label: columnLabel(
                upcomingWithOngoingAppointments.totalCount,
                t('appointmentList:label_appointments_upcoming'),
            ),
            list: (
                <AppointmentList
                    appointmentsData={upcomingWithOngoingAppointments}
                    pickAppointment={handlePickAppointment}
                    reloadAppointments={reloadAppointments}
                    loadMore={loadMoreUpcomingAppointments}
                    noItemsMessage={t('appointmentList:message_appointments_upcoming_empty')}
                    invoices={invoices}
                    isLoading={isLoadMoreLoading[AppointmentRevalidation.UPCOMING]}
                />
            ),
        },
        {
            label: columnLabel(pastAppointments.totalCount, t('appointmentList:label_appointments_past')),
            list: (
                <AppointmentList
                    appointmentsData={pastAppointments}
                    pickAppointment={handlePickAppointment}
                    loadMore={loadMorePastAppointments}
                    reloadAppointments={reloadAppointments}
                    noItemsMessage={t('appointmentList:message_appointments_past_empty')}
                    invoices={invoices}
                    isLoading={isLoadMoreLoading[AppointmentRevalidation.PAST]}
                />
            ),
        },
        {
            label: columnLabel(cancelledAppointments.totalCount, t('appointmentList:label_appointments_canceled')),
            list: (
                <AppointmentList
                    appointmentsData={cancelledAppointments}
                    pickAppointment={handlePickAppointment}
                    reloadAppointments={reloadAppointments}
                    loadMore={loadMoreCancelledAppointments}
                    noItemsMessage={t('appointmentList:message_appointments_canceled_empty')}
                    invoices={invoices}
                    isLoading={isLoadMoreLoading[AppointmentRevalidation.CANCELLED]}
                />
            ),
        },
    ];

    return (
        <>
            <Grid
                columns={3}
                container
                columnSpacing={0}
                sx={{
                    '--Grid-borderWidth': '1px',
                    borderTop: 'var(--Grid-borderWidth) solid',
                    borderLeft: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    '& > div': {
                        borderRight: 'var(--Grid-borderWidth) solid',
                        borderBottom: 'var(--Grid-borderWidth) solid',
                        borderColor: 'divider',
                    },
                }}
            >
                {columns.map((element, index) => (
                    <Grid key={index} xs={1} style={{ height: '50px', textAlign: 'center', paddingTop: 2 }}>
                        <Stack alignItems="center" justifyContent="center" height="100%">
                            {element.label}
                        </Stack>
                    </Grid>
                ))}
                {columns.map((element, index) => (
                    <Grid
                        xs={1}
                        key={index}
                        style={{ overflowY: 'scroll', height: getScaledVh(85, false) }}
                        className="hideScroll"
                    >
                        <div style={{ marginTop: 15, marginRight: 5, marginLeft: 5 }}>{element.list}</div>
                    </Grid>
                ))}
            </Grid>
            <BetterModal
                title={t('appointmentDetails:label_title')}
                state={modalState}
                minWidth={600}
                body={
                    pickedAppointment ? (
                        <DetailsTabs
                            appointment={pickedAppointment}
                            isMobile={false}
                            reloadAppointments={reloadAppointments}
                        />
                    ) : (
                        <>Loading</>
                    )
                }
            />
        </>
    );
};
