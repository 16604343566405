import React, { ReactNode } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Option as SelectOption, Select, selectClasses } from '@mui/joy';
import { NumericOption, Option } from '../../../utils/formUtils';
import { isString } from 'formik';

type Props = {
    options: Option[] | NumericOption[];
    value: string | number;
    onChange: (value: string | number) => void;
    placeHolder?: string | ReactNode;
    defaultValue?: string | number | undefined;
    width?: string | number;
    style?: React.CSSProperties | undefined;
    placeholderStyle?: React.CSSProperties | undefined;
};
export const BetterSelect = ({
    value,
    options,
    onChange,
    placeHolder,
    width = '100%',
    style = undefined,
    placeholderStyle = undefined,
    defaultValue = undefined,
}: Props) => {
    return (
        <Select
            style={style}
            placeholder={placeHolder}
            defaultValue={defaultValue}
            indicator={<KeyboardArrowDown />}
            sx={{
                [`& .${selectClasses.indicator}`]: {
                    transition: '0.2s',
                    [`&.${selectClasses.expanded}`]: {
                        transform: 'rotate(-180deg)',
                    },
                },
                width: width,
            }}
            value={value.toString()}
            onChange={(event, newVal) => {
                if (newVal === null) return;
                const val = isString(value) ? newVal : +newVal;
                if (val === null) return;
                onChange(val);
            }}
        >
            {options.map((element, index) => (
                <SelectOption sx={placeholderStyle} value={element.value.toString()} key={index}>
                    {element.label}
                </SelectOption>
            ))}
        </Select>
    );
};
