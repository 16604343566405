import { Api } from './Api';
import { SecondOpinionApi } from './SecondOpinionApi';

export const api = new Api();
export const apiSecondOpinion = new SecondOpinionApi();

let apiRootWS: string | undefined;
apiRootWS = process.env.REACT_APP_API_URL || '';
apiRootWS = apiRootWS.replace('http', 'ws').replace(/api\/v\d+/g, 'ws');

export const appointmentsSocket = (token: string, organizationSlug: string): string => {
    return `${apiRootWS}/appointments?token=${token}&brand=${organizationSlug}`;
};
