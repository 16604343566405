import { FlatFieldTranslations } from './Translations';

export interface SlotCriteriaValue {
    id: number;
    name: string;
}

export interface SlotCriteriaValueTranslation extends FlatFieldTranslations<'name', string | null> {
    id: number;
    name: string;
}

export interface SlotCriteria {
    id: number;
    name: string;
    type: SlotCriteriaType;
    multiple_selection: boolean; // Specifies if user is allowed to select multiple values when filtering slots
    required: boolean;
    enable_when: SlotCriteriaCondition | null;
    values: SlotCriteriaValue[];
}

export interface SlotCriteriaSimplified {
    id: number;
    name: string;
}

export interface SlotCriteriaTranslation extends FlatFieldTranslations<'name', string | null> {
    id: number;
    name: string;
    type: SlotCriteriaType;
    multiple_selection: boolean;
    required: boolean;
    enable_when: SlotCriteriaCondition | null;
    values: SlotCriteriaValueTranslation[];
}

export interface SlotCriteriaIds {
    id: string;
    multiple_selection: boolean;
    values: string[];
}

export interface SlotCriteriaCondition {
    id: number;
    criteria: SlotCriteriaSimplified | null;
    criteria_value: SlotCriteriaValue | null;
    //healthcare_service: BaseHealthcareService | null;
    operator: SlotCriteriaOperator;
}

export enum SlotCriteriaOperator {
    EQUALS = 'EQUALS',
}

export enum SlotCriteriaType {
    TEXT = 'TEXT',
    PRACTITIONER = 'PRACTITIONER',
}
