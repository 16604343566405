import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { api } from '../../../api';
import { DateRange } from './Calendar.types';
import { BookingStatus } from '../../Dashboard/StatusBadge/StatusBadge';
import { useErrorContext } from 'context/errorContext';
import { slotQueryKeys } from './slots.query-keys';

export const useSlotsAndAppointments = (dateRange: DateRange | undefined, weekIndex: number) => {
    const queryClient = useQueryClient();
    const { error, setError } = useErrorContext();
    const [hasFetchedOnce, setHasFetchedOnce] = useState(false);

    const reloadSchedules = useCallback(() => {
        queryClient.invalidateQueries([slotQueryKeys.slots(), dateRange, weekIndex]);
    }, [queryClient, dateRange, weekIndex]);

    useEffect(() => {
        setHasFetchedOnce(false);
        reloadSchedules();
    }, [weekIndex, reloadSchedules]);

    const getSlotsAndAppointments = async (dateRange: DateRange) => {
        try {
            const requestAppointments = api.getAppointments({
                dateFrom: dateRange.dateFrom,
                dateTo: dateRange.dateTo,
                statuses: [BookingStatus.upcoming, BookingStatus.past],
            });
            const requestSlots = api.getOrganizationSlots(dateRange.dateFrom, dateRange.dateTo);

            const result = await Promise.all([requestAppointments, requestSlots]);

            if (!hasFetchedOnce) {
                setHasFetchedOnce(true);
            }

            return result;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            }
        }
    };

    const { data, isLoading, isFetching } = useQuery({
        queryFn: async () => getSlotsAndAppointments(dateRange!),
        queryKey: [slotQueryKeys.slots(), dateRange],
        staleTime: 1 * 60 * 1000,
        refetchInterval: 1 * 60 * 1000,
        keepPreviousData: true,
        onSuccess: () => {
            if (!hasFetchedOnce) {
                setHasFetchedOnce(true);
            }
        },
    });

    const { slotsQueryData, appointmentsQueryData } = useMemo(() => {
        const appointmentsQueryData = {
            appointments: data ? data[0].appointments : [],
        };
        const slotsQueryData = {
            slots: data ? data[1] : [],
        };
        return { slotsQueryData, appointmentsQueryData };
    }, [data]);

    return {
        appointments: appointmentsQueryData.appointments,
        slots: slotsQueryData.slots,
        isLoading: hasFetchedOnce ? false : isFetching,
        reloadSchedules,
    };
};
