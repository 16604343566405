import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { apiSecondOpinion } from '../../../api';
import { InvoiceRequestDataWithMetadata, InvoiceStatus, Metadata, QueryInvoiceParamsType } from 'api/models';
import { useErrorContext } from 'context/errorContext';

export const useInvoicesSecondOpinion = (params?: QueryInvoiceParamsType) => {
    const { setError } = useErrorContext();

    const getSecondOpinionInvoices = async () => {
        try {
            const requestInvoices = await apiSecondOpinion.getInvoices(params);
            return requestInvoices;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                setError({ message: error.message });
            } else {
                setError({ message: 'Failed to fetch invoices.' });
            }
            throw error;
        }
    };

    const { data, isLoading, isRefetching, isError, refetch } = useQuery<InvoiceRequestDataWithMetadata, AxiosError>({
        queryFn: getSecondOpinionInvoices,
        queryKey: [invoiceQueryKey.invoice(), params],
        staleTime: 30 * 60 * 1000,
        refetchInterval: 30 * 60 * 1000,
        retry: 3,
        keepPreviousData: true,
        refetchOnWindowFocus: true,
    });

    const results = useMemo(() => data?.results || [], [data]);
    const defaultMetadata: Metadata = useMemo(
        () => ({
            count_by_billing_history_status: {
                [InvoiceStatus.All]: 0,
                [InvoiceStatus.Pending]: 0,
                [InvoiceStatus.Paid]: 0,
                [InvoiceStatus.Cancelled]: 0,
                [InvoiceStatus.Overdue]: 0,
            },
            pagination: {
                page_size: 1,
                current_page: 1,
                total_pages: 1,
                total_count: 0,
                has_previous_page: false,
                has_next_page: false,
                date_from: '',
                date_to: '',
                date_due_from: '',
                date_due_to: '',
                status: InvoiceStatus.Pending,
            },
        }),
        [],
    );

    const today = useMemo(() => {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        return now;
    }, []);

    const pendingInvoices = useMemo(
        () =>
            results.filter((invoice) => {
                if (invoice.status !== InvoiceStatus.Pending) return false;
                const invoiceDate = new Date(invoice.invoiceDueDate);
                invoiceDate.setHours(0, 0, 0, 0);
                return invoiceDate >= today;
            }),
        [results, today],
    );

    const overdueInvoices = useMemo(
        () =>
            results
                .filter((invoice) => {
                    if (invoice.status !== InvoiceStatus.Pending) return false;
                    const invoiceDate = new Date(invoice.invoiceDueDate);
                    invoiceDate.setHours(0, 0, 0, 0);
                    return invoiceDate < today;
                })
                .map((invoice) => ({ ...invoice, status: InvoiceStatus.Overdue }))
                .sort((a, b) => new Date(a.invoiceDueDate).getTime() - new Date(b.invoiceDueDate).getTime()),
        [results, today],
    );

    const unpaidInvoices = useMemo(() => [...pendingInvoices, ...overdueInvoices], [overdueInvoices, pendingInvoices]);

    const paidInvoices = useMemo(() => results.filter((invoice) => invoice.status === InvoiceStatus.Paid), [results]);

    const metadata = useMemo(() => data?.metadata || defaultMetadata, [data?.metadata, defaultMetadata]);

    return { invoices: results, unpaidInvoices, paidInvoices, metadata, isLoading, isError, isRefetching, refetch };
};

export const invoiceQueryKey = {
    invoice: () => 'invoices-so',
};
