import React from 'react';
import { LoginProps, LoginValues } from './LoginForm.types';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Divider, FormLabel, Grid, Input, Stack, Typography } from '@mui/joy';
import { LoginStep } from '../../../api/models';
import { FormControlStyled } from '../../shared/Form/FormControlStyled';
import { ConditionalErrorDecorator } from '../../shared/Form/ConditionalErrorDecorator';
import { ErrorHelperText } from '../../shared/Form/ErrorHelperText';
import { Form } from 'react-bootstrap';
import { usePasswordVisibility } from '../../shared/Form/hooks/usePasswordVisibility';
import { OtpCodeAlert } from '../../shared/Form/OtpCodeAlert';
import { Link } from 'react-router-dom';
import { forgotPasswordRoute } from '../../shared/Routing/routes';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import IconButton from '@mui/joy/IconButton';
import { EditOutlined } from '@mui/icons-material';
import { ReactSVG } from 'react-svg';
import LoginIllustration from 'Assets/LoginIllustration.svg';

export const LoginData = (props: LoginProps & FormikProps<LoginValues>) => {
    const { t } = useTranslation();
    const { isPasswordVisible, eyeIconButton } = usePasswordVisibility();
    const { isLarge, isExtraSmall } = useWindowSize();

    return (
        <Form
            style={{ height: '100%', width: '100%' }}
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Grid container height="100%" width="100%">
                {isLarge && (
                    <Grid
                        md={7}
                        sx={{
                            background: 'var(--joy-palette-background-level2)',
                            height: 'calc(100% + 20px)',
                        }}
                        style={{ marginLeft: -20, marginTop: -20 }}
                    >
                        <ReactSVG
                            style={{
                                marginBottom: 0,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                scale: '2em',
                            }}
                            src={LoginIllustration}
                        />
                    </Grid>
                )}
                <Grid
                    container
                    md={isLarge ? 5 : 12}
                    height="fit-content"
                    alignItems="stretch"
                    width="100%"
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                >
                    <div className="mt-1" style={{ width: '100%', marginLeft: isLarge ? 30 : 0 }}>
                        <Typography level="h1">
                            {t(`login:title_${isExtraSmall ? 'mobile_' : ''}login_page`)}
                        </Typography>
                        <Divider
                            className="w-100 mb-3"
                            sx={{ backgroundColor: 'var(--joy-palette-background-tooltip)' }}
                        />
                        <Stack direction="column" spacing={2} maxWidth="310px">
                            <FormControlStyled
                                isError={!!(props.errors.email && props.touched.email)}
                                isTouched={props.touched.email}
                                readOnly={props.values.step > LoginStep.email}
                            >
                                <FormLabel required>{t('login:email_label')}</FormLabel>
                                <Input
                                    endDecorator={
                                        props.values.step == LoginStep.email ? (
                                            <ConditionalErrorDecorator
                                                isError={!!(props.errors.email && props.touched.email)}
                                                isTouched={props.touched.email}
                                            />
                                        ) : (
                                            <IconButton
                                                disabled={false}
                                                variant="plain"
                                                onClick={() => props.setFieldValue('step', LoginStep.email)}
                                            >
                                                <EditOutlined />
                                            </IconButton>
                                        )
                                    }
                                    autoFocus
                                    autoComplete={'email'}
                                    placeholder={t('login:email_placeholder')}
                                    data-testid="email"
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                    readOnly={props.values.step > LoginStep.email}
                                />
                                <ErrorHelperText
                                    isError={!!(props.errors.email && props.touched.email)}
                                    errorText={props.errors.email}
                                />
                            </FormControlStyled>
                            {props.values.step >= LoginStep.password && (
                                <FormControlStyled
                                    isError={
                                        !!(props.errors.password && props.touched.password) ||
                                        props.values.password_error
                                    }
                                    isTouched={props.touched.password}
                                    readOnly={props.values.step > LoginStep.password}
                                >
                                    <FormLabel required>{t('login:password_label')}</FormLabel>
                                    <Input
                                        endDecorator={
                                            props.values.step === LoginStep.password ? (
                                                <ConditionalErrorDecorator
                                                    isError={
                                                        !!(props.errors.password && props.touched.password) ||
                                                        props.values.password_error
                                                    }
                                                    isTouched={props.touched.password}
                                                />
                                            ) : (
                                                <IconButton
                                                    disabled={false}
                                                    variant="plain"
                                                    onClick={() => props.setFieldValue('step', LoginStep.password)}
                                                >
                                                    <EditOutlined />
                                                </IconButton>
                                            )
                                        }
                                        placeholder={t('login:password_placeholder')}
                                        data-testid="password"
                                        id="password"
                                        name="password"
                                        type={isPasswordVisible ? 'text' : 'password'}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password}
                                        readOnly={props.values.step > LoginStep.password}
                                        startDecorator={eyeIconButton}
                                        autoFocus
                                    />
                                    <ErrorHelperText
                                        isError={!!(props.errors.password && props.touched.password)}
                                        errorText={props.errors.password}
                                    />
                                </FormControlStyled>
                            )}
                            {[LoginStep.otpCode].includes(props.values.step) && (
                                <FormControlStyled
                                    isError={!!(props.errors.token && props.touched.token) || props.values.otp_error}
                                    isTouched={props.touched.token}
                                >
                                    <FormLabel required>{t('login:otp_code_label')}</FormLabel>
                                    <Input
                                        endDecorator={
                                            <ConditionalErrorDecorator
                                                isError={
                                                    !!(props.errors.token && props.touched.token) ||
                                                    props.values.otp_error
                                                }
                                                isTouched={props.touched.token}
                                            />
                                        }
                                        placeholder={t('login:otp_code_placeholder')}
                                        data-testid="token"
                                        id="token"
                                        name="token"
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.token}
                                        readOnly={props.values.step > LoginStep.otpCode}
                                        autoFocus
                                    />
                                    <ErrorHelperText
                                        isError={!!(props.errors.token && props.touched.token)}
                                        errorText={props.errors.token}
                                    />
                                </FormControlStyled>
                            )}
                            <Button type="submit" variant="solid" loading={props.request.isLoading}>
                                {![LoginStep.otpCode].includes(props.values.step)
                                    ? t('buttons:button_next')
                                    : t('login:title_login_page')}
                            </Button>
                            <OtpCodeAlert otpCodeSent={props.otpCodeSent} emailExists={!props.emailNotFound} />
                            {(props.values.password_error || props.values.otp_error) && (
                                <Alert color="danger" variant="soft">
                                    {t(
                                        props.values.otp_error
                                            ? 'login:warning_otp_wrong'
                                            : 'login:warning_password_wrong',
                                    )}
                                </Alert>
                            )}
                            <div className="mt-5">
                                {/*Hide this feature for now*/}
                                {/*<Stack direction="row" spacing={1} useFlexGap flexWrap="wrap">
                                    <Typography color="neutral" level="body-sm">
                                        {t('login:register_message')}
                                    </Typography>
                                    <Link to={registerRoute}>
                                        <Typography color="primary" level="body-sm">
                                            {t('register:register_title')}.
                                        </Typography>
                                    </Link>
                                </Stack>*/}
                                <Stack direction="row" spacing={1}>
                                    <Typography color="neutral" level="body-sm">
                                        {t('login:forgot_password_message')}
                                    </Typography>
                                    <Link
                                        to={forgotPasswordRoute
                                            .replace('%ORGANIZATION%', process.env.REACT_APP_BRAND_SLUG || '')
                                            .replace('%ENV%', process.env.REACT_APP_ENV || '')}
                                        target="_blank"
                                    >
                                        <Typography color="primary" level="body-sm">
                                            {t('login:reset_message')}
                                        </Typography>
                                    </Link>
                                </Stack>
                            </div>
                        </Stack>
                    </div>
                </Grid>
            </Grid>
        </Form>
    );
};
