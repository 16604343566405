import { InvoiceProps, InvoiceValues } from './EditInvoiceItem.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
export const mapPropsToValues = (props: InvoiceProps): InvoiceValues => {
    const invoice = props.invoice;
    return {
        description: invoice?.description || '',
        factor_is_constant: invoice?.factor_is_constant || false,
        max_factor: invoice?.max_factor || 1,
        number: invoice?.number || 1,
        price: invoice?.price || 0,
        has_max_factor: !!(invoice && invoice.max_factor),
        id: invoice?.id || 0,
        vat: invoice?.vat || 0,
    };
};

export const validationSchema = ({ t }: InvoiceProps) =>
    Yup.object().shape({
        number: Yup.number().min(1, t('adminPage:error_invoice_number')),
        price: Yup.number().min(0, t('adminPage:error_invoice_price')),
        max_factor: Yup.number().when('has_max_factor', {
            is: true,
            then: (schema) => schema.min(1, t('adminPage:error_invoice_max_factor')),
        }),
        description: Yup.string().max(1000, t('adminPage:error_invoice_description')),
    });

export const handleSubmit = (values: InvoiceValues, { props }: FormikBag<InvoiceProps, InvoiceValues>) => {
    const res = values;
    res.max_factor = res.has_max_factor ? res.max_factor : undefined;
    props.handleSubmit(res);
};
