import React, { useState } from 'react';
import { RadioButton } from '../../../../../shared/RadioButton/RadioButton';
import { Input, Stack } from '@mui/joy';
import { MobileDatePicker } from '@mui/x-date-pickers';
import {
    EndsOnOption,
    RecurringFieldProps,
    RecurringFieldValues,
} from '../RecuringFieldModal/RecurringFieldModal.types';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../../../shared/hooks/useWindowSize';

type Props = {
    props: RecurringFieldProps & FormikProps<RecurringFieldValues>;
};
export const EndSelector = ({ props }: Props) => {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const [r, refresh] = useState(false);

    const onPickedOption = (value: number) => {
        let option: undefined | EndsOnOption = Object.values(EndsOnOption)[value];

        if (option === props.values.endsOn) {
            option = undefined;
        }

        props.setFieldValue('until', option === EndsOnOption.onDay ? new Date() : undefined);

        props.setFieldValue('endsOn', option);
        refresh(!r);
    };

    const isPicked = (index: number) => {
        const option = Object.values(EndsOnOption)[index];
        return props.values.endsOn === option;
    };

    const occurrenceText = () => {
        if (!isPicked(2)) return '';

        return props.values.count === undefined || props.values.count === 1
            ? t('editSlotModal:customRepetition.label_occurrence')
            : t('editSlotModal:customRepetition.label_occurrences');
    };

    return (
        <Stack spacing={1} direction="column">
            <RadioButton
                size="sm"
                style={{ width: '49%' }}
                value={0}
                onClick={onPickedOption}
                selected={isPicked(0)}
                defaultSelected={isPicked(0)}
                label={t('editSlotModal:customRepetition.endOptions.button_never')}
            />
            <Stack direction="row" spacing={1} style={{ width: '100%' }} alignItems="stretch">
                <RadioButton
                    size="sm"
                    style={{ width: '49%' }}
                    value={1}
                    selected={isPicked(1)}
                    onClick={onPickedOption}
                    defaultSelected={isPicked(1)}
                    label={t('editSlotModal:customRepetition.endOptions.button_on_date')}
                />
                <MobileDatePicker
                    sx={{
                        width: '49%',
                        '& .MuiInputBase-root': {
                            height: '100%',
                            borderRadius: '8px',
                            boxShadow: 'var(--joy-shadow-md)',
                        },
                    }}
                    format="dd/MM/yyyy"
                    formatDensity={isExtraSmall ? 'dense' : 'spacious'}
                    defaultValue={new Date(props.values.until || '')}
                    orientation={isExtraSmall ? 'portrait' : 'landscape'}
                    minDate={new Date()}
                    value={props.values.until || new Date()}
                    onChange={(e) => {
                        if (e === null) return;
                        props.setFieldValue('until', e);
                    }}
                    disabled={!isPicked(1)}
                />
            </Stack>
            <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                <RadioButton
                    size="sm"
                    style={{ width: '49%' }}
                    value={2}
                    selected={isPicked(2)}
                    onClick={onPickedOption}
                    defaultSelected={isPicked(2)}
                    label={t('editSlotModal:customRepetition.endOptions.button_after')}
                />
                <Input
                    style={{ width: '49%' }}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.count || ''}
                    id="count"
                    name="count"
                    type="number"
                    disabled={!isPicked(2)}
                    endDecorator={occurrenceText()}
                />
            </Stack>
        </Stack>
    );
};
