export interface ErrorSortingParams {
    order_by: ErrorOrderByOptions;
    descending: boolean;
}

export enum ErrorOrderByOptions {
    errorDescription,
    errorComment,
    errorTimestamp,
    doctorName,
    patientName,
    invoiceNr,
    invoiceDate,
    invoiceDateDue,
    totalPrice,
}
