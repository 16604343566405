import React from 'react';
import { Form } from 'react-bootstrap';
import { InvoiceProps, InvoiceValues } from './EditInvoiceItem.types';
import { FormikProps } from 'formik';
import { Button, Checkbox, FormLabel, Input, Stack, Textarea, TextField } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FormControlStyled } from '../../shared/Form/FormControlStyled';
import { ErrorHelperText } from '../../shared/Form/ErrorHelperText';
import { FormErrorMessage } from '../../shared/FormErrorMessage/FormErrorMessage';

export const EditInvoiceItemData = (props: InvoiceProps & FormikProps<InvoiceValues>) => {
    const { t } = useTranslation();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
            style={{ overflowX: 'hidden' }}
        >
            <Stack spacing={2}>
                <Stack spacing={1} width="100%">
                    <Stack direction="row" spacing={1} maxWidth="100%">
                        <FormControlStyled
                            style={{ maxWidth: '48%' }}
                            isError={!!(props.errors.number && props.touched.number)}
                            isTouched={props.touched.number || false}
                        >
                            <FormLabel required>{t('adminPage:table_label_number')}</FormLabel>
                            <Input
                                onBlur={props.handleBlur}
                                type="number"
                                value={props.values.number}
                                id="number"
                                name="number"
                                onChange={props.handleChange}
                            />
                            <ErrorHelperText
                                isError={!!(props.errors.number && props.touched.number)}
                                errorText={props.errors.number}
                            />
                        </FormControlStyled>
                        <FormControlStyled
                            style={{ maxWidth: '48%' }}
                            isError={!!(props.errors.price && props.touched.price)}
                            isTouched={props.touched.price || false}
                        >
                            <FormLabel required>{t('adminPage:table_label_amount')}</FormLabel>
                            <Input
                                onBlur={props.handleBlur}
                                type="number"
                                value={props.values.price}
                                id="price"
                                name="price"
                                onChange={props.handleChange}
                            />
                            <ErrorHelperText
                                isError={!!(props.errors.price && props.touched.price)}
                                errorText={props.errors.price}
                            />
                        </FormControlStyled>
                    </Stack>
                    <FormControlStyled
                        isError={!!(props.errors.description && props.touched.description)}
                        isTouched={props.touched.description || false}
                    >
                        <FormLabel>{t('adminPage:table_label_description')}</FormLabel>
                        <Textarea
                            minRows={3}
                            value={props.values.description}
                            id="description"
                            name="description"
                            onChange={props.handleChange}
                        />
                    </FormControlStyled>
                    <Stack direction="row" spacing={1} width="100%">
                        <FormControlStyled isError={false} isTouched={false} style={{ width: '50%', height: '63px' }}>
                            <FormLabel>{t('adminPage:table_label_has_max_factor')}</FormLabel>
                            <Checkbox
                                id="has_max_factor"
                                name="has_max_factor"
                                checked={props.values.has_max_factor}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </FormControlStyled>
                        {props.values.has_max_factor && (
                            <>
                                <FormControlStyled
                                    isError={!!(props.errors.max_factor && props.touched.max_factor)}
                                    isTouched={props.touched.max_factor || false}
                                    style={{ width: '50%' }}
                                >
                                    <FormLabel>{t('adminPage:table_label_max_factor')}</FormLabel>
                                    <Input
                                        onBlur={props.handleBlur}
                                        type="number"
                                        value={props.values.max_factor || ''}
                                        id="max_factor"
                                        name="max_factor"
                                        onChange={props.handleChange}
                                    />
                                </FormControlStyled>
                            </>
                        )}
                    </Stack>
                    <FormControlStyled
                        isError={!!(props.errors.factor_is_constant && props.touched.factor_is_constant)}
                        isTouched={props.touched.factor_is_constant || false}
                    >
                        <FormLabel>{t('adminPage:table_label_constant')}</FormLabel>
                        <Checkbox
                            id="factor_is_constant"
                            name="factor_is_constant"
                            checked={props.values.factor_is_constant}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </FormControlStyled>
                </Stack>
                <FormErrorMessage onlyTouched={false} />
                <Button type="submit" fullWidth loading={props.request.isLoading}>
                    {t('buttons:button_submit')}
                </Button>
            </Stack>
        </Form>
    );
};
