import React from 'react';
import { CheckCircleOutlined, ErrorOutlined } from '@mui/icons-material';

type Props = {
    isError: boolean;
    isTouched: boolean | undefined;
};
export const ConditionalErrorDecorator = ({ isError, isTouched }: Props) => {
    if (!!(isError && isTouched)) return <ErrorOutlined sx={{ color: 'var(--joy-palette-danger-500)' }} />;

    return (isTouched ?? false) && !isError ? (
        <CheckCircleOutlined sx={{ color: 'var(--joy-palette-success-500)' }} />
    ) : null;
};
