import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles/CssVarsProvider';
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles/CssVarsProvider';
import * as React from 'react';
import { ReactNode } from 'react';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeIcon from '@mui/icons-material/LightMode';

type ReturnValues = {
    setTheme: (theme: 'dark' | 'light') => void;
    toggleTheme: () => void;
    theme: 'dark' | 'light' | 'system' | undefined;
    icon: ReactNode;
};
export const useSharedColorScheme = (): ReturnValues => {
    const { mode, setMode: setMaterialMode, setColorScheme: setMaterialColorScheme } = useMaterialColorScheme();
    const { setColorScheme: setJoyColorScheme, setMode: setJoyMode } = useJoyColorScheme();
    const setTheme = (theme: 'dark' | 'light') => {
        setMaterialMode(theme);
        setMaterialColorScheme(theme);
        setJoyMode(theme);
        setJoyColorScheme(theme);
        //window.location.reload();
    };

    const toggleTheme = () => {
        setMaterialMode(mode === 'dark' ? 'light' : 'dark');
        setMaterialColorScheme(mode === 'dark' ? 'light' : 'dark');
        setJoyMode(mode === 'dark' ? 'light' : 'dark');
        setJoyColorScheme(mode === 'dark' ? 'light' : 'dark');
        //window.location.reload();
    };

    return { setTheme, toggleTheme, theme: mode, icon: mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeIcon /> };
};
