import React, { useEffect } from 'react';
import { Box } from '@mui/joy';
import Alert from '@mui/joy/Alert';
import { ErrorOutline } from '@mui/icons-material';
import { useErrorContext } from 'context/errorContext';

export const ErrorAlertDialog = () => {
    const { error, setError } = useErrorContext();

    const handleClose = () => {
        setError(null);
    };

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                handleClose();
            }, 3500);

            return () => clearTimeout(timer);
        }
    }, [error]);

    if (!error) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                zIndex: 1400,
                textAlign: 'center',
            }}
        >
            <Alert
                variant="solid"
                color="danger"
                sx={{ borderRadius: 0, height: '64px', zIndex: 1400 }}
                startDecorator={<ErrorOutline />}
            >
                {error.message}
            </Alert>
        </Box>
    );
};
