import useMediaQuery from '@mui/material/useMediaQuery';

export const useWindowSize = (
    customSize: { min: number; max: number } | number = 0,
): { isExtraSmall: boolean; isMedium: boolean; isLarge: boolean; isCustomSize: boolean } => {
    const isExtraSmall = useMediaQuery('(max-width:599px)');
    const isMedium = useMediaQuery('(min-width:600px) and (max-width:1199px)');
    const isLarge = useMediaQuery('(min-width:1200px)');
    const isCustomSize = useMediaQuery(
        typeof customSize === 'number'
            ? `(min-width:${customSize}px)`
            : `(min-width:${customSize.min}px) and (max-width:${customSize.max}px)`,
    );

    return {
        isExtraSmall,
        isMedium,
        isLarge,
        isCustomSize,
    };
};
