import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Stack, Typography } from '@mui/joy';
import { useFilePicker } from 'use-file-picker';
import { AddOutlined, FileCopyOutlined } from '@mui/icons-material';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { useModalState } from '../../shared/BetterModal/useModalState';
import { apiSecondOpinion } from '../../../api';
import useApiRequest from '../../../hooks/useApiRequest';

export const AddBankCsv = () => {
    const { t } = useTranslation();
    const {
        openFilePicker,
        filesContent,
        loading: fileUploadLoading,
    } = useFilePicker({
        accept: '.csv',
        multiple: false,
    });
    const modalState = useModalState();
    const request = useApiRequest();

    const handleSubmit = () => {
        console.log(filesContent[0].content);
        request.dispatch(apiSecondOpinion.uploadBankData(filesContent[0].content)).finally(modalState.toggleModal);
    };

    return (
        <div>
            <BetterModal
                minWidth={400}
                state={modalState}
                title={t('adminPage:modal_add_bank_csv')}
                button={
                    <Button startDecorator={<AddOutlined />} variant="outlined" color="neutral">
                        {t('buttons:button_add_bank_csv')}
                    </Button>
                }
                body={
                    <Stack spacing={1}>
                        {filesContent.map((file, index) => (
                            <Typography key={index} startDecorator={<FileCopyOutlined />} level="h3">
                                {file.name}
                            </Typography>
                        ))}
                        <ButtonGroup spacing={1} variant="solid" color="primary">
                            <Button
                                disabled={request.isLoading}
                                loading={fileUploadLoading}
                                fullWidth
                                onClick={() => openFilePicker()}
                            >
                                {t('buttons:button_select_file')}
                            </Button>
                            {filesContent.length > 0 && (
                                <Button loading={request.isLoading} fullWidth onClick={handleSubmit}>
                                    {t('buttons:button_submit')}
                                </Button>
                            )}
                        </ButtonGroup>
                    </Stack>
                }
            />
        </div>
    );
};
