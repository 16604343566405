import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { CustomProps } from './CustomProps';

export const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatAdapter(
    props,
    ref,
) {
    const { onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            valueIsNumericString
        />
    );
});
