import React from 'react';
import {
    AccountBoxOutlined,
    AdminPanelSettingsOutlined,
    CalendarTodayOutlined,
    CrisisAlert,
    DashboardOutlined,
    ListAltOutlined,
    LoginOutlined,
    Receipt,
    ReceiptOutlined,
} from '@mui/icons-material';
import {
    accountDetailsRoute,
    adminPanelRoute,
    appointmentListRoute,
    dashboardRoute,
    errorManagementRoute,
    errorRoute,
    invitationRegisterRoute,
    invoiceRoute,
    loginRoute,
    slotListRoute,
    videoCallRoute,
} from './routes';
import { TFunction } from 'i18next';
import { Dashboard } from '../../Dashboard/Dashboard';
import { AppointmentPage } from '../../Dashboard/AppointmentPage';
import { SlotsPage } from '../../SlotsPage/SlotsPage';
import { LoginPage } from '../../Auth/LoginFlow/LoginPage';
import { AccountDetails } from '../../AccountDetails/AccountDetails';
import { AdminPanel } from '../../AdminPanel/AdminPanel';
import { UserRole } from '../../../api/models';
import { RouteInfo } from './routes.types';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { InvoicePage } from 'components/Invoice/InvoicePage';
import VideoCall from '../../VideoCall/VideoCall';
import { InvitationRegisterPage } from '../../Auth/InvitationRegisterFlow/InvitationRegisterPage';
import { ErrorManagement } from '../../ErrorManagement/ErrorManagement';

export const getRoles = (excludedRoles: UserRole[] = []): UserRole[] => {
    const allRoles = Object.values(UserRole);
    return allRoles.filter((r) => !excludedRoles.includes(r));
};

export const routeObjects = (t: TFunction): RouteInfo[] => [
    dashboardRouteInfo(t),
    profileRouteInfo(t),
    adminPanelRouteInfo(t),
    errorManagementRouteInfo(t),
    loginRouteInfo(t),
    errorRouteInfo,
    defaultErrorRouteInfo,
    appointmentListRouteInfo(t),
    slotsRouteInfo(t),
    invoiceRouteInfo(t),
    videoCallRouteInfo(t),
    invitationRegisterRouteInfo(t),
];

export const invitationRegisterRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <DashboardOutlined />,
        name: t('invitationRegister:label_title'),
        route: invitationRegisterRoute,
        component: <InvitationRegisterPage />,
        secureMode: 'guest-only',
        menuDisplay: 'show',
    };
};

export const errorManagementRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <CrisisAlert />,
        name: t('errorManagement:title'),
        route: errorManagementRoute,
        component: <ErrorManagement />,
        secureMode: [UserRole.admin, UserRole.organization],
        menuDisplay: 'hide',
    };
};

export const dashboardRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <DashboardOutlined />,
        name: t('sidebar:dashboard'),
        route: dashboardRoute,
        component: <Dashboard />,
        secureMode: getRoles(),
        menuDisplay: 'show',
    };
};

export const appointmentListRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <ListAltOutlined />,
        name: t('sidebar:appointments'),
        route: appointmentListRoute,
        component: <AppointmentPage />,
        secureMode: getRoles(),
        menuDisplay: 'show',
    };
};

export const slotsRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <CalendarTodayOutlined />,
        name: t('sidebar:slots'),
        route: slotListRoute,
        component: <SlotsPage />,
        secureMode: getRoles(),
        menuDisplay: 'show',
    };
};

export const videoCallRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: null,
        name: t('sidebar:videoCallRoute'),
        route: videoCallRoute,
        component: <VideoCall />,
        secureMode: getRoles(),
        menuDisplay: 'hide',
    };
};

export const invoiceRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <ReceiptOutlined />,
        name: t('sidebar:invoices'),
        route: invoiceRoute,
        component: <InvoicePage />,
        secureMode: getRoles(),
        menuDisplay: 'show',
    };
};

export const profileRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <AccountBoxOutlined />,
        name: t('profile:profile_title'),
        route: accountDetailsRoute,
        component: <AccountDetails />,
        secureMode: getRoles(),
        menuDisplay: 'hide',
    };
};

export const adminPanelRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <AdminPanelSettingsOutlined />,
        name: t('adminPage:title'),
        route: adminPanelRoute,
        component: <AdminPanel />,
        secureMode: [UserRole.admin, UserRole.organization],
        menuDisplay: 'hide',
    };
};

export const loginRouteInfo = (t: TFunction): RouteInfo => {
    return {
        icon: <LoginOutlined />,
        name: t('login:title_login_page'),
        route: loginRoute,
        component: <LoginPage />,
        secureMode: 'guest-only',
        menuDisplay: 'hide',
    };
};
export const errorRouteInfo: RouteInfo = {
    icon: null,
    name: '',
    route: errorRoute,
    component: <ErrorPage />,
    secureMode: 'public',
    menuDisplay: 'hide',
    omitMainContent: true,
};
export const defaultErrorRouteInfo: RouteInfo = {
    icon: null,
    name: '',
    route: errorRoute,
    component: <ErrorPage />,
    secureMode: 'public',
    menuDisplay: 'hide',
    omitMainContent: true,
    siblingRoute: errorRouteInfo,
};

export const defaultRouteInfo = (t: TFunction, isLoggedIn: boolean): RouteInfo => {
    return {
        icon: <DashboardOutlined />,
        name: t('sidebar:dashboard'),
        route: '/',
        component: isLoggedIn ? <Dashboard /> : <LoginPage />,
        secureMode: isLoggedIn ? getRoles() : 'guest-only',
        menuDisplay: 'hide',
        siblingRoute: isLoggedIn ? dashboardRouteInfo(t) : loginRouteInfo(t),
    };
};
