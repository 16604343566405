import { Button, FormLabel, Grid, Input, Stack, Textarea, Typography, ButtonGroup, Divider } from '@mui/joy';
import { InvoiceReminderRequestData, InvoiceRequestData } from 'api/models';

import { t } from 'i18next';
import React, { Fragment, useState } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers';

import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { PostalCodeAdapter } from 'components/shared/adapters/PostalCodeAdapter';

import { BankMaskAdapter } from 'components/shared/adapters/BankMaskAdapter';
import { NumericFormatAdapter } from 'components/shared/adapters/NumericFormatAdapter';
import { PhoneAdapter } from 'components/shared/adapters/PhoneAdapter';
import { VatMaskAdapter } from 'components/shared/adapters/VatMaskAdapter';
import { BetterModal, ModalState } from 'components/shared/BetterModal/BetterModal';
import { generatePdfDocument } from '../../Dashboard/AppointmentDetailsSection/BillingTab/InvoiceElement/InvoiceElement';
import useInvoices from 'hooks/useInvoices';
import { useErrorContext } from 'context/errorContext';
import { useQueryClient } from 'react-query';
import { invoiceQueryKey } from '../hooks/invoice.query-keys';

type InvoicePreviewProps = {
    invoice: InvoiceRequestData;
    modalState: ModalState;
    toggleButton?: React.ReactNode;
};

export const InvoicePreview = ({ invoice, modalState, toggleButton }: InvoicePreviewProps) => {
    const queryClient = useQueryClient();
    const { isExtraSmall } = useWindowSize();
    const { sendReminderInvoice } = useInvoices();
    const { setError } = useErrorContext();

    const patient = invoice?.patient || {};
    const doctor = invoice?.doctor || {};

    const [invoiceReminderData, setInvoiceReminderData] = useState<InvoiceReminderRequestData>({
        idBill: 0,
        file: '',
        isReminder: true,
    });

    const invoiceDate = new Date(invoice?.invoiceDate || Date.now());
    const invoiceDueDate = new Date(invoice?.invoiceDueDate || Date.now());
    const tableHeaders: { label: string; xs: number }[] = [
        { label: t('invoice:invoice_name'), xs: 3 },
        { label: t('invoice:table.factor'), xs: 1 },
        { label: t('invoice:table.price'), xs: 1 },
        { label: t('invoice:table.gross'), xs: 1 },
    ];
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    const getColumnCount = () => {
        let c = 0;
        tableHeaders.forEach((e) => (c += e.xs));
        return c;
    };

    const getTotal = (gross: boolean) => {
        if (invoice.items === undefined) return 0;

        let total = 0;
        for (const item of invoice.items) {
            const price = item.price_paid * item.factor;
            if (!gross && item.vat !== undefined) {
                total += price * (1 - item.vat / 100);
            } else {
                total += price;
            }
        }
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total);
    };

    const handlePdfGeneration = async () => {
        await generatePdfDocument(invoice, setIsLoadingPdf);
    };

    const handleSendInvoice = async ({ isReminder }: { isReminder: boolean }) => {
        const generatedPdfDocument = await generatePdfDocument(invoice, setIsLoadingPdf, true);
        if (typeof generatedPdfDocument === 'string') {
            const updatedInvoiceReminderData = {
                ...invoiceReminderData,
                file: generatedPdfDocument,
                idBill: invoice.idBill,
                isReminder: isReminder,
            };

            setInvoiceReminderData(updatedInvoiceReminderData);
            await sendReminderInvoice(updatedInvoiceReminderData);
            modalState.toggleModal();
            queryClient.invalidateQueries(invoiceQueryKey.invoice());
        } else {
            setError({ message: 'Failed to generate PDF document.' });
        }
    };

    return (
        <BetterModal
            title={t('billing:invoice')}
            state={modalState}
            button={toggleButton}
            actions={
                <Stack spacing={1} style={{ width: '100%' }}>
                    <ButtonGroup style={{ maxWidth: '100%' }} variant="solid" color="primary" spacing={1}>
                        <Button type="button" onClick={() => handleSendInvoice({ isReminder: false })} fullWidth>
                            {t('buttons:button_send')}
                        </Button>
                        <Button type="button" onClick={() => handleSendInvoice({ isReminder: true })} fullWidth>
                            {t('buttons:button_send_reminder')}
                        </Button>
                    </ButtonGroup>

                    <Button type="button" onClick={handlePdfGeneration} fullWidth>
                        {t('buttons:button__download_pdf')}
                    </Button>
                </Stack>
            }
            minWidth={650}
            maxWidth={2000}
            layout={isExtraSmall ? 'fullscreen' : 'center'}
            body={
                <Stack
                    direction="column"
                    sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                    spacing={2}
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        height: isExtraSmall ? 'calc(100% - 40px)' : '100%',
                    }}
                >
                    <Grid spacing={{ xs: 0, md: 1 }} maxWidth="100%" container rowSpacing={1}>
                        <Grid xs={12} md={6}>
                            <div>
                                <FormLabel>{t('invoice:invoice_no')}</FormLabel>
                                <Input
                                    id="invoiceNr"
                                    name="invoiceNr"
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    disabled
                                    value={invoice?.invoiceNr}
                                />
                            </div>
                        </Grid>
                        <Grid container xs={12} md={6} width="100%" spacing={1} sx={{ flexGrow: 1 }}>
                            <Grid maxWidth="50%">
                                <FormLabel>{t('invoice:invoice_date')}</FormLabel>
                                <Input
                                    id="invoiceNr"
                                    name="invoiceNr"
                                    sx={{ color: 'var(--joy-palette-text-primary) !important', wordSpacing: 2 }}
                                    disabled
                                    value={invoiceDate.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: '2-digit',
                                    })}
                                />
                            </Grid>
                            <Grid maxWidth="50%">
                                <FormLabel>{t('invoice:invoice_due_date')}</FormLabel>
                                <Input
                                    id="invoiceNr"
                                    name="invoiceNr"
                                    sx={{ color: 'var(--joy-palette-text-primary) !important', wordSpacing: 2 }}
                                    disabled
                                    value={invoiceDueDate.toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: '2-digit',
                                    })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography level="h3">{t('invoice:invoice_patient_data')}</Typography>
                    <Grid container maxWidth="100%" spacing={1}>
                        <Grid xs={12} md={6} container width="100%" sx={{ flexGrow: 1 }}>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_title')}</FormLabel>
                                <Input
                                    id="patientPersonalTitle"
                                    name="patientPersonalTitle"
                                    readOnly
                                    disabled
                                    value={patient.personal_title || ''}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_name')}</FormLabel>
                                <Input
                                    id="patientName"
                                    name="patientName"
                                    readOnly
                                    disabled
                                    value={patient.name}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_surname')}</FormLabel>
                                <Input
                                    id="patientName"
                                    name="patientName"
                                    readOnly
                                    disabled
                                    value={patient.surname}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} xs={12} md={6} width="100%" sx={{ flexGrow: 1 }}>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_address')}</FormLabel>
                                <Input
                                    id="patientAddress2"
                                    name="patientAddress2"
                                    readOnly
                                    disabled
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={patient.street}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_town')}</FormLabel>
                                <Input
                                    id="patientAddress"
                                    name="patientAddress"
                                    readOnly
                                    disabled
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={patient.city}
                                />
                            </Grid>
                            <Grid width="60%">
                                <FormLabel>{t('invoice:invoice_postal_code')}</FormLabel>
                                <Input
                                    id="patientPostalCode"
                                    name="patientPostalCode"
                                    readOnly
                                    disabled
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={patient.post_code}
                                    slotProps={{
                                        input: {
                                            component: PostalCodeAdapter,
                                            maxLength: 5,
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography level="h3">{t('invoice:invoice_doctor_data')}</Typography>
                    <Grid container maxWidth="100%" spacing={1}>
                        <Grid xs={12} md={6} container width="100%" sx={{ flexGrow: 1 }}>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_title')}</FormLabel>
                                <Input
                                    id="doctorPersonalTitle"
                                    name="doctorPersonalTitle"
                                    readOnly
                                    disabled
                                    value={doctor.personal_title || ''}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_name')}</FormLabel>
                                <Input
                                    id="doctorName"
                                    name="doctorName"
                                    readOnly
                                    disabled
                                    value={doctor.name}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_surname')}</FormLabel>
                                <Input
                                    id="doctorSurname"
                                    name="doctorSurname"
                                    readOnly
                                    disabled
                                    value={doctor.surname}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_email')}</FormLabel>
                                <Input
                                    id="doctorEmail"
                                    name="doctorEmail"
                                    type="email"
                                    inputMode="email"
                                    readOnly
                                    disabled
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.email}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_tel')}</FormLabel>
                                <Input
                                    id="doctorTel"
                                    name="doctorTel"
                                    readOnly
                                    disabled
                                    slotProps={{
                                        input: {
                                            component: PhoneAdapter,
                                            maxLength: 18,
                                        },
                                    }}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.phone_number}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_fax')}</FormLabel>
                                <Input
                                    id="doctorFax"
                                    name="doctorFax"
                                    readOnly
                                    disabled
                                    slotProps={{
                                        input: {
                                            component: NumericFormatAdapter,
                                            maxLength: 12,
                                        },
                                    }}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.fax_number}
                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6} container spacing={1} direction="column" justifyContent="flex-start">
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_address')}</FormLabel>
                                <Input
                                    id="doctorAddress2"
                                    name="doctorAddress2"
                                    readOnly
                                    disabled
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.street}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_town')}</FormLabel>
                                <Input
                                    id="doctorAddress"
                                    name="doctorAddress"
                                    readOnly
                                    disabled
                                    value={doctor.city}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>
                            <Grid width="60%">
                                <FormLabel>{t('invoice:invoice_postal_code')}</FormLabel>
                                <Input
                                    id="doctorPostalCode"
                                    name="doctorPostalCode"
                                    readOnly
                                    disabled
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.post_code}
                                    slotProps={{
                                        input: {
                                            component: PostalCodeAdapter,
                                            maxLength: 5,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_bank_account')}</FormLabel>
                                <Input
                                    id="doctorBankNr"
                                    name="doctorBankNr"
                                    readOnly
                                    disabled
                                    slotProps={{
                                        input: {
                                            component: BankMaskAdapter,
                                        },
                                    }}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.bank_number}
                                />
                            </Grid>
                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_bank_name')}</FormLabel>
                                <Input
                                    id="bank_name"
                                    name="bank_name"
                                    readOnly
                                    disabled
                                    value={invoice.bank_name}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                />
                            </Grid>

                            <Grid width="100%">
                                <FormLabel>{t('invoice:invoice_vat_id')}</FormLabel>
                                <Input
                                    id="doctorVatID"
                                    name="doctorVatID"
                                    readOnly
                                    disabled
                                    slotProps={{
                                        input: {
                                            component: VatMaskAdapter,
                                        },
                                    }}
                                    sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                    value={doctor.vat_id}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Stack justifyContent="center" alignItems="stretch" style={{ width: '100%' }} spacing={1}>
                        <FormLabel sx={{ mb: 2 }}>
                            <Typography level="h3">{t('invoice:invoice_diagnosis')}</Typography>
                        </FormLabel>
                        <Textarea
                            id="diagnosis"
                            name="diagnosis"
                            readOnly
                            disabled
                            minRows={3}
                            value={invoice?.diagnosis}
                            style={{ width: '100%' }}
                            sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                        />
                    </Stack>
                    <Stack spacing={1} sx={{ paddingBottom: 2 }}>
                        <Grid
                            columns={getColumnCount()}
                            container
                            columnSpacing={0}
                            sx={{
                                '--Grid-borderWidth': '1px',
                                borderTop: 'var(--Grid-borderWidth) solid',
                                borderLeft: 'var(--Grid-borderWidth) solid',
                                borderColor: 'divider',
                                '& > div': {
                                    borderRight: 'var(--Grid-borderWidth) solid',
                                    borderBottom: 'var(--Grid-borderWidth) solid',
                                    borderColor: 'divider',
                                },
                            }}
                        >
                            {tableHeaders.map((element, index) => (
                                <Grid
                                    key={index}
                                    xs={element.xs}
                                    style={{ height: '50px', textAlign: 'center', paddingTop: 2 }}
                                >
                                    <Stack alignItems="center" justifyContent="center" height="100%">
                                        <Typography
                                            sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                            level="body-xs"
                                        >
                                            {element.label}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            ))}
                            {invoice.items.map((element, index) => (
                                <Fragment key={index}>
                                    <Grid xs={tableHeaders[0].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                                        <Stack alignItems="center" justifyContent="center" height="100%">
                                            <Typography
                                                sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                                level="body-xs"
                                            >
                                                {element.description}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={tableHeaders[1].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                                        <Stack alignItems="center" justifyContent="center" height="100%">
                                            <Typography
                                                sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                                level="body-xs"
                                            >
                                                {element.factor}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid xs={tableHeaders[2].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                                        <Stack marginX="1px" alignItems="center" justifyContent="center" height="100%">
                                            <Typography
                                                level="body-xs"
                                                sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                            >
                                                {new Intl.NumberFormat('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                }).format(element.price_paid)}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid
                                        xs={tableHeaders[2].xs}
                                        style={{ fontSize: '90%', textAlign: 'center', paddingTop: 2 }}
                                    >
                                        <Stack alignItems="center" justifyContent="center" height="100%" marginX="1px">
                                            <Typography
                                                level="body-xs"
                                                sx={{ color: 'var(--joy-palette-text-primary) !important' }}
                                            >
                                                {new Intl.NumberFormat('de-DE', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                }).format(element.price_paid * element.factor)}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Fragment>
                            ))}
                        </Grid>
                        <Stack justifyContent="right" alignItems="flex-end" spacing={1}>
                            <Stack spacing={1} direction="row">
                                <Typography sx={{ color: 'var(--joy-palette-text-primary) !important' }}>
                                    {t('invoice:table.total_net')}:
                                </Typography>
                                <Typography sx={{ color: 'var(--joy-palette-text-primary) !important' }}>
                                    {getTotal(false)}
                                </Typography>
                            </Stack>
                            <Stack spacing={1} direction="row">
                                <Typography sx={{ color: 'var(--joy-palette-text-primary) !important' }}>
                                    {t('invoice:table.total_gross')}:
                                </Typography>
                                <Typography sx={{ color: 'var(--joy-palette-text-primary) !important' }}>
                                    {getTotal(true)}
                                </Typography>
                            </Stack>
                            <Divider />
                        </Stack>
                    </Stack>
                </Stack>
            }
        />
    );
};
