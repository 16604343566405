import React, { ReactNode, useEffect, useState } from 'react';
import { useModalState } from '../../../shared/BetterModal/useModalState';
import { BetterModal } from '../../../shared/BetterModal/BetterModal';
import { ScheduleType, SlotUiMode } from './EditSlotForm.types';
import { useTranslation } from 'react-i18next';
import { AppointmentModel, Slot, SlotEditScope } from '../../../../api/models';
import useApiRequest from '../../../../hooks/useApiRequest';
import { useSchedule } from './hooks/useSchedule';
import { useEditSlotFieldOptions } from '../../hooks/useEditSlotFieldOptions';
import { compareAsc } from 'date-fns';
import EditSlotForm from './EditSlotForm';
import { useLoading } from '../../../shared/LoadingPage/useLoading';
import { useQueryClient } from 'react-query';
import { SlotData } from '../../../../api/models/forms/SlotData';
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal';

type Props = {
    button: ReactNode;
    uiMode: SlotUiMode;
    isMobile: boolean;
    slot?: Slot | AppointmentModel | undefined;
    scheduleType: ScheduleType;
};
export const SchedulePopover = ({ button, uiMode, isMobile, scheduleType, slot = undefined }: Props) => {
    const state = useModalState();
    const { t } = useTranslation();
    const [isReady, setIsReady] = useState(false);
    const request = useApiRequest();
    const {
        activeSlot,
        setDetails,
        clearDetails,
        activeSchedule,
        editSlot,
        addSlot,
        deleteSlot,
        hourRange,
        reloadSchedules,
    } = useSchedule(slot, scheduleType);
    const { secondOpinionReason, fetchSecondOpinionReason } = useEditSlotFieldOptions();
    const queryClient = useQueryClient();
    const [submitValues, setSubmitValues] = useState<
        { values: SlotData; slotKey: string; scope: SlotEditScope } | undefined
    >(undefined);
    const confirmModalState = useModalState();
    const deleteModalState = useModalState();

    const isLoading =
        (activeSchedule === undefined ||
            (activeSlot === undefined && uiMode === SlotUiMode.Edit) ||
            !isReady ||
            secondOpinionReason === undefined) &&
        state.isOpen;
    const {} = useLoading(isLoading);

    const title = () => {
        if (uiMode === SlotUiMode.Edit) return t('editSlotModal:title_edit');

        return t('editSlotModal:title_add');
    };

    const closePopoverAndReloadAppointments = () => {
        state.toggleModal();
        reloadSchedules();
    };

    useEffect(() => {
        if (!state.isOpen) {
            clearDetails();
            return;
        }

        if (activeSlot !== undefined) return;

        setDetails();
        fetchSecondOpinionReason();
        setTimeout(() => setIsReady(true), 500);
    }, [state.isOpen, activeSlot, activeSchedule]);

    const handleSubmit = (values: SlotData, slotKey: string, scope: SlotEditScope) => {
        setSubmitValues({
            scope: scope,
            values: values,
            slotKey: slotKey,
        });
        confirmModalState.toggleModal();
    };

    const onSubmitConfirm = async (): Promise<void> => {
        state.toggleModal();
        if (!submitValues) return Promise.reject();

        const action = uiMode === SlotUiMode.Edit ? editSlot : addSlot;
        action(submitValues.values, submitValues.slotKey, submitValues.scope).finally(() => {
            queryClient.invalidateQueries('slots');
        });
    };

    const onDeleteConfirm = async (): Promise<void> => {
        state.toggleModal();
        return deleteSlot();
    };

    if (scheduleType === ScheduleType.slot) {
        return isLoading ? (
            <>{button}</>
        ) : (
            <>
                <ConfirmModal
                    modalDescription={
                        uiMode === SlotUiMode.Edit
                            ? t('editSlotModal:message_edit_slot')
                            : t('editSlotModal:message_add_slot')
                    }
                    successAlertDescription={
                        uiMode === SlotUiMode.Edit
                            ? t('editSlotModal:message_edit_slot_success')
                            : t('editSlotModal:message_add_slot_success')
                    }
                    onConfirm={onSubmitConfirm}
                    modalState={confirmModalState}
                />
                <ConfirmModal
                    modalDescription={t('editSlotModal:message_delete_slot')}
                    successAlertDescription={t('editSlotModal:message_delete_slot_success')}
                    onConfirm={onDeleteConfirm}
                    modalState={deleteModalState}
                />
                <BetterModal
                    state={state}
                    disabled={slot && compareAsc(new Date(slot.date_from), new Date()) !== 1}
                    title={title()}
                    body={
                        activeSchedule ? (
                            <EditSlotForm
                                isMobile={isMobile}
                                activeSlot={activeSlot}
                                mode={uiMode}
                                toggleMainModal={state.toggleModal}
                                handleSubmit={handleSubmit}
                                request={request}
                                activeSchedule={activeSchedule}
                                deleteSlot={deleteModalState.toggleModal}
                                hourRange={hourRange}
                                closeSlotFormAndReloadAppointments={closePopoverAndReloadAppointments}
                                secondOpinionReason={secondOpinionReason?.value || ''}
                            />
                        ) : (
                            <></>
                        )
                    }
                    button={button}
                    layout={isMobile ? 'fullscreen' : 'center'}
                />
            </>
        );
    }

    if (scheduleType === ScheduleType.appointment) {
        return <div>appointment modal</div>;
    }

    return null;
};
