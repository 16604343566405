import { useContext, useEffect, useId } from 'react';
import { LoadingContext } from './LoadingContext';
import { ErrorType } from 'context/errorContext';

export const useLoading = (trackedLoadingValue: boolean | undefined = undefined, error?: ErrorType) => {
    const id = useId();
    const loadingContext = useContext(LoadingContext);

    useEffect(() => {
        if (error) {
            setIsLoading(false);
            return;
        }
        if (trackedLoadingValue === undefined) return;
        setIsLoading(trackedLoadingValue);
    }, [trackedLoadingValue]);

    const setIsLoading = (isLoading: boolean) => {
        loadingContext?.setIsLoading(isLoading, id);
    };

    return { isLoading: loadingContext?.isLoading || false, setIsLoading };
};
