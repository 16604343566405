import useApiRequest from './useApiRequest';
import { apiSecondOpinion } from '../api';
import { InvoiceReminderRequestData, InvoiceRequestData } from '../api/models';
import { useLoading } from 'components/shared/LoadingPage/useLoading';
import { useErrorContext } from 'context/errorContext';
import { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { invoiceQueryKey } from 'components/Invoice/hooks/invoice.query-keys';

const useInvoices = (idAppointment?: number) => {
    const request = useApiRequest();
    const { setError } = useErrorContext();
    const queryClient = useQueryClient();

    const getInvoiceAppointments = async (): Promise<InvoiceRequestData[]> => {
        try {
            if (idAppointment) {
                const response = await apiSecondOpinion.getInvoiceForAppointment(idAppointment);
                return response;
            } else {
                const response = await apiSecondOpinion.getInvoices();
                return response.results;
            }
        } catch (error) {
            throw error;
        }
    };

    const {
        data: invoices = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<InvoiceRequestData[], AxiosError>({
        queryKey: [invoiceQueryKey.invoiceCount(), idAppointment],
        queryFn: getInvoiceAppointments,
        onError: () => setError({ message: "Failed to fetch appointment's invoices." }),
        initialData: [],
    });

    useLoading(isLoading);

    const mutationOptions = {
        onSuccess: () => {
            queryClient.invalidateQueries([invoiceQueryKey.invoiceCount(), invoiceQueryKey.invoice()]);
            queryClient.invalidateQueries();
            refetch();
        },
    };

    const markCanceled = useMutation(
        async (id: number) => await request.dispatch(apiSecondOpinion.markInvoiceAsCancelled(id)),
        mutationOptions,
    );

    const markPaid = useMutation(
        async (id: number) => await request.dispatch(apiSecondOpinion.markInvoiceAsPaid(id)),
        mutationOptions,
    );

    const addInvoice = useMutation(
        async (invoice: InvoiceRequestData) => await request.dispatch(apiSecondOpinion.addInvoice(invoice)),
        mutationOptions,
    );

    const sendReminderInvoice = useMutation(
        async (reminderInvoiceData: InvoiceReminderRequestData) =>
            await request.dispatch(apiSecondOpinion.sentInvoiceReminder(reminderInvoiceData)),
        mutationOptions,
    );

    return {
        invoices,
        addInvoice: addInvoice.mutate,
        markCanceled: markCanceled.mutate,
        markPaid: markPaid.mutate,
        sendReminderInvoice: sendReminderInvoice.mutate,
        isLoading,
        isError,
    };
};

export default useInvoices;
