import React, { useMemo } from 'react';
import { InvoiceRequestData, InvoiceStatus } from '../../../api/models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { StyledBadge } from './InvoiceStatusBadge.styled';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

interface InvoiceStatusBadgeProps {
    invoice: InvoiceRequestData;
}
function InvoiceStatusBadge(props: InvoiceStatusBadgeProps) {
    const { t } = useTranslation();

    const state = useMemo(() => {
        const status = props.invoice.status;
        const dateFrom = new Date(props.invoice.invoiceDueDate);

        if (status == InvoiceStatus.Pending && moment(new Date()).isAfter(dateFrom)) {
            return InvoiceStatus.Overdue;
        }

        return status;
    }, [props.invoice]);

    let text = '';

    switch (state) {
        case InvoiceStatus.Cancelled:
            text = t('billing:Canceled');
            break;
        case InvoiceStatus.Overdue:
            text = t('billing:Overdue');
            break;
        case InvoiceStatus.Pending:
            text = t('billing:Pending');
            break;
        case InvoiceStatus.Paid:
            text = t('billing:Paid');
            break;
    }
    return (
        <StyledBadge type={state}>
            {text}{' '}
            {props.invoice.emailSent ? <MarkEmailReadIcon style={{ fontSize: '1rem' }}></MarkEmailReadIcon> : null}
        </StyledBadge>
    );
}

export default InvoiceStatusBadge;
