import React, { Fragment } from 'react';
import { Grid, Stack, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { DeleteOutlined } from '@mui/icons-material';
import { InvoiceMobileProps, InvoiceMobileValues } from '../InvoiceMobileForm.types';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

type Props = {
    tableHeaders: { label: string; xs: number }[];
    baseProps: InvoiceMobileProps & FormikProps<InvoiceMobileValues>;
};
export const InvoiceItemDesktopDisplay = ({ tableHeaders, baseProps }: Props) => {
    const { t } = useTranslation();
    const getColumnCount = () => {
        let c = 0;
        tableHeaders.forEach((e) => (c += e.xs));
        return c;
    };

    return (
        <Grid
            columns={getColumnCount()}
            container
            columnSpacing={0}
            sx={{
                '--Grid-borderWidth': '1px',
                borderTop: 'var(--Grid-borderWidth) solid',
                borderLeft: 'var(--Grid-borderWidth) solid',
                borderColor: 'divider',
                '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                },
            }}
        >
            {tableHeaders.map((element, index) => (
                <Grid key={index} xs={element.xs} style={{ height: '50px', textAlign: 'center', paddingTop: 2 }}>
                    <Stack alignItems="center" justifyContent="center" height="100%">
                        <Typography level="body-xs">{element.label}</Typography>
                    </Stack>
                </Grid>
            ))}
            {baseProps.values.items?.map((element, index) => (
                <Fragment key={index}>
                    <Grid xs={tableHeaders[0].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                        <Stack alignItems="center" justifyContent="center" height="100%">
                            <Typography level="body-xs">{element.name}</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={tableHeaders[1].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                        <Stack alignItems="center" justifyContent="center" height="100%">
                            <Typography level="body-xs">{element.factor}</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={tableHeaders[2].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                        <Stack marginX="1px" alignItems="center" justifyContent="center" height="100%">
                            <Typography level="body-xs">
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    element.price,
                                )}
                            </Typography>
                        </Stack>
                    </Grid>
                    {/*<Grid xs={tableHeaders[3].xs} style={{ textAlign: 'center', paddingTop: 2 }}>
                            <Stack alignItems="center" justifyContent="center" height="100%">
                                {element.vat}%
                            </Stack>
                        </Grid>
                        <Grid xs={tableHeaders[4].xs} style={{ fontSize: '90%', textAlign: 'center', paddingTop: 2 }}>
                            <Stack
                                id={`net_dynamic_cell${index}`}
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                                marginX="1px"
                            >
                                {net.getText(
                                    new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                        element.price * element.factor * (1 - element.vat / 100),
                                    ),
                                    `net_dynamic${index}`,
                                    `net_dynamic_cell${index}`,
                                )}
                            </Stack>
                        </Grid>*/}
                    <Grid xs={tableHeaders[2].xs} style={{ fontSize: '90%', textAlign: 'center', paddingTop: 2 }}>
                        <Stack alignItems="center" justifyContent="center" height="100%" marginX="1px">
                            <Typography level="body-xs">
                                {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(
                                    element.price * element.factor,
                                )}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={tableHeaders[3].xs} style={{ minHeight: '50px' }}>
                        <Stack alignItems="center" justifyContent="center" height="100%">
                            <IconButton
                                variant="outlined"
                                color="danger"
                                onClick={() => {
                                    const curr = baseProps.values.items;
                                    curr?.splice(index, 1);
                                    baseProps.setFieldValue('items', curr);
                                }}
                            >
                                <DeleteOutlined />
                            </IconButton>
                        </Stack>
                    </Grid>
                </Fragment>
            ))}
            {baseProps.values.items?.length === 0 && (
                <Grid xs={getColumnCount()} style={{ height: '50px', textAlign: 'center', paddingTop: 2 }}>
                    <Stack alignItems="center" justifyContent="center" height="100%">
                        {t('invoice:table.empty_message')}
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};
