import { FormikProps } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { RecurringFieldProps, RecurringFieldValues } from './RecurringFieldModal.types';
import { Button, FormLabel, Input, Option, Select, selectClasses, Stack } from '@mui/joy';
import { FormControlStyled } from '../../../../../shared/Form/FormControlStyled';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Frequency } from 'api/models';
import { DaySelector } from '../DaySelector/DaySelector';
import { useIntervalOptions } from '../hooks/useIntervalOptions';
import { useWindowSize } from '../../../../../shared/hooks/useWindowSize';
import { EndSelector } from '../EndSelector/EndSelector';
import { FormErrorMessage } from '../../../../../shared/FormErrorMessage/FormErrorMessage';

export const RecurringFieldModalData = (props: RecurringFieldProps & FormikProps<RecurringFieldValues>) => {
    const { t } = useTranslation();
    const intervalOptions = useIntervalOptions(props.values.interval > 1);
    const { isExtraSmall } = useWindowSize();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.handleSubmit();
            }}
            style={{ height: '100%', position: 'relative', width: '100%' }}
        >
            <Stack
                direction="column"
                spacing={2}
                sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: isExtraSmall ? 'calc(100% - 40px)' : '100%',
                    marginBottom: isExtraSmall ? '40px' : '40px',
                }}
            >
                <Stack spacing={1}>
                    <FormLabel>{props.t('editSlotModal:customRepetition.label_repeat_every')}</FormLabel>
                    <Stack direction="row" spacing={1} style={{ width: '100%' }}>
                        <FormControlStyled
                            isError={!!(props.errors.interval && props.touched.interval)}
                            isTouched={props.touched.interval}
                            style={{ width: '30%' }}
                        >
                            <Input
                                style={{ width: '100%' }}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.interval}
                                id="interval"
                                name="interval"
                                type="number"
                            />
                        </FormControlStyled>
                        <FormControlStyled
                            isError={!!(props.errors.interval && props.touched.interval)}
                            isTouched={props.touched.interval}
                            style={{ width: '70%' }}
                        >
                            <Select
                                indicator={<KeyboardArrowDown />}
                                sx={{
                                    [`& .${selectClasses.indicator}`]: {
                                        transition: '0.2s',
                                        [`&.${selectClasses.expanded}`]: {
                                            transform: 'rotate(-180deg)',
                                        },
                                    },
                                }}
                                onChange={(e, value) => {
                                    if (value === null) return;
                                    props.setFieldValue('freq', Object.values(Frequency)[value]);
                                }}
                                style={{ width: '100%' }}
                                defaultValue={1}
                            >
                                {intervalOptions.map((element, index) => (
                                    <Option value={index} key={index}>
                                        {element.label}
                                    </Option>
                                ))}
                            </Select>
                        </FormControlStyled>
                    </Stack>
                </Stack>
                {props.values.freq === Frequency.WEEKLY && (
                    <Stack spacing={1}>
                        <FormLabel>{t('editSlotModal:customRepetition.label_repeat_on')}</FormLabel>
                        <DaySelector props={props} />
                    </Stack>
                )}
                <Stack spacing={1}>
                    <FormLabel>{t('editSlotModal:customRepetition.label_ends_on')}</FormLabel>
                    <EndSelector props={props} />
                </Stack>
                <FormErrorMessage onlyTouched={false} />
            </Stack>
            <div style={{ position: 'fixed', width: '95%', bottom: 10, left: '50%', transform: 'translate(-50%, 0)' }}>
                <Button fullWidth type="submit">
                    {t('buttons:button_save')}
                </Button>
            </div>
        </Form>
    );
};
