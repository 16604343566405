import React from 'react';
import useAppointmentDataForVideoCall from '../../hooks/useAppointmentDataForVideoCall';
import { useVideoCall } from '../../hooks/useVideoCall';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { LoadingPage } from '../shared/LoadingPage/LoadingPage';
import { getScaledVh } from '../../utils/styleUtils';
import { useWindowSize } from '../shared/hooks/useWindowSize';

export default function VideoCall() {
    const { videoCall } = useAppointmentDataForVideoCall();
    const { jitsiConfig, jitsiInterfaceConfig, videoCallStatus, setJitsiApi } = useVideoCall();
    const { isExtraSmall } = useWindowSize();

    return (
        <div>
            {videoCallStatus === 'jitsiLoading' && <LoadingPage />}
            {videoCall && (
                <JitsiMeeting
                    domain={videoCall.host}
                    roomName={videoCall.room}
                    jwt={videoCall.jwt}
                    configOverwrite={jitsiConfig}
                    interfaceConfigOverwrite={jitsiInterfaceConfig}
                    onApiReady={setJitsiApi}
                    getIFrameRef={(iframeRef) => {
                        iframeRef.style.height = getScaledVh(96, isExtraSmall);
                    }}
                />
            )}
        </div>
    );
}
