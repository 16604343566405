import { AppointmentModel, Slot, SlotEditScope } from '../../../../../api/models';
import { ScheduleType } from '../EditSlotForm.types';
import { api } from '../../../../../api';
import { SlotData } from '../../../../../api/models/forms/SlotData';
import { useQueryClient } from 'react-query';
import { HourRange } from 'components/SlotsPage/hooks/Calendar.types';
import { addHours, addMinutes } from 'date-fns';
import { useEffect, useState } from 'react';
import { useErrorContext } from 'context/errorContext';

export const useSchedule = (slot: undefined | Slot | AppointmentModel, type: ScheduleType) => {
    const queryClient = useQueryClient();
    const hourRange: HourRange = { min: 6, max: 21 };
    const [activeAppointment, setActiveAppointment] = useState<AppointmentModel | undefined>(undefined);
    const [activeSlot, setActiveSlot] = useState<Slot | undefined>(undefined);
    const [activeSchedule, setActiveSchedule] = useState<ActiveSchedule | undefined>(undefined);
    const { setError } = useErrorContext();

    const clearDetails = () => {
        setActiveSlot(undefined);
        setActiveAppointment(undefined);
    };

    const setDetails = () => {
        if (activeSchedule === undefined) {
            setActiveSlot(undefined);
            setActiveAppointment(undefined);
            return;
        }

        if (activeSchedule.type === ScheduleType.slot && activeSchedule.id) {
            api.getOrganizationSlot(activeSchedule.id).then(setActiveSlot);
            return;
        }

        const intId = parseInt(activeSchedule.id);
        if (activeSchedule.type === ScheduleType.appointment && isNaN(intId))
            api.getAppointment(intId).then(setActiveAppointment);
    };

    const getDateFrom = (duration: number): Date => {
        let newDate = new Date();
        newDate = addHours(newDate, 1);
        const modulo = newDate.getMinutes() % duration;
        const minDiff = modulo > duration / 2 ? duration - modulo : modulo;
        return addMinutes(newDate, minDiff);
    };
    useEffect(() => {
        if (activeSchedule !== undefined) return;

        const duration = 30;
        const dateFrom = slot !== undefined ? new Date(slot.date_from) : getDateFrom(duration);
        setActiveSchedule({
            id: slot ? ('id' in slot ? slot.id.toString() : slot.key) : '',
            dateFrom: dateFrom,
            dateTo: new Date(dateFrom.getMinutes() + duration),
            duration: duration,
            type: type,
        });
    }, [slot]);

    const deleteSlot = async (selectedScope: SlotEditScope = SlotEditScope.SINGLE) => {
        if (activeSchedule === undefined) return;

        try {
            await api.deleteSlot(activeSchedule.id, selectedScope);
            await reloadSchedules();
        } catch (error) {
            setError({ message: (error as string).toString() });
        }
    };

    const addSlot = async (values: SlotData) => {
        try {
            await api.createSlot(values);
            await reloadSchedules();
        } catch (error) {
            setError({ message: (error as string).toString() });
        }
    };

    const editSlot = async (values: SlotData, slotKey: string, scope: SlotEditScope) => {
        try {
            await api.updateSlot(values, slotKey, scope);
            await reloadSchedules();
        } catch (error) {
            setError({ message: (error as string).toString() });
        }
    };

    const reloadSchedules = async () => {
        queryClient.invalidateQueries('slots');
    };

    return {
        activeSchedule,
        activeSlot,
        activeAppointment,
        setDetails,
        clearDetails,
        editSlot,
        addSlot,
        deleteSlot,
        hourRange,
        reloadSchedules,
    };
};

export interface ActiveSchedule {
    id: string;
    dateFrom: Date;
    dateTo: Date;
    duration: number;
    type: ScheduleType;
}
