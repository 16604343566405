import React, { Fragment } from 'react';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { LoginOutlined } from '@mui/icons-material';
import { loginRoute } from '../../shared/Routing/routes';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { OutlinedIconButton } from '../styled/OutlinedIcon.styled';

export const LoginButton = () => {
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();

    return (
        <Fragment>
            {isExtraSmall ? (
                <OutlinedIconButton onClick={() => window.location.replace(loginRoute)}>
                    <LoginOutlined />
                </OutlinedIconButton>
            ) : (
                <Button startDecorator={<LoginOutlined />} onClick={() => window.location.replace(loginRoute)}>
                    {t('buttons:button_login')}
                </Button>
            )}
        </Fragment>
    );
};
