import { withFormik } from 'formik';
import { SlotProps, SlotValues } from './EditSlotForm.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './EditSlotForm.schema';
import { EditSlotData } from './EditSlotData';
import { withTranslation } from 'react-i18next';

const EditSlotForm = withFormik<SlotProps, SlotValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(EditSlotData);
export default withTranslation()(EditSlotForm);
