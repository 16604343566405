import React, { ReactNode } from 'react';
import { BetterModal } from '../../../shared/BetterModal/BetterModal';
import { useModalState } from '../../../shared/BetterModal/useModalState';
import { AppointmentModel } from '../../../../api/models';
import { Button, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useAppointmentsActions } from '../../../../hooks/useAppointmentsActions';
import { dateToLocaleString } from '../../../../utils/stringUtils';
import { getFullName } from '../../../../api/utils';
import { appointmentListRoute, videoCallRoute } from '../../../shared/Routing/routes';

type Props = {
    appointment: AppointmentModel | undefined;
    reloadSchedules: () => void;
    button: ReactNode;
};
export const AppointmentActionsModal = ({ button, appointment, reloadSchedules }: Props) => {
    const appointmentModalState = useModalState();
    const appointmentDateFrom = moment(appointment?.date_from);
    const appointmentDateTo = moment(appointment?.date_to);
    const { t } = useTranslation();
    const {
        attachQuestionnaireModal,
        cancelAppointmentModal,
        rescheduleAppointmentModal,
        attachQuestionnaireModalState,
        cancelAppointmentModalState,
        rescheduleAppointmentModalState,
    } = useAppointmentsActions(reloadSchedules, appointment, appointmentModalState);

    const body: ReactNode = (
        <Stack spacing={2}>
            <Stack spacing={1} direction="row">
                <Typography level="title-md">
                    <b>{getFullName(appointment?.patient)}</b>
                </Typography>
                ,
                <Typography level="body-md">
                    {dateToLocaleString(appointment?.patient_metadata.date_of_birth)}
                </Typography>
            </Stack>
            <Stack spacing={1}>
                <span>{dateToLocaleString(appointment?.date_from)}</span>
                <span>
                    {appointmentDateFrom.format('HH:mm')} -{appointmentDateTo.format('HH:mm')}
                </span>
                <span>{appointmentDateFrom.format('dddd')}</span>
            </Stack>
        </Stack>
    );

    return (
        <BetterModal
            minWidth={400}
            state={appointmentModalState}
            title={<Typography fontSize={23}>{t('editAppointmentSlotModal:title')}</Typography>}
            button={button}
            onClose={() => {
                reloadSchedules();
                appointmentModalState.toggleModal();
            }}
            body={body}
            actions={
                <Stack spacing={1} width="100%">
                    {appointment && new Date(appointment?.date_from) > new Date() && (
                        <Stack spacing={1} direction="row" width="100%">
                            <div style={{ width: '100%' }}>
                                {cancelAppointmentModal(
                                    <Button fullWidth color="danger" onClick={cancelAppointmentModalState.toggleModal}>
                                        {t('buttons:button_cancel_appointment')}
                                    </Button>,
                                )}
                            </div>
                            <div style={{ width: '100%' }}>
                                {
                                    <Button fullWidth onClick={appointmentModalState.toggleModal}>
                                        {t('buttons:button_cancel')}
                                    </Button>
                                }
                            </div>
                        </Stack>
                    )}
                    {appointment && new Date(appointment?.date_from) < new Date() && (
                        <Link to={{ pathname: appointmentListRoute, search: '?appId=' + appointment.id }}>
                            <Button fullWidth>{t('buttons:button_go_to_appointment')}</Button>
                        </Link>
                    )}
                    {appointment?.video_call_available && (
                        <Link to={{ pathname: videoCallRoute, search: '?appId=' + appointment.id }}>
                            <Button fullWidth>{t('videoCall:navigateToVideoCall')}</Button>
                        </Link>
                    )}
                </Stack>
            }
        />
    );
};
