import React, { useEffect, useState } from 'react';
import { Button, Input, Stack, Typography } from '@mui/joy';
import { AppointmentsTabs } from './AppointmentListSection/AppointmentsTabs';
import { AppointmentModel, SortTypes } from '../../api/models';
import { AppointmentRevalidation, useAppointments } from '../../hooks/useAppointments';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../shared/hooks/useWindowSize';
import { appointmentListRouteInfo } from '../shared/Routing/routeObjects';
import { AppointmentsTable } from './AppointmentListSection/AppointmentsTable';
import { FilterList, Search } from '@mui/icons-material';
import { useLoading } from 'components/shared/LoadingPage/useLoading';
import useInvoices from 'hooks/useInvoices';
import { getDateXDaysAgo, Option } from '../../utils/formUtils';
import { MyAppointmentsData } from 'hooks/constants';
import { useQueryClient } from 'react-query';
import { FilterDrawer, FilterGroup, FilterTypes } from '../shared/FilterDrawer/FilterDrawer';

export const AppointmentPage = () => {
    const { t } = useTranslation();
    const queryClinet = useQueryClient();
    const { invoices } = useInvoices();
    const { isExtraSmall, isLarge, isCustomSize: displayAsTable } = useWindowSize(900);
    const [acceptedSearchQuery, setAcceptedSearchQuery] = useState<string>('');
    const [appliedDateFilters, applyDateFilters] = useState<Date | undefined>(undefined);
    const [appliedSortTypeFilters, applySortTypeFilters] = useState<SortTypes | undefined>(undefined);
    const {
        reloadAppointments,
        pastAppointmentsQueryData,
        cancelledAppointmentsQueryData,
        upcomingWithOngoingAppointmentsQuery,
        loadMoreQuery,
        isLoading,
        isLoadMoreLoading,
    } = useAppointments(acceptedSearchQuery, appliedDateFilters, appliedSortTypeFilters);

    const [pickedAppointment, pickAppointment] = useState<AppointmentModel | undefined>(undefined);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [isFilterDrawerOpen, toggleFilterDrawer] = useState(false);

    const appointmentsRouteObject = appointmentListRouteInfo(t);

    const handleLoadMorePastAppointments = () => {
        loadMoreQuery(AppointmentRevalidation.PAST);
    };

    const handleLoadMoreUpcomingAppointments = () => {
        loadMoreQuery(AppointmentRevalidation.UPCOMING);
    };

    const handleLoadMoreCancelledAppointments = () => {
        loadMoreQuery(AppointmentRevalidation.CANCELLED);
    };

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key !== 'Enter') return;
        setAcceptedSearchQuery(searchQuery);
    };

    const dateFilterKey = 'date';
    const sortTypeKey = 'dateDescAsc';

    const sortTypesOptions: Option[] = [
        { value: SortTypes.dateAsc, label: t('appointmentList:label_sort_date_asc') },
        { value: SortTypes.dateDesc, label: t('appointmentList:label_sort_date_desc') },
    ];

    const dateFilters: Option[] = [
        { value: getDateXDaysAgo(7), label: t('errorManagement:filters.date_7_days_ago') },
        { value: getDateXDaysAgo(30), label: t('errorManagement:filters.date_30_days_ago') },
        { value: getDateXDaysAgo(60), label: t('errorManagement:filters.date_60_days_ago') },
        { value: getDateXDaysAgo(90), label: t('errorManagement:filters.date_90_days_ago') },
        { value: getDateXDaysAgo(180), label: t('errorManagement:filters.date_6_months_ago') },
    ];

    const appointmentsFilterGroups: FilterGroup[] = [
        {
            key: sortTypeKey,
            many_options: false,
            default_values: [],
            title: t('appointmentList:label_sort_type'),
            options: sortTypesOptions,
        },
        {
            title: t('errorManagement:filters.label_date_filters'),
            options: dateFilters,
            key: dateFilterKey,
            many_options: false,
            default_values: [],
        },
    ];

    const applyFilters = (filteredTypes: FilterTypes[]) => {
        const sortTypeStr = filteredTypes.find((f) => f.key === sortTypeKey)?.values![0] as string | undefined;
        const date = filteredTypes.find((f) => f.key === dateFilterKey)?.values![0] as string | undefined;

        const sortType = Object.values(SortTypes).find((s) => s == sortTypeStr);

        applySortTypeFilters(sortType);
        applyDateFilters(date ? new Date(date) : undefined);

        reloadAppointments();
    };

    useEffect(() => {
        queryClinet.invalidateQueries('invoices-so');
    }, [queryClinet]);

    const filterAppointments = (appointmentsData: MyAppointmentsData, query: string): MyAppointmentsData => {
        if (!query) return appointmentsData;

        const filteredAppointments = appointmentsData.appointments.filter((appointment) => {
            return Object.values(appointment).some((value) => {
                if (value === null || value === undefined) {
                    return false;
                } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
                    return value.toString().toLowerCase().includes(query.toLowerCase());
                } else if (typeof value === 'object') {
                    return JSON.stringify(value).toLowerCase().includes(query.toLowerCase());
                }
                return false;
            });
        });

        return { ...appointmentsData, appointments: filteredAppointments };
    };

    const filteredPastAppointments = filterAppointments(pastAppointmentsQueryData, acceptedSearchQuery);
    const filteredCancelledAppointments = filterAppointments(cancelledAppointmentsQueryData, acceptedSearchQuery);
    const filteredUpcomingAppointments = filterAppointments(upcomingWithOngoingAppointmentsQuery, acceptedSearchQuery);

    useLoading(isLoading);

    return (
        <Stack
            display="flex"
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            sx={{ width: 'calc(100% + 40px)' }}
            spacing={1}
            style={{ marginTop: 0, marginLeft: -20, marginRight: -20 }}
        >
            <div id="appointment-list-header">
                <Stack
                    spacing={4}
                    direction={isExtraSmall ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        paddingX: !isLarge ? '5px' : '20px',
                    }}
                >
                    {!isExtraSmall && (
                        <>
                            <Typography level="h1" startDecorator={appointmentsRouteObject.icon}>
                                {appointmentsRouteObject.name}
                            </Typography>
                        </>
                    )}
                    <Stack direction="row" width="100%" alignItems="center" justifyContent="stretch" spacing={1}>
                        <Input
                            startDecorator={<Search />}
                            placeholder={t('appointmentList:placeholder_search')}
                            style={{ width: '100%', height: 'fit-content' }}
                            fullWidth
                            value={searchQuery}
                            onKeyPress={handleEnterKeyPress}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<FilterList />}
                            onClick={() => toggleFilterDrawer(true)}
                        >
                            {t('buttons:button_filter')}
                        </Button>
                    </Stack>
                </Stack>
            </div>
            {displayAsTable ? (
                <AppointmentsTable
                    key="appointments-table"
                    pastAppointments={filteredPastAppointments}
                    loadMorePastAppointments={handleLoadMorePastAppointments}
                    cancelledAppointments={filteredCancelledAppointments}
                    loadMoreCancelledAppointments={handleLoadMoreCancelledAppointments}
                    upcomingWithOngoingAppointments={filteredUpcomingAppointments}
                    reloadAppointments={reloadAppointments}
                    loadMoreUpcomingAppointments={handleLoadMoreUpcomingAppointments}
                    invoices={invoices}
                    isLoadMoreLoading={isLoadMoreLoading}
                />
            ) : (
                <AppointmentsTabs
                    key="appointments-tabs"
                    pickAppointment={pickAppointment}
                    pickedAppointment={pickedAppointment}
                    pastAppointments={filteredPastAppointments}
                    loadMorePastAppointments={handleLoadMorePastAppointments}
                    cancelledAppointments={filteredCancelledAppointments}
                    loadMoreCancelledAppointments={handleLoadMoreCancelledAppointments}
                    upcomingWithOngoingAppointments={filteredUpcomingAppointments}
                    reloadAppointments={reloadAppointments}
                    loadMoreUpcomingAppointments={handleLoadMoreUpcomingAppointments}
                    headerHeight={document.getElementById('appointment-list-header')?.clientHeight || 0}
                    invoices={invoices}
                    isLoadMoreLoading={isLoadMoreLoading}
                />
            )}
            <FilterDrawer
                applyFilters={applyFilters}
                toggleDrawer={toggleFilterDrawer}
                isOpen={isFilterDrawerOpen}
                filterGroups={appointmentsFilterGroups}
            />
        </Stack>
    );
};
