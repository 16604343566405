import React, { useEffect, useState } from 'react';
import { Radio, radioClasses, Sheet } from '@mui/joy';
import { boolean } from 'yup';

type Props = {
    label: string;
    value: number;
    style?: React.CSSProperties | undefined;
    defaultColor?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
    selectedColor?: 'primary' | 'neutral' | 'danger' | 'success' | 'warning';
    selectable?: boolean;
    defaultSelected?: boolean | ((value: number) => boolean);
    defaultVariant?: 'plain' | 'outlined' | 'soft' | 'solid';
    selectedVariant?: 'plain' | 'outlined' | 'soft' | 'solid';
    onClick?: ((value: number, selected: boolean) => void) | undefined;
};
export const CircleRadioButton = ({
    label,
    value,
    defaultVariant = 'outlined',
    selectedVariant = 'solid',
    defaultColor = 'primary',
    selectedColor = 'primary',
    defaultSelected = false,
    selectable = false,
    onClick = undefined,
    style = undefined,
}: Props) => {
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (!selectable) return;

        if (typeof defaultSelected == 'boolean') setSelected(defaultSelected);
        else setSelected(defaultSelected(value));
    }, [defaultSelected]);

    const isSelected = () => {
        return selected && selectable;
    };

    const handleClick = () => {
        if (onClick) onClick(value, !selected && selectable);
        setSelected(!selected);
    };

    return (
        <Sheet
            style={style}
            key={value}
            sx={{
                position: 'relative',
                width: style?.width || 40,
                height: style?.width || 40,
                flexShrink: 0,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '--joy-focus-outlineOffset': '4px',
                '--joy-palette-focusVisible': (theme) => theme.vars.palette.neutral.outlinedBorder,
                [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                    outlineWidth: '2px',
                },
            }}
        >
            <Radio
                variant={isSelected() ? selectedVariant : defaultVariant}
                color={isSelected() ? selectedColor : defaultColor}
                overlay
                disableIcon
                value={value}
                label={label}
                onClick={handleClick}
                style={{ zIndex: 0 }}
            />
        </Sheet>
    );
};
