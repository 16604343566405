import React from 'react';
import { Sheet, Stack, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { MenuOutlined } from '@mui/icons-material';
import { ReactSVG } from 'react-svg';
import SecondOpinionLogoWithName from '../../../Assets/SecondOpinionLogoWithName.svg';
import SecondOpinionLogoWithNameDarkTheme from '../../../Assets/SecondOpinionLogoWithNameDarkTheme.svg';
import { useTranslation } from 'react-i18next';
import { UserProfile } from '../../../api/models';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { routeObjects } from '../../shared/Routing/routeObjects';
import { TopBarOptions } from './TopBarOptions';
import { useNavigate } from 'react-router-dom';
import { useSharedColorScheme } from 'components/shared/ColorScheme/useSharedColorScheme';

type Props = {
    openDrawer: () => void;
    authUser: UserProfile | undefined;
    isLoggedIn: boolean;
};
export const TopBar = ({ openDrawer, authUser, isLoggedIn }: Props) => {
    const navigate = useNavigate();
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();
    const { theme } = useSharedColorScheme();

    const getRouteName = () => {
        const allRoutes = routeObjects(t);
        const currentLoc = window.location.pathname;
        for (const r of allRoutes) {
            if (!currentLoc.includes(r.route)) continue;
            return r?.siblingRoute?.name || r.name;
        }
        return '';
    };

    const handleNavigate = () => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    };

    return (
        <>
            {isExtraSmall && (
                <Typography
                    level="title-lg"
                    style={{
                        width: '50%',
                        height: '53px',
                        position: 'fixed',
                        top: 0,
                        right: '50%',
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1,
                        color: 'var(--joy-palette-text-secondary)',
                    }}
                >
                    {getRouteName()}
                </Typography>
            )}
            <Sheet
                variant="plain"
                style={{
                    width: '100%',
                    height: isExtraSmall ? '50px' : '70px',
                    position: 'fixed',
                    top: 0,
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 1,
                    background: 'var(--joy-palette-background-level1)',
                }}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Stack
                    sx={{ ml: 1, mr: 1, width: '100%' }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {!isExtraSmall && (
                            <IconButton variant="plain" onClick={openDrawer}>
                                <MenuOutlined />
                            </IconButton>
                        )}
                        <div
                            style={{ width: isExtraSmall ? '100px' : '50%', paddingLeft: isExtraSmall ? '0px' : '8px' }}
                        >
                            <ReactSVG
                                src={theme === 'light' ? SecondOpinionLogoWithName : SecondOpinionLogoWithNameDarkTheme}
                                onClick={handleNavigate}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    </Stack>
                    <TopBarOptions isLoggedIn={isLoggedIn} user={authUser} />
                </Stack>
            </Sheet>
        </>
    );
};
