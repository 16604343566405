import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../Auth/AuthContext/AuthContext';
import { TopBar } from './TopBar/TopBar';
import { ContentDrawer } from './ContentDrawer';
import { useWindowSize } from '../shared/hooks/useWindowSize';
import { BottomBar } from './BottomBar';
export const MainContent = () => {
    const [opened, toggleDrawer] = useState(false);
    const authContext = useAuthContext();
    const { isExtraSmall } = useWindowSize();

    return (
        <div style={{ height: '100%' }}>
            <TopBar
                openDrawer={() => toggleDrawer(true)}
                authUser={authContext?.authUser}
                isLoggedIn={authContext?.isLoggedIn ?? false}
            />
            {isExtraSmall ? (
                <>
                    <BottomBar />
                </>
            ) : (
                <>
                    <ContentDrawer
                        toggleDrawer={toggleDrawer}
                        isOpen={opened}
                        authUser={authContext?.authUser}
                        isLoggedIn={authContext?.isLoggedIn ?? false}
                    />
                </>
            )}
            <div
                style={{
                    height: '100%',
                    zIndex: 0,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: isExtraSmall ? 60 : 80,
                    marginBottom: isExtraSmall ? 50 : 0,
                }}
            >
                <Outlet />
            </div>
        </div>
    );
};
