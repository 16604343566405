import React, { useState } from 'react';
import IconButton from '@mui/joy/IconButton';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
export const usePasswordVisibility = (): { isPasswordVisible: boolean; eyeIconButton: React.ReactNode } => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

    const eyeIconButton = (
        <IconButton variant="soft" onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
            {isPasswordVisible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
        </IconButton>
    );

    return {
        isPasswordVisible,
        eyeIconButton,
    };
};
