import { useCallback, useEffect } from 'react';
import { SlotCriteria, SlotCriteriaValue } from '../../../../../../api/models';
import { idNameToOption, Option } from '../../../../../../utils/formUtils';
import { useQuery } from 'react-query';
import { api } from '../../../../../../api';
import { HealthcareServicesCriteriaParams } from '../../../../../../api/models/forms/HealthcareServiceCriteriaFinderParams';

export const useOrganizationCriteriaOptions = (healthcareServiceId: string) => {
    const params: HealthcareServicesCriteriaParams = {
        healthcare_service: healthcareServiceId,
    };
    const query = useQuery('slotsCriteria', () => api.getSlotsHSCriteria(params), {
        staleTime: Infinity,
    });
    const slotsCriteria = query.data || [];
    useEffect(() => {
        query.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthcareServiceId]);

    const toOption = useCallback((criteriaOrValues: SlotCriteria[] | SlotCriteriaValue[]): Option[] => {
        return criteriaOrValues.map(idNameToOption);
    }, []);

    const getSingleCriteria = (criteriaId: string) => {
        return slotsCriteria?.find((criteria) => criteria.id.toString() === criteriaId) || null;
    };

    const getCriteriaValueOptions = (criteriaId: string) => {
        if (slotsCriteria) {
            const criteriaValues = getSingleCriteria(criteriaId)?.values;
            return (criteriaValues && toOption(criteriaValues)) || [];
        }
        return [];
    };

    return {
        isLoading: query.isLoading,
        slotsCriteria,
        getSingleCriteria,
        slotsCriteriaOptions: toOption(slotsCriteria),
        getCriteriaValueOptions,
    };
};
