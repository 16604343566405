import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../../api/models';
import { SecureMode } from '../Routing/routes.types';
import { useAuthContext } from 'components/Auth/AuthContext/AuthContext';
import { isArray } from 'rrule/dist/esm/helpers';
import { AuthPending } from 'components/Auth/AuthPending/AuthPending';
import { loginRoute } from '../Routing/routes';

type Props = {
    children: ReactNode;
    secureMode: SecureMode;
};

export const SecuredRoute = ({ children, secureMode }: Props) => {
    const authContext = useAuthContext();
    const navigate = useNavigate();
    const [isError, setIsError] = useState<boolean | 'first_load'>('first_load');
    const lang = localStorage.getItem('lang') || 'de';

    useEffect(() => {
        if (authContext === undefined) return;

        const loggedButGuestOnly_Error = authContext?.isLoggedIn && secureMode === 'guest-only';
        const loggedOutButSecured_Error = !authContext?.isLoggedIn && isArray(secureMode);
        const loggedButWrongRole_Error =
            authContext?.isLoggedIn &&
            isArray(secureMode) &&
            !secureMode.includes(authContext.authUser?.role || UserRole.patient);

        const isError = loggedButGuestOnly_Error || loggedOutButSecured_Error || loggedButWrongRole_Error;

        if (isError) {
            navigate(`${loginRoute}?lang=${lang}`);
        } else {
            setIsError(false);
        }
    }, [authContext, secureMode, navigate]);

    if (secureMode === 'public') return <>{children}</>;

    if (isError === 'first_load' || isError) return null;

    return <AuthPending>{children}</AuthPending>;
};
