import React, { useState } from 'react';
import LoginForm from './LoginForm';
import useLoginFlow from '../../../hooks/useLoginFlow';
import { LoginCredentials } from '../../../api/models';
import { Snackbar, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, WarningOutlined } from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';
import { api } from 'api';
import { finalizeLogin } from '../actions';
import { refreshAuthToken } from 'utils/tokens';

export const LoginPage = () => {
    const { request, emailNotFound, otpCodeSent, passwordReset, submitCredentials, ensureEmailExists, submitOtp } =
        useLoginFlow();
    const [openRoleError, setOpenRoleError] = useState(false);
    const { t } = useTranslation();

    const tryLogin = async (values: LoginCredentials) => {
        const success = await submitOtp(values);
        if (success) {
            const refreshData = await refreshAuthToken();
            const secondOpinionToken = await api.generateSecondOpinionToken();
            await finalizeLogin(refreshData, secondOpinionToken);
        }
        setOpenRoleError(!success);
        return success;
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <LoginForm
                request={request}
                emailNotFound={emailNotFound}
                otpCodeSent={otpCodeSent}
                passwordReset={passwordReset}
                ensureEmailExists={ensureEmailExists}
                submitCredentials={submitCredentials}
                submitOtp={tryLogin}
            />
            <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                color="danger"
                variant="soft"
                size="lg"
                open={openRoleError}
                autoHideDuration={5000}
                onClose={() => setOpenRoleError(false)}
            >
                <Stack width="100%" direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                    <Typography startDecorator={<WarningOutlined />}>{t('login:error_role')}</Typography>
                    <IconButton
                        onClick={() => setOpenRoleError(false)}
                        color="danger"
                        variant="plain"
                        sx={{
                            '&: hover': {
                                background: 'var(--palette-joy-danger-500)',
                            },
                        }}
                    >
                        <CloseOutlined />
                    </IconButton>
                </Stack>
            </Snackbar>
        </div>
    );
};
