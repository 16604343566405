import { Components, CssVarsTheme, Theme } from '@mui/material';

export const ComponentStylesMaterialUI: Components<Omit<Theme, 'palette' | 'components'> & CssVarsTheme> = {
    MuiOutlinedInput: {
        defaultProps: {
            size: 'small',
        },
        styleOverrides: {
            root: {
                borderRadius: '6px',
                height: '36px',
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                color: 'var(--joy-palette-text-secondary)',
                border: 'none',
                fontWeight: 400,
            },
        },
    },
};
