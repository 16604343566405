import React from 'react';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import { List, Typography } from '@mui/joy';

import { AppointmentItem } from 'components/Dashboard/AppointmentListSection/AppointmentItem/AppointmentItem';

import { AppointmentModel, InvoiceRequestData } from 'api/models';
import { useModalState } from 'components/shared/BetterModal/useModalState';
import { BetterModal } from 'components/shared/BetterModal/BetterModal';
import { DetailsTabs } from 'components/Dashboard/AppointmentDetailsSection/DetailsTabs/DetailsTabs';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { slotListRoute } from 'components/shared/Routing/routes';

type Props = {
    appointments: AppointmentModel[];
    pickedAppointment?: AppointmentModel | undefined;
    loadMore: (prev: AppointmentModel[]) => void;
    reloadAppointment: () => void;
    noItemsMessage?: string;
    pickAppointment: (appointment: AppointmentModel | undefined) => void;
    isMobile: boolean;
    hasMore: string | boolean;
    invoices: InvoiceRequestData[];
};

export const TodaysAppointments = ({
    appointments = [],
    loadMore,
    isMobile,
    pickAppointment,
    reloadAppointment,
    invoices,
    hasMore = false,
    pickedAppointment = undefined,
}: Props) => {
    const modalState = useModalState();
    const { t } = useTranslation();
    const now = moment();
    const { isExtraSmall } = useWindowSize();
    const navigate = useNavigate();

    const handleSlotPageNavigation = () => {
        navigate({ pathname: slotListRoute });
    };

    const handlePickAppointment = (data: AppointmentModel) => {
        pickAppointment(data);
        modalState.toggleModal();
    };

    const handleCloseModal = () => {
        modalState.toggleModal();
        pickAppointment(undefined);
    };

    const handleLoadAppointments = () => {
        loadMore(appointments);
    };

    const noAppointmentsSheet = () => (
        <Box
            sx={{
                p: 1,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                marginTop: 2,
                marginBottom: 2,
            }}
        >
            <Typography fontWeight="bold" level="h4">
                {t('dashboard:no_appointments')}
            </Typography>
            <Box
                sx={{
                    width: { sm: '100%', lg: '75%' },
                }}
            >
                <Typography fontWeight="bold" level="body-xs">
                    {t('dashboard:no_appointments_schedule_description')}
                </Typography>
            </Box>
            <Button onClick={handleSlotPageNavigation} sx={{ backgroundColor: 'var(--joy-palette-primary-solidBg)' }}>
                <Typography level="body-sm" sx={{ color: 'var(--joy-palette-primary-solidColor)' }}>
                    {t('dashboard:no_appointments_schedule_button')}
                </Typography>
            </Button>
        </Box>
    );

    return (
        <Box
            sx={{
                borderRadius: 'sm',
                p: 1,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography level="title-md" sx={{ fontWeight: 'bold', textAlign: 'start' }}>
                    {t('dashboard:todays_appointment')}
                </Typography>
                <Typography color="neutral" sx={{ fontWeight: '500', fontSize: '14px', alignSelf: 'start' }}>
                    {now.format('DD MMMM YYYY')}
                </Typography>
            </Box>
            <>
                {appointments.length !== 0 ? (
                    <>
                        <Box
                            sx={{
                                overflowY: 'auto',
                                textAlign: 'start',
                                maxHeight: '100%',
                                height: '100%',
                                width: '100%',
                                marginTop: 2,
                            }}
                        >
                            <List sx={{ '--ListItemDecorator-size': '24px' }}>
                                {appointments.map((app, appIdx) => (
                                    <div
                                        key={appIdx}
                                        style={{
                                            paddingRight: isExtraSmall ? '16px' : '0',
                                            paddingLeft: isExtraSmall ? '16px' : '0',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <AppointmentItem
                                            appointment={app}
                                            pickedAppointment={pickedAppointment}
                                            isMobile={isMobile}
                                            reloadAppointment={reloadAppointment}
                                            viewDetails={handlePickAppointment}
                                            invoices={invoices}
                                        />
                                        <div style={{ height: '8px' }} />
                                    </div>
                                ))}
                            </List>
                            {hasMore ? (
                                <Box>
                                    <Button
                                        onClick={handleLoadAppointments}
                                        sx={{
                                            width: '100%',
                                            marginTop: 1.5,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            backgroundColor: 'var(--joy-palette-primary-solidBg)',
                                        }}
                                    >
                                        {t('buttons:button_load_more')}
                                    </Button>
                                </Box>
                            ) : null}
                        </Box>
                        {!isMobile && (
                            <>
                                <BetterModal
                                    title={t('appointmentDetails:label_title')}
                                    state={modalState}
                                    minWidth={600}
                                    body={
                                        pickedAppointment ? (
                                            <DetailsTabs
                                                appointment={pickedAppointment}
                                                isMobile={isMobile}
                                                reloadAppointments={reloadAppointment}
                                            />
                                        ) : (
                                            <>Loading</>
                                        )
                                    }
                                    onClose={handleCloseModal}
                                />
                            </>
                        )}
                    </>
                ) : (
                    noAppointmentsSheet()
                )}
            </>
        </Box>
    );
};
