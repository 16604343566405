import React from 'react';
import { AppointmentModel, InvoiceRequestData } from '../../../../api/models';
import { MyAppointmentsData } from '../../../../hooks/constants';
import { LoadMoreList } from '../../../shared/LoadMoreList/LoadMoreList';
import { useTranslation } from 'react-i18next';
import { AppointmentItem } from '../AppointmentItem/AppointmentItem';
import { useWindowSize } from '../../../shared/hooks/useWindowSize';

type Props = {
    appointmentsData: MyAppointmentsData;
    pickedAppointment?: AppointmentModel | undefined;
    loadMore?: (prev: AppointmentModel[]) => void;
    noItemsMessage?: string;
    pickAppointment: (appointment: AppointmentModel) => void;
    reloadAppointments: () => void;
    isMobile?: boolean;
    invoices: InvoiceRequestData[];
    isLoading?: boolean;
};
export const AppointmentList = ({
    appointmentsData,
    loadMore,
    noItemsMessage,
    pickAppointment,
    pickedAppointment = undefined,
    reloadAppointments,
    isMobile = undefined,
    invoices,
    isLoading,
}: Props) => {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();

    const handleLoadMore = () => {
        if (loadMore && appointmentsData.next) {
            loadMore(appointmentsData.appointments);
        }
    };

    return (
        <div style={{ marginTop: -10, marginBottom: -15 }}>
            <LoadMoreList
                fullWidth
                data={appointmentsData.appointments}
                hasMore={!!appointmentsData.next}
                isLoading={isLoading}
                loadMore={handleLoadMore}
                renderItem={({ item }) => {
                    return (
                        <AppointmentItem
                            key={item.id}
                            reloadAppointment={reloadAppointments}
                            isMobile={isMobile !== undefined ? isMobile : isExtraSmall}
                            pickedAppointment={pickedAppointment}
                            appointment={item}
                            viewDetails={pickAppointment}
                            invoices={invoices}
                        />
                    );
                }}
                ListEmptyComponent={!appointmentsData.isLoading && <p>{noItemsMessage}</p>}
                loadMoreText={t('buttons:button_load_more')}
            />
        </div>
    );
};
