import React from 'react';
import { Sheet } from '@mui/joy';
import { RoutingList } from '../shared/Routing/RoutingList';

export const BottomBar = () => {
    return (
        <Sheet
            variant="plain"
            style={{
                width: '100%',
                height: '50px',
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                zIndex: 50,
                background: 'var(--joy-palette-background-level1)',
            }}
            sx={{
                borderTop: '1px solid',
                borderColor: 'divider',
            }}
        >
            <RoutingList listOrientation="horizontal" listItemOrientation="vertical" />
        </Sheet>
    );
};
