import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterList, Search } from '@mui/icons-material';
import {
    Grid,
    Input,
    Stack,
    Tab,
    TabList,
    TabPanel,
    Table,
    Tabs,
    Typography,
    Dropdown,
    MenuButton,
    Chip,
    Sheet,
} from '@mui/joy';
import { TableContainer } from '@mui/material';

import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { useInvoicesSecondOpinion } from './hooks/useInvoiceSecondOpinion';

import { FilterDrawer, FilterGroup, FilterTypes } from '../shared/FilterDrawer/FilterDrawer';
import { GroupServicePair } from 'components/SlotsPage/SlotsPage';
import { invoiceRouteInfo } from 'components/shared/Routing/routeObjects';
import { InvoiceTableBody } from './components/TableBody';
import { InvoiceTableHeader } from './components/TableHeader';
import { InvoiceTableFooter } from './components/TableFooter';
import { getDateXDaysAgo, Option } from '../../utils/formUtils';

import { useLoading } from 'components/shared/LoadingPage/useLoading';
import { InvoiceRequestData, InvoiceSortingOptions, InvoiceStatus, QueryInvoiceParamsType } from 'api/models';
import { useQueryClient } from 'react-query';
import { invoiceQueryKey } from './hooks/invoice.query-keys';
import useInvoices from 'hooks/useInvoices';
import { useModalState } from 'components/shared/BetterModal/useModalState';

type TabData = {
    label: string;
    invoices: InvoiceRequestData[];
    invoicesLength: number | undefined;
};

export const InvoicePage = () => {
    const { t } = useTranslation();
    const { markPaid } = useInvoices();

    const invoiceRouteObject = invoiceRouteInfo(t);
    const { isExtraSmall, isLarge } = useWindowSize();

    const [isFilterDrawerOpen, toggleFilterDrawer] = useState(false);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const queryClient = useQueryClient();
    const modalState = useModalState();

    const invoiceTitle = 'Date';

    const invoiceOptions: Option[] = [
        { value: getDateXDaysAgo(7), label: t('filterDrawer:last_days', { count: 7 }) },
        { value: getDateXDaysAgo(30), label: t('filterDrawer:last_days', { count: 30 }) },
        { value: getDateXDaysAgo(60), label: t('filterDrawer:last_days', { count: 60 }) },
        { value: getDateXDaysAgo(90), label: t('filterDrawer:last_days', { count: 90 }) },
        { value: getDateXDaysAgo(180), label: t('filterDrawer:last_months', { count: 6 }) },
    ];

    const scheduleFilterKey = 'schedule';
    const scheduleFilterGroup: FilterGroup[] = [
        {
            title: invoiceTitle,
            options: invoiceOptions,
            key: scheduleFilterKey,
            many_options: false,
            default_values: [],
        },
    ];

    const getInvoiceStatusFromTab = (tabIndex: number) => {
        switch (tabIndex) {
            case 0:
                return InvoiceStatus.All;
            case 1:
                return InvoiceStatus.Pending;
            case 2:
                return InvoiceStatus.Paid;
            case 3:
                return InvoiceStatus.Overdue;
            case 4:
                return InvoiceStatus.Cancelled;
            default:
                return InvoiceStatus.All;
        }
    };

    const [sortField, setSortField] = useState<InvoiceSortingOptions | null>(null);
    const [isDescending, setIsDescending] = useState<boolean | null>(null);

    const handleTabChange = (event: React.SyntheticEvent | null, newValue: number | string | null) => {
        setCurrentTab(newValue as number);
        setQueryParams((prev) => ({ ...prev, status: getInvoiceStatusFromTab(newValue as number), current_page: 1 }));
        queryClient.invalidateQueries(invoiceQueryKey.invoice());
    };

    const [queryParams, setQueryParams] = useState<QueryInvoiceParamsType>({
        page_size: 12,
        current_page: 1,
        date_from: undefined,
        date_to: undefined,
        date_due_from: undefined,
        date_due_to: undefined,
        status: getInvoiceStatusFromTab(currentTab),
        search_params: '',
        sort_by: sortField !== null ? sortField : undefined,
        descending: isDescending !== null ? isDescending : undefined,
    });

    const { isLoading, invoices, metadata, refetch } = useInvoicesSecondOpinion(queryParams);

    const handleMarkAsPaid = async (idBill: number) => {
        await markPaid(idBill);
        refetch();
        modalState.toggleModal();
    };

    const handleSortChange = (field: InvoiceSortingOptions) => {
        let newDescending: boolean | null;
        if (sortField === field) {
            newDescending = isDescending === null ? false : isDescending ? null : true;
        } else {
            newDescending = false;
        }
        setSortField(field);
        setIsDescending(newDescending);
        setQueryParams((prev) => ({
            ...prev,
            sort_by: field,
            descending: newDescending !== null ? newDescending : undefined,
        }));
    };

    const [filteredOutServices, setFilteredOutServices] = useState<GroupServicePair[]>([]);
    const [filteredOutInvoiceTypes, setFilteredOutScheduleTypes] = useState<string[]>([]);

    const applyFilters = (filterTypes: FilterTypes[], services: GroupServicePair[]) => {
        const invoices = filterTypes.find((f) => f.key === scheduleFilterKey)?.values;
        if (invoices === undefined) return;

        const filteredOutScheduleTypes = invoiceOptions.filter((o) => !invoices.includes(o.value));
        setFilteredOutScheduleTypes(filteredOutScheduleTypes.map((s) => s.value));
        setFilteredOutServices(services);

        const values = filterTypes.find((f) => f.key === scheduleFilterKey)?.values;
        const dateFrom = values?.length ? values[0].toString() : '';

        setQueryParams((prev) => {
            const newParams = { ...prev };
            if (dateFrom) {
                newParams.date_from = dateFrom;
            } else {
                delete newParams.date_from;
            }
            return newParams;
        });
    };

    const handlePaginationChange = (page: number) => {
        setQueryParams((prev) => ({
            ...prev,
            current_page: page,
            status: prev.status,
        }));
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setQueryParams((prevParams) => ({
            ...prevParams,
            search_params: event.target.value,
        }));
    };

    const allInvoicesLength = metadata.count_by_billing_history_status.ALL;
    const paidInvoicesLength = metadata.count_by_billing_history_status.PAID;
    const overdueInvoicesLength = metadata.count_by_billing_history_status.OVERDUE;
    const pendingInvoicesLength = metadata.count_by_billing_history_status.PENDING;
    const canceledInvoicesLength = metadata.count_by_billing_history_status.CANCELLED;

    const tableHeaderData = isExtraSmall
        ? [t('invoice:invoice_no'), t('invoice:invoice_patient_name')]
        : [t('invoice:invoice_no'), t('invoice:invoice_patient_name'), t('invoice:invoice_due_date')];

    const combinedTabs: TabData[] = isExtraSmall
        ? [
              { label: t('invoice:all_invoices'), invoices: invoices, invoicesLength: allInvoicesLength },
              { label: t('invoice:pending_invoices'), invoices: invoices, invoicesLength: pendingInvoicesLength },
              { label: t('invoice:overdue_invoices'), invoices: invoices, invoicesLength: overdueInvoicesLength },
          ]
        : [
              { label: t('invoice:all_invoices'), invoices: invoices, invoicesLength: allInvoicesLength },
              { label: t('invoice:pending_invoices'), invoices: invoices, invoicesLength: pendingInvoicesLength },
              { label: t('invoice:paid_invoices'), invoices: invoices, invoicesLength: paidInvoicesLength },
              { label: t('invoice:overdue_invoices'), invoices: invoices, invoicesLength: overdueInvoicesLength },
              { label: t('invoice:cancelled_invoices'), invoices: invoices, invoicesLength: canceledInvoicesLength },
          ];

    useLoading(isLoading);

    return (
        <Sheet
            sx={{
                paddingBottom: isExtraSmall ? 4 : 2,
                marginLeft: -2.5,
                marginRight: -2.5,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                height: 'auto',
            }}
        >
            <Stack
                spacing={4}
                direction={isExtraSmall ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="stretch"
                sx={{
                    width: '100%',
                    paddingX: !isLarge ? '5px' : '20px',
                }}
            >
                {!isExtraSmall && (
                    <>
                        <Typography level="h1" startDecorator={invoiceRouteObject.icon}>
                            {invoiceRouteObject.name}
                        </Typography>
                    </>
                )}
                <Grid
                    container
                    sx={{
                        justifyContent: 'space-between',
                        width: '100%',
                        gap: 1,
                        alignItems: 'center',
                        marginTop: 2,
                        marginBottom: 1,
                    }}
                >
                    <Grid xs={12} sx={{ display: 'flex', gap: 2 }}>
                        <Input
                            startDecorator={<Search />}
                            placeholder="Search invoice"
                            fullWidth
                            value={queryParams.search_params}
                            onChange={handleSearchChange}
                        />
                        <Dropdown>
                            <MenuButton onClick={() => toggleFilterDrawer(true)}>
                                <>
                                    <FilterList
                                        sx={{
                                            height: '20px',
                                            width: '20px',
                                            marginRight: 1,
                                        }}
                                    />
                                    <Typography level="body-sm">{t('filterDrawer:title')}</Typography>
                                </>
                            </MenuButton>
                        </Dropdown>
                    </Grid>
                </Grid>
            </Stack>

            <Grid container xs={12} sx={{ marginTop: 2 }}>
                <Grid xs={12}>
                    <Tabs
                        value={currentTab}
                        onChange={handleTabChange}
                        sx={{
                            borderRadius: '8px',
                            backgroundColor: 'var(--joy-palette-background-body)',
                        }}
                    >
                        <TabList>
                            {combinedTabs.map((label, index) => (
                                <div key={index}>
                                    <Tab>
                                        {label.label}
                                        <Chip size="sm" variant="solid" color="neutral">
                                            {label.invoicesLength}
                                        </Chip>
                                    </Tab>
                                </div>
                            ))}
                        </TabList>

                        {combinedTabs.map((label, index) => (
                            <TabPanel value={index} key={label.label} sx={{ p: 0 }}>
                                <Sheet sx={{ height: 'auto' }}>
                                    <TableContainer>
                                        <Table
                                            sx={{
                                                marginTop: 1,
                                                width: '100%',
                                                tableLayout: 'auto',
                                                padding: 0,
                                                borderTop: 1,
                                                overflow: 'hidden',
                                                borderColor: 'var(--joy-palette-divider)',
                                            }}
                                        >
                                            <InvoiceTableHeader
                                                headers={tableHeaderData}
                                                onSortChange={handleSortChange}
                                                sortField={sortField}
                                                isDescending={isDescending}
                                            />
                                            <InvoiceTableBody
                                                invoices={label.invoices}
                                                isExtraSmall={isExtraSmall}
                                                onMarkAsPaid={handleMarkAsPaid}
                                            />
                                            <InvoiceTableFooter
                                                handlePagination={handlePaginationChange}
                                                totalPages={metadata.pagination.total_pages}
                                                totalPageItems={metadata.pagination.total_count}
                                                pageNo={metadata.pagination.current_page}
                                                itemsPerPage={metadata.pagination.page_size}
                                                totalItems={metadata.pagination.total_count}
                                            />
                                        </Table>
                                    </TableContainer>
                                </Sheet>
                            </TabPanel>
                        ))}
                    </Tabs>
                </Grid>
            </Grid>
            <FilterDrawer
                applyFilters={applyFilters}
                toggleDrawer={toggleFilterDrawer}
                isOpen={isFilterDrawerOpen}
                filterGroups={scheduleFilterGroup}
            />
        </Sheet>
    );
};
