import React from 'react';
import { InvoiceItemData } from '../../../api/models';
import { ApiRequest } from '../../../hooks/useApiRequest';
import IconButton from '@mui/joy/IconButton';
import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { EditInvoiceItemModal } from '../EditInvoiceItem/EditInvoiceItemModal';
import { Stack } from '@mui/joy';
import { useModalState } from '../../shared/BetterModal/useModalState';
import ConfirmModal from '../../shared/ConfirmModal/ConfirmModal';
import { useTranslation } from 'react-i18next';

type Props = {
    invoice?: InvoiceItemData | undefined;
    request: ApiRequest;
    addInvoice: (data: InvoiceItemData) => Promise<void>;
    editInvoice: (data: InvoiceItemData) => Promise<void>;
    deleteInvoice: (id: number) => Promise<void>;
    refreshItems: () => void;
};
export const InvoiceActions = ({ invoice, deleteInvoice, editInvoice, request, addInvoice, refreshItems }: Props) => {
    const { t } = useTranslation();
    const deleteConfirmationModalState = useModalState();

    const handleDeleteInvoice = async () => {
        const deleteInvoicePromise = deleteInvoice(invoice?.id || -1);
        deleteInvoicePromise.then(() => refreshItems());
        return deleteInvoicePromise;
    };

    return (
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
            <EditInvoiceItemModal
                request={request}
                addInvoice={addInvoice}
                editInvoice={editInvoice}
                refreshItems={refreshItems}
                invoice={invoice}
                button={
                    <IconButton variant="outlined">
                        <EditOutlined />
                    </IconButton>
                }
                mode="edit"
            />
            <ConfirmModal
                button={
                    <IconButton variant="outlined" color="danger">
                        <DeleteOutlined />
                    </IconButton>
                }
                modalDescription={t('adminPage:message_delete_invoice')}
                successAlertDescription={t('adminPage:message_delete_invoice_success')}
                onConfirm={handleDeleteInvoice}
                modalState={deleteConfirmationModalState}
            />
        </Stack>
    );
};
