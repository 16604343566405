import { AppointmentParams, AppointmentsDataPage, SortTypes, WaitingRoomAppointmentsDataPage } from '../api/models';

export interface MyAppointmentsData extends Omit<AppointmentsDataPage, 'metadata'> {
    isLoading: boolean;
}

export interface MyWaitingRoomAppointmentsData extends Omit<WaitingRoomAppointmentsDataPage, 'metadata'> {
    isLoading: boolean;
}

export const basicParams = (offset: number): AppointmentParams => {
    return {
        offset,
        sort: SortTypes.dateAsc,
        limit: 3,
    };
};

export const specificParams = (offset: number, search: string): AppointmentParams => {
    return {
        offset,
        sort: SortTypes.dateAsc,
        limit: 3,
        search,
    };
};

export const basicAppointmentDataObject = {
    next: '',
    appointments: [],
    totalCount: 0,
    isLoading: false,
};

export const basicWaitingRoomDataObject = {
    next: '',
    waitingRoomAppointments: [],
    totalCount: 0,
    isLoading: false,
};

export const SET_USER = 'SET_USER';
