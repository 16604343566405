import { TypographySystemOptions } from '@mui/joy/styles/types';

export const TextStyles: Partial<TypographySystemOptions> = {
    'body-md': {
        fontSize: 14,
    },
    'body-xs': {
        fontSize: 12,
    },
    'body-sm': {
        fontSize: 12,
    },
};
