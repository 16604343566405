import React from 'react';
import { Box, Typography } from '@mui/joy';
import { TableRow, TableCell, PaginationItem, Pagination, TableFooter } from '@mui/material';

import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';

type InvoiceFooterProps = {
    pageNo: number;
    totalPageItems: number;
    totalPages: number;
    itemsPerPage: number;
    handlePagination: (page: number) => void;
    totalItems: number;
};
export const InvoiceTableFooter = ({
    pageNo,
    totalPageItems,
    totalPages,
    itemsPerPage,
    handlePagination,
    totalItems,
}: InvoiceFooterProps) => {
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();
    return (
        <TableFooter sx={{ backgroundColor: 'var(--joy-palette-background-body)' }}>
            <TableRow>
                <TableCell
                    style={{
                        padding: 0,
                        backgroundColor: 'var(--joy-palette-background-body)',
                    }}
                    colSpan={12}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: isExtraSmall ? 'space-between' : 'end',
                            alignItems: 'center',
                            backgroundColor: 'var(--joy-palette-background-body)',
                            marginLeft: 2,
                            marginRight: 2,
                        }}
                    >
                        <Typography>
                            {t('invoice:page_count', {
                                current_page: pageNo,
                                total_pages: totalPages === 0 ? 1 : totalPages,
                            })}
                        </Typography>
                        <Pagination
                            sx={{ marginTop: 2, marginBottom: 2 }}
                            count={totalPages}
                            page={pageNo}
                            renderItem={(item) => (
                                <PaginationItem
                                    {...item}
                                    disabled={totalItems === 0}
                                    onClick={(event) => {
                                        if (item.page !== 0) {
                                            handlePagination(item.page || 0);
                                        }
                                        item.onClick(event);
                                    }}
                                />
                            )}
                        />
                    </Box>
                </TableCell>
            </TableRow>
        </TableFooter>
    );
};
