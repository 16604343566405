import { MenuButton, styled } from '@mui/joy';

type Props = {
    enable_outline?: number;
};

export const OutlinedMenuButton = styled(MenuButton)<Props>(({ enable_outline = 1 }) => ({
    border: enable_outline === 1 ? '2px solid' : '0',
    color: 'var(--joy-palette-neutral-plainColor)',
}));
