import React from 'react';
import { AppointmentModel } from '../../../../api/models';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../api';
import { Alert, Stack, Typography, Box } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import { FileDownloadOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import '../../../shared/styles/hideScroll.css';

type Props = {
    appointment: AppointmentModel;
};
export const AttachmentTab = ({ appointment }: Props) => {
    const { t } = useTranslation();

    const openAttachmentLink = (id: number): void => {
        // Safari blocks window.open() if used inside async function. This workaround fixes the issue.
        const windowReference = window.open();

        api.getAttachmentLink(id).then((url) => {
            if (windowReference) {
                windowReference.location.href = url;
            }
        });
    };

    return (
        <Stack spacing={1} width="100%">
            <Alert color="primary">{t('appointmentDetails:attachmentWarningMessage')}</Alert>
            <Stack className="hideScroll" spacing={1} style={{ overflowY: 'auto', maxHeight: '140px' }}>
                {appointment.attachments.map((element, index) => (
                    <Stack key={index} direction="row" alignItems="stretch" justifyContent="space-between">
                        <Stack direction="row" spacing={1}>
                            <Box
                                sx={{
                                    height: '40px',
                                    width: '40px',
                                    borderRadius: 5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    bgcolor: 'primary.300',
                                }}
                            >
                                <InsertDriveFileOutlined />
                            </Box>
                            <Typography level="body-sm">{element.filename}</Typography>
                        </Stack>
                        <IconButton
                            sx={{ height: '40px', width: '40px' }}
                            onClick={() => openAttachmentLink(element.id)}
                        >
                            <FileDownloadOutlined />
                        </IconButton>
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};
