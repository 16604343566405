import axios from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { api } from '../../../api';
import { SlotStatus } from '../../../api/models';
import { BookingStatus } from '../../Dashboard/StatusBadge/StatusBadge';
import { DateRange } from 'components/SlotsPage/hooks/Calendar.types';
import { useErrorContext } from 'context/errorContext';
import { dashboardQueryKey } from './dashboard.query-keys';

export const useSlotsAndAppointNumber = (dateRange: DateRange | undefined) => {
    const { error, setError } = useErrorContext();

    const getSlotsAndAppointments = async (dateRange: DateRange) => {
        try {
            const requestAppointments = api.getAppointments({
                dateFrom: dateRange.dateFrom,
                dateTo: dateRange.dateTo,
                statuses: [BookingStatus.upcoming, BookingStatus.past],
            });
            const requestSlots = api.getOrganizationSlots(dateRange.dateFrom, dateRange.dateTo);

            return await Promise.all([requestAppointments, requestSlots]);
        } catch (error) {
            if (axios.isAxiosError(error)) {
                setError({ message: error.message, status: error.code });
            }
            setError({ message: 'Failed to fetch slos and appointments' });
            throw error;
        }
    };

    const { data, isLoading, isError } = useQuery({
        queryFn: async () => getSlotsAndAppointments(dateRange!),
        queryKey: [dashboardQueryKey.calendarSchedules],
        staleTime: 1 * 60 * 1000,
        refetchInterval: 1 * 60 * 1000,
        enabled: !!dateRange,
        keepPreviousData: true,
        retry: 3,
    });

    const { noOfAppointments, freeSlots, busySlots } = useMemo(() => {
        const appointments = data ? data[0].appointments : [];
        const slots = data ? data[1] : [];
        const noOfAppointments = appointments.length;
        const freeSlots = slots.filter((slot) => slot.status === SlotStatus.FREE).length;
        const busySlots = slots.filter((slot) => slot.status === SlotStatus.BUSY).length;

        return { noOfAppointments, freeSlots, busySlots };
    }, [data]);

    return {
        noOfAppointments,
        freeSlots,
        busySlots,
        isLoading,
        error,
    };
};
