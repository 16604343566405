export const loginRoute = '/login';
export const invitationRegisterRoute = '/invitationRegister';
export const dashboardRoute = '/dashboard';
export const appointmentListRoute = '/appointmentList';
export const slotListRoute = '/slotList';
export const invoiceRoute = '/invoice';
export const videoCallRoute = `/video`;
export const registerRoute = '/register';
export const forgotPasswordRoute = 'https://app%ENV%.medrefer.de/b/%ORGANIZATION%/forgot_password';
export const errorRoute = '/error';
export const accountDetailsRoute = '/account-details';
export const adminPanelRoute = '/adminPanel';
export const errorManagementRoute = '/errorManagement';
