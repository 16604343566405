import { useEffect, useState } from 'react';
import { ErrorLog, ErrorOrderByOptions } from '../../../api/models/errorLog';
import { ErrorLogMetadata, ErrorSortingParams } from '../../../api/models/errorLog';
import { apiSecondOpinion } from '../../../api';
import { number } from 'yup';
import { ApiRoutes } from '../../../api/ApiRoutes';
import { formatISODate, formatISODateTime } from 'api/utils';
import moment from 'moment';
import { formatDateCustomISO } from '../../../utils/formUtils';

export type SearchParams = {
    page_size: number;
    current_page: number;
    date_to?: Date;
    date_from?: Date;
    is_resolved?: boolean;
    sort_by?: ErrorOrderByOptions;
    descending?: boolean;
};
export type SearchParamsComplete = Omit<SearchParams, 'date_to' | 'date_from'> & {
    id_bill?: number;
    search_params?: string;
    date_to?: string;
    date_from?: string;
};
export const useErrorLogs = (search_query: string, params: SearchParams) => {
    const [errorLogList, setErrorLogList] = useState<ErrorLog[]>([]);
    const [metadata, setMetadata] = useState<ErrorLogMetadata | undefined>(undefined);

    const fetchData = async () => {
        const billId = parseFloat(search_query);
        const isIdValid = !isNaN(billId) && (billId | 0) === billId;

        const searchParams: SearchParamsComplete = {
            current_page: params.current_page,
            date_from: params.date_from ? formatDateCustomISO(params.date_from) : undefined,
            date_to: params.date_to ? formatDateCustomISO(params.date_to) : undefined,
            descending: params.descending,
            is_resolved: params.is_resolved,
            page_size: params.page_size,
            sort_by: params.sort_by,
            search_params: isIdValid ? undefined : search_query,
            id_bill: isIdValid ? billId : undefined,
        };

        return await apiSecondOpinion.getErrorLogs(searchParams);
    };

    const refreshErrorLogs = () => {
        fetchData().then((res) => {
            setErrorLogList(res.results);
            setMetadata(res.metadata);
        });
    };

    useEffect(() => {
        refreshErrorLogs();
    }, [search_query, params]);

    return {
        errorLogList,
        metadata,
        refreshErrorLogs,
    };
};
