/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { api, apiSecondOpinion } from '../../../api';
import { clearAuthTokens, getAuthTokens, isTokenValid, setSoAuthTokens } from '../../../utils/tokens';
import { dashboardRoute, loginRoute } from '../../shared/Routing/routes';
import { UserProfile, UserRole } from '../../../api/models';
import { useStorageState } from '../../../web-kit/hooks/useStorageState';
import { useErrorContext } from 'context/errorContext';

type AuthContextValue = {
    authUser: UserProfile | undefined;
    logout: () => void;
    login: (user: UserProfile | undefined) => boolean;
    isLoggedIn: boolean;
    updateDoctor: () => void;
    updateProfile: () => void;
    fetchSecondOpinionToken: () => void;
};

export const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);

export function useAuthContext(): AuthContextValue | undefined {
    return useContext(AuthContext);
}

type Props = {
    children: React.ReactNode;
};

export const AuthContextProvider = ({ children }: Props) => {
    const [authUser, setAuthUser] = useStorageState<UserProfile>('authUser');
    const navigate = useNavigate();
    const { setError } = useErrorContext();

    useEffect(() => {
        if (!authUser) return;

        const tokens = getAuthTokens();
        if (!isTokenValid(tokens.token || '')) {
            logout();
            return;
        }

        api.setAuthToken(tokens.token || '');
        fetchSecondOpinionToken();
    }, [authUser]);

    const IsLoggedIn = (): boolean => {
        return authUser !== undefined;
    };

    const logout = (redirect = true) => {
        setAuthUser(undefined);
        clearAuthTokens();

        if (!redirect) return;

        navigate(loginRoute);
    };

    const fetchSecondOpinionToken = async () => {
        try {
            const { second_opinion_token } = await api.generateSecondOpinionToken();
            setSoAuthTokens(second_opinion_token);

            if (authUser?.role !== UserRole.organizationMember) return;

            const doctor = await apiSecondOpinion.getDoctorProfile();

            if (doctor == null) {
                return;
            } else {
                const doctor = await apiSecondOpinion.getDoctorProfile();
                if (doctor == null) {
                    return;
                } else {
                    await updateDoctor();
                }
            }
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (axios.isAxiosError(e)) {
                    setError({ message: e.message, status: e.code });
                }
                setAuthUser(undefined);
            } else {
                console.log('An unexpected error occurred', e);
                logout();
            }
        }
    };

    const updateDoctor = async () => {
        try {
            const res = await apiSecondOpinion.getDoctorProfile();
            const user = authUser;

            if (user === undefined) return;
            user.doctor = res;
            setAuthUser(user);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (axios.isAxiosError(e)) {
                    setError({ message: e.message, status: e.code });
                }
                setAuthUser(undefined);
            } else {
                console.log('An unexpected error occurred', e);
                logout();
            }
        }
    };

    const updateProfile = async () => {
        try {
            const doctor = authUser?.doctor;
            let user = await api.getUserProfile();
            user.doctor = doctor;
            setAuthUser(user);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (axios.isAxiosError(e)) {
                    setError({ message: e.message, status: e.code });
                }
                setAuthUser(undefined);
            } else {
                console.log('An unexpected error occurred', e);
                logout();
            }
        }
    };

    const login = (user: UserProfile | undefined): boolean => {
        setAuthUser(user);

        if (user?.role === UserRole.patient) {
            logout(false);
            return false;
        }

        navigate(dashboardRoute);
        return true;
    };

    const value = {
        authUser,
        login,
        logout,
        updateDoctor,
        isLoggedIn: IsLoggedIn(),
        fetchSecondOpinionToken,
        updateProfile,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
