import { MissingInfoProps, MissingInfoValues } from './MissingInfo.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
export const mapPropsToValues = (props: MissingInfoProps): MissingInfoValues => {
    return {
        phone_number: props.authUser?.doctor?.phone_number || props.authUser?.phone || '',
        name: props.authUser?.doctor?.name || props.authUser?.first_name || '',
        surname: props.authUser?.doctor?.surname || props.authUser?.last_name || '',
        terms_and_conditions: false,
        privacy_policy: false,
        bank_number: props.authUser?.doctor?.bank_number || '',
        city: props.authUser?.doctor?.city || props.authUser?.address2 || '',
        fax_number: props.authUser?.doctor?.fax_number || '',
        id_medrefer: props.authUser?.id.toString() || '',
        personal_title: props.authUser?.doctor?.personal_title || props.authUser?.personal_title || '',
        post_code: props.authUser?.doctor?.post_code || props.authUser?.post_code || '',
        street: props.authUser?.doctor?.street || props.authUser?.address || '',
        vat_id: props.authUser?.doctor?.vat_id || '',
        image: props.authUser?.practitioner?.image?.id,
        description: props.authUser?.practitioner?.description || '',
    };
};

export const validationSchema = ({ t }: MissingInfoProps) =>
    Yup.object().shape({
        name: Yup.string().required(t('invoice:error_field_required')),
        surname: Yup.string().required(t('invoice:error_field_required')),
        terms_and_conditions: Yup.boolean().isTrue(t('profile:terms_and_conditions_accept')),
        privacy_policy: Yup.boolean().isTrue(t('profile:data_privacy_accept')),
        street: Yup.string().required(t('invoice:error_field_required')),
        phone_number: Yup.string()
            .required(t('invoice:error_field_required'))
            .max(18, t('invoice:error_tel_length'))
            .min(15, t('invoice:error_tel_length')),
        city: Yup.string().required(t('invoice:error_field_required')),
        post_code: Yup.string()
            .required(t('invoice:error_field_required'))
            .length(5, t('invoice:error_postal_code_length')),
        bank_number: Yup.string()
            .required(t('invoice:error_field_required'))
            .min(16, t('invoice:error_bank_nr_length'))
            .max(35, t('invoice:error_bank_nr_length')),
    });

export const handleSubmit = (values: MissingInfoValues, { props }: FormikBag<MissingInfoProps, MissingInfoValues>) => {
    props.handleSubmit(values);
};
