import React, { ChangeEvent, useState } from 'react';
import { FormikProps } from 'formik';
import { SlotEditScope } from '../../../../../api/models';
import { SlotProps, SlotValues } from '../EditSlotForm.types';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Stack, Typography } from '@mui/joy';
import { RadioButton } from '../../../../shared/RadioButton/RadioButton';

interface Props {
    handleSubmit: (selectedOption: SlotEditScope) => void;
    handleCancel: () => void;
    baseProps: SlotProps & FormikProps<SlotValues>;
    isLoading?: boolean;
    recurring: boolean;
}
export const DeleteSlotBody = ({ isLoading, recurring, baseProps, handleCancel, handleSubmit }: Props) => {
    const [editOption, setEditOption] = useState<number>(0);
    const { t } = useTranslation();

    const onSubmit = () => {
        const scope = Object.values(SlotEditScope)[editOption];
        const option =
            scope === SlotEditScope.SINGLE
                ? SlotEditScope.SINGLE
                : scope === SlotEditScope.FOLLOWING
                ? SlotEditScope.FOLLOWING
                : SlotEditScope.ALL;
        handleSubmit(option);
    };

    return (
        <Stack direction="column" spacing={2} alignItems="stretch" justifyContent="center">
            {recurring ? (
                <Stack direction={'column'} spacing={1}>
                    <RadioButton
                        value={0}
                        label={t('editSlotModal:recurringSlotActionConfirmation.thisSlotOnly')}
                        onClick={setEditOption}
                        selected={editOption === 0}
                    />
                    <RadioButton
                        value={1}
                        label={t('editSlotModal:recurringSlotActionConfirmation.thisAndUpcoming')}
                        onClick={setEditOption}
                        selected={editOption === 1}
                    />
                    <RadioButton
                        value={2}
                        label={t('editSlotModal:recurringSlotActionConfirmation.all')}
                        onClick={setEditOption}
                        selected={editOption === 2}
                    />
                </Stack>
            ) : (
                <Typography>{t('editSlotModal:deleteSlotModalBody.message')}</Typography>
            )}
            <ButtonGroup spacing={1} variant="solid">
                <Button fullWidth color="danger" onClick={onSubmit} loading={isLoading}>
                    {t('buttons:button_delete')}
                </Button>
                <Button fullWidth onClick={handleCancel} color="primary">
                    {t('buttons:button_cancel')}
                </Button>
            </ButtonGroup>
        </Stack>
    );
};
