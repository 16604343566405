import React from 'react';
import { Divider, Tab, tabClasses, TabList, Tabs } from '@mui/joy';
import { SlotList } from '../SlotList';
import { useTranslatedDates } from '../../../shared/hooks/useTranslatedDates';
import { getScaledVh } from '../../../../utils/styleUtils';

type Props = {
    dayIndex: number;
    weekIndex: number;
    setDayIndex: (index: number) => void;
    filteredOutServices: string[];
    filteredOutSchedules: string[];
};
export const MobileLayout = ({
    dayIndex,
    weekIndex,
    setDayIndex,
    filteredOutServices,
    filteredOutSchedules,
}: Props) => {
    const { getWeek } = useTranslatedDates();

    return (
        <>
            <Divider />
            <Tabs
                sx={{
                    width: '100%',
                    height: getScaledVh(100, true),
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    background: 'var(--joy-palette-background-500)',
                }}
                variant="outlined"
                value={dayIndex}
                onChange={(event, value) => setDayIndex(value as number)}
            >
                <TabList
                    sticky="top"
                    sx={{
                        justifyContent: 'center',
                        [`&& .${tabClasses.root}`]: {
                            flex: 'initial',
                            bgcolor: 'transparent',
                            '&:hover': {
                                bgcolor: 'transparent',
                            },
                            [`&.${tabClasses.selected}`]: {
                                color: 'primary.plainColor',
                                '&::after': {
                                    height: 2,
                                    borderTopLeftRadius: 3,
                                    borderTopRightRadius: 3,
                                    bgcolor: 'primary.500',
                                },
                            },
                        },
                    }}
                >
                    {getWeek(false, true).map((day) => {
                        return <Tab key={day.key}>{day.value}</Tab>;
                    })}
                </TabList>
                <SlotList
                    dayIndex={dayIndex}
                    weekIndex={weekIndex}
                    filteredOutServices={filteredOutServices}
                    filteredOutSchedules={filteredOutSchedules}
                />
            </Tabs>
        </>
    );
};
