import { loginRoute } from 'components/shared/Routing/routes';
import { NavigateFunction } from 'react-router-dom';
import { clearAuthTokens } from './tokens';

let navigate: NavigateFunction | null = null;

export const setNavigate = (nav: NavigateFunction) => {
    navigate = nav;
};

export const navigateToLogin = () => {
    if (navigate) {
        clearAuthTokens();
        navigate(loginRoute);
    } else {
        console.error('Navigate function is not set');
    }
};
