import React, { useState } from 'react';
import { getScaledVh } from '../../utils/styleUtils';
import { Box, Button, Divider, Input, Sheet, Stack, Table, Typography } from '@mui/joy';
import { Check, FilterList, InfoOutlined, Search } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../shared/hooks/useWindowSize';
import { TableHeader } from '../shared/TypeUtils/TableHeader';
import { SearchParams, useErrorLogs } from './hooks/useErrorLogs';
import { dateToLocaleString } from '../../utils/stringUtils';
import { errorManagementRouteInfo } from '../shared/Routing/routeObjects';
import { DetailsModal } from './DetailsModal/DetailsModal';
import { MarkResolvedModal } from './MarkResolvedModal/MarkResolvedModal';
import { PaginationRow } from './Pagination/PaginationRow';
import moment from 'moment';
import { Filters } from './Filters/Filters';

export const ErrorManagement = () => {
    const [params, setParams] = useState<SearchParams>({
        current_page: 1,
        page_size: 9,
    });
    const { t } = useTranslation();
    const [isFilterDrawerOpen, toggleFilterDrawer] = useState(false);
    const { isLarge, isExtraSmall } = useWindowSize();
    const errorManagement = errorManagementRouteInfo(t);
    const tableHeaders: TableHeader[] = [
        { label: t('errorManagement:header_bill_id'), percentage: 10 },
        { label: t('errorManagement:header_description'), percentage: 40 },
        { label: t('errorManagement:header_comment'), percentage: 20 },
        { label: t('errorManagement:header_timestamp'), percentage: 10 },
        { label: t('errorManagement:header_resolved'), percentage: 5 },
        { label: t('adminPage:table_label_actions'), percentage: 5 },
    ];
    const middleRowMobileScaleMultiplier = 6;
    const getColumnWidth = (index: number, baseWidth: number) => {
        if (isLarge) return baseWidth;
        return baseWidth * middleRowMobileScaleMultiplier;
    };
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [acceptedSearchQuery, setAcceptedSearchQuery] = useState<string>('');
    const { errorLogList, metadata, refreshErrorLogs } = useErrorLogs(acceptedSearchQuery, params);
    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key !== 'Enter') return;
        setAcceptedSearchQuery(searchQuery);
    };

    return (
        <Stack
            display="flex"
            direction="column"
            alignItems="stretch"
            justifyContent="center"
            sx={{ width: 'calc(100% + 40px)' }}
            spacing={1}
            style={{ marginTop: 0, marginLeft: -20, marginRight: -20 }}
        >
            <div>
                <Stack
                    spacing={4}
                    direction={isExtraSmall ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: '100%',
                        paddingX: !isLarge ? '5px' : '20px',
                    }}
                >
                    {!isExtraSmall && (
                        <Typography minWidth={350} level="h1" startDecorator={errorManagement.icon}>
                            {errorManagement.name}
                        </Typography>
                    )}
                    <Stack direction="row" width="100%" alignItems="center" justifyContent="stretch" spacing={1}>
                        <Input
                            startDecorator={<Search />}
                            placeholder={t('appointmentList:placeholder_search')}
                            style={{ width: '100%', height: 'fit-content' }}
                            fullWidth
                            value={searchQuery}
                            onKeyPress={handleEnterKeyPress}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <Button
                            variant="outlined"
                            color="neutral"
                            startDecorator={<FilterList />}
                            onClick={() => toggleFilterDrawer(true)}
                        >
                            {t('buttons:button_filter')}
                        </Button>
                    </Stack>
                </Stack>
            </div>
            <Sheet
                variant="outlined"
                sx={{
                    '--TableCell-height': '40px',
                    '--TableHeader-height': 'calc(1 * var(--TableCell-height))',
                    '--Table-firstColumnWidth': '80px',
                    '--Table-lastColumnWidth': '144px',
                    maxHeight: getScaledVh(92, !isLarge),
                    overflow: 'auto',
                    width: '100%',
                    backgroundSize:
                        '40px calc(100% - var(--TableCell-height)), 40px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height)), 14px calc(100% - var(--TableCell-height))',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'local, local, scroll, scroll',
                    backgroundPosition:
                        'var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height), var(--Table-firstColumnWidth) var(--TableCell-height), calc(100% - var(--Table-lastColumnWidth)) var(--TableCell-height)',
                }}
            >
                <Table
                    stickyHeader
                    borderAxis="bothBetween"
                    style={{ maxHeight: '100%', overflowY: 'auto' }}
                    sx={{
                        '& tr > *:first-of-type': {
                            position: 'sticky',
                            left: 0,
                            bgcolor: 'background.surface',
                        },
                        '& tr > *:last-of-type': {
                            position: 'sticky',
                            right: 0,
                            bgcolor: 'var(--TableCell-headBackground)',
                        },
                    }}
                >
                    <thead>
                        <tr>
                            {tableHeaders.map((element, index) => (
                                <th
                                    key={index}
                                    style={{
                                        textAlign: 'center',
                                        width: `${getColumnWidth(index, element.percentage)}px`,
                                        zIndex: index === 0 ? 2 : 1,
                                    }}
                                    aria-label={index === tableHeaders.length - 1 ? 'last' : undefined}
                                >
                                    {element.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {errorLogList.map((element, index) => (
                            <tr key={index}>
                                <td style={{ textAlign: 'center', zIndex: 0 }}>{element.id_bill}</td>
                                <td style={{ zIndex: -1 }}>{element.error_description}</td>
                                <td style={{ textAlign: 'center', zIndex: -1 }}>{element.comment}</td>
                                <td style={{ textAlign: 'center', zIndex: -1 }}>
                                    <Stack direction="column">
                                        <Typography>
                                            <span>{dateToLocaleString(element.timestamp)}</span>
                                        </Typography>
                                        <Typography>
                                            <span>{moment(element.timestamp).format('HH:mm')}</span>{' '}
                                            <span>{moment(element.timestamp).format('dddd')}</span>
                                        </Typography>
                                    </Stack>
                                </td>
                                <td style={{ textAlign: 'center', zIndex: 0 }}>{element.is_resolved && <Check />}</td>
                                <td style={{ textAlign: 'center', zIndex: 0 }}>
                                    {
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <DetailsModal errorLog={element} />
                                            <MarkResolvedModal
                                                isResolved={element.is_resolved}
                                                refreshList={refreshErrorLogs}
                                                errorLogId={element.id}
                                            />
                                        </Stack>
                                    }
                                </td>
                            </tr>
                        ))}
                        <PaginationRow
                            colSpan={tableHeaders.length}
                            metadata={metadata}
                            params={params}
                            setParams={setParams}
                        />
                    </tbody>
                </Table>
            </Sheet>
            <Filters
                toggleFilterDrawer={toggleFilterDrawer}
                setParams={setParams}
                isFilterDrawerOpen={isFilterDrawerOpen}
                currentParams={params}
            />
        </Stack>
    );
};
