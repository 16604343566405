import {
    AppointmentModel,
    Invoice,
    InvoiceItem,
    InvoiceItemRequestData,
    InvoiceRequestData,
    InvoiceRequestDoctorData,
    InvoiceRequestPatientData,
} from '../../../../api/models';

export const mapInvoiceRequestDataToInvoice = (requestData: InvoiceRequestData): Invoice => {
    const items: InvoiceItem[] = [];
    requestData.items.map((item) => {
        items.push({
            factor: item.factor,
            factor_is_constant: false,
            name: item.description,
            number: item.number,
            price: item.price_paid,
            vat: item.vat,
        });
    });

    const invoice: Invoice = {
        diagnosis: requestData.diagnosis,
        doctorAddress: requestData.doctor.city,
        doctorAddress2: requestData.doctor.street,
        doctorBankNr: requestData.doctor.bank_number,
        doctorEmail: requestData.doctor.email,
        doctorFax: requestData.doctor.fax_number,
        doctorSurname: requestData.doctor.surname,
        doctorTitle: requestData.doctor.personal_title,
        doctorName: requestData.doctor.name,
        doctorTel: requestData.doctor.phone_number,
        doctorVatID: requestData.doctor.vat_id || '',
        invoiceDate: new Date(requestData.invoiceDate),
        invoiceDueDate: new Date(requestData.invoiceDueDate),
        invoiceNr: requestData.invoiceNr,
        patientAddress: requestData.patient.city,
        patientAddress2: requestData.patient.street,
        patientName: requestData.patient.name,
        patientSurname: requestData.patient.surname,
        patientTitle: requestData.patient.personal_title || '',
        items: items,
        doctorPostalCode: requestData.doctor.post_code,
        patientPostalCode: requestData.patient.post_code,
        bank_name: requestData.bank_name,
    };
    return invoice;
};

export const mapInvoiceToInvoiceRequestData = (
    invoice: Invoice,
    appointment: AppointmentModel,
    idUser: number,
): InvoiceRequestData => {
    const invoiceItems: InvoiceItemRequestData[] = [];
    invoice.items?.map((item) => {
        invoiceItems.push({
            date_of_service: invoice.invoiceDate,
            description: item.name,
            factor: item.factor,
            id: 0,
            id_bill: 0,
            number: item.number,
            price_paid: item.price,
            vat: item.vat,
        });
    });
    const doctor: InvoiceRequestDoctorData = {
        bank_number: invoice.doctorBankNr,
        city: invoice.doctorAddress,
        email: invoice.doctorEmail,
        fax_number: invoice.doctorFax,
        id_medrefer: idUser,
        name: invoice.doctorName,
        personal_title: invoice.doctorTitle,
        phone_number: invoice.doctorTel,
        post_code: invoice.doctorPostalCode,
        street: invoice.doctorAddress2,
        surname: invoice.doctorSurname,
        vat_id: invoice.doctorVatID,
    };
    const patient: InvoiceRequestPatientData = {
        city: invoice.patientAddress,
        email: appointment.patient?.email || '',
        id_medrefer: appointment.patient?.id || 0,
        name: invoice.patientName,
        post_code: invoice.patientPostalCode,
        street: invoice.patientAddress2,
        surname: invoice.patientSurname,
        personal_title: invoice.patientTitle,
    };
    const invoiceRequestData: InvoiceRequestData = {
        idBill: 0,
        bill_number: invoice.invoiceNr,
        diagnosis: invoice.diagnosis,
        doctor: doctor,
        idMedreferPatient: appointment.patient?.id || 0,
        id_appointment: appointment.id,
        invoiceDate: invoice.invoiceDate,
        invoiceDueDate: invoice.invoiceDueDate,
        invoiceNr: invoice.invoiceNr,
        items: invoiceItems,
        legal_note: '',
        patient: patient,
        bank_name: invoice.bank_name,
        total_price: invoiceItems.reduce((acc, item) => {
            return acc + item.price_paid * item.factor;
        }, 0),
    };
    return invoiceRequestData;
};
