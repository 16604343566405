import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { api } from 'api';
import { BookingStatus } from '../StatusBadge/StatusBadge';
import { SortTypes } from 'api/models';
import { useErrorContext } from 'context/errorContext';
import { dashboardQueryKey } from './dashboard.query-keys';

export const useTodaysAppointments = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [limit, setLimit] = useState(6);
    const { error, setError } = useErrorContext();

    const todayStart = new Date();
    todayStart.setHours(todayStart.getHours() - 1);

    const todayEnd = new Date(todayStart);
    todayEnd.setHours(23, 59, 59, 999);

    const getTodaysAppointments = async () => {
        try {
            const requestAppointments = await api.getAppointments({
                dateFrom: todayStart,
                dateTo: todayEnd,
                limit: limit,
                sort: SortTypes.dateAsc,
                statuses: [BookingStatus.upcoming, BookingStatus.past],
            });
            return requestAppointments;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            } else {
                setError({ message: "Failed to fetch today's appointments" });
            }
            throw error;
        }
    };

    const { data, isLoading, isError } = useQuery({
        queryFn: async () => getTodaysAppointments(),
        queryKey: [dashboardQueryKey.todaysAppointments(), currentPage],
        staleTime: 1 * 60 * 1000,
        retry: 3,
        refetchInterval: 1 * 60 * 1000,
        keepPreviousData: true,
    });

    const { todaysAppointments, nextData } = useMemo(() => {
        const todaysAppointments = data
            ? data.appointments.sort((a, b) => moment(a.date_from).valueOf() - moment(b.date_from).valueOf())
            : [];
        const metadata = data ? data.metadata : [];
        const nextData = data ? data.next : false;
        return { todaysAppointments, metadata, nextData };
    }, [data]);

    const fetchNextPage = useCallback(() => {
        if (nextData) {
            setCurrentPage((prev) => prev + 1);
            setLimit((prev) => prev + 5);
        }
    }, [nextData]);

    return {
        todaysAppointments,
        isLoading,
        isError,
        error,
        fetchNextPage,
        nextData,
    };
};
