import React, { Fragment } from 'react';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { EditDoctorProps, EditDoctorValues } from './EditDoctor.types';
import { FormikProps } from 'formik';
import IconButton from '@mui/joy/IconButton';
import { EditOutlined } from '@mui/icons-material';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { Button, FormLabel, Grid, Input, Stack, Textarea } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { FormControlStyled } from '../../shared/Form/FormControlStyled';
import { PhoneAdapter } from '../../shared/adapters/PhoneAdapter';
import { PostalCodeAdapter } from '../../shared/adapters/PostalCodeAdapter';
import { VatMaskAdapter } from '../../shared/adapters/VatMaskAdapter';
import { BankMaskAdapter } from '../../shared/adapters/BankMaskAdapter';
import { NumericFormatAdapter } from '../../shared/adapters/NumericFormatAdapter';
import { FormErrorMessage } from '../../shared/FormErrorMessage/FormErrorMessage';
import AttachmentInput from '../../shared/AttachmentInput/AttachmentInput';

export const EditDoctorModal = (baseProps: EditDoctorProps & FormikProps<EditDoctorValues>) => {
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();

    return (
        <BetterModal
            minWidth={500}
            layout={isExtraSmall ? 'fullscreen' : 'center'}
            state={baseProps.modalState}
            title={'Edit doctor profile'}
            button={
                <IconButton variant="outlined" color="neutral">
                    <EditOutlined />
                </IconButton>
            }
            actions={
                <Stack direction="column" spacing={1} width="100%">
                    <FormErrorMessage onlyTouched={false} />
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            baseProps.handleSubmit();
                        }}
                        loading={baseProps.request.isLoading}
                    >
                        {t('buttons:button_submit')}
                    </Button>
                </Stack>
            }
            body={
                <>
                    <Stack
                        direction="column"
                        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                        spacing={2}
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: isExtraSmall ? 'calc(100% - 40px)' : '100%',
                        }}
                    >
                        <Grid spacing={{ xs: 0, md: 1 }} maxWidth="100%" container rowSpacing={1}>
                            <Grid
                                height="min-content"
                                xs={12}
                                md={6}
                                spacing={1}
                                container
                                width="100%"
                                sx={{ flexGrow: 1 }}
                            >
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.name && baseProps.touched.name)}
                                        isTouched={baseProps.touched.name}
                                    >
                                        <FormLabel required>{t('invoice:invoice_name')}</FormLabel>
                                        <Input
                                            id="name"
                                            name="name"
                                            value={baseProps.values.name}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.surname && baseProps.touched.surname)}
                                        isTouched={baseProps.touched.surname}
                                    >
                                        <FormLabel required>{t('profile:surname')}</FormLabel>
                                        <Input
                                            id="surname"
                                            name="surname"
                                            value={baseProps.values.surname}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={
                                            !!(baseProps.errors.personal_title && baseProps.touched.personal_title)
                                        }
                                        isTouched={baseProps.touched.personal_title}
                                    >
                                        <FormLabel>{t('profile:personal_title')}</FormLabel>
                                        <Input
                                            id="personal_title"
                                            name="personal_title"
                                            value={baseProps.values.personal_title}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.phone_number && baseProps.touched.phone_number)}
                                        isTouched={baseProps.touched.phone_number}
                                    >
                                        <FormLabel required>{t('profile:phone')}</FormLabel>
                                        <Input
                                            id="phone_number"
                                            name="phone_number"
                                            value={baseProps.values.phone_number}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                            slotProps={{
                                                input: {
                                                    component: PhoneAdapter,
                                                    maxLength: 17,
                                                },
                                            }}
                                        />
                                    </FormControlStyled>
                                </Grid>
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                                spacing={1}
                                container
                                width="100%"
                                sx={{ flexGrow: 1 }}
                                height="min-content"
                            >
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.city && baseProps.touched.city)}
                                        isTouched={baseProps.touched.city}
                                    >
                                        <FormLabel required>{t('invoice:invoice_town')}</FormLabel>
                                        <Input
                                            id="city"
                                            name="city"
                                            value={baseProps.values.city}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.street && baseProps.touched.street)}
                                        isTouched={baseProps.touched.street}
                                    >
                                        <FormLabel required>{t('invoice:invoice_address')}</FormLabel>
                                        <Input
                                            id="street"
                                            name="street"
                                            value={baseProps.values.street}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.post_code && baseProps.touched.post_code)}
                                        isTouched={baseProps.touched.post_code}
                                    >
                                        <FormLabel required>{t('invoice:invoice_postal_code')}</FormLabel>
                                        <Input
                                            style={{ width: '60%' }}
                                            id="post_code"
                                            name="post_code"
                                            value={baseProps.values.post_code}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                            slotProps={{
                                                input: {
                                                    component: PostalCodeAdapter,
                                                    maxLength: 5,
                                                },
                                            }}
                                        />
                                    </FormControlStyled>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Stack direction="column" spacing={1}>
                            <FormControlStyled
                                isError={!!(baseProps.errors.image && baseProps.touched.image)}
                                isTouched={baseProps.touched.image}
                            >
                                <FormLabel>{t('profile:image')}</FormLabel>
                                <AttachmentInput
                                    setFieldValue={baseProps.setFieldValue}
                                    value={baseProps.values.image}
                                    name={'image'}
                                />
                            </FormControlStyled>
                            <FormControlStyled
                                isError={!!(baseProps.errors.description && baseProps.touched.description)}
                                isTouched={baseProps.touched.description}
                            >
                                <FormLabel>{t('profile:description')}</FormLabel>
                                <Textarea
                                    minRows={3}
                                    id="description"
                                    name="description"
                                    onBlur={baseProps.handleBlur}
                                    value={baseProps.values.description}
                                    onChange={baseProps.handleChange}
                                />
                            </FormControlStyled>
                            <FormControlStyled
                                isError={!!(baseProps.errors.vat_id && baseProps.touched.vat_id)}
                                isTouched={baseProps.touched.vat_id}
                            >
                                <FormLabel>{t('invoice:invoice_vat_id')}</FormLabel>
                                <Input
                                    id="vat_id"
                                    name="vat_id"
                                    slotProps={{
                                        input: {
                                            component: VatMaskAdapter,
                                        },
                                    }}
                                    onBlur={baseProps.handleBlur}
                                    value={baseProps.values.vat_id}
                                    onChange={baseProps.handleChange}
                                />
                            </FormControlStyled>
                            <FormControlStyled
                                isError={!!(baseProps.errors.bank_number && baseProps.touched.bank_number)}
                                isTouched={baseProps.touched.bank_number}
                            >
                                <FormLabel required>{t('invoice:invoice_bank_account')}</FormLabel>
                                <Input
                                    id="bank_number"
                                    name="bank_number"
                                    slotProps={{
                                        input: {
                                            component: BankMaskAdapter,
                                        },
                                    }}
                                    value={baseProps.values.bank_number}
                                    onBlur={baseProps.handleBlur}
                                    onChange={baseProps.handleChange}
                                    defaultValue="AA 000000000000000000000"
                                />
                            </FormControlStyled>
                            <FormControlStyled
                                isError={!!(baseProps.errors.fax_number && baseProps.touched.fax_number)}
                                isTouched={baseProps.touched.fax_number}
                            >
                                <FormLabel>{t('invoice:invoice_fax')}</FormLabel>
                                <Input
                                    id="fax_number"
                                    name="fax_number"
                                    slotProps={{
                                        input: {
                                            component: NumericFormatAdapter,
                                            maxLength: 12,
                                        },
                                    }}
                                    value={baseProps.values.fax_number}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Stack>
                    </Stack>
                </>
            }
        />
    );
};
