import React, { useEffect, useState } from 'react';
import { Chip, Stack, Tab, tabClasses, TabList, TabPanel, Tabs } from '@mui/joy';
import { AppointmentDetailsTab } from '../AppointmentDetailsTab/AppointmentDetailsTab';
import { VideoCallTab } from '../VideoCallTab/VideoCallTab';
import { BillingTab } from '../BillingTab/BillingTab';
import { useTranslation } from 'react-i18next';
import { AppointmentModel } from '../../../../api/models';
import { AttachmentTab } from '../AttachmentTab/AttachmentTab';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';

type Props = {
    appointment: AppointmentModel;
    reloadAppointments: () => void;
    isMobile: boolean;
    dashboardHeight?: string;
};
export const DetailsTabs = ({ appointment, isMobile, reloadAppointments, dashboardHeight }: Props) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<string | number | null>('details');
    const { isExtraSmall, isMedium } = useWindowSize();

    const isSmallOrMedium = isExtraSmall || isMedium;

    useEffect(() => {
        setValue('details');
    }, [appointment]);

    return (
        <Tabs
            sx={{
                maxWidth: '100%',
                width: '100%',
                borderRadius: '7px',
                maxHeight: !!dashboardHeight ? dashboardHeight : '90vh',
                overflowY: !isMobile ? '' : 'auto',
                background: 'var(--joy-palette-modalBackground)',
            }}
            defaultValue={value}
            variant={isMobile ? 'plain' : 'soft'}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
        >
            <TabList
                variant={isMobile ? 'plain' : 'soft'}
                sx={{
                    background: 'var(--joy-palette-modalBackground)',
                    justifyContent: 'center',
                    borderRadius: '4px 4px 0 0',
                    [`&& .${tabClasses.root}`]: {
                        flex: 'initial',
                        bgcolor: 'transparent',
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        [`&.${tabClasses.selected}`]: {
                            color: 'primary.plainColor',
                            '&::after': {
                                height: 2,
                                borderTopLeftRadius: 3,
                                borderTopRightRadius: 3,
                                bgcolor: 'primary.500',
                            },
                        },
                    },
                }}
            >
                <Tab value="details" style={{ fontWeight: 'bold' }}>
                    {t('appointmentDetails:label_details')}
                </Tab>
                <Tab value="videoCall" style={{ fontWeight: 'bold' }}>
                    {t('appointmentDetails:label_video_call')}
                </Tab>
                <Tab value="billing" style={{ fontWeight: 'bold' }}>
                    {t('appointmentDetails:label_billings')}
                </Tab>
                {appointment.attachments.length > 0 && (
                    <Tab value="attachment" style={{ fontWeight: 'bold' }}>
                        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center">
                            <div>{t('appointmentDetails:label_attachments')}</div>
                            <Chip size="sm" variant="solid" color={value === 'attachment' ? 'primary' : 'neutral'}>
                                {appointment.attachments.length}
                            </Chip>
                        </Stack>
                    </Tab>
                )}
            </TabList>

            <TabPanel value="details">
                <AppointmentDetailsTab appointment={appointment} reloadAppointments={reloadAppointments} />
            </TabPanel>
            <TabPanel value="videoCall">
                <VideoCallTab appointment={appointment} />
            </TabPanel>
            <TabPanel value="billing">
                <BillingTab appointment={appointment} />
            </TabPanel>
            <TabPanel value="attachment">
                <AttachmentTab appointment={appointment} />
            </TabPanel>
        </Tabs>
    );
};
