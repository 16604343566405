import React from 'react';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { usePasswordVisibility } from '../../shared/Form/hooks/usePasswordVisibility';
import { Button, Checkbox, Divider, FormLabel, Grid, Input, Link, Stack, Typography } from '@mui/joy';
import { ReactSVG } from 'react-svg';
import { FormControlStyled } from '../../shared/Form/FormControlStyled';
import { PhoneAdapter } from '../../shared/adapters/PhoneAdapter';
import { PostalCodeAdapter } from '../../shared/adapters/PostalCodeAdapter';
import { VatMaskAdapter } from '../../shared/adapters/VatMaskAdapter';
import { BankMaskAdapter } from '../../shared/adapters/BankMaskAdapter';
import { NumericFormatAdapter } from '../../shared/adapters/NumericFormatAdapter';
import { privatePolicyLink, termsAndConditionsLink } from '../../../klaro/klaroConstants';
import { InvitationRegisterFormProps, InvitationRegisterFormValues } from './InvitationRegister.types';
import { FormikProps } from 'formik';
import LoginIllustration from '../../../Assets/LoginIllustration.svg';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { FormErrorMessage } from '../../shared/FormErrorMessage/FormErrorMessage';
import { StringParam, useQueryParam } from 'use-query-params';

export const InvitationRegisterData = (
    baseProps: InvitationRegisterFormProps & FormikProps<InvitationRegisterFormValues>,
) => {
    const { isLarge, isExtraSmall } = useWindowSize();
    const { t } = useTranslation();
    const { eyeIconButton, isPasswordVisible } = usePasswordVisibility();

    return (
        <Form
            style={{ height: '100%', width: '100%' }}
            onSubmit={(e) => {
                e.preventDefault();
                baseProps.handleSubmit();
            }}
        >
            <Grid container height="100%" width="100%">
                {isLarge && (
                    <Grid
                        md={7}
                        sx={{
                            background: 'var(--joy-palette-background-level2)',
                            height: 'calc(100% + 20px)',
                        }}
                        style={{ marginLeft: -20, marginTop: -20 }}
                    >
                        <ReactSVG
                            style={{
                                marginBottom: 0,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                scale: '2em',
                            }}
                            src={LoginIllustration}
                        />
                    </Grid>
                )}
                <Grid
                    container
                    md={isLarge ? 5 : 12}
                    paddingX={2}
                    height="fit-content"
                    alignItems="stretch"
                    width="100%"
                    justifyContent={isLarge ? 'flex-start' : 'center'}
                >
                    <Stack
                        direction="column"
                        sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                        spacing={2}
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: isExtraSmall ? 'calc(100% - 40px)' : '100%',
                        }}
                    >
                        <Typography level="h1">
                            {t(`invitationRegister:title_${isExtraSmall ? 'mobile' : ''}desktop`)}
                        </Typography>
                        <Divider
                            className="w-100 mb-3"
                            sx={{ backgroundColor: 'var(--joy-palette-background-tooltip)' }}
                        />
                        <Grid spacing={{ xs: 0, md: 1 }} maxWidth="100%" container rowSpacing={1}>
                            <Grid
                                height="min-content"
                                xs={12}
                                md={6}
                                spacing={1}
                                container
                                width="100%"
                                sx={{ flexGrow: 1 }}
                            >
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={
                                            !!(baseProps.errors.personal_title && baseProps.touched.personal_title)
                                        }
                                        isTouched={baseProps.touched.personal_title}
                                    >
                                        <FormLabel>{t('profile:personal_title')}</FormLabel>
                                        <Input
                                            id="personal_title"
                                            name="personal_title"
                                            value={baseProps.values.personal_title}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.name && baseProps.touched.name)}
                                        isTouched={baseProps.touched.name}
                                    >
                                        <FormLabel required>{t('invoice:invoice_name')}</FormLabel>
                                        <Input
                                            id="name"
                                            name="name"
                                            value={baseProps.values.name}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.surname && baseProps.touched.surname)}
                                        isTouched={baseProps.touched.surname}
                                    >
                                        <FormLabel required>{t('profile:surname')}</FormLabel>
                                        <Input
                                            id="surname"
                                            name="surname"
                                            value={baseProps.values.surname}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.password && baseProps.touched.password)}
                                        isTouched={baseProps.touched.password}
                                    >
                                        <FormLabel required>{t('login:password_label')}</FormLabel>
                                        <Input
                                            data-testid="password"
                                            id="password"
                                            name="password"
                                            type={isPasswordVisible ? 'text' : 'password'}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                            value={baseProps.values.password}
                                            startDecorator={eyeIconButton}
                                            autoFocus
                                        />
                                    </FormControlStyled>
                                </Grid>
                            </Grid>
                            <Grid
                                xs={12}
                                md={6}
                                spacing={1}
                                container
                                width="100%"
                                sx={{ flexGrow: 1 }}
                                height="min-content"
                            >
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.phone_number && baseProps.touched.phone_number)}
                                        isTouched={baseProps.touched.phone_number}
                                    >
                                        <FormLabel required>{t('profile:phone')}</FormLabel>
                                        <Input
                                            id="phone_number"
                                            name="phone_number"
                                            value={baseProps.values.phone_number}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                            slotProps={{
                                                input: {
                                                    component: PhoneAdapter,
                                                    maxLength: 18,
                                                },
                                            }}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.city && baseProps.touched.city)}
                                        isTouched={baseProps.touched.city}
                                    >
                                        <FormLabel required>{t('invoice:invoice_town')}</FormLabel>
                                        <Input
                                            id="city"
                                            name="city"
                                            value={baseProps.values.city}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.street && baseProps.touched.street)}
                                        isTouched={baseProps.touched.street}
                                    >
                                        <FormLabel required>{t('invoice:invoice_address')}</FormLabel>
                                        <Input
                                            id="street"
                                            name="street"
                                            value={baseProps.values.street}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </FormControlStyled>
                                </Grid>
                                <Grid width="100%">
                                    <FormControlStyled
                                        isError={!!(baseProps.errors.post_code && baseProps.touched.post_code)}
                                        isTouched={baseProps.touched.post_code}
                                    >
                                        <FormLabel required>{t('invoice:invoice_postal_code')}</FormLabel>
                                        <Input
                                            style={{ width: '60%' }}
                                            id="post_code"
                                            name="post_code"
                                            value={baseProps.values.post_code}
                                            onChange={baseProps.handleChange}
                                            onBlur={baseProps.handleBlur}
                                            slotProps={{
                                                input: {
                                                    component: PostalCodeAdapter,
                                                    maxLength: 5,
                                                },
                                            }}
                                        />
                                    </FormControlStyled>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Stack direction="column" spacing={1}>
                            <FormControlStyled
                                isError={!!(baseProps.errors.vat_id && baseProps.touched.vat_id)}
                                isTouched={baseProps.touched.vat_id}
                            >
                                <FormLabel>{t('invoice:invoice_vat_id')}</FormLabel>
                                <Input
                                    id="vat_id"
                                    name="vat_id"
                                    slotProps={{
                                        input: {
                                            component: VatMaskAdapter,
                                        },
                                    }}
                                    onBlur={baseProps.handleBlur}
                                    value={baseProps.values.vat_id}
                                    onChange={baseProps.handleChange}
                                />
                            </FormControlStyled>
                            <FormControlStyled
                                isError={!!(baseProps.errors.bank_number && baseProps.touched.bank_number)}
                                isTouched={baseProps.touched.bank_number}
                            >
                                <FormLabel required>{t('invoice:invoice_bank_account')}</FormLabel>
                                <Input
                                    id="bank_number"
                                    name="bank_number"
                                    slotProps={{
                                        input: {
                                            component: BankMaskAdapter,
                                        },
                                    }}
                                    value={baseProps.values.bank_number}
                                    onBlur={baseProps.handleBlur}
                                    onChange={baseProps.handleChange}
                                    placeholder="AA 000000000000000000000"
                                />
                            </FormControlStyled>
                            <FormControlStyled
                                isError={!!(baseProps.errors.fax_number && baseProps.touched.fax_number)}
                                isTouched={baseProps.touched.fax_number}
                            >
                                <FormLabel>{t('invoice:invoice_fax')}</FormLabel>
                                <Input
                                    id="fax_number"
                                    name="fax_number"
                                    slotProps={{
                                        input: {
                                            component: NumericFormatAdapter,
                                            maxLength: 12,
                                        },
                                    }}
                                    value={baseProps.values.fax_number}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Stack>
                        <FormControlStyled
                            isError={!!(baseProps.errors.privacy_policy && baseProps.touched.privacy_policy)}
                            isTouched={baseProps.touched.privacy_policy}
                        >
                            <Stack direction="row" spacing={1}>
                                <Checkbox
                                    required
                                    aria-required
                                    checked={baseProps.values.privacy_policy}
                                    name="privacy_policy"
                                    id="privacy_policy"
                                    onClick={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                                <FormLabel required>
                                    <div style={{ fontSize: 14 }}>{t('profile:data_privacy_message')}</div>{' '}
                                    <Link
                                        style={{ fontSize: 14, marginBottom: -1 }}
                                        onClick={() => window.open(privatePolicyLink)}
                                    >
                                        {t('profile:data_privacy_notice')}
                                    </Link>
                                </FormLabel>
                            </Stack>
                        </FormControlStyled>
                        <FormControlStyled
                            isError={
                                !!(baseProps.errors.terms_and_conditions && baseProps.touched.terms_and_conditions)
                            }
                            isTouched={baseProps.touched.terms_and_conditions}
                        >
                            <Stack direction="row" spacing={1}>
                                <Checkbox
                                    required
                                    aria-required
                                    checked={baseProps.values.terms_and_conditions}
                                    name="terms_and_conditions"
                                    id="terms_and_conditions"
                                    onClick={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                                <FormLabel required>
                                    <div style={{ fontSize: 14 }}>{t('profile:terms_and_conditions_message')} </div>{' '}
                                    <Link
                                        style={{ fontSize: 14, marginBottom: -1 }}
                                        onClick={() => window.open(termsAndConditionsLink)}
                                    >
                                        {t('profile:terms_and_conditions_notice')}
                                    </Link>
                                </FormLabel>
                            </Stack>
                        </FormControlStyled>
                        <FormErrorMessage onlyTouched={false} />
                        <Button loading={baseProps.request.isLoading} style={{ marginBottom: '10px' }} type="submit">
                            {t('buttons:button_register')}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Form>
    );
};
