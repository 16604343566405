import { useEffect, useState } from 'react';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';
import { useNavigate, useParams } from 'react-router-dom';
import { appointmentListRoute } from '../components/shared/Routing/routes';
import { NumberParam, useQueryParam } from 'use-query-params';

type VideoCallStatus = 'jitsiLoading' | 'waitingRoom' | 'infoScreen' | 'ongoing' | 'callEnded';

export const useVideoCall = () => {
    const [jitsiApi, setJitsiApi] = useState<IJitsiMeetExternalApi | null>(null);
    const [videoCallStatus, setVideoCallStatus] = useState<VideoCallStatus>('jitsiLoading');
    const [appointmentId, setAppointmentId] = useQueryParam('appId', NumberParam);
    const navigate = useNavigate();
    useEffect(() => {
        if (jitsiApi) {
            jitsiApi.executeCommand('toggleTileView');

            jitsiApi.addListener(`tileViewChanged`, ({ enabled }: { enabled: boolean }) => {
                if (!enabled) {
                    jitsiApi.executeCommand('toggleTileView');
                }
            });

            jitsiApi.addListener('errorOccurred', () => {
                setVideoCallStatus('infoScreen');
            });

            jitsiApi.addListener(`videoConferenceJoined`, () => {
                setVideoCallStatus('ongoing');
            });

            jitsiApi.addListener(`participantJoined`, () => {
                setVideoCallStatus('ongoing');
            });

            jitsiApi.addListener('readyToClose', () => {
                setVideoCallStatus('callEnded');
                navigate({ pathname: appointmentListRoute, search: '?appId=' + appointmentId });
            });
        }
    }, [jitsiApi]);

    const jitsiConfig = {
        disableProfile: false,
        hideEmailInSettings: true,
        prejoinConfig: { enabled: false },
        toolbarButtons: ['settings', 'hangup', 'microphone', 'camera', 'fullscreen', 'chat'],
        connectionIndicators: { disableDetails: true },
        hideConferenceSubject: true,
        hideConferenceTimer: true,
        startWithVideoMuted: true,
        readOnlyName: true,
        disablePolls: true,
        deeplinking: {
            disabled: true,
        },
        disableSelfViewSettings: true,
        remoteVideoMenu: {
            disableKick: true,
            disableGrantModerator: true,
            disableRemoteMute: true,
        },
    };

    const jitsiInterfaceConfig = {
        LANG_DETECTION: true,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        MAXIMUM_ZOOMING_COEFFICIENT: 1,
        SETTINGS_SECTIONS: ['devices'],
        TOOLBAR_ALWAYS_VISIBLE: true,
    };

    return { jitsiConfig, jitsiInterfaceConfig, videoCallStatus, setJitsiApi };
};
