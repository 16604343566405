import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { Alert } from '@mui/joy';
import { useTranslation } from 'react-i18next';

type Props = {
    onlyTouched?: boolean;
};

export const FormErrorMessage = ({ onlyTouched = true }: Props) => {
    const { t } = useTranslation();
    const { touched, errors } = useFormikContext();
    const message = useMemo(() => {
        let retValue: string | null = null;
        const formErrors = Object.keys(errors) as Array<keyof typeof errors>;
        formErrors.forEach((key) => {
            const touchedKey =
                typeof touched[key] === 'object' ? touched[key][Object.keys(touched[key])[-1]] : touched[key];

            if (!onlyTouched || (touchedKey === true && !retValue)) {
                retValue = typeof errors[key] === 'object' ? errors[key][Object.keys(errors[key])[-1]] : errors[key];
            }
        });
        return retValue;
    }, [touched, errors, onlyTouched]);

    if (!message) return null;

    return (
        <Alert color="danger" variant="soft">
            {message}
        </Alert>
    );
};
