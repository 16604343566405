import React, { useState } from 'react';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { useModalState } from '../../shared/BetterModal/useModalState';
import { WarningOutlined } from '@mui/icons-material';
import { Button, ButtonGroup, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../AuthContext/AuthContext';

type Props = {
    isOpen: boolean;
    closeModal: () => void;
};
export const LogoutModal = ({ isOpen, closeModal }: Props) => {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleAgreeButton = () => {
        setIsLoading(true);
        authContext?.logout();
        closeModal();
    };

    return (
        <BetterModal
            state={{ toggleModal: closeModal, isOpen: isOpen }}
            title={
                <Typography startDecorator={<WarningOutlined />} level="h3">
                    {t('login:logout_modal_title')}
                </Typography>
            }
            body={<div>{t('login:logout_message')}</div>}
            actions={
                <ButtonGroup variant="solid" spacing={1} style={{ width: '100%' }}>
                    <Button fullWidth loading={isLoading} color="danger" onClick={handleAgreeButton}>
                        {t('basics:yes')}
                    </Button>
                    <Button fullWidth sx={{ width: '100%' }} onClick={closeModal} color="primary">
                        {t('basics:no')}
                    </Button>
                </ButtonGroup>
            }
            minWidth={400}
        />
    );
};
