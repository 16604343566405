import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { checkAuthentication } from '../actions';
import { useLoading } from '../../shared/LoadingPage/useLoading';
import { useAuthContext } from '../AuthContext/AuthContext';
import { useModalState } from '../../shared/BetterModal/useModalState';
import MissingInfoForm from '../../AccountDetails/MissingInfoModal/MissingInfoForm';
import { MissingInfoValues } from '../../AccountDetails/MissingInfoModal/MissingInfo.types';
import { useDoctor } from '../../../hooks/useDoctor';
import { UserRole } from '../../../api/models';
import axios from 'axios';
import { useErrorContext } from 'context/errorContext';
import { LoadingContext } from 'components/shared/LoadingPage/LoadingContext';
import { BetterAlert } from '../../shared/BetterAlert/BetterAlert';
import { useTranslation } from 'react-i18next';

type Props = {
    children: ReactNode;
};
export const AuthPending = ({ children }: Props) => {
    const [isAuthPending, setIsAuthPending] = useState(true);
    const authContext = useAuthContext();
    const loadingContext = useContext(LoadingContext);
    const modalState = useModalState();
    const { isValid, request, updateDoctor, getDoctor } = useDoctor();
    const [updatedDoctor, setUpdatedDoctor] = useState(false);
    const { setError } = useErrorContext();
    const successAlertState = useModalState();
    const { t } = useTranslation();

    useEffect(() => {
        const doAuthentication = async () => {
            try {
                await checkAuthentication();
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    setError({ message: e.message, status: e.code });
                } else {
                    setError({ message: 'An unexpected error occurred' });
                }
            } finally {
                setIsAuthPending(false);
            }
        };

        doAuthentication();
    }, []);

    useEffect(() => {
        if (
            !authContext?.authUser ||
            !authContext.isLoggedIn ||
            !authContext.authUser.doctor ||
            authContext.authUser.role !== UserRole.organizationMember ||
            updatedDoctor
        )
            return;

        if (!modalState.isOpen && !isValid(authContext.authUser.doctor)) {
            modalState.toggleModal();
        }
    }, [authContext]);

    const handleMissingData = async (values: MissingInfoValues) => {
        try {
            const payload = { ...values, images: values.image || '' };

            await updateDoctor(payload);
            await getDoctor();

            if (modalState.isOpen) {
                modalState.toggleModal();
                successAlertState.toggleModal();
            }

            setUpdatedDoctor(true);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (axios.isAxiosError(e)) {
                    setError({ message: e.message, status: e.code });
                }
            } else {
                setError({ message: 'An unexpected error occurred' });
            }
        }
    };
    useLoading(isAuthPending);

    if (isAuthPending) return null;

    if (!authContext) return null;

    if (
        authContext.isLoggedIn &&
        !authContext.authUser?.doctor &&
        authContext.authUser?.role === UserRole.organizationMember
    ) {
        window.location.reload();
        return null;
    }

    return (
        <>
            <BetterAlert state={successAlertState} description={t('editDoctorModal:success_message')} />
            {authContext?.authUser && (
                <MissingInfoForm
                    modalState={modalState}
                    request={request}
                    authUser={authContext.authUser}
                    handleSubmit={handleMissingData}
                />
            )}
            {children}
        </>
    );
};
