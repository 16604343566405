import { Button } from '@mui/joy';
import React from 'react';

interface LoadMoreButtonProps {
    hasMore?: boolean;
    isLoading?: boolean;
    loadMore?: () => void;
    fullWidth?: boolean;
    text?: string;
}

export const LoadMoreButton = ({ hasMore, fullWidth = false, isLoading, loadMore, text }: LoadMoreButtonProps) => {
    if (!isLoading && !hasMore) {
        return null;
    }

    return (
        <Button fullWidth={fullWidth} loading={isLoading} onClick={loadMore}>
            {text}
        </Button>
    );
};
