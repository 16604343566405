import React, { useEffect } from 'react';
import { InvoiceMobileProps, InvoiceMobileValues } from './InvoiceMobileForm.types';
import { FormikProps } from 'formik';
import { FormLabel, Grid, Input, Stack, Textarea, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../../../../shared/hooks/useWindowSize';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { OutlinedInputSx } from '../../../../../shared/OutlinedInputSx/OutlinedInputSx';
import { FormControlStyled } from '../../../../../shared/Form/FormControlStyled';
import { PostalCodeAdapter } from 'components/shared/adapters/PostalCodeAdapter';
import { NumericFormatAdapter } from 'components/shared/adapters/NumericFormatAdapter';
import { BankMaskAdapter } from '../../../../../shared/adapters/BankMaskAdapter';
import { VatMaskAdapter } from 'components/shared/adapters/VatMaskAdapter';
import { InvoiceItemTable } from './SubModules/InvoiceItemTable';
import { PhoneAdapter } from '../../../../../shared/adapters/PhoneAdapter';
import { FormErrorMessage } from '../../../../../shared/FormErrorMessage/FormErrorMessage';
import useApiRequest from '../../../../../../hooks/useApiRequest';
import { apiSecondOpinion } from '../../../../../../api';

type Props = {
    baseProps: InvoiceMobileProps & FormikProps<InvoiceMobileValues>;
};
export const InvoiceMobileBody = ({ baseProps }: Props) => {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const request = useApiRequest();

    useEffect(() => {
        if (baseProps.values.doctorBankNr) {
            request.dispatch(apiSecondOpinion.getBankName(baseProps.values.doctorBankNr)).then((res) => {
                if (res.length > 0) {
                    baseProps.values.bank_name = res[0].bank_name;
                }
            });
        }
        baseProps.values.items = [];
    }, []);

    return (
        <>
            <Stack
                direction="column"
                sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                spacing={2}
                style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: isExtraSmall ? 'calc(100% - 40px)' : '100%',
                }}
            >
                <Grid spacing={{ xs: 0, md: 1 }} maxWidth="100%" container rowSpacing={1}>
                    <Grid xs={12} md={6}>
                        <FormControlStyled
                            isError={!!(baseProps.errors.invoiceNr && baseProps.touched.invoiceNr)}
                            isTouched={baseProps.touched.invoiceNr}
                        >
                            <FormLabel required>{t('invoice:invoice_no')}</FormLabel>
                            <Input
                                id="invoiceNr"
                                name="invoiceNr"
                                value={baseProps.values.invoiceNr}
                                onChange={baseProps.handleChange}
                            />
                        </FormControlStyled>
                    </Grid>
                    <Grid container xs={12} md={6} width="100%" spacing={1} sx={{ flexGrow: 1 }}>
                        <Grid maxWidth="50%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.invoiceDate && baseProps.touched.invoiceDate)}
                                isTouched={false}
                            >
                                <FormLabel required>{t('invoice:invoice_date')}</FormLabel>
                                <MobileDatePicker
                                    sx={OutlinedInputSx}
                                    format="dd/MM/yyyy"
                                    formatDensity={isExtraSmall ? 'dense' : 'spacious'}
                                    orientation={isExtraSmall ? 'portrait' : 'landscape'}
                                    minDate={new Date()}
                                    value={baseProps.values.invoiceDate}
                                    onAccept={(value) => {
                                        if (value === null) return;
                                        baseProps.setFieldValue('invoiceDate', value);
                                    }}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid maxWidth="50%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.invoiceDueDate && baseProps.touched.invoiceDueDate)}
                                isTouched={false}
                            >
                                <FormLabel required>{t('invoice:invoice_due_date')}</FormLabel>
                                <MobileDatePicker
                                    sx={OutlinedInputSx}
                                    format="dd/MM/yyyy"
                                    formatDensity={isExtraSmall ? 'dense' : 'spacious'}
                                    orientation={isExtraSmall ? 'portrait' : 'landscape'}
                                    minDate={new Date()}
                                    value={baseProps.values.invoiceDueDate}
                                    onAccept={(value) => {
                                        if (value === null) return;
                                        baseProps.setFieldValue('invoiceDueDate', value);
                                    }}
                                />
                            </FormControlStyled>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography level="h3">{t('invoice:invoice_patient_data')}</Typography>
                <Grid container maxWidth="100%" spacing={1}>
                    <Grid xs={12} md={6} container width="100%" sx={{ flexGrow: 1 }}>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.patientTitle && baseProps.touched.patientTitle)}
                                isTouched={baseProps.touched.patientTitle}
                            >
                                <FormLabel>{t('invoice:invoice_title')}</FormLabel>
                                <Input
                                    id="patientTitle"
                                    name="patientTitle"
                                    value={baseProps.values.patientTitle}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.patientName && baseProps.touched.patientName)}
                                isTouched={baseProps.touched.patientName}
                            >
                                <FormLabel required>{t('invoice:invoice_name')}</FormLabel>
                                <Input
                                    id="patientName"
                                    name="patientName"
                                    value={baseProps.values.patientName}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.patientSurname && baseProps.touched.patientSurname)}
                                isTouched={baseProps.touched.patientSurname}
                            >
                                <FormLabel required>{t('invoice:invoice_surname')}</FormLabel>
                                <Input
                                    id="patientSurname"
                                    name="patientNamepatientSurname"
                                    value={baseProps.values.patientSurname}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} xs={12} md={6} width="100%" sx={{ flexGrow: 1 }}>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.patientAddress2 && baseProps.touched.patientAddress2)}
                                isTouched={baseProps.touched.patientAddress2}
                            >
                                <FormLabel required>{t('invoice:invoice_address')}</FormLabel>
                                <Input
                                    id="patientAddress2"
                                    name="patientAddress2"
                                    value={baseProps.values.patientAddress2}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.patientAddress && baseProps.touched.patientAddress)}
                                isTouched={baseProps.touched.patientAddress}
                            >
                                <FormLabel required>{t('invoice:invoice_town')}</FormLabel>
                                <Input
                                    id="patientAddress"
                                    name="patientAddress"
                                    value={baseProps.values.patientAddress}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="60%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.patientPostalCode && baseProps.touched.patientPostalCode)}
                                isTouched={baseProps.touched.patientPostalCode}
                            >
                                <FormLabel required>{t('invoice:invoice_postal_code')}</FormLabel>
                                <Input
                                    id="patientPostalCode"
                                    name="patientPostalCode"
                                    value={baseProps.values.patientPostalCode}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                    slotProps={{
                                        input: {
                                            component: PostalCodeAdapter,
                                            maxLength: 5,
                                        },
                                    }}
                                />
                            </FormControlStyled>
                        </Grid>
                    </Grid>
                </Grid>
                <Typography level="h3">{t('invoice:invoice_doctor_data')}</Typography>
                <Grid container maxWidth="100%" spacing={1}>
                    <Grid xs={12} md={6} container width="100%" sx={{ flexGrow: 1 }}>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorTitle && baseProps.touched.doctorTitle)}
                                isTouched={baseProps.touched.doctorTitle}
                            >
                                <FormLabel>{t('invoice:invoice_title')}</FormLabel>
                                <Input
                                    id="doctorTitle"
                                    name="doctorTitle"
                                    value={baseProps.values.doctorTitle}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorName && baseProps.touched.doctorName)}
                                isTouched={baseProps.touched.doctorName}
                            >
                                <FormLabel required>{t('invoice:invoice_name')}</FormLabel>
                                <Input
                                    id="doctorName"
                                    name="doctorName"
                                    value={baseProps.values.doctorName}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorSurname && baseProps.touched.doctorSurname)}
                                isTouched={baseProps.touched.doctorSurname}
                            >
                                <FormLabel required>{t('invoice:invoice_surname')}</FormLabel>
                                <Input
                                    id="doctorSurname"
                                    name="doctorSurname"
                                    value={baseProps.values.doctorSurname}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorEmail && baseProps.touched.doctorEmail)}
                                isTouched={baseProps.touched.doctorEmail}
                            >
                                <FormLabel required>{t('invoice:invoice_email')}</FormLabel>
                                <Input
                                    id="doctorEmail"
                                    name="doctorEmail"
                                    type="email"
                                    inputMode="email"
                                    value={baseProps.values.doctorEmail}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorTel && baseProps.touched.doctorTel)}
                                isTouched={baseProps.touched.doctorTel}
                            >
                                <FormLabel required>{t('invoice:invoice_tel')}</FormLabel>
                                <Input
                                    id="doctorTel"
                                    name="doctorTel"
                                    slotProps={{
                                        input: {
                                            component: PhoneAdapter,
                                            maxLength: 18,
                                        },
                                    }}
                                    value={baseProps.values.doctorTel}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorFax && baseProps.touched.doctorFax)}
                                isTouched={baseProps.touched.doctorFax}
                            >
                                <FormLabel>{t('invoice:invoice_fax')}</FormLabel>
                                <Input
                                    id="doctorFax"
                                    name="doctorFax"
                                    slotProps={{
                                        input: {
                                            component: NumericFormatAdapter,
                                            maxLength: 12,
                                        },
                                    }}
                                    value={baseProps.values.doctorFax}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={6} container spacing={1} direction="column" justifyContent="flex-start">
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorAddress2 && baseProps.touched.doctorAddress2)}
                                isTouched={baseProps.touched.doctorAddress2}
                            >
                                <FormLabel required>{t('invoice:invoice_address')}</FormLabel>
                                <Input
                                    id="doctorAddress2"
                                    name="doctorAddress2"
                                    value={baseProps.values.doctorAddress2}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorAddress && baseProps.touched.doctorAddress)}
                                isTouched={baseProps.touched.doctorAddress}
                            >
                                <FormLabel required>{t('invoice:invoice_town')}</FormLabel>
                                <Input
                                    id="doctorAddress"
                                    name="doctorAddress"
                                    value={baseProps.values.doctorAddress}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="60%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorPostalCode && baseProps.touched.doctorPostalCode)}
                                isTouched={baseProps.touched.doctorPostalCode}
                            >
                                <FormLabel required>{t('invoice:invoice_postal_code')}</FormLabel>
                                <Input
                                    id="doctorPostalCode"
                                    name="doctorPostalCode"
                                    value={baseProps.values.doctorPostalCode}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                    slotProps={{
                                        input: {
                                            component: PostalCodeAdapter,
                                            maxLength: 5,
                                        },
                                    }}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorBankNr && baseProps.touched.doctorBankNr)}
                                isTouched={baseProps.touched.doctorBankNr}
                            >
                                <FormLabel required>{t('invoice:invoice_bank_account')}</FormLabel>
                                <Input
                                    id="doctorBankNr"
                                    name="doctorBankNr"
                                    slotProps={{
                                        input: {
                                            component: BankMaskAdapter,
                                        },
                                    }}
                                    value={baseProps.values.doctorBankNr}
                                    onBlur={async (e) => {
                                        baseProps.handleBlur(e);
                                        if (baseProps.values.doctorBankNr) {
                                            const bankName = await request.dispatch(
                                                apiSecondOpinion.getBankName(baseProps.values.doctorBankNr),
                                            );
                                            if (bankName.length > 0) {
                                                baseProps.values.bank_name = bankName[0].bank_name;
                                            }
                                        } else {
                                            baseProps.values.bank_name = '';
                                        }
                                    }}
                                    onChange={baseProps.handleChange}
                                    placeholder="AA 000000000000000000000"
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.bank_name && baseProps.touched.bank_name)}
                                isTouched={baseProps.touched.bank_name}
                            >
                                <FormLabel required>{t('invoice:invoice_bank_name')}</FormLabel>
                                <Input
                                    id="bank_name"
                                    name="bank_name"
                                    value={baseProps.values.bank_name}
                                    onChange={baseProps.handleChange}
                                    onBlur={baseProps.handleBlur}
                                />
                            </FormControlStyled>
                        </Grid>
                        <Grid width="100%">
                            <FormControlStyled
                                isError={!!(baseProps.errors.doctorVatID && baseProps.touched.doctorVatID)}
                                isTouched={baseProps.touched.doctorVatID}
                            >
                                <FormLabel>{t('invoice:invoice_vat_id')}</FormLabel>
                                <Input
                                    id="doctorVatID"
                                    name="doctorVatID"
                                    slotProps={{
                                        input: {
                                            component: VatMaskAdapter,
                                        },
                                    }}
                                    onBlur={baseProps.handleBlur}
                                    value={baseProps.values.doctorVatID}
                                    onChange={baseProps.handleChange}
                                />
                            </FormControlStyled>
                        </Grid>
                    </Grid>
                </Grid>
                <Stack justifyContent="center" alignItems="stretch" style={{ width: '100%' }} spacing={1}>
                    <FormControlStyled
                        isError={!!(baseProps.errors.diagnosis && baseProps.touched.diagnosis)}
                        isTouched={baseProps.touched.diagnosis}
                    >
                        <FormLabel required sx={{ mb: 2 }}>
                            <Typography level="h3">{t('invoice:invoice_diagnosis')}</Typography>
                        </FormLabel>
                        <Textarea
                            id="diagnosis"
                            name="diagnosis"
                            minRows={3}
                            value={baseProps.values.diagnosis}
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                            style={{ width: '100%' }}
                        />
                    </FormControlStyled>
                </Stack>
                <FormErrorMessage onlyTouched={false} />
                <Typography level="h3">{t('invoice:table.title')}</Typography>
                <InvoiceItemTable baseProps={baseProps} />
            </Stack>
        </>
    );
};
