import * as React from 'react';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import { useSharedColorScheme } from './useSharedColorScheme';

export default function ColorSchemeToggle({ onClick, sx, ...props }: IconButtonProps) {
    const { icon, toggleTheme } = useSharedColorScheme();
    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="neutral"
            {...props}
            onClick={(e) => {
                toggleTheme();
                onClick?.(e);
            }}
        >
            {icon}
        </IconButton>
    );
}
