import React, { ReactNode } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { useModalState } from '../components/shared/BetterModal/useModalState';
import { api } from '../api';
import { AppointmentModel } from '../api/models';
import { CancelAppointmentModal } from '../components/shared/AppointmentActions/CancelAppointmentModal';
import { RescheduleAppointmentModal } from '../components/shared/AppointmentActions/RescheduleAppointmentModal';
import { AttachQuestionnairesModal } from '../components/shared/AppointmentActions/AttachQuestionnairesModal';
import { ModalState } from '../components/shared/BetterModal/BetterModal';
import { useQueryClient } from 'react-query';
import { dashboardQueryKey } from 'components/Dashboard/hooks/dashboard.query-keys';
import { appointmentQueryKey } from './queryKeys/appointment.querykey';
import { useAlert } from 'components/shared/BetterAlert/useAlert';
import ConfirmModal from 'components/shared/ConfirmModal/ConfirmModal';
import { t } from 'i18next';

export enum ActionType {
    cancel = 'cancel',
    reschedule = 'reschedule',
}

export enum QueryParam {
    action = 'action',
    appointmentId = 'appointment_id',
}

export const useAppointmentsActions = (
    reloadAppointments: (() => void) | undefined,
    pickedAppointment: AppointmentModel | undefined,
    appointmentModalState: ModalState | undefined,
) => {
    const cancelAppointmentModalState = useModalState();
    const rescheduleAppointmentModalState = useModalState();
    const attachQuestionnaireModalState = useModalState();
    const request = useApiRequest();
    const queryClient = useQueryClient();
    const { openAlert } = useAlert();

    const cancelAppointment = async () => {
        if (pickedAppointment === undefined) return;

        try {
            await request.dispatch(api.cancelAppointment(pickedAppointment.id));
            await queryClient.invalidateQueries([
                appointmentQueryKey.upcomingAppointments(),
                appointmentQueryKey.pastAppointments(),
                appointmentQueryKey.cancelledAppointments(),
            ]);
            reloadAppointments?.();
            cancelAppointmentModalState.toggleModal();
            appointmentModalState?.toggleModal();
            openAlert(t('appointmentActions:message_success'));
        } catch (error) {
            console.error('Failed to cancel appointment', error);
        }
    };

    const rescheduleAppointment = (appointmentId: number, slotKey: string) => {
        request
            .dispatch(api.rescheduleAppointment(appointmentId, slotKey))
            .then(reloadAppointments)
            .then(() => {
                queryClient.invalidateQueries([
                    dashboardQueryKey.statistics,
                    dashboardQueryKey.todaysAppointments,
                    appointmentQueryKey.upcomingAppointments,
                ]);
                reloadAppointments?.();
                rescheduleAppointmentModalState.toggleModal();
            });
    };

    const rescheduleAppointmentModal = (button: ReactNode): ReactNode => (
        <RescheduleAppointmentModal
            button={button}
            modalState={rescheduleAppointmentModalState}
            rescheduleAppointment={rescheduleAppointment}
        />
    );

    const cancelAppointmentModal = (button: ReactNode): ReactNode => (
        <>
            <ConfirmModal
                button={button}
                modalState={cancelAppointmentModalState}
                title={t('appointmentActions:title_cancel')}
                modalDescription={t('appointmentActions:message_cancel')}
                successAlertDescription={t('appointmentActions:message_success')}
                onConfirm={async () => {
                    await cancelAppointment();
                }}
            />
        </>
    );

    const attachQuestionnaireModal = (button: ReactNode): ReactNode => (
        <AttachQuestionnairesModal button={button} modalState={attachQuestionnaireModalState} />
    );

    return {
        cancelAppointmentModalState,
        rescheduleAppointmentModalState,
        attachQuestionnaireModalState,
        rescheduleAppointmentModal,
        cancelAppointmentModal,
        attachQuestionnaireModal,
        isAppointmentRescheduleLoading: request.isLoading,
    };
};
