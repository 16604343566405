import { useTranslation } from 'react-i18next';

type RetProps = {
    getWeek: (workingWeek: boolean, short: boolean) => { key: EDay | string; value: string }[];
    getYear: (short: boolean) => { key: EMonth | string; value: string }[];
};
export const useTranslatedDates = (): RetProps => {
    const { t } = useTranslation();

    const getWeek = (workingWeek = false, short = false) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const values = Object.values(EDay);
        const weekStr = short ? 'weekShort.' : 'week.';
        const week: { key: EDay | string; value: string }[] = [];
        values.forEach((key, index) => {
            if ((workingWeek && week.length === 5) || week.length === 7) return week;
            week.push({ key: key, value: t('dateTime:' + weekStr + index) });
        });

        return week;
    };

    const getYear = (short = false) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const values = Object.values(EMonth);
        const yearStr = short ? 'yearShort.' : 'year.';
        const year: { key: EMonth | string; value: string }[] = [];
        values.forEach((key, index) => {
            year.push({ key: key, value: t('dateTime:' + yearStr + index) });
        });

        return year;
    };

    return { getWeek, getYear };
};

export const enum EDay {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
}

export const enum EMonth {
    january,
    february,
    march,
    april,
    may,
    june,
    july,
    august,
    september,
    october,
    november,
    december,
}
