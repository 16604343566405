import { RRule } from 'rrule';
import { freqToRRule, weekdayToRRule } from './rrule';
import { EndsOnOption } from '../RecuringFieldModal/RecurringFieldModal.types';
import { Frequency, Weekday } from '../../../../../../api/models';

export interface RRuleMapValues {
    interval: number;
    freq: Frequency;
    days: Weekday[];
    endsOn: EndsOnOption;
    until?: Date;
    count?: number;
}
export const mapValuesToScheduleRule = (values: RRuleMapValues): RRule => {
    let rrule: RRule = new RRule({
        freq: freqToRRule(values.freq),
        interval: values.interval,
        byweekday: values.freq === Frequency.WEEKLY ? values.days.map(weekdayToRRule) : [],
    });

    if (values.endsOn === EndsOnOption.onDay) {
        rrule = new RRule({
            ...rrule.origOptions,
            until: values.until,
        });
    }

    if (values.endsOn === EndsOnOption.afterX) {
        rrule = new RRule({
            ...rrule.origOptions,
            count: values.count,
        });
    }

    return rrule;
};
