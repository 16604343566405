import moment from 'moment';
import {
    AcceptInvitationData,
    AppointmentModel,
    AppointmentParams,
    AppointmentReason,
    AppointmentRequestParams,
    AppointmentsDataPage,
    AppointmentStep,
    AppointmentUpdateData,
    Attachment,
    EditMemberProfileData,
    Language,
    LoginData,
    LoginOtpRequestData,
    NewPasswordData,
    OrganizationLocation,
    OrganizationProfile,
    PaginatedDataParams,
    PatientRegistrationData,
    SecondOpinionToken,
    Slot,
    SlotCriteria,
    SlotEditScope,
    SlotStatus,
    SortTypes,
    TokenPair,
    User,
    UserDoctor,
    UserPatientProfile,
    UserProfile,
    UserProfileOrganization,
    UserRole,
    UserUpdateData,
    VideoCallDetails,
} from './models';

import { ApiRoutes } from './ApiRoutes';
import { MedReferAPI, PaginatedData, RecursivePartial } from './ApiTypes';
import { HttpClient } from './HttpClient';
import { formatISODate, formatISODateTime } from './utils';
import { appointmentFactory } from './factories';
import {
    AppointmentRequestsDataPage,
    AppointmentRequestsModel,
    AppointmentRequestStatus,
} from './models/AppointmentRequestsModel';
import { appointmentRequestsFactory } from './factories/appointmentRequests';
import { AppointmentRequestData } from './models/forms/AppointmentRequestData';
import { HealthcareServicesCriteriaParams } from './models/forms/HealthcareServiceCriteriaFinderParams';
import { SlotData } from './models/forms/SlotData';
import { SlotBookingData } from '../components/SlotsPage/SlotList/EditSlotModal/EditSlotForm.types';
import { DashboardStatistics } from './models/DashboardStatisticsModel';

// API Implementation
export class Api implements MedReferAPI {
    client: HttpClient;

    constructor() {
        this.client = new HttpClient(
            process.env.REACT_APP_API_URL || '',
            this.refreshToken.bind(this),
            this.generateSecondOpinionToken.bind(this),
        );
        this.setBrandSlugHeader(process.env.REACT_APP_BRAND_SLUG || '');
    }

    async getAppointmentSteps(id: number): Promise<AppointmentStep[]> {
        const res = await this.client.get(ApiRoutes.appointmentStepsRoute(id));
        return res.data;
    }

    async generateSecondOpinionToken(): Promise<SecondOpinionToken> {
        const res = await this.client.get(ApiRoutes.secondOpinionTokenRoute);
        return res.data;
    }

    setAuthToken(token: string): void {
        this.client.setCommonHeader('Authorization', `Bearer ${token}`);
    }

    setBrandSlugHeader(slug: string) {
        this.client.setCommonHeader('X-Medrefer-Brand', slug); // todo: second opinion slug 2op
    }

    clearAuthToken(): void {
        this.client.deleteCommonHeader('Authorization');
    }

    setLanguage(language: string): void {
        this.client.setCommonHeader('Accept-Language', language);
    }

    async getStatistics(): Promise<DashboardStatistics> {
        const res = await this.client.get(ApiRoutes.dashboardStatisticRoute);
        return res.data;
    }

    async getAppointment(id: number): Promise<AppointmentModel> {
        const res = await this.client.get(ApiRoutes.appointmentIdRoute(id));
        return appointmentFactory(res.data);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async getAppointmentVideoCall(id: number): Promise<VideoCallDetails> {
        const res = await this.client.get(ApiRoutes.appointmentVideoCallRoute(id));
        return res.data;
    }

    async getAppointmentVideoCallPatientAccess(id: number, token: string): Promise<VideoCallDetails> {
        const res = await this.client.get(ApiRoutes.appointmentVideoCallPatientAccessRoute(id), {
            params: {
                access_token: token,
            },
        });
        return res.data;
    }

    async getAppointments(params: AppointmentParams): Promise<AppointmentsDataPage> {
        const orderingValue = {
            [SortTypes.dateAsc]: 'date_from',
            [SortTypes.dateDesc]: '-date_from',
            [SortTypes.nameAsc]: 'full_name',
            [SortTypes.nameDesc]: '-full_name',
        };

        const apiParams = {
            date_from__gte: params.dateFrom ? formatISODateTime(moment(params.dateFrom)) : undefined,
            date_from__lte: params.dateTo ? formatISODateTime(moment(params.dateTo)) : undefined,
            search: params.search,
            ordering: params.ordering || (params.sort && orderingValue[params.sort]),
            booking_status__in: params.statuses && params.statuses.join(','),
            limit: params.limit,
            offset: params.offset,
        };

        const res = await this.client.get(ApiRoutes.appointmentsRoute, { params: apiParams });
        return {
            next: res.data.next,
            appointments: res.data.results.map(appointmentFactory),
            totalCount: res.data.count,
            metadata: res.data.metadata,
        };
    }

    async cancelAppointment(id: number): Promise<AppointmentModel> {
        const res = await this.client.post(ApiRoutes.cancelAppointmentRoute(id));
        return appointmentFactory(res.data);
    }

    async rescheduleAppointment(id: number, slotKey: string): Promise<AppointmentModel> {
        const res = await this.client.post(ApiRoutes.rescheduleAppointmentRoute(id), { slot: slotKey });
        return appointmentFactory(res.data);
    }

    async getAppointmentReasons(): Promise<AppointmentReason[]> {
        const res = await this.client.get(ApiRoutes.appointmentReasonsRoute);
        return res.data;
    }

    async getAppointmentReason(id: number): Promise<AppointmentReason> {
        const res = await this.client.get(ApiRoutes.appointmentReasonsIdRoute(id));
        return res.data;
    }

    async getAppointmentRequests(params: AppointmentRequestParams): Promise<AppointmentRequestsDataPage> {
        let statuses: AppointmentRequestStatus[] = [];
        if (params.statuses) {
            if (params.statuses?.length == 0) {
                statuses = [
                    AppointmentRequestStatus.PENDING,
                    AppointmentRequestStatus.CANCELED,
                    AppointmentRequestStatus.RESPONDED,
                    AppointmentRequestStatus.APPROVED,
                    AppointmentRequestStatus.REJECTED,
                ];
            } else {
                statuses = params.statuses;
            }
        }
        const apiParams = {
            search: params.search,
            ordering: params.ordering,
            status__in: statuses.join(','),
            limit: params.limit,
            offset: params.offset,
        };

        const res = await this.client.get(ApiRoutes.appointmentRequestsRoute, { params: apiParams });
        res.data.results.map(async (app: AppointmentRequestsModel) => {
            app.isChecked = false;
            /*if (app.healthcare_service_group) {
                const services = await this.getHealthcareServiceGroup(app.healthcare_service_group.id);
                app.healthcare_service_group.services = services.services;
            }*/
        });
        return {
            next: res.data.next,
            appointment_requests: res.data.results.map(appointmentRequestsFactory),
            totalCount: res.data.count,
            metaData: res.data.metadata.count_by_booking_status,
        };
    }
    async createAppointmentRequest(appointmentRequest: AppointmentRequestData): Promise<AppointmentRequestsModel> {
        const res = await this.client.post(ApiRoutes.appointmentRequestsRoute, appointmentRequest);
        return res.data;
    }
    async getAppointmentRequest(id: number): Promise<AppointmentRequestsModel> {
        const res = await this.client.get(ApiRoutes.appointmentRequestRoute(id));
        return appointmentRequestsFactory(res.data);
    }
    async changeAppointmentRequestStatus(
        id: number,
        status: AppointmentRequestStatus,
        idAppointment: number | null = null,
    ) {
        if (status == AppointmentRequestStatus.APPROVED && idAppointment) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkApprovedRoute(id, idAppointment));
            return res.data;
        }
        if (status == AppointmentRequestStatus.RESPONDED) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkRespondedRoute(id));
            return res.data;
        }
        if (status == AppointmentRequestStatus.PENDING) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkPendingRoute(id));
            return res.data;
        }
        if (status == AppointmentRequestStatus.CANCELED) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkCanceledRoute(id));
            return res.data;
        }
    }

    async login(data: LoginData): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.loginRoute, data);
        return res.data;
    }

    async requestLoginOtp(data: LoginOtpRequestData): Promise<void> {
        await this.client.post(ApiRoutes.requestLoginOtpRoute, data);
    }

    async loginWithCode(code: string, ephemeralToken: string): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.loginCodeRoute, {
            ephemeral_token: ephemeralToken,
            code: code,
        });
        return res.data;
    }

    async registerPatient(form: PatientRegistrationData): Promise<TokenPair> {
        const data = {
            email: form.email,
            password: form.password,
            first_name: form.firstName,
            last_name: form.lastName,
            phone: form.phone,
            token: form.token,
            patient: form.patient,
            personal_title: form.personalTitle,
        };
        const res = await this.client.post(ApiRoutes.patientRegistrationRoute, data);
        return res.data;
    }
    async checkEmailTaken(email: string): Promise<boolean> {
        try {
            const res = await this.client.post(ApiRoutes.checkFieldTakenRoute, {
                field: 'email',
                value: email,
            });
            return res.data.taken;
        } catch (error) {
            throw error;
        }
    }

    async refreshToken(refresh: string): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.refreshTokenRoute, { refresh });
        return res.data;
    }

    async requestOtp(email: string, organization_id?: number): Promise<void> {
        await this.client.post(ApiRoutes.otpRequestsRoute, { email, organization_id });
    }

    async uploadAppointmentAttachments(appointment: AppointmentModel, attachments: File[]): Promise<AppointmentModel> {
        const formData = new FormData();
        for (let i = 0; i < attachments.length; i++) {
            const attachment = attachments[i];
            const numberOfAttachments = appointment.attachments?.length || 0;
            const fileName = this.createAttachmentName(
                appointment.patient,
                numberOfAttachments + i + 1,
                attachment.name,
            );

            fileName && formData.append('files', attachment, fileName);
        }
        const res = await this.client.post(ApiRoutes.uploadAppointmentAttachmentRoute(appointment.id), formData);
        return appointmentFactory(res.data);
    }

    async getAttachmentLink(id: number): Promise<string> {
        const attachment = await this.client.get(ApiRoutes.appointmentAttachmentIdRoute(id));
        return attachment.data.url;
    }

    async updateAppointment(appointment: AppointmentUpdateData): Promise<AppointmentModel> {
        const { id } = appointment;
        const res = await this.client.patch(ApiRoutes.appointmentIdRoute(id), appointment);
        return appointmentFactory(res.data);
    }

    async uploadAttachment(file: File): Promise<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        const res = await this.client.post(ApiRoutes.uploadAttachmentRoute, formData);
        return res.data;
    }

    async updateMemberProfile(data: EditMemberProfileData): Promise<UserProfile> {
        const values: Partial<UserUpdateData> = {
            practitioner: data,
        };
        const res = await this.client.patch(ApiRoutes.userProfileRoute, values);
        return res.data;
    }

    async updateUserProfile(userData: UserUpdateData): Promise<UserProfile> {
        const data: RecursivePartial<UserProfile> = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            phone: userData.phone,
            personal_title: userData.personalTitle,
        };
        if (userData.role === UserRole.patient) {
            data.patient = {
                date_of_birth: userData.dateOfBirth && formatISODate(moment(userData.dateOfBirth)),
                post_code: userData.postCode,
                city: userData.city,
                street: userData.street,
                insurance_number: userData.insuranceNumber,
                insurance_company: userData.insuranceCompany,
                insurance_status: userData.insuranceStatus,
                sms_agreement: userData.smsAgreement,
                email_agreement: userData.emailAgreement,
            };
        }
        const res = await this.client.patch(ApiRoutes.userProfileRoute, data);
        return res.data;
    }

    async updateUserLanguage(language: Language): Promise<UserProfile> {
        const res = await this.client.patch(ApiRoutes.userProfileRoute, { language });
        return res.data;
    }

    async acceptInvitation(data: AcceptInvitationData): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.acceptInvitationRoute, {
            invitation_token: data.invitation_token,
            password: data.password,
            personal_title: data.personal_title,
            first_name: data.name,
            last_name: data.surname,
        });
        return res.data;
    }

    async validateInvitation(invitationToken: string): Promise<User> {
        const res = await this.client.post(ApiRoutes.validateInvitationRoute, {
            invitation_token: invitationToken,
        });
        return res.data;
    }

    async getUserProfile(): Promise<UserProfile> {
        const res = await this.client.get(ApiRoutes.userProfileRoute);
        return res.data;
    }

    async getUserProfiles(params: PaginatedDataParams): Promise<PaginatedData<UserProfileOrganization[]>> {
        const res = await this.client.get(ApiRoutes.userProfilesRoute, { params });
        return res.data;
    }

    async setUserPassword(password: NewPasswordData): Promise<void> {
        await this.client.post(ApiRoutes.passwordResetSetNewPassword, {
            password: password.password,
            token: password.token,
        });
    }

    async checkRecoveryToken(token: string): Promise<void> {
        await this.client.post(ApiRoutes.passwordResetCheckRecoveryToken, { token: token });
    }

    async requestPasswordReset(email: string): Promise<void> {
        await this.client.post(ApiRoutes.requestPasswordResetRoute, { email });
    }

    private createAttachmentName(user: UserPatientProfile | undefined, index: number, oldFileName: string): string {
        if (user === undefined) return '';

        const fileExtension =
            oldFileName.substring(oldFileName.lastIndexOf('.') + 1, oldFileName.length) || oldFileName;

        return user.first_name + '_' + user.last_name + '_' + index + '.' + fileExtension;
    }

    // Organization
    async getOrganization(organizationSlug: string): Promise<OrganizationProfile> {
        const res = await this.client.get(ApiRoutes.organizationsSlugRoute(organizationSlug));
        return res.data;
    }

    async getOrganizationSlots(dateFrom?: Date, dateTo?: Date): Promise<Slot[]> {
        const params = {
            status: SlotStatus.FREE,
            slots_after: dateFrom ? formatISODateTime(moment(dateFrom)) : undefined,
            slots_before: dateTo ? formatISODateTime(moment(dateTo)) : undefined,
        };
        const res = await this.client.get(ApiRoutes.slotsOrganizationsRoute, { params });
        return res.data.results;
    }

    async getCalendarOrganizationLocations(): Promise<OrganizationLocation[]> {
        const res = await this.client.get(ApiRoutes.getCalendarOrganizationLocationsRoute);
        return res.data;
    }

    async getSlotsHSCriteria(params: HealthcareServicesCriteriaParams): Promise<SlotCriteria[]> {
        const res = await this.client.get(ApiRoutes.slotsCriteriaHSRoute);
        return res.data;
    }

    async createSlot(slot: SlotData): Promise<Slot> {
        const res = await this.client.post(ApiRoutes.slotsOrganizationsRoute, slot);
        return res.data;
    }

    async updateSlot(slot: SlotData, slotKey: string, scope: SlotEditScope): Promise<Slot> {
        const res = await this.client.put(ApiRoutes.slotOrganizationsRoute(slotKey), slot, { params: { scope } });
        return res.data;
    }

    async deleteSlot(slotKey: string, scope: SlotEditScope): Promise<void> {
        await this.client.delete(ApiRoutes.slotOrganizationsRoute(slotKey), { params: { scope } });
    }

    async bookSlot(data: SlotBookingData): Promise<AppointmentModel> {
        const body = {
            slot: data.slot.key,
            patient: data.patient,
            reason: data.reason,
            comment: data.comment,
            proposal: {
                personal_title: data.personal_title,
                phone: data.phone,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                insurance_number: data.insurance_number,
                insurance_status: data.insurance_status,
                sms_agreement: data.sms_agreement,
                email_agreement: data.email_agreement,
                date_of_birth: data.date_of_birth && formatISODate(moment(data.date_of_birth)),
            },
            mode: data.mode,
            criteria_values: data.criteria_values,
        };
        const res = await this.client.post(ApiRoutes.slotBookingRoute, body);
        return appointmentFactory(res.data);
    }

    async getOrganizationSlot(slotKey: string): Promise<Slot> {
        const res = await this.client.get(ApiRoutes.slotOrganizationsRoute(slotKey));
        return res.data;
    }

    async getOrganizationPatient(userId: number): Promise<UserPatientProfile> {
        const res = await this.client.get(ApiRoutes.getOrganizationPatientRoute(userId));
        return res.data;
    }
}
