import React, { ReactNode } from 'react';
import { BetterModal, ModalState } from '../BetterModal/BetterModal';
import { useTranslation } from 'react-i18next';

type Props = {
    button: ReactNode;
    modalState: ModalState;
};
export const AttachQuestionnairesModal = ({ button, modalState }: Props) => {
    const { t } = useTranslation();

    return (
        <BetterModal
            minWidth={400}
            button={button}
            body={<>not implemented yet</>}
            title={t('appointmentActions:title_attach_questionnaires')}
            state={modalState}
        />
    );
};
