/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@fontsource/inter';
import { BrowserRouter } from 'react-router-dom';
import { CssVarsProvider, extendTheme, GlobalStyles } from '@mui/joy';
import { CssBaseline } from '@mui/joy';
import { ProSidebarProvider } from 'react-pro-sidebar';
import './i18n/i18n';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AuthContextProvider } from './components/Auth/AuthContext/AuthContext';
import './api/HttpClient';
import { App } from './App';
import { ColorStyles, ComponentsStyles, ShadowStyles, IShadow, IPalette } from './Theme';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    experimental_extendTheme as materialExtendTheme,
    Experimental_CssVarsProvider as MaterialCssVarsProvider,
    THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { ColorStylesMaterialUI } from './Theme/Styles/ColorStylesMaterialUI';
import { ComponentStylesMaterialUI } from './Theme/Styles/ComponentStylesMaterialUI';
import { LoadingContextProvider } from './components/shared/LoadingPage/LoadingContext';
import { FontFamily } from './Theme/fontFamily';
import { TextStyles } from './Theme/Styles/TextStyles';
import i18n from 'i18next';
import { ErrorProvider } from 'context/errorContext';
import { SuccessContextProvider } from './components/shared/BetterAlert/AlertContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

declare module '@mui/joy/styles' {
    interface Shadow extends IShadow {}
    interface Palette extends IPalette {}
}

const materialTheme = materialExtendTheme({
    colorSchemes: ColorStylesMaterialUI,
    components: ComponentStylesMaterialUI,
});

const theme = extendTheme({
    colorSchemes: ColorStyles,
    shadow: ShadowStyles,
    components: ComponentsStyles,
    fontFamily: FontFamily,
    typography: TextStyles,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retryDelay: (attemptIndex) => Math.min(1000 * 10 ** attemptIndex, 30000),
        },
    },
});

root.render(
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
        <CssVarsProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <QueryClientProvider client={queryClient}>
                    <ProSidebarProvider>
                        <GlobalStyles
                            styles={{
                                html: {
                                    height: 'calc(100% - 80px)',
                                },
                                body: {
                                    background: 'var(--joy-palette-background-body)',
                                    height: '100%',
                                },
                                '#root': {
                                    height: '100%',
                                },
                            }}
                        />
                        <BrowserRouter>
                            <QueryParamProvider adapter={ReactRouter6Adapter}>
                                <ErrorProvider>
                                    <LoadingContextProvider>
                                        <AuthContextProvider>
                                            <SuccessContextProvider>
                                                <App />
                                            </SuccessContextProvider>
                                        </AuthContextProvider>
                                    </LoadingContextProvider>
                                </ErrorProvider>
                            </QueryParamProvider>
                        </BrowserRouter>
                    </ProSidebarProvider>
                </QueryClientProvider>
            </LocalizationProvider>
        </CssVarsProvider>
    </MaterialCssVarsProvider>,
);
