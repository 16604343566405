import React from 'react';
import { AspectRatio, Button, Snackbar, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Check, Close, Warning } from '@mui/icons-material';
import { ModalState } from '../BetterModal/BetterModal';
import { AlertMode } from './AlertContext';

export type AlertState = ModalState;

type Props = {
    state: AlertState;
    description: string;
    duration?: number;
    alertMode?: AlertMode;
};
export const BetterAlert = ({ description, duration = 3000, alertMode = 'success', state }: Props) => {
    const { t } = useTranslation();
    const [left, setLeft] = React.useState<undefined | number>();
    const timer = React.useRef<undefined | number>();
    const countdown = () => {
        timer.current = window.setInterval(() => {
            setLeft((prev) => (prev === undefined ? prev : Math.max(0, prev - 100)));
        }, 100);
    };
    React.useEffect(() => {
        if (state.isOpen && duration !== undefined && duration > 0) {
            setLeft(duration);
            countdown();
        } else {
            window.clearInterval(timer.current);
        }
    }, [state.isOpen, duration]);
    const handlePause = () => {
        window.clearInterval(timer.current);
    };
    const handleResume = () => {
        countdown();
    };

    const getTitle = (): string => {
        let key = '';
        switch (alertMode) {
            case 'success':
                key = 'buttons:button_success';
                break;
            case 'warning':
                key = 'basics:warning';
                break;
            case 'danger':
                key = 'basics:alert';
                break;
        }
        return t(key);
    };

    return (
        <Snackbar
            variant="solid"
            color={alertMode}
            autoHideDuration={duration}
            resumeHideDuration={left}
            onMouseEnter={handlePause}
            onMouseLeave={handleResume}
            onFocus={handlePause}
            onBlur={handleResume}
            onUnmount={() => setLeft(undefined)}
            open={state.isOpen}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            invertedColors
            onClose={() => {
                if (state.isOpen) state.toggleModal();
            }}
            endDecorator={
                <Button onClick={state.toggleModal} size="sm" variant="plain" color="success">
                    {t('buttons:button_dismiss')}
                </Button>
            }
            startDecorator={
                <AspectRatio
                    variant="solid"
                    ratio="1"
                    sx={{
                        minWidth: 40,
                        borderRadius: '50%',
                        boxShadow: '0 2px 12px 0 rgb(0 0 0/0.2)',
                    }}
                >
                    <div>
                        {alertMode === 'success' && <Check fontSize="medium" />}
                        {alertMode === 'warning' && <Warning fontSize="medium" />}
                        {alertMode === 'danger' && <Close fontSize="medium" />}
                    </div>
                </AspectRatio>
            }
        >
            <Stack justifyContent="left" alignItems="flex-start">
                <Typography level="title-lg">{getTitle()}</Typography>
                <Typography level="body-sm" sx={{ mt: 1, mb: 2, ml: 0.7 }}>
                    {description}
                </Typography>
            </Stack>
        </Snackbar>
    );
};
