import { useMemo } from 'react';
import { useOrganizationLocations } from 'web-kit/hooks/useOrganizationLocations';
export const useSlotsDisplay = () => {
    const getSlotColor = (serviceId: number): string => {
        return calendars.find((s) => s.id === serviceId.toString())?.color || 'clear';
    };
    const organizationLocations = useOrganizationLocations();
    const services = useMemo(() => {
        return organizationLocations.map((x) => x.service_set).flat(1);
    }, [organizationLocations]);
    const varName = (name: string) => `var(--joy-palette-${name})`;

    const calendars: ISlotInfo[] = useMemo(() => {
        const colors: string[] = [
            varName('saddlebrown'),
            varName('darkslategray'),
            varName('darkgreen'),
            varName('darkkhaki'),
            varName('indigo'),
            varName('red'),
            varName('darkturquoise'),
            varName('mediumspringgreen'),
            varName('fuchsia'),
            varName('cornflower'),
            varName('deeppink'),
            varName('yellow'),
            varName('lightpink'),
        ];

        return services.map((service, index) => {
            return {
                id: service.id.toString(),
                color: colors[index],
            };
        });
    }, [services]);

    return { getSlotColor, organizationLocations };
};

export interface ISlotInfo {
    id: string;
    color: string;
}
