import { QueryInvoiceParamsType } from './models';
import { SearchParams, SearchParamsComplete } from '../components/ErrorManagement/hooks/useErrorLogs';
import { formatISODate, formatISODateTime } from './utils';
import moment from 'moment';

export class ApiRoutes {
    //auth
    static userProfileRoute = '/auth/me';
    static userProfilesRoute = '/auth/me/profiles';
    static loginRoute = '/auth/login';
    static loginCodeRoute = '/auth/login/code';
    static requestLoginOtpRoute = '/auth/login/otp';
    static patientRegistrationRoute = '/auth/registration/patients';
    static checkFieldTakenRoute = '/auth/registration/check-field-taken';
    static requestPasswordResetRoute = '/auth/password-reset/';
    static passwordResetCheckRecoveryToken = '/auth/password-reset/validate_token/';
    static passwordResetSetNewPassword = '/auth/password-reset/confirm/';
    static refreshTokenRoute = '/auth/refresh-token';

    static secondOpinionTokenRoute = '/auth/second-opinion-generate-token';
    // Attachments
    static uploadAttachmentRoute = '/attachments/';

    // otp
    static otpRequestsRoute = '/otp/requests';

    // dashboard
    static dashboardStatisticRoute = '/stats';

    // appointments
    static appointmentsRoute = '/appointments/';
    static appointmentsWithSlotRoute = '/appointmentsWithSlots/';
    static appointmentIdRoute = (id: number): string => `/appointments/${id}`;
    static appointmentStepsRoute = (id: number): string => `/appointments/${id}/steps`;
    static appointmentVideoCallRoute = (id: number): string => `/appointments/${id}/video-call`;
    static appointmentPatientAccessRoute = (id: number) => `/appointments/patient-access/${id}`;
    static appointmentVideoCallPatientAccessRoute = (id: number) => `/appointments/patient-access/${id}/video-call`;
    static appointmentReasonsRoute = '/appointment/reasons/';
    static appointmentReasonsIdRoute = (id: number) => `/appointment/reasons/${id}`;
    static uploadAppointmentAttachmentRoute = (id: number): string => `/appointments/${id}/send_attachments/`;
    static uploadAppointmentRequestAttachmentRoute = (id: number): string =>
        `/appointment-requests/${id}/send_attachments/`;
    static appointmentAttachmentIdRoute = (id: number): string => `/appointments/attachments/${id}`;
    static slotBookingRoute = '/appointments/slot-booking';
    static cancelAppointmentRoute = (id: number): string => `/appointments/${id}/cancel`;
    static rescheduleAppointmentRoute = (id: number): string => `/appointments/${id}/reschedule`;

    // appointment_requests
    static appointmentRequestsRoute = '/appointment-requests';
    static appointmentRequestRoute = (id: number) => `/appointment-requests/${id}`;
    static appointmentRequestAttachmentIdRoute = (id: number) => `/appointment-requests/attachments/${id}`;
    static appointmentRequestMarkRespondedRoute = (id: number) => `/appointment-requests/${id}/mark_responded`;
    static appointmentRequestMarkPendingRoute = (id: number) => `/appointment-requests/${id}/mark_pending`;
    static appointmentRequestMarkCanceledRoute = (id: number) => `/appointment-requests/${id}/mark_canceled`;
    static appointmentRequestMarkRejectedRoute = (id: number) => `/appointment-requests/${id}/mark_rejected`;
    static appointmentRequestMarkApprovedRoute = (id: number, idAppointment: number) =>
        `/appointment-requests/${id}/mark_approved/${idAppointment}`;

    // organization
    static organizationsSlugRoute = (slug: string) => `/organizations/slug/${slug}`;
    static slotsOrganizationsRoute = '/slots/organization-slots/';

    static getCalendarOrganizationLocationsRoute = '/organizations/calendar/locations';

    // Invitation
    static validateInvitationRoute = '/invitations/validate-token';
    static acceptInvitationRoute = '/invitations/accept';

    // Slots criteria
    static slotsCriteriaRoute = '/slots/criteria';
    static slotsCriteriaHSRoute = '/slots/criteriaHS';

    static getOrganizationPatientRoute = (id: number) => `/organizations/patients/${id}`;
    static slotOrganizationsRoute = (id: string): string => `/slots/organization-slots/${id}`;

    ///Second opinion
    static invoices = ({
        page_size,
        current_page,
        date_from,
        date_to,
        date_due_from,
        date_due_to,
        status,
        search_params,
        sort_by,
        descending,
    }: QueryInvoiceParamsType): string => {
        const queryParams = [];

        if (page_size !== undefined) queryParams.push(`page_size=${page_size}`);
        if (current_page !== undefined) queryParams.push(`current_page=${current_page}`);
        if (date_from !== undefined) queryParams.push(`date_from=${date_from}`);
        if (date_to !== undefined) queryParams.push(`date_to=${date_to}`);
        if (date_due_from !== undefined) queryParams.push(`date_due_from=${date_due_from}`);
        if (date_due_to !== undefined) queryParams.push(`date_due_to=${date_due_to}`);
        if (status !== undefined) queryParams.push(`status=${status}`);
        if (sort_by !== undefined) queryParams.push(`sort_by=${sort_by}`);
        if (descending !== undefined) queryParams.push(`descending=${descending}`);
        if (search_params !== undefined && search_params.length !== 0)
            queryParams.push(`search_params=${search_params}`);

        const query = queryParams.join('&');
        return query ? `invoice/?${query}` : 'invoice/';
    };
    static billingItems = 'services/';
    static invoiceForAppointmentRoute = (id: number): string => `invoice/get_by_appointmentId/${id}`;
    static markAsCanceled = (id: number): string => `invoice/bill/${id}/mark_canceled`;
    static markAsPaid = (id: number): string => `invoice/bill/${id}/mark_paid`;
    static invoiceSendReminder = `invoice/invoice_reminder`;
    static invoiceRoute = 'invoice/';
    static updateBillingItems = 'services/update';
    static deleteBillingItems = (id: number) => `services/delete/${id}`;

    // Doctor
    static getDoctor = 'doctor/get_current_doctor';
    static getAllDoctors = 'doctor/';
    static createDoctor = 'doctor/';
    static updateDoctor = 'doctor/update';

    // BankData
    static uploadBankData = 'bank_data/';
    static getBankName = (bankNumber: string): string => `bank_data/${bankNumber}`;

    // Error Management
    static getAllErrors = (params: SearchParamsComplete) => {
        let string_params = '';

        const addParam = (paramTitle: string, p: string | undefined | number | boolean) => {
            if (p === '' || p === undefined) return string_params;

            return `${string_params}${string_params ? '&' : '?'}${paramTitle}=${p}`;
        };

        string_params = addParam('date_to', params.date_to);
        string_params = addParam('date_from', params.date_from);
        string_params = addParam('current_page', params.current_page);
        string_params = addParam('page_size', params.page_size);
        string_params = addParam('descending', params.descending);
        string_params = addParam('is_resolved', params.is_resolved);
        string_params = addParam('sort_by', params.sort_by);
        string_params = addParam('id_bill', params.id_bill);
        string_params = addParam('search_params', params.search_params);

        return `error_log/${string_params}`;
    };
    static resolveError = (id_error_log: number) => `error_log/error_log/${id_error_log}/mark_resolved`;
}
