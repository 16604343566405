import React from 'react';
import IconButton from '@mui/joy/IconButton';
import { InfoOutlined } from '@mui/icons-material';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { useModalState } from '../../shared/BetterModal/useModalState';
import { Divider, Grid, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { ErrorLog } from '../../../api/models/errorLog';
import { dateToLocaleString } from '../../../utils/stringUtils';
import moment from 'moment/moment';
import { BillStatus } from '../utils/BillStatus';
import { NumericOption } from '../../../utils/formUtils';

type Props = {
    errorLog: ErrorLog;
};
export const DetailsModal = ({ errorLog }: Props) => {
    const modalState = useModalState();
    const { t } = useTranslation();

    const statusList: NumericOption<BillStatus>[] = [
        { value: BillStatus.CANCELLED, label: t('errorManagement:modal_title_details.status_cancelled') },
        { value: BillStatus.PAID, label: t('errorManagement:modal_title_details.status_paid') },
        { value: BillStatus.PENDING, label: t('errorManagement:modal_title_details.status_pending') },
    ];
    const getStatusLabel = (status: BillStatus) => {
        let label = '';
        statusList.forEach((s) => {
            if (BillStatus[s.value] === status.toString()) label = s.label;
        });
        return label;
    };

    return (
        <BetterModal
            state={modalState}
            title={t('errorManagement:modal_title_details.title')}
            minWidth={500}
            body={
                <Stack spacing={2} overflow="hidden">
                    <Stack spacing={1}>
                        <Typography level="body-md" fontWeight="bold" fontSize={16}>
                            {t('errorManagement:modal_title_details.label_general_info')}
                        </Typography>
                        <Divider />
                        <Grid container spacing={1}>
                            <Grid xs={12} md={6}>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_id')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.id}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_is_resolved')}
                                    </Typography>
                                    <Typography level="body-md">
                                        {t(`buttons:${errorLog.is_resolved ? 'button_yes' : 'button_no'}`)}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12} md={6} direction="row" container>
                                <Grid>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_timestamp')}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Stack direction="column">
                                        <Typography level="body-md">
                                            <span>{dateToLocaleString(errorLog.timestamp)}</span>
                                        </Typography>
                                        <Typography level="body-md">
                                            <span>{moment(errorLog.timestamp).format('HH:mm')}</span>{' '}
                                            <span>{moment(errorLog.timestamp).format('dddd')}</span>
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Stack>
                            <Typography level="body-sm">
                                {t('errorManagement:modal_title_details.label_description')}
                            </Typography>
                            <Typography level="body-md">{errorLog.error_description}</Typography>
                        </Stack>
                        <Stack>
                            <Typography level="body-sm">
                                {t('errorManagement:modal_title_details.label_comment')}
                            </Typography>
                            <Typography level="body-md">{errorLog.comment}</Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography level="body-md" fontWeight="bold" fontSize={16}>
                            {t('errorManagement:modal_title_details.label_doctor_info')}
                        </Typography>
                        <Divider />
                        <Grid container spacing={1}>
                            <Grid xs={12} md={6}>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_personal_title')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.doctor.personal_title || '-'}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_name')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.doctor.name}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_surname')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.doctor.surname}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_phone_number')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.doctor.phone_number}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12} md={6} container>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_address')}
                                    </Typography>
                                    <Typography level="body-md">{`${errorLog.doctor.city} ${errorLog.doctor.post_code}, ${errorLog.doctor.street}`}</Typography>
                                </Stack>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_bank_number')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.doctor.bank_number || '-'}</Typography>
                                </Stack>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_fax_number')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.doctor.fax_number || '-'}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography level="body-md" fontWeight="bold" fontSize={16}>
                            {t('errorManagement:modal_title_details.label_patient_info')}
                        </Typography>
                        <Divider />
                        <Grid container spacing={1}>
                            <Grid xs={12} md={6}>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_name')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.patient.name}</Typography>
                                </Stack>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_surname')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.patient.surname}</Typography>
                                </Stack>
                                <Stack width="200%" direction="row" spacing={1} textOverflow="ellipsis">
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_email')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.patient.email}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12} md={6} container>
                                <Stack width="100%" direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_address')}
                                    </Typography>
                                    <Typography level="body-md">{`${errorLog.patient.city} ${errorLog.patient.post_code}, ${errorLog.patient.street}`}</Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography level="body-md" fontWeight="bold" fontSize={16}>
                            {t('errorManagement:modal_title_details.label_bill_info')}
                        </Typography>
                        <Divider />
                        <Grid container spacing={1} justifyContent="flex-start">
                            <Grid xs={12} md={6}>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_id')}
                                    </Typography>
                                    <Typography level="body-md">{errorLog.bill.id}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_email_sent')}
                                    </Typography>
                                    <Typography level="body-md">
                                        {t(`buttons:${errorLog.bill.email_sent ? 'button_yes' : 'button_no'}`)}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_total_price')}
                                    </Typography>
                                    <Typography level="body-md">
                                        {new Intl.NumberFormat('de-DE', {
                                            style: 'currency',
                                            currency: 'EUR',
                                        }).format(errorLog.bill.total_price)}
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">
                                        {t('errorManagement:modal_title_details.label_status')}
                                    </Typography>
                                    <Typography level="body-md">{getStatusLabel(errorLog.bill.status)}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">{t('invoice:pdf.invoice_number')}</Typography>
                                    <Typography level="body-md">
                                        <span>{dateToLocaleString(errorLog.bill.invoice_due_date)}</span>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">{t('invoice:invoice_date')}</Typography>
                                    <Typography level="body-md">
                                        <span>{dateToLocaleString(errorLog.bill.invoice_date)}</span>
                                    </Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}>
                                    <Typography level="body-sm">{t('invoice:invoice_due_date')}</Typography>
                                    <Typography level="body-md">
                                        <span>{dateToLocaleString(errorLog.bill.invoice_due_date)}</span>
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
            }
            button={
                <IconButton variant="outlined">
                    <InfoOutlined />
                </IconButton>
            }
        />
    );
};
