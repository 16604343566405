import React from 'react';
import { Alert } from '@mui/joy';
import { useTranslation } from 'react-i18next';

type Props = {
    otpCodeSent: boolean;
    emailExists: boolean | undefined;
};
export const OtpCodeAlert = ({ otpCodeSent, emailExists }: Props) => {
    const { t } = useTranslation();

    if (emailExists == undefined) return null;

    if (emailExists && otpCodeSent)
        return (
            <Alert color="success" variant="soft">
                {t('login:info_otp_code_sent')}
            </Alert>
        );

    if (!emailExists)
        return (
            <Alert color="danger" variant="soft">
                {t('login:warning_account_not_found')}
            </Alert>
        );

    return null;
};
