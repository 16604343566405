import styled, { css } from 'styled-components';
import { InvoiceStatus } from '../../../api/models';

export interface StyledBadgeProps {
    type?: InvoiceStatus;
}

const cssGreen = css`
    background: var(--joy-palette-neutral-softHoverBg);
    color: #4cc13a;
    &:after {
        background: #4cc13a;
    }
`;

const cssRed = css`
    background: var(--joy-palette-neutral-softHoverBg);
    color: #f92763;

    &:after {
        background: #f92763;
    }
`;

const cssGrey = css`
    background: var(--joy-palette-neutral-softHoverBg);
    color: #747478;

    &:after {
        background: #747478;
    }
`;

const cssBlue = css`
    background: var(--joy-palette-neutral-softHoverBg);
    color: #4895ff;

    &:after {
        background: #4895ff;
    }
`;

export const StyledBadge = styled.span<StyledBadgeProps>`
    display: block;
    position: relative;
    border-radius: 13px;
    font-weight: 600;
    font-size: 10px;
    color: black;
    padding: 6px 11px 7px 22px;
    height: 27px;
    box-sizing: border-box;
    width: fit-content;

    &:after {
        position: absolute;
        top: 11px;
        left: 11px;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
    ${(props) => props.type === InvoiceStatus.Paid && cssGreen}
    ${(props) => props.type === InvoiceStatus.Cancelled && cssGrey}
    ${(props) => props.type === InvoiceStatus.Overdue && cssRed}
    ${(props) => props.type === InvoiceStatus.Pending && cssBlue}
`;
