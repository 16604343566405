import React, { useEffect, useState } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { InvoiceMobileProps, InvoiceMobileValues } from '../InvoiceMobileForm.types';
import { FormikProps } from 'formik';
import { AddOutlined } from '@mui/icons-material';
import { BetterModal } from '../../../../../../shared/BetterModal/BetterModal';
import { useModalState } from '../../../../../../shared/BetterModal/useModalState';
import { useInvoiceData } from '../../../hooks/useInvoiceData';
import AddInvoiceItemBody, { InvoiceItemBodyValues } from './AddInvoiceBillingItem';
import { InvoiceItemDesktopDisplay } from './InvoiceItemDesktopDisplay';
import { InvoiceItemMobileDisplay } from './InvoiceItemMobileDisplay';
import { useWindowSize } from '../../../../../../shared/hooks/useWindowSize';

type Props = {
    baseProps: InvoiceMobileProps & FormikProps<InvoiceMobileValues>;
};
export const InvoiceItemTable = ({ baseProps }: Props) => {
    const modalState = useModalState();
    const { invoiceBillingItems } = useInvoiceData();
    const { isExtraSmall } = useWindowSize();
    const { t } = useTranslation();
    const tableHeaders: { label: string; xs: number }[] = [
        { label: t('invoice:invoice_name'), xs: 3 },
        { label: t('invoice:table.factor'), xs: 1 },
        { label: t('invoice:table.price'), xs: 1 },
        /*{ label: t('invoice:table.vat'), xs: 1 },
        { label: t('invoice:table.net'), xs: 1 },*/
        { label: t('invoice:table.gross'), xs: 1 },
        { label: t('invoice:table.delete'), xs: 1 },
    ];
    const [items, setItems] = useState(baseProps.values.items || []);

    useEffect(() => {
        setItems(baseProps.values.items || []);
    }, [baseProps.values.items]);

    const addInvoiceBillingItem = (values: InvoiceItemBodyValues) => {
        modalState.toggleModal();
        const curr = [...items];
        curr.push({
            number: values.selectedItem?.number || 0,
            name: values.selectedItem?.description || '',
            factor: values.factor || 0,
            price: values.selectedItem?.price || 0.0,
            factor_is_constant: values.selectedItem?.factor_is_constant || false,
            vat: values.vat,
        });
        setItems(curr);
        baseProps.setFieldValue('items', curr);
    };

    const getTotal = (gross: boolean) => {
        if (baseProps.values.items === undefined) return 0;

        let total = 0;
        for (const item of baseProps.values.items) {
            const price = item.price * item.factor;
            total += gross ? price : price * (1 - item.vat / 100);
        }
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total);
    };

    return (
        <Stack spacing={1}>
            {!isExtraSmall ? (
                <InvoiceItemDesktopDisplay tableHeaders={tableHeaders} baseProps={baseProps} />
            ) : (
                <InvoiceItemMobileDisplay baseProps={baseProps} />
            )}
            <BetterModal
                state={modalState}
                disabled={invoiceBillingItems.length === 0}
                minWidth={300}
                title={t('invoice:modal.title')}
                button={
                    <Button
                        disabled={invoiceBillingItems.length === 0}
                        fullWidth
                        startDecorator={<AddOutlined />}
                        variant="outlined"
                        color="neutral"
                    >
                        {t('invoice:modal.title')}
                    </Button>
                }
                body={
                    <AddInvoiceItemBody
                        baseProps={baseProps}
                        handleSubmit={addInvoiceBillingItem}
                        invoiceBillingItems={invoiceBillingItems}
                    />
                }
            />
            <Stack justifyContent="right" alignItems="flex-end" spacing={1}>
                <Stack spacing={1} direction="row">
                    <Typography>{t('invoice:table.total_net')}:</Typography>
                    <Typography>{getTotal(false)}</Typography>
                </Stack>
                <Stack spacing={1} direction="row">
                    <Typography>{t('invoice:table.total_gross')}:</Typography>
                    <Typography>{getTotal(true)}</Typography>
                </Stack>
                <Divider />
            </Stack>
        </Stack>
    );
};
