import { CSSProperties } from 'react';

const colorDark = '#222';
const colorDark2 = '#666';
const colorGray = '#e3e3e3';
const colorWhite = '#fff';

interface CSSClasses {
    [key: string]: CSSProperties;
}

const styles: CSSClasses = {
    dark: {
        color: colorDark,
    },

    white: {
        color: colorWhite,
    },

    border: {
        border: `2px solid ${colorDark}`,
    },

    'bg-dark': {
        backgroundColor: colorDark2,
    },

    'bg-gray': {
        backgroundColor: colorGray,
    },

    flex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '0px',
    },
    marginLeft: {
        marginLeft: 'auto',
    },
    textLeft: {
        textAlign: 'left',
    },
    inlineBlock: {
        display: 'inline-block',
    },
    block: {
        display: 'block',
    },
    flexCenter: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    flexRight: {
        alignItems: 'end',
        justifyContent: 'end',
        textAlign: 'right',
        textAlignLast: 'end',
    },

    textCenter: {
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        textAlignLast: 'center',
        verticalAlign: 'middle',
    },
    'w-auto': {
        flex: 1,
        paddingRight: '8px',
    },

    'm-0': {
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '0px',
        marginRight: '0px',
    },
    'ml-30': {
        flex: 1,
    },

    'ml-50': {
        marginLeft: '40%',
    },
    'mr-10': {
        marginRight: '10px',
    },
    'ml-10': {
        marginLeft: '10px',
    },
    'w-100': {
        width: '100%',
    },
    'w-75': {
        width: '60%',
    },
    'w-70': {
        width: '70%',
    },
    'w-50': {
        width: '50%',
    },

    'w-55': {
        width: '55%',
    },

    'w-45': {
        width: '45%',
    },

    'w-60': {
        width: '60%',
    },

    'w-40': {
        width: '40%',
    },

    'w-48': {
        width: '48%',
    },
    'w-33': {
        width: '33%',
    },
    'w-30': {
        width: '30%',
    },
    'w-25': {
        width: '25%',
    },
    'w-17': {
        width: '17%',
    },

    'w-18': {
        width: '18%',
    },
    'w-15': {
        width: '15%',
    },
    'w-13': {
        width: '13%',
    },
    'w-12': {
        width: '12.5%',
    },
    'w-10': {
        width: '10%',
    },
    'w-8': {
        width: '8%',
    },
    'w-7': {
        width: '7%',
    },
    row: {
        borderBottom: `1px solid ${colorGray}`,
    },

    'mt-40': {
        marginTop: '40px',
    },

    'mt-30': {
        marginTop: '30px',
    },

    'mt-20': {
        marginTop: '20px',
    },

    'mt-10': {
        marginTop: '10px',
    },

    'mb-10': {
        marginBottom: '10px',
    },
    'mb-5': {
        marginBottom: '5px',
    },

    'p-4-8': {
        padding: '4px 8px',
    },

    'p-5': {
        padding: '5px',
    },

    'pb-10': {
        paddingBottom: '10px',
    },

    right: {
        textAlign: 'right',
    },

    bold: {
        fontWeight: 'bold',
    },

    grayFont: {
        color: colorGray,
    },

    'fs-20': {
        fontSize: '20px',
    },

    'fs-45': {
        fontSize: '45px',
    },
    'fs-12': {
        fontSize: '12px',
    },
    'fs-10': {
        fontSize: '10px',
    },
    'fs-8': {
        fontSize: '8px',
    },

    'invoice-right': {
        justifyContent: 'right',
        textAlign: 'right',
        marginLeft: 'auto',
    },
    page: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        color: '#555',
        padding: '40px 35px',
    },

    // span: {
    //     padding: '4px 12px 4px 0',
    // },

    logo: {
        display: 'block',
    },
};

export default styles;
