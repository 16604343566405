import React from 'react';

import { Box, Button, Typography } from '@mui/joy';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppointmentModel } from 'api/models/AppointmentModel';
import { DetailsTabs } from 'components/Dashboard/AppointmentDetailsSection/DetailsTabs/DetailsTabs';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { appointmentListRoute } from 'components/shared/Routing/routes';

type Props = {
    appointment?: AppointmentModel;
    reloadAppointments: () => void;
    isMobile: boolean;
};

export const NextAppointment = ({ appointment, reloadAppointments, isMobile }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isExtraSmall } = useWindowSize();

    const handleAppointmentPageNavigation = () => {
        navigate({ pathname: appointmentListRoute });
    };

    const noAppointmentsSheet = () => (
        <Box
            sx={{
                p: 1,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                marginTop: 2,
                marginBottom: 2,
            }}
        >
            <Typography fontWeight="bold" level="h4">
                {t('dashboard:no_appointments')}
            </Typography>
            <Box sx={{ width: { sm: '100%', lg: '75%' } }}>
                <Typography fontWeight="bold" level="body-xs">
                    {t('dashboard:no_appointments_description')}
                </Typography>
            </Box>
            <Button
                onClick={handleAppointmentPageNavigation}
                sx={{ backgroundColor: 'var(--joy-palette-primary-solidBg)' }}
            >
                <Typography level="body-sm" sx={{ color: 'var(--joy-palette-primary-solidColor)' }}>
                    {t('dashboard:no_appointments_button')}
                </Typography>
            </Button>
        </Box>
    );

    return (
        <Box
            sx={{
                borderRadius: 'sm',
                paddingTop: 1,
                width: '100%',
                maxWidth: '100%',
                height: isExtraSmall ? '500px' : '100%',
                display: 'flex',
                flexGrow: 1,
                flexShrink: 1,
                flexDirection: 'column',
                justifyContent: 'start',

                overflowY: 'auto',
            }}
        >
            <Box>
                <Typography
                    level="title-md"
                    sx={{ fontWeight: 'bold', paddingLeft: 1, marginBottom: 2, textAlign: 'start' }}
                >
                    {t('dashboard:next_appointment')}
                </Typography>
            </Box>

            {appointment ? (
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        textAlign: 'start',
                    }}
                >
                    <DetailsTabs
                        appointment={appointment}
                        isMobile={isMobile}
                        reloadAppointments={reloadAppointments}
                        dashboardHeight="100%"
                    />
                </Box>
            ) : (
                noAppointmentsSheet()
            )}
        </Box>
    );
};
