import { TFunction } from 'i18next';
import { MenuDisplayMode, RouteFilter, RouteInfo, SecureMode } from './routes.types';
import { isArray } from 'rrule/dist/esm/helpers';
import { UserRole } from '../../../api/models';
import { routeObjects } from './routeObjects';

export const grantAccess = (routeSecureMode: SecureMode, userSecureMode: SecureMode | undefined): boolean => {
    if (!userSecureMode) return false;

    if (!isArray(routeSecureMode)) return userSecureMode === routeSecureMode;

    for (const routeRole of routeSecureMode) {
        if (!userSecureMode.includes(routeRole)) continue;
        return true;
    }
    return false;
};
export const mapUserRoleToSecuredMode = (userRole: UserRole | undefined | null | 'ignore'): SecureMode | undefined => {
    if (userRole === undefined) return undefined;
    return userRole
        ? Object.values(UserRole).filter((r) => r >= (userRole || 0))
        : userRole === 'ignore'
        ? Object.values(UserRole)
        : [];
};
export const filterRoutes = (
    t: TFunction,
    { userRole = 'ignore', menuDisplay, forceSecureMode }: RouteFilter,
): RouteInfo[] => {
    const routes = routeObjects(t);
    let secureMode = mapUserRoleToSecuredMode(userRole);
    if (forceSecureMode) secureMode = forceSecureMode;
    const displayMode: MenuDisplayMode[] = menuDisplay ? [menuDisplay] : ['show', 'hide'];

    return routes.filter((r) => grantAccess(r.secureMode, secureMode) && displayMode.includes(r.menuDisplay));
};

export const routeArrToRouteStr = (routeObject: RouteInfo): string => {
    if (!isArray(routeObject.route)) return routeObject.route;

    return routeObject.route.length > 0 ? routeObject.route[0] : '';
};
