import { useState, useCallback, useMemo } from 'react';
import { BookingStatus } from 'components/Dashboard/StatusBadge/StatusBadge';
import { AppointmentParams, AppointmentRequestParams, AppointmentsDataPage, SortTypes } from '../api/models';
import { api } from '../api';
import {
    basicAppointmentDataObject,
    basicWaitingRoomDataObject,
    MyAppointmentsData,
    MyWaitingRoomAppointmentsData,
} from './constants';
import { AppointmentRequestStatus } from '../api/models';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useErrorContext } from 'context/errorContext';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { appointmentQueryKey } from './queryKeys/appointment.querykey';

export enum AppointmentRevalidation {
    PAST = 'past-appointments',
    UPCOMING = 'upcoming-appointments',
    CANCELLED = 'cancelled-appointments',
    WAITING = 'waiting-appointments',
}

const mapRevalidationToQueryKey = (revalidation: AppointmentRevalidation): (() => string) => {
    switch (revalidation) {
        case AppointmentRevalidation.PAST:
            return appointmentQueryKey.pastAppointments;
        case AppointmentRevalidation.UPCOMING:
            return appointmentQueryKey.upcomingAppointments;
        case AppointmentRevalidation.CANCELLED:
            return appointmentQueryKey.cancelledAppointments;
        case AppointmentRevalidation.WAITING:
            return appointmentQueryKey.waitingAppointments;
        default:
            throw new Error(`Unknown revalidation type: ${revalidation}`);
    }
};

export const useAppointments = (searchPhrase?: string | undefined, dateFrom?: Date, sortType?: SortTypes) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { error, setError } = useErrorContext();
    const [fetchEnabled, setFetchEnabled] = useState(true);

    const [isLoadMoreLoading, setIsLoadMoreLoading] = useState<{
        [key in AppointmentRevalidation]: boolean;
    }>({
        [AppointmentRevalidation.PAST]: false,
        [AppointmentRevalidation.UPCOMING]: false,
        [AppointmentRevalidation.CANCELLED]: false,
        [AppointmentRevalidation.WAITING]: false,
    });

    const [limits, setLimits] = useState<{ [key in AppointmentRevalidation]: number }>({
        [AppointmentRevalidation.PAST]: 8,
        [AppointmentRevalidation.UPCOMING]: 8,
        [AppointmentRevalidation.CANCELLED]: 8,
        [AppointmentRevalidation.WAITING]: 8,
    });

    const [upcomingAppointments, setUpcomingAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [pastAppointments, setPastAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [cancelledAppointments, setCancelledAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [onGoingAppointments, setOnGoingAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [waitingRoomAppointments, setWaitingRoomAppointments] =
        useState<MyWaitingRoomAppointmentsData>(basicWaitingRoomDataObject);

    const commonQueryConfig: UseQueryOptions<AppointmentsDataPage, unknown, AppointmentsDataPage> = {
        staleTime: 1 * 60 * 1000,
        refetchInterval: 1 * 60 * 1000,
        keepPreviousData: true,
        enabled: fetchEnabled,
        notifyOnChangeProps: ['data', 'error'],
    };

    const fetchAppointments = async (params: AppointmentParams) => {
        try {
            const requestAppointments = api.getAppointments(params);
            return await requestAppointments;
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            } else {
                setError({ message: 'Failed to fetch appointments' });
            }
            setFetchEnabled(false);
            throw e;
        }
    };

    const { data: upcomingAppointmentData, isLoading: upcomingAppointmentLoading } = useQuery({
        queryFn: () =>
            fetchAppointments({
                statuses: [BookingStatus.upcoming],
                search: searchPhrase,
                sort: sortType || SortTypes.dateAsc,
                dateFrom: dateFrom,
                limit: limits[AppointmentRevalidation.UPCOMING],
            }),
        queryKey: [
            appointmentQueryKey.upcomingAppointments(),
            searchPhrase,
            sortType,
            dateFrom,
            limits[AppointmentRevalidation.UPCOMING],
        ],
        ...commonQueryConfig,
    });

    const { data: pastAppointmentData, isLoading: pastAppointmentLoading } = useQuery({
        queryFn: () =>
            fetchAppointments({
                statuses: [BookingStatus.past],
                search: searchPhrase,
                sort: sortType || SortTypes.dateDesc,
                dateFrom: dateFrom,
                limit: limits[AppointmentRevalidation.PAST],
            }),
        queryKey: [
            appointmentQueryKey.pastAppointments(),
            sortType,
            dateFrom,
            searchPhrase,
            limits[AppointmentRevalidation.PAST],
        ],
        ...commonQueryConfig,
    });

    const { data: cancelledAppointmentData, isLoading: cancelledAppointmentLoading } = useQuery({
        queryFn: () =>
            fetchAppointments({
                statuses: [BookingStatus.cancelled],
                search: searchPhrase,
                sort: sortType || SortTypes.dateDesc,
                dateFrom: dateFrom,
                limit: limits[AppointmentRevalidation.CANCELLED],
            }),
        queryKey: [
            appointmentQueryKey.cancelledAppointments(),
            searchPhrase,
            sortType,
            dateFrom,
            limits[AppointmentRevalidation.CANCELLED],
        ],
        ...commonQueryConfig,
    });

    const fetchAppointmentRequests = async (params: AppointmentRequestParams) => {
        try {
            const requestAppointments = await api.getAppointmentRequests(params);
            return requestAppointments;
        } catch (e) {
            setFetchEnabled(false);
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            } else {
                setError({ message: 'Failed to fetch appointments' });
            }
            throw e;
        }
    };

    const { data: waitingAppointmentData, isLoading: waitingAppointmentLoading } = useQuery({
        queryFn: () =>
            fetchAppointmentRequests({
                statuses: [AppointmentRequestStatus.PENDING],
                limit: limits[AppointmentRevalidation.CANCELLED],
                sort: sortType || SortTypes.dateDesc,
                dateFrom: dateFrom,
            }),
        queryKey: [appointmentQueryKey.waitingAppointments(), sortType, dateFrom],
        staleTime: 1 * 60 * 1000,
        refetchInterval: 1 * 60 * 1000,
        keepPreviousData: true,
        enabled: fetchEnabled,
        notifyOnChangeProps: ['data', 'error'],
    });

    const loadMoreQuery = async (appointmentType: AppointmentRevalidation) => {
        setIsLoadMoreLoading((prev) => ({ ...prev, [appointmentType]: true }));
        setLimits((prev) => ({
            ...prev,
            [appointmentType]: prev[appointmentType] + 5,
        }));

        await queryClient.invalidateQueries(mapRevalidationToQueryKey(appointmentType)());
        await queryClient.refetchQueries(mapRevalidationToQueryKey(appointmentType)());

        setIsLoadMoreLoading((prev) => ({ ...prev, [appointmentType]: false }));
    };

    const { pastAppointmentsQueryData } = useMemo(() => {
        const pastAppointmentsQueryData: MyAppointmentsData = {
            next: pastAppointmentData?.next || '',
            appointments: pastAppointmentData?.appointments || [],
            totalCount: pastAppointmentData?.totalCount || 0,
            isLoading: pastAppointmentLoading,
        };
        return { pastAppointmentsQueryData };
    }, [pastAppointmentData, pastAppointmentLoading]);

    const { cancelledAppointmentsQueryData } = useMemo(() => {
        const cancelledAppointmentsQueryData: MyAppointmentsData = {
            next: cancelledAppointmentData?.next || '',
            appointments: cancelledAppointmentData?.appointments || [],
            totalCount: cancelledAppointmentData?.totalCount || 0,
            isLoading: cancelledAppointmentLoading,
        };
        return { cancelledAppointmentsQueryData };
    }, [cancelledAppointmentData, cancelledAppointmentLoading]);

    const { upcomingAppointmentsQueryData } = useMemo(() => {
        const upcomingAppointmentsQueryData: MyAppointmentsData = {
            next: upcomingAppointmentData?.next || '',
            appointments: upcomingAppointmentData?.appointments || [],
            totalCount: upcomingAppointmentData?.totalCount || 0,
            isLoading: upcomingAppointmentLoading,
        };
        return { upcomingAppointmentsQueryData };
    }, [upcomingAppointmentData, upcomingAppointmentLoading]);

    const { waitingAppointmentsQueryData } = useMemo(() => {
        const waitingAppointmentsQueryData: MyWaitingRoomAppointmentsData = {
            next: waitingAppointmentData?.next || '',
            waitingRoomAppointments: waitingAppointmentData?.appointment_requests || [],
            totalCount: waitingAppointmentData?.totalCount || 0,
            isLoading: waitingAppointmentLoading,
        };
        return { waitingAppointmentsQueryData };
    }, [waitingAppointmentData, waitingAppointmentLoading]);

    const reloadAppointments = useCallback(() => {
        queryClient.invalidateQueries(appointmentQueryKey.upcomingAppointments());
        queryClient.invalidateQueries(appointmentQueryKey.pastAppointments());
        queryClient.invalidateQueries(appointmentQueryKey.cancelledAppointments());
        queryClient.invalidateQueries(appointmentQueryKey.waitingAppointments());
    }, [queryClient]);

    const getUpcomingWithOngoingAppointments = () => {
        const arr = { ...upcomingAppointments };
        onGoingAppointments.totalCount += onGoingAppointments.totalCount;
        onGoingAppointments.appointments.forEach((a) => arr.appointments.push(a));
        onGoingAppointments.appointments.sort(
            (a, b) => new Date(b.date_from).getTime() - new Date(a.date_from).getTime(),
        );
        return arr;
    };

    const getUpcomingWithOngoingAppointmentsQuery = () => {
        const arr = { ...upcomingAppointmentsQueryData };
        onGoingAppointments.totalCount += onGoingAppointments.totalCount;
        onGoingAppointments.appointments.forEach((a) => arr.appointments.push(a));
        onGoingAppointments.appointments.sort(
            (a, b) => new Date(b.date_from).getTime() - new Date(a.date_from).getTime(),
        );
        return arr;
    };

    const isLoading =
        upcomingAppointmentLoading ||
        cancelledAppointmentLoading ||
        waitingAppointmentLoading ||
        pastAppointmentLoading;

    return {
        onGoingAppointments,
        upcomingAppointments,
        upcomingWithOngoingAppointments: getUpcomingWithOngoingAppointments(),
        pastAppointments,
        cancelledAppointments,
        waitingRoomAppointments,
        upcomingAppointmentData,
        cancelledAppointmentData,
        pastAppointmentData,
        waitingAppointmentData,
        pastAppointmentsQueryData,
        upcomingAppointmentsQueryData,
        cancelledAppointmentsQueryData,
        waitingAppointmentsQueryData,
        upcomingWithOngoingAppointmentsQuery: getUpcomingWithOngoingAppointmentsQuery(),
        reloadAppointments,
        loadMoreQuery,
        isLoading,
        isLoadMoreLoading,
    };
};
