import React, { useEffect, useLayoutEffect, useRef } from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import useKlaro from 'klaro/useKlaro';
import { useAuthContext } from 'components/Auth/AuthContext/AuthContext';

import { MainContent } from './components/MainContent/MainContent';
import { SecuredRoute } from './components/shared/SecuredRoute/SecuredRoute';
import { defaultRouteInfo, routeObjects } from 'components/shared/Routing/routeObjects';
import { Footer } from 'components/MainContent/BottomSlider/BottomSlider';
import { ErrorAlertDialog } from 'components/shared/ErrorPage/ErrorAlertDialog';
import { ErrorPage } from 'components/shared/ErrorPage/ErrorPage';
import { api } from 'api';
import { checkAuthentication } from 'components/Auth/actions';
import { setNavigate } from 'utils/loginNavigation';

export const App = () => {
    const [lang, setLang] = useQueryParam('lang', StringParam);
    const previousLang = useRef<string>('de');
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const defaultRoute = defaultRouteInfo(t, authContext?.isLoggedIn || false);
    const location = useLocation();
    const navigate = useNavigate();
    const { setupKlaro } = useKlaro();

    useLayoutEffect(() => {
        if (location.pathname === '/' || location.pathname === '/home') {
            if (authContext?.isLoggedIn) {
                navigate(`/dashboard?lang=${lang}`, { replace: true });
            } else {
                navigate(`/login?lang=${lang}`, { replace: true });
            }
        }
    }, [authContext?.isLoggedIn, navigate, location.pathname, lang]);

    useEffect(() => {
        if (!lang) {
            const langFromLocalStorage = localStorage.getItem('lang');
            if (langFromLocalStorage) {
                setLang(langFromLocalStorage);
                i18n.changeLanguage(langFromLocalStorage);
            } else {
                localStorage.setItem('lang', 'de');
                setLang('de');
                i18n.changeLanguage('de');
            }
        } else if (lang !== 'de' && lang !== 'en') {
            console.warn(`Language ${lang} is not supported. Reverting to previous language: ${previousLang.current}`);
            setLang(previousLang.current);
            i18n.changeLanguage(previousLang.current);
        } else {
            previousLang.current = lang;
            localStorage.setItem('lang', lang);
            setLang(lang);
            i18n.changeLanguage(lang);
        }

        setupKlaro(lang || 'de');
    }, [lang, setupKlaro, setLang]);

    useEffect(() => {
        const checkAuth = async () => {
            setNavigate(navigate);

            try {
                await checkAuthentication();
                api.client.refreshAndScheduleTokens();
            } catch (error) {
                console.error('Error during authentication check:', error);
            }
        };

        checkAuth();
    }, []);

    if (!authContext || !lang) {
        return <></>;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={<MainContent />}>
                    {routeObjects(t).map(
                        (element, index) =>
                            !element.omitMainContent && (
                                <Route
                                    key={index}
                                    path={element.route}
                                    element={
                                        <SecuredRoute secureMode={element.secureMode}>{element.component}</SecuredRoute>
                                    }
                                />
                            ),
                    )}
                    <Route
                        path={defaultRoute.route}
                        element={
                            <SecuredRoute secureMode={defaultRoute.secureMode}>
                                {defaultRoute?.siblingRoute?.component}
                            </SecuredRoute>
                        }
                    />
                </Route>

                {routeObjects(t).map(
                    (element, index) =>
                        element.omitMainContent && (
                            <Route key={index} path={element.route} element={element.component} />
                        ),
                )}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
            <ErrorAlertDialog />
            <Footer />
        </>
    );
};
