import { withFormik } from 'formik';
import { LoginProps, LoginValues } from './LoginForm.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './LoginForm.schema';
import { LoginData } from './LoginData';
import { withTranslation } from 'react-i18next';

const LoginForm = withFormik<LoginProps, LoginValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(LoginData);
export default withTranslation()(LoginForm);
