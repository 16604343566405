import { WithTranslation } from 'react-i18next';
import { ApiRequest } from '../../../../hooks/useApiRequest';
import { Slot, SlotCriteriaIds, SlotEditScope, SlotMode } from '../../../../api/models';
import { RRule } from 'rrule';
import { SlotData } from '../../../../api/models/forms/SlotData';
import { HourRange } from '../../hooks/Calendar.types';
import { ActiveSchedule } from './hooks/useSchedule';

export interface SlotProps extends WithTranslation {
    mode: SlotUiMode;
    activeSchedule: ActiveSchedule;
    request: ApiRequest;
    activeSlot: Slot | undefined;
    handleSubmit: (values: SlotData, slotKey: string, scope: SlotEditScope) => void;
    deleteSlot: (selectedOption: SlotEditScope) => void;
    hourRange: HourRange;
    closeSlotFormAndReloadAppointments: () => void;
    isMobile: boolean;
    toggleMainModal: () => void;
    secondOpinionReason: string;
}

export interface SlotValues {
    healthcare_service: number;
    appointment_reasons: string[];
    repeat: string;
    status: string;
    date: Date;
    date_hour: Date;
    dateTo_hour: Date;
    duration: number;
    comment: string;
    scope: SlotEditScope;
    recurring_option: RecurringSlotOption;
    recurring_rule?: RRule;
    criteriaIds: SlotCriteriaIds[];
    mode: SlotMode | null;
    duration_type: number;
    slot_visibility: number;
}
export enum RecurringSlotOption {
    dontRepeat = 'dontRepeat',
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly',
    everyWeekday = 'everyWeekday',
    custom = 'custom',
}

export interface SlotBookingData {
    slot: Slot;
    patient?: number;
    personal_title?: string;
    first_name: string;
    last_name: string;
    phone?: string;
    email: string;
    insurance_number?: string;
    insurance_status?: string;
    street?: string;
    city?: string;
    date_of_birth: Date | null;
    reason?: string;
    comment?: string;
    sms_agreement: boolean;
    email_agreement: boolean;
    terms_agreement: boolean;
    mode: SlotMode | null;
    criteria_values?: string[];
}

export enum SlotUiMode {
    Edit,
    Add,
}

export enum ScheduleType {
    appointment = 'appointment',
    slot = 'slot',
}
