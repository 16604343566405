import { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';

import { AppointmentModel, VideoCallDetails } from '../api/models';
import { api } from '../api';
import { useErrorContext } from 'context/errorContext';

const useAppointmentId = () => {
    const { appointmentId } = useParams<{ appointmentId?: string }>();
    return Number(appointmentId);
};
const useAppointmentDataForVideoCall = (appointmentId?: number) => {
    const { setError } = useErrorContext();

    const [tmpAppId, setAppointmentId] = useQueryParam('appId', NumberParam);
    let appId: number;
    const [appointment, setAppointment] = useState<AppointmentModel>();
    const [videoCall, setVideoCall] = useState<VideoCallDetails>();
    const [appointmentIncorrect, setAppointmentIncorrect] = useState(false);
    const [videoCallAvailable, setVideoCallAvailable] = useState(false);
    if (appointmentId) {
        appId = appointmentId;
    } else {
        appId = tmpAppId || 0;
    }

    useEffect(() => {
        loadObject();
    }, []);

    const loadObject = async () => {
        try {
            const [videoCall, appointment] = await Promise.all([loadVideoCall(), loadAppointment()]);
            if (videoCall) {
                setVideoCall(videoCall);
                setVideoCallAvailable(true);
            }
            setAppointment(appointment);
        } catch (e) {
            setAppointmentIncorrect(true);
            if (axios.isAxiosError(e)) {
                if (e.status === 400) {
                    setVideoCallAvailable(false);
                }
            } else {
                setError({ message: 'Unexpected error occured. Please, refresh the page' });
            }
        }
    };
    const loadAppointment = () => {
        return api.getAppointment(appId);
    };
    const loadVideoCall = () => {
        return api.getAppointmentVideoCall(appId);
    };

    return { appointmentIncorrect, appointment, videoCall, videoCallAvailable };
};

export default useAppointmentDataForVideoCall;
