import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import Box from '@mui/joy/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { StyledComponentProps, styled } from '@mui/material/styles';
import { Typography } from '@mui/joy';
import { useWindowSize } from 'components/shared/hooks/useWindowSize';
import { useAppointments } from '../../hooks/useAppointments';
import { useDashboardStatistics } from './hooks/useStatistics';
import { useInvoicesSecondOpinion } from 'components/Invoice/hooks/useInvoiceSecondOpinion';
import { useSlotsAndAppointNumber } from './hooks/useSlotAppointNumber';
import { useLoading } from 'components/shared/LoadingPage/useLoading';
import { useTodaysAppointments } from './hooks/useTodaysAppointments';
import { StatisticsCard } from './Dashboard/MainStatistics/StatisticsCard';
import { SlotChart } from './Dashboard/SlotChart/SlotChart';
import { TodaysAppointments } from './Dashboard/TodaysAppointments/TodaysAppointments';
import { NextAppointment } from './Dashboard/NextAppointment/NextAppointment';
import { Invoices } from './Dashboard/Invoices/Invoices';
import { AppointmentModel, InvoiceStatus, QueryInvoiceParamsType } from 'api/models';
import { getScaledVh } from '../../utils/styleUtils';
import { useQueryClient } from 'react-query';
import { useErrorContext } from 'context/errorContext';
import { dashboardQueryKey } from './hooks/dashboard.query-keys';
import { invoiceQueryKey } from 'components/Invoice/hooks/invoice.query-keys';
import { appointmentQueryKey } from 'hooks/queryKeys/appointment.querykey';

interface ItemProps extends StyledComponentProps {
    textalignment?: 'left' | 'center' | 'right';
    padding?: number;
}

export const Item = styled(Paper)<ItemProps>(({ theme, textalignment, padding = 1 }) => ({
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--mui-palette-background-paper)' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(padding),
    textAlign: textalignment || 'center',
    color: theme.palette.text.secondary,
}));

export const getFirstAndLastDayOfWeek = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + diff);
    const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6);

    return { dateFrom: startOfWeek, dateTo: endOfWeek };
};

export const Dashboard = () => {
    const queryClient = useQueryClient();
    const dateRange = useMemo(() => getFirstAndLastDayOfWeek(), []);
    const { error } = useErrorContext();
    const { isExtraSmall } = useWindowSize();
    const [queryParams, setQueryParams] = useState<QueryInvoiceParamsType>({
        page_size: undefined,
        current_page: undefined,
        date_from: undefined,
        date_to: undefined,
        date_due_from: undefined,
        date_due_to: undefined,
        status: InvoiceStatus.All,
        search_params: '',
    });

    const { upcomingAppointmentsQueryData, isLoading: isLoadingUpcommingApp, reloadAppointments } = useAppointments();
    const {
        unpaidInvoices,
        paidInvoices,
        isLoading: isInvoiceLoading,
        refetch,
        isRefetching,
    } = useInvoicesSecondOpinion(queryParams);

    const { dashboardStatistics } = useDashboardStatistics();
    const { noOfAppointments, freeSlots, isLoading: isSlotsLoading } = useSlotsAndAppointNumber(dateRange);

    const [displayedInvoices, setDisplayedInvoices] = useState(() =>
        unpaidInvoices.sort((a, b) => new Date(a.invoiceDate).getTime() - new Date(b.invoiceDate).getTime()),
    );

    const {
        todaysAppointments,
        isLoading: isToAppLoading,
        fetchNextPage: fetchNext,
        nextData,
    } = useTodaysAppointments();

    const [pickedAppointment, pickAppointment] = useState<AppointmentModel | undefined>(undefined);
    const [nextAppointment, setNextAppointment] = useState<AppointmentModel>();

    const todaysAppointmentsLength = upcomingAppointmentsQueryData.totalCount;
    const queryToRevalidate = useMemo(
        () => [
            invoiceQueryKey.invoice(),
            dashboardQueryKey.calendarSchedules(),
            dashboardQueryKey.statistics(),
            dashboardQueryKey.todaysAppointments(),
            appointmentQueryKey.upcomingAppointments(),
        ],
        [],
    );

    const handleQueryRevalidation = useCallback(() => {
        queryToRevalidate.forEach((key) => {
            queryClient.invalidateQueries(key);
        });
    }, [queryClient, queryToRevalidate]);

    const { isLoading } = useLoading(
        upcomingAppointmentsQueryData.isLoading || isToAppLoading || isInvoiceLoading || isLoadingUpcommingApp,
        error ? error : null,
    );

    useEffect(() => {
        handleQueryRevalidation();
        setDisplayedInvoices(unpaidInvoices);
        if (!isLoading && upcomingAppointmentsQueryData.appointments.length > 0) {
            setNextAppointment(upcomingAppointmentsQueryData.appointments[0]);
        } else {
            setNextAppointment(undefined);
        }
    }, [isLoading, unpaidInvoices, upcomingAppointmentsQueryData.appointments]);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: isExtraSmall ? getScaledVh(105, isExtraSmall) : '100%',
                gap: 2,
                width: '100%',
                justifyContent: 'center',
                textAlign: 'center',
                mx: 'auto',
            }}
        >
            {isExtraSmall ? (
                <>
                    <Item>
                        <StatisticsCard
                            height="100%"
                            title={t('dashboard:total_appointments_no')}
                            value={dashboardStatistics.total_appointments}
                        />
                    </Item>

                    <Box
                        sx={{
                            flexGrow: 1,
                            flexShrink: 0,
                            height: '100%',
                        }}
                    >
                        <Item>
                            <TodaysAppointments
                                isMobile
                                pickAppointment={pickAppointment}
                                pickedAppointment={pickedAppointment}
                                loadMore={fetchNext}
                                reloadAppointment={reloadAppointments}
                                hasMore={nextData}
                                noItemsMessage={t('appointmentList:message_appointments_upcoming_empty')}
                                appointments={todaysAppointments}
                                invoices={paidInvoices.concat(unpaidInvoices)}
                            />
                        </Item>
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 1,
                            flexShrink: 1,
                            height: '100%',
                        }}
                    >
                        <Item>
                            <NextAppointment
                                appointment={nextAppointment}
                                isMobile={false}
                                reloadAppointments={reloadAppointments}
                            />
                        </Item>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            height: '100%',
                            marginBottom: 8,
                        }}
                    >
                        <Item>
                            <Invoices isMobile={isExtraSmall} invoices={displayedInvoices} refetch={refetch} />
                        </Item>
                    </Box>
                </>
            ) : (
                <Grid container spacing={2} sx={{ height: getScaledVh(105, isExtraSmall) }}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{ order: { xs: 2, md: 1 }, display: 'flex', flexDirection: 'column' }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                flexGrow: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    display: { sm: 'none', md: 'flex' },
                                    flexDirection: 'column',
                                    flexGrow: 0,
                                    flexShrink: 1,
                                    height: '50%',
                                }}
                            >
                                <Item>
                                    <Typography
                                        level="h4"
                                        sx={{
                                            fontWeight: 'bold',
                                            textAlign: 'start',
                                            whiteSpace: 'normal',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxHeight: '100%',
                                            marginTop: 1,
                                            marginLeft: 1,
                                            maxWidth: { xs: '100%', sm: '250px', lg: '450px' },
                                            fontSize: { xs: '4vw', sm: '2vw', lg: '1.5vw' },
                                        }}
                                    >
                                        {t('dashboard:slot_statistics')}
                                    </Typography>
                                    <SlotChart
                                        totalAppointments={noOfAppointments}
                                        freeSlots={freeSlots}
                                        isLoading={isSlotsLoading}
                                    />
                                </Item>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: { xs: 1, md: 0 },
                                    height: '50%',
                                    marginBottom: { xs: 12, sm: 2, md: 0 },
                                }}
                            >
                                <Item>
                                    <Invoices isMobile={isExtraSmall} invoices={displayedInvoices} refetch={refetch} />
                                </Item>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={8}
                        sx={{ order: { xs: 1, md: 2 }, display: 'flex', flexDirection: 'column' }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 0,
                                marginBottom: isExtraSmall ? 8 : 0,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: 2,
                                    height: { sm: '25%', md: '20%' },
                                    width: '100%',
                                    flexGrow: 0,
                                }}
                            >
                                <Item>
                                    <StatisticsCard
                                        height="100%"
                                        title={t('dashboard:monthly_appointments')}
                                        value={dashboardStatistics.month_appointments}
                                    />
                                </Item>
                                <Item>
                                    <StatisticsCard
                                        height="100%"
                                        title={t('dashboard:total_appointments_no')}
                                        value={dashboardStatistics.total_appointments}
                                    />
                                </Item>
                            </Box>
                            <Box
                                sx={{
                                    height: todaysAppointmentsLength !== 0 ? '80%' : '100%',
                                    display: 'flex',
                                    flexGrow: 1,
                                    flexDirection: 'column',
                                    justifyContent: 'end',
                                    gap: 2,
                                }}
                            >
                                <Grid container spacing={2} sx={{ height: '100%' }}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            height: '100%',
                                        }}
                                    >
                                        <Item>
                                            <TodaysAppointments
                                                isMobile={isExtraSmall}
                                                pickAppointment={pickAppointment}
                                                pickedAppointment={pickedAppointment}
                                                loadMore={fetchNext}
                                                hasMore={nextData}
                                                noItemsMessage={t(
                                                    'appointmentList:message_appointments_upcoming_empty',
                                                )}
                                                reloadAppointment={reloadAppointments}
                                                appointments={todaysAppointments}
                                                invoices={paidInvoices.concat(unpaidInvoices)}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            height: '100%',
                                            marginBottom: { sm: 8, md: 0, lg: 0 },
                                        }}
                                    >
                                        <Item>
                                            <NextAppointment
                                                appointment={nextAppointment}
                                                isMobile={isExtraSmall}
                                                reloadAppointments={reloadAppointments}
                                            />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
