import { RRule } from 'rrule';
import { WithTranslation } from 'react-i18next';
import { Frequency, Weekday } from '../../../../../../api/models';

export interface RecurringFieldProps extends WithTranslation {
    onSubmit: (rule: RRule) => void;
    currentRule: RRule | undefined;
}

export enum EndsOnOption {
    never = 'never',
    onDay = 'onDay',
    afterX = 'afterX',
}

export interface RecurringFieldValues {
    interval: number;
    freq: Frequency;
    days: Weekday[];
    endsOn: EndsOnOption;
    until?: Date;
    count?: number;
}
