import { DateRange } from './Calendar.types';
import { useEffect, useState } from 'react';
import { startOfWeek, endOfWeek, addDays } from 'date-fns';

export const useSlotTime = (dayIndex: number, weekIndex: number) => {
    const [timeDayRange, setTimeDayRange] = useState<DateRange | undefined>(undefined);
    const [weekRange, setWeekRange] = useState<DateRange | undefined>(undefined);

    useEffect(() => {
        let curr = new Date();
        curr = addDays(curr, 7 * weekIndex);

        const start = startOfWeek(curr, { weekStartsOn: 1 });
        const end = endOfWeek(curr, { weekStartsOn: 1 });

        setWeekRange({ dateFrom: start, dateTo: end });
    }, [weekIndex]);

    useEffect(() => {
        let curr = new Date();
        curr = addDays(curr, dayIndex - curr.getDay() + 1 + 7 * weekIndex); // todo: this is retarded and needs to be changed, am bad at math man

        const start = new Date(curr);
        start.setHours(0, 0);
        const end = new Date(curr);
        end.setHours(23, 59, 0);

        setTimeDayRange({ dateFrom: start, dateTo: end });
    }, [dayIndex, weekIndex]);

    return { timeDayRange, weekRange };
};
