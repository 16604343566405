import useApiRequest from 'hooks/useApiRequest';
import { LoginData, LoginCredentials, UserUpdateData, LoginOtpRequestData } from 'api/models';
import { api, apiSecondOpinion } from 'api';
import { errorTimeout, messageTimeout } from 'utils/constants';
import { useEffect } from 'react';
import { BooleanParam, useQueryParam } from 'use-query-params';
import { useTemporaryState } from '../web-kit/hooks';
import { setAuthTokens } from '../utils/tokens';
import { useAuthContext } from '../components/Auth/AuthContext/AuthContext';

const useLoginFlow = () => {
    const request = useApiRequest({ errorTimeout });
    const afterPasswordReset = useQueryParam('password-reset', BooleanParam);
    const [emailNotFound, setEmailNotFound] = useTemporaryState(false, errorTimeout);
    const [otpCodeSent, setOtpCodeSent] = useTemporaryState(false, messageTimeout);
    const [passwordReset, setPasswordReset] = useTemporaryState(false, messageTimeout);
    const authContext = useAuthContext();

    useEffect(() => {
        if (afterPasswordReset) {
            setPasswordReset(true);
        }
    }, [afterPasswordReset, setPasswordReset]);

    const updateProfile = async (data: UserUpdateData) => {
        const userProfile = await api.updateUserProfile(data);
        authContext?.login(userProfile);
    };

    const ensureEmailExists = async (data: LoginCredentials) => {
        try {
            const exists = await request.dispatch(api.checkEmailTaken(data.email));
            setEmailNotFound(!exists);
            return exists;
        } catch (error) {
            return false;
        }
    };

    const submitCredentials = async (data: LoginCredentials) => {
        const credentialsData: LoginOtpRequestData = {
            email: data.email,
            password: data.password,
            organization_id: +(process.env.REACT_APP_ORGANIZATION_ID || 0),
        };
        await request.dispatch(api.requestLoginOtp(credentialsData));
        setOtpCodeSent(true);
    };

    const submitOtp = async (data: LoginCredentials) => {
        return await request.dispatch(dispatchLogin(data));
    };

    const dispatchLogin = async (data: LoginData): Promise<boolean> => {
        const response = await request.dispatch(api.login(data));
        setAuthTokens(response);
        const userProfile = await api.getUserProfile();
        return authContext?.login(userProfile) || false;
    };

    return {
        emailNotFound,
        otpCodeSent,
        passwordReset,
        request,
        ensureEmailExists,
        submitCredentials,
        submitOtp,
        updateProfile,
    };
};

export default useLoginFlow;
