import React, { useEffect, useState } from 'react';

import {
    Accordion,
    AccordionDetails,
    AccordionGroup,
    AccordionSummary,
    Box,
    Checkbox,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    List,
    Stack,
    Typography,
} from '@mui/joy';

import { NumericOption, Option } from '../../../utils/formUtils';
import { FilterList } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { OrganizationLocation } from 'api/models';

type GroupFilterOption = {
    groupName: string;
    serviceName: string;
};

export type FilterGroup = {
    title: string;
    options: (Option | NumericOption)[];
    key: string;
    many_options: boolean;
    default_values: Option[];
};

export type FilterTypes = {
    key: string;
    values: (string | number)[];
};

type FilterDrawerProps = {
    isOpen: boolean;
    toggleDrawer: (open: boolean) => void;
    applyFilters: (filteredTypes: FilterTypes[], filteredServices: GroupFilterOption[]) => void;
    filterGroups?: FilterGroup[];
    filterCityGroups?: OrganizationLocation[];
    locationSlotColor?: (serviceId: number) => string;
};

export const FilterDrawer = ({
    isOpen,
    toggleDrawer,
    applyFilters,
    filterGroups,
    filterCityGroups,
    locationSlotColor,
}: FilterDrawerProps) => {
    const { t } = useTranslation();

    const [filteredTypes, setFilteredTypes] = useState<FilterTypes[]>([]);
    const [setupComplete, setSetupComplete] = useState<boolean>(false);
    const [filteredServices, setFilteredServices] = useState<GroupFilterOption[]>([]);

    //without this type of refresh, checkboxes wouldnt update their value
    const [r, refresh] = useState(true);

    useEffect(() => {
        if (setupComplete) return;

        filterGroups?.forEach((f) =>
            filteredTypes.push({
                values: f.default_values.map((v) => v.value),
                key: f.key,
            }),
        );

        setSetupComplete(true);
    }, [setupComplete]);

    const handleFilterGroupChange = ({
        groupIndex,
        option,
        many_values,
    }: {
        groupIndex: string;
        option: string | number;
        many_values: boolean;
    }) => {
        const types = filteredTypes.find((f) => f.key === groupIndex);
        if (!types) return;
        const filteredList = types.values.filter((v) => v !== option);

        if (filteredList.length === types.values.length) {
            if (many_values) types.values.push(option);
            else types.values = [option];
        } else types.values = filteredList;

        const currentTypes = filteredTypes;
        const index = currentTypes.findIndex((f) => f.key === groupIndex);
        currentTypes[index] = types;

        setFilteredTypes(currentTypes);

        refresh(!r);
    };

    const isChecked = (groupKey: string, option: string | number): boolean => {
        const list = filteredTypes.find((f) => f.key === groupKey)?.values;
        return list ? list.includes(option) : false;
    };

    const handleFilterGroupServicesChange = (groupName: string, services: { name: string; id: string }[]) => {
        setFilteredServices((prev) => {
            const isEveryServiceIncluded = services.every((service) =>
                prev.some((s) => s.groupName === groupName && s.serviceName === service.name),
            );

            if (isEveryServiceIncluded) {
                return prev.filter((service) => service.groupName !== groupName);
            } else {
                const filteredOutExisting = prev.filter((service) => service.groupName !== groupName);
                const newServices = services.map((service) => ({
                    groupName,
                    serviceName: service.name,
                }));
                return [...filteredOutExisting, ...newServices];
            }
        });
    };

    const handleServiceChange = (service: GroupFilterOption) => {
        setFilteredServices((prev) => {
            const serviceIndex = prev.findIndex(
                (s) => s.groupName === service.groupName && s.serviceName === service.serviceName,
            );
            if (serviceIndex === -1) return [...prev, service];
            return prev.filter((_, index) => index !== serviceIndex);
        });
    };

    const handleCloseDrawer = () => {
        applyFilters(filteredTypes, filteredServices);
        toggleDrawer(false);
    };

    return (
        <Drawer variant="plain" color="neutral" open={isOpen} onClose={handleCloseDrawer}>
            <DialogTitle level="h2">
                <Typography startDecorator={<FilterList />}>{t('filterDrawer:title')}</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <AccordionGroup size="lg" disableDivider>
                    {filterGroups &&
                        filterGroups.map((filterGroup, index) => (
                            <Accordion defaultExpanded key={index}>
                                <AccordionSummary>
                                    <Typography>{filterGroup.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {filterGroup.options.map((filterOptions, filterOptionsIdx) => (
                                            <Stack
                                                key={filterOptionsIdx + filteredTypes.length.toString()}
                                                direction="row"
                                                spacing={1}
                                                marginBottom={1}
                                            >
                                                <Checkbox
                                                    onChange={() =>
                                                        handleFilterGroupChange({
                                                            groupIndex: filterGroup.key,
                                                            option: filterOptions.value,
                                                            many_values: filterGroup.many_options,
                                                        })
                                                    }
                                                    checked={isChecked(filterGroup.key, filterOptions.value)}
                                                    color="neutral"
                                                    variant="outlined"
                                                />
                                                <Typography>{filterOptions.label}</Typography>
                                            </Stack>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    {filterCityGroups &&
                        filterCityGroups.map((filterOptions, filterOptionsIdx) => (
                            <Accordion key={filterOptionsIdx} defaultExpanded>
                                <AccordionSummary>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            color="neutral"
                                            variant="outlined"
                                            checked={
                                                !filterOptions.service_set.every((service) =>
                                                    filteredServices.some(
                                                        (s) =>
                                                            s.groupName === filterOptions.name &&
                                                            s.serviceName === service.name,
                                                    ),
                                                )
                                            }
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleFilterGroupServicesChange(
                                                    filterOptions.name,
                                                    filterOptions.service_set.map((service) => ({
                                                        name: service.name,
                                                        id: service.id.toString(),
                                                    })),
                                                );
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                        <Typography alignSelf="self-start">{filterOptions.name}</Typography>
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails sx={{ marginLeft: 2, marginTop: 0 }}>
                                    <List>
                                        {filterOptions.service_set.map((service, serviceIdx) => (
                                            <Stack key={serviceIdx} direction="row" spacing={1} marginBottom={1}>
                                                <Checkbox
                                                    onChange={() =>
                                                        handleServiceChange({
                                                            groupName: filterOptions.name,
                                                            serviceName: service.name,
                                                        })
                                                    }
                                                    checked={
                                                        !filteredServices.some((s) => s.serviceName === service.name)
                                                    }
                                                    color="neutral"
                                                    slotProps={{
                                                        checkbox: {
                                                            sx: {
                                                                background: locationSlotColor
                                                                    ? locationSlotColor(service.id)
                                                                    : 'inherit',
                                                                '&:hover': {
                                                                    background: locationSlotColor
                                                                        ? locationSlotColor(service.id)
                                                                        : 'inherit',
                                                                },
                                                                '&&.Mui-checked': {
                                                                    color: 'white',
                                                                    backgroundColor: locationSlotColor
                                                                        ? locationSlotColor(service.id)
                                                                        : 'inherit',
                                                                    '&:hover': {
                                                                        backgroundColor: locationSlotColor
                                                                            ? locationSlotColor(service.id)
                                                                            : 'inherit',
                                                                    },
                                                                },
                                                            },
                                                        },
                                                    }}
                                                />
                                                <Typography>{service.name}</Typography>
                                            </Stack>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                </AccordionGroup>
            </DialogContent>
        </Drawer>
    );
};
