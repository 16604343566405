import React, { Fragment } from 'react';
import { Check, WarningOutlined } from '@mui/icons-material';
import IconButton from '@mui/joy/IconButton';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { useModalState } from '../../shared/BetterModal/useModalState';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Typography } from '@mui/joy';
import useApiRequest from '../../../hooks/useApiRequest';
import { apiSecondOpinion } from '../../../api';
import { useAlert } from '../../shared/BetterAlert/useAlert';

type Props = {
    errorLogId: number;
    refreshList: () => void;
    isResolved: boolean;
};
export const MarkResolvedModal = ({ errorLogId, refreshList, isResolved }: Props) => {
    const modalState = useModalState();
    const { t } = useTranslation();
    const request = useApiRequest();
    const { openAlert } = useAlert();

    const onClick = () => {
        request.dispatch(apiSecondOpinion.markErrorResolved(errorLogId)).finally(() => {
            openAlert(t('errorManagement:modal_title_mark_resolved.success'));
            modalState.toggleModal();
            refreshList();
        });
    };

    return (
        <Fragment>
            {!isResolved && (
                <BetterModal
                    state={modalState}
                    title={
                        <Typography startDecorator={<WarningOutlined />}>
                            {t('errorManagement:modal_title_mark_resolved.title')}
                        </Typography>
                    }
                    body={<Typography>{t('errorManagement:modal_title_mark_resolved.message')}</Typography>}
                    actions={
                        <ButtonGroup style={{ width: '100%' }} spacing={1} variant="solid">
                            <Button fullWidth color="primary" onClick={onClick} loading={request.isLoading}>
                                {t('buttons:button_yes')}
                            </Button>
                            <Button fullWidth color="danger" onClick={modalState.toggleModal}>
                                {t('buttons:button_no')}
                            </Button>
                        </ButtonGroup>
                    }
                    button={
                        <IconButton color="success" variant="outlined">
                            <Check />
                        </IconButton>
                    }
                />
            )}
        </Fragment>
    );
};
