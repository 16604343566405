import React from 'react';
import { SlotPermission } from '../../../../api/models';
import { Card, Grid, Stack, Theme, Typography } from '@mui/joy';
import {
    FolderOff,
    FolderOffOutlined,
    LanguageOutlined,
    LockOutlined,
    PeopleAltOutlined,
    VideocamOutlined,
} from '@mui/icons-material';
import { compareAsc } from 'date-fns';
import { useSlotsDisplay } from '../../hooks/useSlotsDisplay';
import { SlotProps } from '../SlotList';
import { getFullName } from '../../../../api/utils';

type Props = {
    slot: SlotProps;
    slotKey: number;
    vertical: boolean;
};
export const SlotItem = ({ slot, slotKey, vertical }: Props) => {
    const { getSlotColor } = useSlotsDisplay();
    const getSlotIcon = () => {
        if (slot.appointment) return slot.appointment.mode == 'ONLINE' ? <VideocamOutlined /> : <FolderOffOutlined />;

        switch (slot.emptySlot?.slot_permission) {
            case SlotPermission.INTERNAL:
                return <PeopleAltOutlined />;
            case SlotPermission.PRIVATE:
                return <LockOutlined />;
            case SlotPermission.PUBLIC:
                return <LanguageOutlined />;
        }
    };

    const addZeroBefore = (n: number) => {
        return (n < 10 ? '0' : '') + n;
    };
    const getTime = (): string => {
        const dateFrom = new Date(slot.date_from);
        const dateTo = new Date(slot.date_to);
        const timeFrom = dateFrom.getHours() + ':' + addZeroBefore(dateFrom.getMinutes());
        const timeTo = dateTo.getHours() + ':' + addZeroBefore(dateTo.getMinutes());
        return timeFrom + '-' + timeTo;
    };

    const gradientPercentage = vertical ? '30px' : '55px';
    const detailsTextAlign = vertical ? 'left' : 'left';
    const detailsTextWidth = vertical ? '100%' : '50%';
    const isActive = compareAsc(new Date(slot.date_from), new Date()) === 1;

    const backgroundColor = (theme: Theme) =>
        slot.emptySlot
            ? theme.vars.palette.background.level1
            : `color-mix(in srgb, ${getSlotColor(slot.healthcare_service.id)} 30%, ${
                  theme.vars.palette.background.level1
              })`;

    return (
        <Card
            key={slotKey}
            sx={(theme) => ({
                paddingX: vertical ? '3px' : '16px',
                mr: 0.5,
                ml: 0.5,
                mt: 0.5,
                opacity: !isActive ? 0.5 : 1,
                '&:hover': { opacity: !isActive ? 0.5 : vertical && isActive ? 0.8 : 1 },
                background: `linear-gradient(90deg, color-mix(in srgb, ${getSlotColor(slot.healthcare_service.id)} ${
                    slot.emptySlot ? '35' : '100'
                }%, transparent) ${gradientPercentage}, ${backgroundColor(theme)} ${gradientPercentage})`,
            })}
        >
            <Stack
                style={{ height: vertical ? '40px' : '20px' }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                {getSlotIcon()}
                <Grid
                    direction={vertical ? 'column' : 'row'}
                    spacing={0.5}
                    marginLeft={3}
                    container
                    width="100%"
                    alignItems="center"
                >
                    <Grid xs={6} style={{ textAlign: detailsTextAlign }} width={detailsTextWidth}>
                        <Typography fontSize={vertical ? 15 : 20}>{getTime()}</Typography>
                    </Grid>
                    <Grid xs={6} style={{ textAlign: detailsTextAlign }} width={detailsTextWidth}>
                        <Typography fontSize={vertical ? 15 : 20}>
                            {slot.appointment ? getFullName(slot.appointment.patient) : slot?.healthcare_service.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Card>
    );
};
