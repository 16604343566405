import React from 'react';

import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';

import { PeopleOutlineTwoTone } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

type Props = {
    title: string;
    value: number;
    height?: string;
    day?: boolean;
};

export const StatisticsCard = ({ title, value, day = false }: Props) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignContent: 'center',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'start',
                    justifyContent: 'space-between',
                    width: '100%',
                    flexWrap: 'wrap',
                }}
            >
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                    <Typography
                        level="h3"
                        sx={{
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textAlign: 'left',
                            textOverflow: 'ellipsis',
                            maxWidth: { xs: '100%', sm: '250px', lg: '350px', xl: '450px' },
                            fontSize: { xs: '4vw', sm: '1.7vw', lg: '1.4vw' },
                        }}
                    >
                        {title}
                        {day && (
                            <span style={{ fontSize: '1rem', fontWeight: '400', color: 'gray' }}>
                                / {t('editSlotModal:customRepetition.frequency.option_day')}
                            </span>
                        )}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        padding: { xs: 0.5, sm: 0.25, lg: 0.5 },
                        borderRadius: '5px',
                        color: 'white',
                        marginBottom: 0.5,
                        flexShrink: 0,
                        backgroundColor: 'var(--joy-palette-primary-solidBg)',
                    }}
                >
                    <PeopleOutlineTwoTone sx={{ xs: '4vw', sm: '1.5vw', lg: '1.2vw' }} />
                </Box>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignSelf: 'end',
                    paddingTop: 1,
                    width: '100%',
                }}
            >
                <Typography
                    level="h2"
                    sx={{
                        whiteSpace: 'normal',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: { xs: '100%', sm: '200px', lg: '250px' },
                        fontSize: { xs: '4vw', sm: '1.7vw', lg: '1.4vw' },
                    }}
                >
                    {value}
                </Typography>
            </Box>
        </Box>
    );
};
