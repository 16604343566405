import { ApiRoutes } from './ApiRoutes';
import { HttpClientSecondOpinion } from './HttpClientSecondOpinion';
import {
    Invoice,
    InvoiceItemData,
    InvoiceReminderData,
    InvoiceReminderRequestData,
    InvoiceRequestData,
    InvoiceRequestDataWithMetadata,
    InvoiceStatus,
    UserDoctor,
} from './models';
import { BankData } from './models/BankData';
import { ErrorLog, ErrorLogResponse } from './models/errorLog';
import { SearchParamsComplete } from '../components/ErrorManagement/hooks/useErrorLogs';

export class SecondOpinionApi {
    second_opinion_client = new HttpClientSecondOpinion(process.env.REACT_APP_API_SECOND_OPINION_URL || '');

    setSecondOpinionAuthToken(token: string): void {
        this.second_opinion_client.setCommonHeader('X-SecondOpinion-Token', token);
    }

    async getBillingItems(): Promise<InvoiceItemData[]> {
        const response = await this.second_opinion_client.get(ApiRoutes.billingItems);
        return response.data;
    }
    async createBillingItems(data: InvoiceItemData): Promise<InvoiceItemData> {
        const response = await this.second_opinion_client.post(ApiRoutes.billingItems, data);
        return response.data;
    }

    async editBillingItems(data: InvoiceItemData): Promise<InvoiceItemData> {
        const response = await this.second_opinion_client.post(ApiRoutes.updateBillingItems, data);
        return response.data;
    }

    async deleteBillingItems(id: number): Promise<void> {
        await this.second_opinion_client.get(ApiRoutes.deleteBillingItems(id));
    }

    async markInvoiceAsCancelled(id: number): Promise<InvoiceRequestData> {
        const response = await this.second_opinion_client.get(ApiRoutes.markAsCanceled(id));
        return response.data;
    }
    async markInvoiceAsPaid(id: number): Promise<InvoiceRequestData> {
        const response = await this.second_opinion_client.get(ApiRoutes.markAsPaid(id));
        return response.data;
    }
    async getInvoiceForAppointment(id: number): Promise<InvoiceRequestData[]> {
        const response = await this.second_opinion_client.get(ApiRoutes.invoiceForAppointmentRoute(id));
        return response.data.results;
    }

    async getInvoices(
        params: {
            page_size?: number;
            current_page?: number;
            date_from?: string;
            date_to?: string;
            date_due_from?: string;
            date_due_to?: string;
            status?: InvoiceStatus;
            search_params?: string;
        } = {},
    ): Promise<InvoiceRequestDataWithMetadata> {
        const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value != null));
        const response = await this.second_opinion_client.get(ApiRoutes.invoices(filteredParams));
        return response.data;
    }

    async sentInvoiceReminder(invoiceReminder: InvoiceReminderRequestData): Promise<InvoiceReminderData> {
        const response = await this.second_opinion_client.post(ApiRoutes.invoiceSendReminder, invoiceReminder);
        return response.data;
    }

    async addInvoice(invoice: InvoiceRequestData): Promise<Invoice> {
        const response = await this.second_opinion_client.post(ApiRoutes.invoiceRoute, invoice);
        return response.data;
    }

    async getDoctorProfile(): Promise<UserDoctor> {
        const res = await this.second_opinion_client.get(ApiRoutes.getDoctor);
        return res.data;
    }

    async updateDoctorProfile(doctor: UserDoctor): Promise<UserDoctor> {
        const res = await this.second_opinion_client.post(ApiRoutes.updateDoctor, doctor);
        return res.data;
    }

    async addDoctorProfile(doctor: UserDoctor): Promise<UserDoctor> {
        const res = await this.second_opinion_client.post(ApiRoutes.createDoctor, doctor);
        return res.data;
    }

    async getBankName(bankNumber: string): Promise<BankData[]> {
        const res = await this.second_opinion_client.get(ApiRoutes.getBankName(bankNumber));
        return res.data;
    }

    async uploadBankData(csvContent: string): Promise<string> {
        const res = await this.second_opinion_client.post(ApiRoutes.uploadBankData, csvContent);
        return res.data;
    }

    async getErrorLogs(params: SearchParamsComplete): Promise<ErrorLogResponse> {
        const res = await this.second_opinion_client.get(ApiRoutes.getAllErrors(params));
        return res.data;
    }

    async markErrorResolved(error_log_id: number): Promise<ErrorLog> {
        const res = await this.second_opinion_client.get(ApiRoutes.resolveError(error_log_id));
        return res.data;
    }
}
