import { healthcareServiceFactory } from 'api/factories/healthcareServiceFactory';
import { useOrganizationLocations } from '../../../web-kit/hooks/useOrganizationLocations';
import { useCallback, useEffect, useState } from 'react';
import { api } from '../../../api';
import { idNameToOption, Option } from '../../../utils/formUtils';

export const useEditSlotFieldOptions = () => {
    const organizationLocations = useOrganizationLocations();
    const [secondOpinionReason, setSecondOpinionReason] = useState<Option>();
    const healthcareServices = organizationLocations.map((x) => x.service_set).flat(1);
    const healthcareServiceOptions = healthcareServices.map(healthcareServiceFactory);

    const getSecondOpinionReason = useCallback(async () => {
        const res = await api.getAppointmentReasons();
        const options: Option[] = res.map(idNameToOption);

        setSecondOpinionReason(options[2]);
    }, []);

    const healthcareOptionsToOptionsArr = (): Option[] => {
        const options: Option[] = [];
        healthcareServiceOptions.forEach((h) => {
            options.push({
                value: h.value.toString(),
                label: h.label,
            });
        });
        return options;
    };

    const fetchSecondOpinionReason = () => {
        getSecondOpinionReason();
    };

    return { healthcareServiceOptions, secondOpinionReason, fetchSecondOpinionReason };
};
