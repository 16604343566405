import React from 'react';
import { useModalState } from '../../../../shared/BetterModal/useModalState';
import { BetterModal } from '../../../../shared/BetterModal/BetterModal';
import { DeleteSlotBody } from './DeleteSlotBody';
import { SlotProps, SlotValues } from '../EditSlotForm.types';
import { FormikProps } from 'formik';
import { SlotEditScope } from '../../../../../api/models';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

type Props = {
    props: SlotProps & FormikProps<SlotValues>;
};
export const DeleteSlotModal = ({ props }: Props) => {
    const modalState = useModalState();
    const { t } = useTranslation();
    const recurring = props.activeSlot?.is_recurring || false;

    const handleSubmit = (selectedOption: SlotEditScope) => {
        props.deleteSlot(selectedOption);
        modalState.toggleModal();
    };

    return (
        <BetterModal
            state={modalState}
            minWidth={400}
            title={
                recurring
                    ? t('editSlotModal:deleteSlotModalBody.title_recurring')
                    : t('editSlotModal:deleteSlotModalBody.title')
            }
            button={
                <Button fullWidth onClick={modalState.toggleModal} color="danger">
                    {t('buttons:button_delete')}
                </Button>
            }
            body={
                <DeleteSlotBody
                    recurring={recurring}
                    baseProps={props}
                    handleCancel={modalState.toggleModal}
                    handleSubmit={handleSubmit}
                    isLoading={props.request.isLoading}
                />
            }
        />
    );
};
