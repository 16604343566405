import { Moment } from 'moment';
import { AppointmentModel, AppointmentStatus } from './models';
import { BookingStatus } from '../components/Dashboard/StatusBadge/StatusBadge';
import moment from 'moment';

export const constants = {
    pagination: 20,
    dateISOFormat: 'YYYY-MM-DD',
    timeFormat: 'HH:mm',
    dateISOFormatWithTimezone: 'YYYY-MM-DDTHH:mm:ssZ',
};

export const formatISODate = (moment: Moment): string => {
    return moment.format(constants.dateISOFormat);
};

export const formatISODateTime = (moment: Moment): string => {
    return `${moment.format(constants.dateISOFormatWithTimezone)}`;
};

export const getFullName = (
    data: { personal_title: string; first_name: string; last_name: string } | undefined,
    skipTitle?: boolean,
) => {
    if (data === undefined) return '';

    const personalTitle = skipTitle ? '' : data.personal_title;
    return `${personalTitle || ''} ${data.first_name} ${data.last_name}`.trim();
};

export const getInitials = (data: { first_name: string; last_name: string } | undefined): string => {
    return data === undefined ? '' : data.first_name[0] + data.last_name[0];
};

export const getAppointmentState = (appointment: AppointmentModel): BookingStatus => {
    const status = appointment.status;
    const dateFrom = new Date(appointment.date_from);

    if (status === AppointmentStatus.CANCELLED) {
        return BookingStatus.cancelled;
    }

    if (moment(dateFrom).isAfter(new Date())) {
        return BookingStatus.upcoming;
    }

    return BookingStatus.past;
};

export const getTranslatedAppointmentStatus = (appointment: AppointmentModel, t: (key: string) => string) => {
    if (appointment === undefined) return 'ERROR';

    switch (getAppointmentState(appointment)) {
        case BookingStatus.upcoming:
            return t('statusBadge:upcoming');
        case BookingStatus.past:
            return t('statusBadge:past');
        case BookingStatus.cancelled:
            return t('statusBadge:cancelled');
        default:
            return 'ERROR';
    }
};

/**
 * @param dateStart
 * @param dateEnd
 * @returns Median date between two dates
 */
export const getMedianDate = (dateStart: Date, dateEnd: Date) =>
    new Date((dateStart.getTime() + dateEnd.getTime()) / 2);

/**
 * @param o: Object to extract property from
 * @param propertyName: string key of object
 * @returns Value from object selected by string
 */
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}
