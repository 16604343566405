import React from 'react';
import { t } from 'i18next';

import Box from '@mui/joy/Box';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/joy';
import { rightReserved } from './klaroConstants';

interface KlaroLinksProps {
    termsAndConditionsLink: string;
    privatePolicyLink: string;
    impressumLink: string;
    showConsentModal?: () => void;
    flexDirection?: 'column' | 'row';
    flexDirectionLink?: 'column' | 'row';
    alignItems?: 'center' | 'start' | 'end';
    justifyContent?: 'space-between' | 'center' | 'start' | 'end';
    gap?: number;
    copyrightPosition?: 'top' | 'bottom';
}

export const KlaroLinks = ({
    termsAndConditionsLink,
    privatePolicyLink,
    impressumLink,
    showConsentModal,
    flexDirection = 'row',
    flexDirectionLink = 'column',
    alignItems = 'center',
    justifyContent = 'center',
    gap = 8,
    copyrightPosition = 'bottom',
}: KlaroLinksProps) => {
    const theme = useTheme();

    const Dot = () => (
        <Box
            sx={{
                height: '4px',
                width: '4px',
                backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
                borderRadius: '50%',
                alignSelf: 'center',
                margin: '0 4px',
            }}
        />
    );

    const Links = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: flexDirectionLink,
                alignItems: alignItems,
                gap: `${gap}px`,
            }}
        >
            <Typography level="body-xs">
                <Link href={termsAndConditionsLink} target="_blank" rel="noopener noreferrer" color="neutral">
                    {t('policy_notes:terms_and_conditions')}
                </Link>
            </Typography>
            {flexDirectionLink === 'row' && <Dot />}
            <Typography level="body-xs">
                <Link href={privatePolicyLink} target="_blank" rel="noopener noreferrer" color="neutral">
                    {t('policy_notes:private_policy')}
                </Link>
            </Typography>
            {flexDirectionLink === 'row' && <Dot />}
            <Typography sx={{ cursor: 'pointer' }} onClick={showConsentModal} level="body-xs">
                {t('policy_notes:cookies_settings')}
            </Typography>
            {flexDirectionLink === 'row' && <Dot />}
            <Typography level="body-xs">
                <Link href={impressumLink} target="_blank" rel="noopener noreferrer" color="neutral">
                    {t('policy_notes:impressum')}
                </Link>
            </Typography>
        </Box>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: flexDirection,
                justifyContent: justifyContent,
                alignItems: alignItems,
                width: '100%',
            }}
        >
            {copyrightPosition === 'top' && (
                <Typography level="body-xs" sx={{ alignSelf: 'center' }}>
                    {rightReserved}
                </Typography>
            )}
            {Links()}
            {copyrightPosition === 'bottom' && (
                <Typography level="body-xs" sx={{ alignSelf: 'center', marginTop: '8px' }}>
                    {rightReserved}
                </Typography>
            )}
        </Box>
    );
};
