import { EditDoctorProps, EditDoctorValues } from './EditDoctor.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';

export const mapPropsToValues = (props: EditDoctorProps): EditDoctorValues => {
    return {
        bank_number: props.doctor?.bank_number || '',
        city: props.doctor?.city || '',
        fax_number: props.doctor?.fax_number || '',
        id_medrefer: props.doctor?.id_medrefer || '',
        name: props.doctor?.name || '',
        personal_title: props.doctor?.personal_title || '',
        phone_number: props.doctor?.phone_number || '',
        post_code: props.doctor?.post_code || '',
        street: props.doctor?.street || '',
        surname: props.doctor?.surname || '',
        vat_id: props.doctor?.vat_id || '',
        description: props.doctor?.description || '',
        image: props.doctor?.image,
    };
};
export const validationSchema = ({ t }: EditDoctorProps) =>
    Yup.object().shape({
        name: Yup.string().required(t('invoice:error_field_required')),
        surname: Yup.string().required(t('invoice:error_field_required')),
        terms_and_conditions: Yup.boolean().isTrue(t('profile:terms_and_conditions_accept')),
        privacy_policy: Yup.boolean().isTrue(t('profile:data_privacy_accept')),
        street: Yup.string().required(t('invoice:error_field_required')),
        phone_number: Yup.string().required(t('invoice:error_field_required')).max(18, t('invoice:error_tel_length')),
        city: Yup.string().required(t('invoice:error_field_required')),
        post_code: Yup.string()
            .required(t('invoice:error_field_required'))
            .length(5, t('invoice:error_postal_code_length')),
        bank_number: Yup.string()
            .required(t('invoice:error_field_required'))
            .min(16, t('invoice:error_bank_nr_length'))
            .max(35, t('invoice:error_bank_nr_length')),
        description: Yup.string().max(1500, t('editDoctorModal:error_description_too_long')),
    });

export const handleSubmit = (values: EditDoctorValues, { props }: FormikBag<EditDoctorProps, EditDoctorValues>) => {
    props.handleSubmit(values);
};
