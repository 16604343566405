export const idNameToOption = ({ id, name }: { id: number | string; name: string }): Option => {
    return {
        value: id.toString(),
        label: name,
    };
};

export const numericOptionsToOptions = (numericOptions: NumericOption[]): Option[] => {
    const options: Option[] = [];
    numericOptions.forEach((o) => {
        options.push({
            label: o.label,
            value: o.value.toString(),
        });
    });
    return options;
};

export interface Option<V extends string = string> {
    label: string;
    value: V;
}

export type NumericOption<V extends number = number> = {
    label: string;
    value: V;
};

export const getDateXDaysAgo = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return formatDateCustomISO(date);
};

export const formatDateCustomISO = (date: Date): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    const time = '01:00:00.000000';

    return `${year}-${formattedMonth}-${formattedDay}T${time}`;
};
