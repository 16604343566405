import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { handleSubmit, mapPropsToValues, validationSchema } from './EditInvoiceItem.schema';
import { InvoiceProps, InvoiceValues } from './EditInvoiceItem.types';
import { EditInvoiceItemData } from './EditInvoiceItemData';

const EditInvoiceItemForm = withFormik<InvoiceProps, InvoiceValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(EditInvoiceItemData);
export default withTranslation()(EditInvoiceItemForm);
