import { ColorSystemOptions, SupportedColorScheme } from '@mui/material';

export const ColorStylesMaterialUI: Partial<Record<SupportedColorScheme, ColorSystemOptions>> = {
    dark: {
        palette: {
            background: {
                default: '#131517',
                defaultChannel: '#131517',
                paper: '#131517',
            },
            primary: {
                700: '#5218FA',
                600: '#6734fe',
                500: '#320d9d',
            },
            error: {
                500: '#ae1a1a',
            },
            action: {
                disabled: '#171A1C',
            },
            text: {
                disabled: '#43494f',
            },
            grey: {
                500: '#31373d',
            },
        },
    },
    light: {
        palette: {
            background: {
                defaultChannel: '#F5F9FC',
                default: '#F5F9FC',
                paper: '#F5F9FC',
            },
            primary: {
                50: '#f0f4f8',
                100: '#fce4e4',
                200: '#d9e0e8',
                500: '#5218FA',
                600: '#6734fe',
                700: '#320d9d',
            },
            error: {
                500: '#f09898',
                600: '#ff5339',
                700: '#e12c2c',
            },
            action: {
                disabled: '#dde7ee',
            },
            text: {
                disabled: '#c0c5ca',
                primary: '#32383e',
            },
            grey: {
                500: '#31373d',
            },
        },
    },
};
