export interface InvoiceRequestDataWithMetadata {
    results: InvoiceRequestData[];
    metadata?: Metadata;
}

export interface InvoiceItem {
    name: string;
    price: number;
    factor: number;
    max_factor?: number;
    vat: number;
    readonly factor_is_constant: boolean;
    number: number;
}

export interface InvoiceItemRequestData {
    price_paid: number;
    factor: number;
    date_of_service: Date;
    number: number;
    description: string;
    id_bill: number;
    id: number;
    vat: number;
}
export interface InvoiceRequestPatientData {
    id_medrefer: number;
    name: string;
    surname: string;
    city: string;
    street: string;
    post_code: string;
    email: string;
    id?: number;
    personal_title?: string;
}
export interface InvoiceRequestDoctorData {
    personal_title: string;
    name: string;
    surname: string;
    vat_id?: string;
    phone_number: string;
    fax_number: string;
    bank_number: string;
    id_medrefer: number;
    city: string;
    street: string;
    post_code: string;
    email: string;
    id?: number;
}
export enum InvoiceStatus {
    All = 'ALL',
    Pending = 'PENDING',
    Paid = 'PAID',
    Cancelled = 'CANCELLED',
    Overdue = 'OVERDUE',
}
export interface InvoiceRequestData {
    idBill: number;
    invoiceNr: string;
    bill_number: string;
    invoiceDate: Date;
    invoiceDueDate: Date;
    diagnosis: string;
    total_price: number;
    legal_note: string;
    bank_name: string;
    id_appointment: number;
    items: InvoiceItemRequestData[];
    idMedreferPatient: number;
    patient: InvoiceRequestPatientData;
    doctor: InvoiceRequestDoctorData;
    status?: InvoiceStatus;
    file?: string;
    emailSent?: boolean;
}
export interface Invoice {
    doctorTitle: string;
    doctorName: string;
    doctorSurname: string;
    doctorAddress: string;
    doctorAddress2: string;
    doctorPostalCode: string;
    doctorTel: string;
    doctorFax: string;
    doctorEmail: string;
    doctorVatID: string;
    doctorBankNr: string;

    patientName: string;
    patientSurname: string;
    patientTitle: string;
    patientAddress: string;
    patientAddress2: string;
    patientPostalCode: string;

    invoiceNr: string;
    invoiceDate: Date;
    invoiceDueDate: Date;

    bank_name: string;
    diagnosis: string;

    items?: InvoiceItem[];
}

export interface InvoiceItemData {
    number: number;
    description: string;
    price: number;
    max_factor?: number;
    factor_is_constant: boolean;
    id: number;
    vat: number;
}

export interface InvoiceReminderRequestData {
    idBill: number;
    file: string | undefined;
    isReminder: boolean;
}

export interface InvoiceReminderData {
    idBill: number;
    message: string;
}

export interface Metadata {
    count_by_billing_history_status: {
        [key in InvoiceStatus]?: number;
    };
    pagination: Pagination;
}

export interface Pagination {
    current_page: number;
    page_size: number;
    total_pages: number;
    total_count: number;
    has_previous_page: boolean;
    has_next_page: boolean;
}

export interface QueryInvoiceParamsType {
    page_size?: number;
    current_page?: number;
    date_from?: string;
    date_to?: string;
    date_due_from?: string;
    date_due_to?: string;
    status?: InvoiceStatus;
    search_params?: string;
    sort_by?: string;
    descending?: boolean;
}

export enum InvoiceSortingOptions {
    InvoiceNr = 'invoiceNr',
    InvoiceDate = 'invoiceDate',
    InvoiceDateDue = 'invoiceDateDue',
    PatientName = 'patientName',
    TotalPrice = 'totalPrice',
}
