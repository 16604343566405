import React, { ReactNode, useState } from 'react';
import { BetterModal } from '../../shared/BetterModal/BetterModal';
import { useModalState } from '../../shared/BetterModal/useModalState';
import { InvoiceUiMode } from './EditInvoiceItem.types';
import { useTranslation } from 'react-i18next';
import { InvoiceItemData } from '../../../api/models';
import EditInvoiceForm from './EditInvoiceItemForm';
import { ApiRequest } from '../../../hooks/useApiRequest';
import ConfirmModal from '../../shared/ConfirmModal/ConfirmModal';

type Props = {
    button: ReactNode;
    mode: InvoiceUiMode;
    invoice?: InvoiceItemData | undefined;
    request: ApiRequest;
    addInvoice: (data: InvoiceItemData) => Promise<void>;
    editInvoice: (data: InvoiceItemData) => Promise<void>;
    refreshItems: () => void;
};
export const EditInvoiceItemModal = ({
    button,
    editInvoice,
    mode,
    invoice,
    request,
    addInvoice,
    refreshItems,
}: Props) => {
    const modalState = useModalState();
    const { t } = useTranslation();
    const actionConfirmationModalState = useModalState();
    const [submitData, setSubmitData] = useState<InvoiceItemData | undefined>(undefined);

    const handleSubmit = (data: InvoiceItemData) => {
        setSubmitData(data);
        actionConfirmationModalState.toggleModal();
    };

    const confirmSubmit = async (): Promise<void> => {
        if (!submitData) return Promise.reject();

        const action = mode === 'edit' ? editInvoice : addInvoice;
        return action(submitData)
            .then(() => refreshItems())
            .finally(() => modalState.toggleModal());
    };

    return (
        <>
            <ConfirmModal
                modalDescription={
                    mode === 'edit' ? t('adminPage:message_edit_invoice') : t('adminPage:message_add_invoice')
                }
                successAlertDescription={
                    mode === 'edit'
                        ? t('adminPage:message_edit_invoice_success')
                        : t('adminPage:message_add_invoice_success')
                }
                onConfirm={confirmSubmit}
                modalState={actionConfirmationModalState}
            />
            <BetterModal
                state={modalState}
                title={t(`adminPage:label_invoice_${mode}`)}
                button={button}
                body={<EditInvoiceForm request={request} handleSubmit={handleSubmit} mode={mode} invoice={invoice} />}
            />
        </>
    );
};
