import React, { Fragment, ReactNode } from 'react';
import { List, ListItemButton, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../Auth/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { filterRoutes, routeArrToRouteStr } from './routeUtils';
import { RouteInfo } from './routes.types';

type Props = {
    listOrientation: 'horizontal' | 'vertical';
    listItemOrientation: 'horizontal' | 'vertical';
    onPickedRoute?: () => void;
};
export const RoutingList = ({ listOrientation, listItemOrientation, onPickedRoute }: Props) => {
    const { t } = useTranslation();
    const authContext = useAuthContext();
    const navigate = useNavigate();
    const isListItemVertical = listItemOrientation === 'vertical';

    const listItemButtonStyled = (element: RouteInfo, index: number, array: RouteInfo[]): ReactNode => (
        <ListItemButton
            style={{
                width: isListItemVertical
                    ? `calc(100% / ${array.filter((r) => r.menuDisplay === 'show').length})`
                    : '100%',
                height: isListItemVertical ? '100%' : 'min-content',
            }}
            key={index}
            orientation={listItemOrientation}
            selected={window.location.pathname.includes(routeArrToRouteStr(element))}
            onClick={(e) => {
                e.stopPropagation();
                navigate(routeArrToRouteStr(element));
                onPickedRoute?.();
            }}
            sx={{
                '&.Mui-selected': {
                    background: 'var(--joy-palette-background2)',
                    color: 'var(--joy-palette-secondary-500)',
                },
                ':active': {
                    background: 'var(--joy-palette-background2)',
                    color: 'var(--joy-palette-secondary-500)',
                },
            }}
        >
            {element.icon}
            <Typography sx={{ mt: isListItemVertical ? -1.3 : 0, fontSize: 12 }} level="body-xs">
                {element.name}
            </Typography>
        </ListItemButton>
    );

    return (
        <List
            size="lg"
            orientation={listOrientation}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: listOrientation === 'horizontal' ? 'center' : 'start',
            }}
        >
            {filterRoutes(t, { userRole: authContext?.authUser?.role || null, menuDisplay: 'show' }).map(
                (element, index, array) => (
                    <Fragment key={index}>
                        <Fragment>{listItemButtonStyled(element, index, array)}</Fragment>
                    </Fragment>
                ),
            )}
        </List>
    );
};
