import { useMemo } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';

import { api } from '../../../api';
import { DashboardStatistics } from 'api/models/DashboardStatisticsModel';
import { useErrorContext } from 'context/errorContext';
import { dashboardQueryKey } from './dashboard.query-keys';

export const useDashboardStatistics = () => {
    const { error, setError } = useErrorContext();

    const getDashboardStatistics = async () => {
        try {
            const requestStatistics = api.getStatistics();

            return await Promise.resolve(requestStatistics);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            } else {
                setError({ message: 'Failed to fetch statistics' });
            }
            throw error;
        }
    };

    const { data, isLoading, isError } = useQuery({
        queryFn: async () => getDashboardStatistics(),
        queryKey: [dashboardQueryKey.statistics()],
        staleTime: 1 * 60 * 1000,
        retry: 3,
        refetchInterval: 1 * 60 * 1000,
        keepPreviousData: true,
    });

    const dashboardStatistics: DashboardStatistics = useMemo(
        () => ({
            total_appointments: data?.total_appointments ?? 0,
            today_appointments: data?.today_appointments ?? 0,
            month_appointments: data?.month_appointments ?? 0,
            week_appointments: data?.week_appointments ?? 0,
        }),
        [data],
    );

    return { dashboardStatistics, isLoading, error };
};
