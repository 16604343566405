import i18n from '../i18n/i18n';
import { UserRole } from '../api/models';
export const getActiveLanguage = () => {
    return i18n.languages[0];
};

export const translateUserRole = (role: UserRole | undefined) => {
    switch (role) {
        case UserRole.patient:
            return i18n.t('userRole:patient');
        case UserRole.organization:
            return i18n.t('userRole:organization');
        case UserRole.organizationMember:
            return i18n.t('userRole:organization_member');
        case UserRole.cooperator:
            return i18n.t('userRole:cooperator');
        case UserRole.admin:
            return i18n.t('userRole:admin');
        default:
            return 'Unknown role';
    }
};
