import * as Yup from 'yup';
import { InvitationRegisterFormProps, InvitationRegisterFormValues } from './InvitationRegister.types';
import { FormikBag } from 'formik';
export const mapPropsToValues = (props: InvitationRegisterFormProps): InvitationRegisterFormValues => {
    return {
        bank_number: '',
        city: '',
        fax_number: '',
        id_medrefer: '',
        name: '',
        personal_title: '',
        phone_number: '',
        post_code: '',
        privacy_policy: false,
        street: '',
        surname: '',
        terms_and_conditions: false,
        vat_id: '',
        password: '',
        invitation_token: props.invitationToken,
    };
};

export const validationSchema = ({ t }: InvitationRegisterFormProps) =>
    Yup.object().shape({
        name: Yup.string().required(t('invoice:error_field_required')),
        surname: Yup.string().required(t('invoice:error_field_required')),
        terms_and_conditions: Yup.boolean().isTrue(t('profile:terms_and_conditions_accept')),
        privacy_policy: Yup.boolean().isTrue(t('profile:data_privacy_accept')),
        street: Yup.string().required(t('invoice:error_field_required')),
        phone_number: Yup.string()
            .required(t('invoice:error_field_required'))
            .max(18, t('invoice:error_tel_length'))
            .min(15, t('invoice:error_tel_length')),
        city: Yup.string().required(t('invoice:error_field_required')),
        post_code: Yup.string()
            .required(t('invoice:error_field_required'))
            .length(5, t('invoice:error_postal_code_length')),
        bank_number: Yup.string()
            .required(t('invoice:error_field_required'))
            .min(16, t('invoice:error_bank_nr_length'))
            .max(35, t('invoice:error_bank_nr_length')),
        password: Yup.string()
            .min(3, t('login:warning_password_min_length'))
            .required(t('invoice:error_field_required')),
    });

export const handleSubmit = (
    values: InvitationRegisterFormValues,
    { props }: FormikBag<InvitationRegisterFormProps, InvitationRegisterFormValues>,
) => {
    props.handleSubmit(values);
};
