import axios from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { api } from '../../api';
import { useErrorContext } from 'context/errorContext';

export const useOrganizationLocations = () => {
    const navigate = useNavigate();
    const { setError } = useErrorContext();

    const getCalendarOrganizationLocations = async () => {
        try {
            const request = api.getCalendarOrganizationLocations();

            return await Promise.resolve(request);
        } catch (e) {
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            } else {
                setError({ message: 'Unexpected error occured. Please, refresh the page' });
            }
        }
    };

    const { data, isLoading, isError } = useQuery({
        queryFn: async () => getCalendarOrganizationLocations(),
        queryKey: ['calendar-organization-locations'],
        staleTime: Infinity,
        refetchOnMount: 'always',
        onError: (e) => {
            if (axios.isAxiosError(e)) {
                setError({ message: e.message, status: e.code });
            } else {
                setError({ message: 'Unexpected error occured. Please, refresh the page' });
            }
        },
    });

    const { queryData } = useMemo(() => {
        const queryData = data || [];
        return { queryData };
    }, [data]);

    return queryData;
};
