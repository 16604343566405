import React from 'react';

import { ResponsiveContainer, PieChart, Pie, Legend, Cell } from 'recharts';
import { useTranslation } from 'react-i18next';

import { truncateString } from 'utils/stringUtils';

type ChartData = {
    totalAppointments: number;
    freeSlots: number;
    isLoading: boolean;
};

export const SlotChart = ({ totalAppointments, freeSlots }: ChartData) => {
    const { t } = useTranslation();

    const data = [
        {
            id: 0,
            value: freeSlots || 0,
            name: t('dashboard:free_slots'),
        },
        { id: 1, value: totalAppointments || 0, name: t('dashboard:appointments') },
    ];

    const truncatedData = data.map((item) => ({
        ...item,
        label: truncateString(item.name, 20),
    }));

    const COLORS = ['#3640E1', '#E13640'];

    return (
        <ResponsiveContainer width="100%" height="75%">
            <PieChart>
                <Pie dataKey="value" data={truncatedData} label={({ value }) => ` ${value}`}>
                    {truncatedData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
};
