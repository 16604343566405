import React, { useEffect, useState } from 'react';
import { Chip, Stack, Tab, tabClasses, TabList, TabPanel, Tabs } from '@mui/joy';
import { AppointmentList } from './AppointmentList/AppointmentList';
import { useTranslation } from 'react-i18next';
import { AppointmentModel, InvoiceRequestData } from '../../../api/models';
import { DynamicHeight } from '../../shared/DynamicHeight/DynamicHeight';
import { useWindowSize } from '../../shared/hooks/useWindowSize';
import { NumberParam, useQueryParam } from 'use-query-params';
import { MyAppointmentsData } from '../../../hooks/constants';
import { getScaledVh } from '../../../utils/styleUtils';
import { api } from '../../../api';
import { AppointmentRevalidation } from 'hooks/useAppointments';

type Props = {
    pickAppointment: (appointment: AppointmentModel) => void;
    pickedAppointment: AppointmentModel | undefined;
    pastAppointments: MyAppointmentsData;
    cancelledAppointments: MyAppointmentsData;
    upcomingWithOngoingAppointments: MyAppointmentsData;
    loadMoreCancelledAppointments: (prev: AppointmentModel[]) => void;
    loadMorePastAppointments: (prev: AppointmentModel[]) => void;
    reloadAppointments: () => void;
    loadMoreUpcomingAppointments: (prev: AppointmentModel[]) => void;
    headerHeight: number;
    invoices: InvoiceRequestData[];
    isLoadMoreLoading: { [key in AppointmentRevalidation]: boolean };
};
export const AppointmentsTabs = ({
    pickAppointment,
    pickedAppointment,
    pastAppointments,
    cancelledAppointments,
    upcomingWithOngoingAppointments,
    loadMoreCancelledAppointments,
    loadMorePastAppointments,
    loadMoreUpcomingAppointments,
    reloadAppointments,
    headerHeight,
    invoices,
    isLoadMoreLoading,
}: Props) => {
    const { t } = useTranslation();
    const { isExtraSmall } = useWindowSize();
    const [appointmentId, setAppointmentId] = useQueryParam('appId', NumberParam);
    const [index, setIndex] = useState<number>(0);

    useEffect(() => {
        if (appointmentId) {
            const allAppointments = [
                ...pastAppointments.appointments,
                ...cancelledAppointments.appointments,
                ...upcomingWithOngoingAppointments.appointments,
            ];
            const picked = allAppointments.find((app) => app.id === appointmentId);
            if (picked) {
                pickAppointment(picked);
                if (cancelledAppointments.appointments.find((app) => app.id == picked.id)) {
                    setIndex(2);
                }
                if (pastAppointments.appointments.find((app) => app.id == picked.id)) {
                    setIndex(1);
                }
                if (upcomingWithOngoingAppointments.appointments.find((app) => app.id == picked.id)) {
                    setIndex(0);
                }
                setAppointmentId(null);
            } else {
                api.getAppointment(appointmentId).then((res) => {
                    pastAppointments.appointments.push(res);
                    pickAppointment(res);
                    setIndex(1);
                });
            }
        }
    }, [
        appointmentId,
        cancelledAppointments.appointments,
        pastAppointments.appointments,
        pickAppointment,
        setAppointmentId,
        upcomingWithOngoingAppointments.appointments,
    ]);

    return (
        <Tabs
            aria-label="Sticky tabs"
            sx={{
                width: '100%',
                maxHeight: getScaledVh(100, isExtraSmall, headerHeight),
                overflowY: 'auto',
                '&::-webkit-scrollbar': { display: 'none' },
            }}
            variant="outlined"
            defaultValue={index}
            value={index}
            onChange={(event, value) => setIndex(value as number)}
        >
            <TabList
                key="tablist"
                sticky="top"
                sx={{
                    '&::-webkit-scrollbar': { display: 'none' },
                    [`&& .${tabClasses.root}`]: {
                        bgcolor: 'transparent',
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                        [`&.${tabClasses.selected}`]: {
                            color: 'primary.plainColor',
                            '&::after': {
                                height: 2,
                                borderTopLeftRadius: 3,
                                borderTopRightRadius: 3,
                                bgcolor: 'primary.500',
                            },
                        },
                    },
                }}
            >
                <Stack
                    direction="row"
                    style={{ overflowX: 'auto', overflowY: 'hidden' }}
                    sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
                >
                    <div style={{ marginLeft: 'auto' }}>
                        <AppointmentTab
                            id={0}
                            index={index}
                            count={upcomingWithOngoingAppointments.totalCount}
                            text={t('appointmentList:label_appointments_upcoming')}
                        />
                    </div>
                    <AppointmentTab
                        id={1}
                        index={index}
                        text={t('appointmentList:label_appointments_past')}
                        count={pastAppointments.totalCount}
                    />
                    <div style={{ marginRight: 'auto' }}>
                        <AppointmentTab
                            id={2}
                            index={index}
                            text={t('appointmentList:label_appointments_canceled')}
                            count={cancelledAppointments.totalCount}
                        />
                    </div>
                </Stack>
            </TabList>
            <DynamicHeight style={{ overflowY: 'auto' }}>
                <TabPanel value={0}>
                    <AppointmentList
                        isMobile
                        appointmentsData={upcomingWithOngoingAppointments}
                        pickAppointment={pickAppointment}
                        pickedAppointment={pickedAppointment}
                        reloadAppointments={reloadAppointments}
                        loadMore={loadMoreUpcomingAppointments}
                        noItemsMessage={t('appointmentList:message_appointments_upcoming_empty')}
                        invoices={invoices}
                        isLoading={isLoadMoreLoading[AppointmentRevalidation.UPCOMING]}
                    />
                </TabPanel>
                <TabPanel value={1}>
                    <AppointmentList
                        isMobile
                        appointmentsData={pastAppointments}
                        pickAppointment={pickAppointment}
                        pickedAppointment={pickedAppointment}
                        loadMore={loadMorePastAppointments}
                        reloadAppointments={reloadAppointments}
                        noItemsMessage={t('appointmentList:message_appointments_past_empty')}
                        invoices={invoices}
                        isLoading={isLoadMoreLoading[AppointmentRevalidation.PAST]}
                    />
                </TabPanel>
                <TabPanel value={2}>
                    <AppointmentList
                        isMobile
                        appointmentsData={cancelledAppointments}
                        pickAppointment={pickAppointment}
                        pickedAppointment={pickedAppointment}
                        reloadAppointments={reloadAppointments}
                        loadMore={loadMoreCancelledAppointments}
                        noItemsMessage={t('appointmentList:message_appointments_canceled_empty')}
                        invoices={invoices}
                        isLoading={isLoadMoreLoading[AppointmentRevalidation.CANCELLED]}
                    />
                </TabPanel>
            </DynamicHeight>
        </Tabs>
    );
};

type AppointmentTabProps = {
    id: number;
    index: number;
    text: string;
    count: number;
};
const AppointmentTab = ({ id, text, count, index }: AppointmentTabProps) => {
    return (
        <Tab sx={{ flex: 'none', scrollSnapAlign: 'start' }}>
            {text}
            <Chip size="sm" variant="solid" color={id === index ? 'primary' : 'neutral'}>
                {count}
            </Chip>
        </Tab>
    );
};
